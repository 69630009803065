import React, { useEffect } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

const HARD_PACK = 255; // Example value for HARD_PACK

const VehicleInformation = ({ formData, onChange }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  useEffect(() => {
    if (formData.DealType === 'New') {
      const invoice = parseFloat(formData.Invoice) || 0;
      onChange('GLVehicleCost', invoice + HARD_PACK);
    }
  }, [formData.DealType, formData.Invoice, onChange]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth required>
          <InputLabel>Deal Type</InputLabel>
          <Select
            name="DealType"
            value={formData.DealType || ''}
            onChange={handleChange}
            label="Deal Type"
          >
            <MenuItem value="New">New</MenuItem>
            <MenuItem value="Used">Used</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="StockNo"
          label="Stock Number"
          value={formData.StockNo || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      {/* Year, Make and Model */}
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          name="Year"
          label="Year"
          value={formData.Year || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          name="Make"
          label="Make"
          value={formData.Make || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          name="Model"
          label="Model"
          value={formData.Model || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      {formData.DealType === 'New' && (
        <>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              name="MSRP"
              label="MSRP"
              type="number"
              value={formData.MSRP || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              name="Invoice"
              label="Invoice"
              type="number"
              value={formData.Invoice || ''}
              onChange={handleChange}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          name="GLVehicleCost"
          label="GL Vehicle Cost"
          type="number"
          value={formData.GLVehicleCost || ''}
          onChange={handleChange}
          required
        />
      </Grid>
    </Grid>
  );
};

export default VehicleInformation;
