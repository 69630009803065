// DeleteFeeDialog.js
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { API_BASE_URL } from 'config'; // Import the API_BASE_URL

const DeleteFeeDialog = ({ open, onClose, fee, refetchFees }) => {
  const handleDelete = async () => {
    try {
      // Make an API call to delete the fee
      const response = await fetch(`${API_BASE_URL}/api/fees/${fee._id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete fee');
      }

      // Refresh fees data
      refetchFees();

      // Close the dialog
      onClose();
    } catch (error) {
      console.error('Error deleting fee:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Fee</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Are you sure you want to delete the fee:{' '}
          <strong>{fee.Description}</strong>?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteFeeDialog;
