import React, { useState, useEffect, useMemo } from 'react';
import {
  DataGridPremium,
  GridToolbar,
  useGridApiRef,
  GRID_AGGREGATION_FUNCTIONS,
  GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';

import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Stack,
  Typography,
  Button,
  LinearProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

import DetailDialog from './DetailDialog';

import LaunchIcon from '@mui/icons-material/Launch';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { SparkLineChart } from '@mui/x-charts/SparkLineChart';

import { API_BASE_URL } from 'config';

import axios from 'axios';

// Define a formatter function for currency values
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

function findCommonSubstrings(descriptions) {
  // Split each description into words and store in an array of arrays
  const wordsInDescriptions = descriptions.map((desc) => desc.split(' '));

  // Find common words across all descriptions
  const commonWords = wordsInDescriptions.reduce((common, words, index) => {
    if (index === 0) {
      // For the first item, all words are potential common words
      return words;
    } else {
      // For subsequent items, filter out only the words that are common with the previous set
      return common.filter((word) => words.includes(word));
    }
  }, []);

  // Join the common words to form a common substring
  return commonWords.join(' ');
}

export default function AccountDataGrid() {
  const apiRef = useGridApiRef();
  const [expenses, setExpenses] = useState([]);
  const [header, setHeader] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreviewUrl, setFilePreviewUrl] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [processingStatus, setProcessingStatus] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [expenseRange, setExpenseRange] = useState(12);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentRowData, setCurrentRowData] = useState(null);

  const fetchExpenses = async () => {
    try {
      // Make a GET request to your backend endpoint
      const response = await axios.get(`${API_BASE_URL}/api/expenses`);

      const data = response.data;

      setHeader([data.header_info]); // Add this line

      // Filter the expenses data to only include those that are from 7000 to 7999
      const filteredAccountNumbers = data.expense_details.filter(
        (items) => items.Account >= 7000 && items.Account <= 7999,
      );

      // Create an array of objects with the filtered account numbers
      const rows = filteredAccountNumbers.map((item, index) => {
        return {
          id: index, // Assuming no natural unique ID, using index as fallback
          account: item.Account,
          description: item.Description,
          group: item.Group * 10, //Turn this 3 digit number into a 4 digit number
          department: item.Department,
          current: item.CurrentMonth,
          prior: item.PriorMonth,
          selectedMonth: item.SelectedMonth,
          ...item.Amounts, // Spread the monthly amounts directly into the row object
        };
      });

      setExpenses(rows);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching expenses:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExpenses();
  }, []);

  const selectedMonth = header.length > 0 ? header[0].extracted_month : 'JAN';
  const runDateMonth =
    header.length > 0 ? header[0].run_date.split(' ')[1] : '';
  const runDateYear =
    header.length > 0 ? header[0].run_date.split(' ').pop() : '';

  const selectedYear =
    selectedMonth === 'DEC' && runDateMonth === 'JAN'
      ? parseInt(runDateYear) - 1
      : parseInt(runDateYear);

  // Memoize getVisibleColumns result
  const visibleColumns = useMemo(() => {
    return getVisibleColumns(expenseRange, selectedMonth, selectedYear);
  }, [expenseRange, selectedMonth, selectedYear]);

  function getVisibleColumns(expenseRange, selectedMonth, year) {
    const months = [
      { field: 'JAN', headerName: 'JAN', type: 'number', width: 100 },
      { field: 'FEB', headerName: 'FEB', type: 'number', width: 100 },
      { field: 'MAR', headerName: 'MAR', type: 'number', width: 100 },
      { field: 'APR', headerName: 'APR', type: 'number', width: 100 },
      { field: 'MAY', headerName: 'MAY', type: 'number', width: 100 },
      { field: 'JUN', headerName: 'JUN', type: 'number', width: 100 },
      { field: 'JUL', headerName: 'JUL', type: 'number', width: 100 },
      { field: 'AUG', headerName: 'AUG', type: 'number', width: 100 },
      { field: 'SEP', headerName: 'SEP', type: 'number', width: 100 },
      { field: 'OCT', headerName: 'OCT', type: 'number', width: 100 },
      { field: 'NOV', headerName: 'NOV', type: 'number', width: 100 },
      { field: 'DEC', headerName: 'DEC', type: 'number', width: 100 },
    ];

    // Find the index of the selected month
    const monthIndex = months.findIndex((m) => m.field === selectedMonth);

    // Start the result with the selected month and current year
    let result = [
      {
        ...months[monthIndex],
        headerName: `${selectedMonth} ${year}`,
      },
    ];

    // Calculate and add the previous months based on the expenseRange
    for (let i = 1; i < expenseRange; i++) {
      let index = monthIndex - i;
      let currentYear = year;

      // Adjust the year and index for months in the previous year
      if (index < 0) {
        index += months.length;
        currentYear--;
      }

      // Add the month with the adjusted year to the result
      result.push({
        ...months[index],
        headerName: `${months[index].field} ${currentYear}`,
      });
    }
    return result;
  }

  const openDetailDialog = (rowData) => {
    console.log('Opening detail dialog for row:', rowData);
    setCurrentRowData(rowData);
    setIsDialogOpen(true);
  };

  // Define the function to update visible columns based on expenseRange and selectedMonth
  const columns = useMemo(() => {
    const descriptionColumns = [
      { field: 'group', headerName: 'Group', width: 75 },
      {
        field: 'account',
        headerName: 'Account',
        width: 150,
        renderCell: (params) => {
          return (
            <Button
              variant="text"
              color="primary"
              endIcon={<LaunchIcon />}
              onClick={() => openDetailDialog(params.row)}
            >
              {params.value}
            </Button>
          );
        },
      },
      { field: 'description', headerName: 'Description', width: 200 },
      { field: 'department', headerName: 'Department', width: 100 },
    ];
    const calculationColumns = [
      {
        field: 'Total',
        headerName: 'Total',
        type: 'number',
        width: 110,
        valueGetter: (value, row) => {
          const monthlyValues = visibleColumns.map((month) => {
            return row?.[month.field] || 0;
          });
          return monthlyValues.reduce((a, b) => a + b, 0);
        },
        valueFormatter: (value) => {
          if (!value) {
            return value;
          }
          return currencyFormatter.format(value);
        },
      },
      {
        field: 'Average',
        headerName: `Average`,
        type: 'number',
        width: 100,
        valueGetter: (value, row) => {
          const monthlyValues = visibleColumns.map(
            (month) => row?.[month.field] || 0, // Null check added
          );
          return (
            monthlyValues.reduce((a, b) => a + b, 0) / monthlyValues.length
          );
        },
        valueFormatter: (value) => {
          if (!value) {
            return value;
          }
          return currencyFormatter.format(value);
        },
      },
      {
        field: 'Change',
        headerName: 'Change',
        type: 'number',
        width: 100,
        valueGetter: (value, row) => {
          if (row) {
            const selectedMonthValue = row[row.selectedMonth];

            const monthlyValues = visibleColumns.map(
              (month) => row?.[month.field] || 0, // Null check added
            );

            // Take into account 0 values in the average calculation
            const averageValue =
              monthlyValues.reduce((a, b) => a + b, 0) / monthlyValues.length;

            const percentageChange =
              ((selectedMonthValue - averageValue) / averageValue) * 100;

            return percentageChange;
          } else {
            return NaN;
          }
        },
        renderCell: (params) => {
          const value = params.value;
          return (
            <div variant="body2" style={{ color: value < 0 ? 'red' : 'green' }}>
              {value.toFixed(2)}% {value < 0 ? '↓' : '↑'}
            </div>
          );
        },
        getSortValue: (params) => {
          return params.value;
        },
      },
      {
        field: 'PriorMonthChange',
        headerName: 'MoM',
        type: 'number',
        width: 100,
        valueGetter: (value, row) => {
          if (row) {
            const selectedMonthValue = row[row.selectedMonth];
            const priorMonthValue = getVisibleColumns(2, row.selectedMonth).map(
              (month) => row[month.field],
            )[1];

            // Check if either value is not a number or if priorMonthValue is 0 to avoid division by zero
            if (
              isNaN(selectedMonthValue) ||
              isNaN(priorMonthValue) ||
              priorMonthValue === 0
            ) {
              return NaN; // or another placeholder value that makes sense for your application
            }

            const percentageChange =
              ((selectedMonthValue - priorMonthValue) / priorMonthValue) * 100;

            // Optionally, handle extremely large percentage changes if needed
            if (!isFinite(percentageChange)) {
              return Infinity; // or another placeholder value
            }

            return percentageChange;
          } else {
            return NaN;
          }
        },
        renderCell: (params) => {
          const value = params.value;
          return (
            <div variant="body2" style={{ color: value < 0 ? 'red' : 'green' }}>
              {value.toFixed(2)}% {value < 0 ? '↓' : '↑'}
            </div>
          );
        },
      },
      {
        field: 'chart',
        headerName: 'Trend',
        valueGetter: (value, row) => {
          const monthlyValues = visibleColumns
            .map(
              (month) => row?.[month.field] || 0, // Null check added
            )
            .reverse();
          return monthlyValues;
        },
        renderCell: (params) => {
          const value = params.value;
          return <SparkLineChart data={value} showHighlight showTooltip />;
        },
        width: 100,
      },
      { field: 'selectedMonth', headerName: 'Selected Month', width: 100 },
    ];

    return [...descriptionColumns, ...visibleColumns, ...calculationColumns];
  }, [visibleColumns]);

  const trendLineAggregation = {
    // Get the cell value for each row, creating an object with month fields and their values
    getCellValue: ({ row }) => {
      return visibleColumns.reduce((acc, month) => {
        acc[month.field] = row[month.field] || 0;
        return acc;
      }, {});
    },

    // Aggregate all cell values across rows, summing up the values for each month
    apply: ({ values }) => {
      const reducedData = values.reduce((acc, curr) => {
        Object.keys(curr).forEach((month) => {
          acc[month] = (acc[month] || 0) + curr[month];
        });
        return acc;
      }, {});

      // Convert the aggregated object into an array of values for the chart
      const resultArray = Object.values(reducedData).reverse();

      return resultArray;
    },

    // Label for the aggregation, used as the column header or identifier
    label: 'chart',
  };

  const descriptionAggregation = {
    // Get the description cell value for each row, creating an object with month fields and their values
    getCellValue: ({ row }) => {
      return row.description;
    },

    // Aggregate all cell values across rows, summing up the values for each month
    apply: ({ values }) => {
      const descriptions = values.map((value) => value);
      const commonSubstring = findCommonSubstrings(descriptions);
      return commonSubstring;
    },

    // Label for the aggregation, used as the column header or identifier
    label: 'description',
  };

  const averageChangeAggregation = {
    getCellValue: ({ row }) => {
      return visibleColumns.reduce((acc, month) => {
        acc[month.field] = row[month.field] || 0;
        return acc;
      }, {});
    },

    apply: ({ values }) => {
      const monthlyValues = values.map((value) => {
        return Object.values(value);
      });

      const monthlySums = monthlyValues.reduce((acc, curr) => {
        curr.forEach((value, index) => {
          acc[index] = (acc[index] || 0) + value;
        });
        return acc;
      }, []);

      const selectedMonthIndex = visibleColumns.findIndex(
        (month) => month.field === selectedMonth,
      );
      const selectedMonthSum = monthlySums[selectedMonthIndex];
      const averageValue =
        monthlySums.reduce((a, b) => a + b, 0) / monthlySums.length;
      const averageChange =
        ((selectedMonthSum - averageValue) / averageValue) * 100;

      return averageChange;
    },

    label: 'change',
  };

  const monthOvermonthAggregation = {
    getCellValue: ({ row }) => {
      return visibleColumns.reduce((acc, month) => {
        acc[month.field] = row[month.field] || 0;
        return acc;
      }, {});
    },

    apply: ({ values }) => {
      const monthlyValues = values.map((value) => {
        return Object.values(value);
      });

      const monthlySums = monthlyValues.reduce((acc, curr) => {
        curr.forEach((value, index) => {
          acc[index] = (acc[index] || 0) + value;
        });
        return acc;
      }, []);

      const selectedMonthIndex = visibleColumns.findIndex(
        (month) => month.field === selectedMonth,
      );
      const selectedMonthSum = monthlySums[selectedMonthIndex];
      const priorMonthSum = monthlySums[selectedMonthIndex + 1];
      const averageChange =
        ((selectedMonthSum - priorMonthSum) / priorMonthSum) * 100;

      return averageChange;
    },

    label: 'MoM',
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreviewUrl(reader.result);
      };
      reader.readAsText(file);
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setFilePreviewUrl('');
    setDialogOpen(false);
  };

  const handleProcessFile = async () => {
    if (!selectedFile) return;

    setProcessingStatus('Submitting file...');
    setSnackbarOpen(true);

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await fetch(`${API_BASE_URL}/api/upload/expenses`, {
        method: 'POST',
        body: formData,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100,
          );
          setUploadProgress(progress);
        },
      });

      if (!response.ok) {
        throw new Error('Failed to upload file.');
      }

      setProcessingStatus('Processing file...');

      await response.json(); // Assuming server response doesn't contain relevant data

      // Reset state
      setSelectedFile(null);
      setFilePreviewUrl('');
      setUploadProgress(0);
      setProcessingStatus('File processed successfully.');
      // Refetch the expenses data
      fetchExpenses();
      setProcessingStatus('New Data Loaded Successfully!');
      setDialogOpen(false);
    } catch (error) {
      console.error(error);
      setProcessingStatus('Error processing file');
    }
  };

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['group'],
      },
      pinnedColumns: {
        left: [GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD],
      },
      columns: {
        columnVisibilityModel: {
          group: false,
          account: false,
          selectedMonth: false,
          department: false,
        },
      },
      aggregation: {
        model: {
          description: 'description',
          JAN: 'sum',
          FEB: 'sum',
          MAR: 'sum',
          APR: 'sum',
          MAY: 'sum',
          JUN: 'sum',
          JUL: 'sum',
          AUG: 'sum',
          SEP: 'sum',
          OCT: 'sum',
          NOV: 'sum',
          DEC: 'sum',
          Total: 'sum',
          Average: 'sum',
          Change: 'change',
          PriorMonthChange: 'mom',
          chart: 'trendLine',
        },
      },
    },
  });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          paddingBottom: 2,
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="caption" color="text.secondary">
            {header.length > 0 ? 'Month: ' + header[0].extracted_month : ''}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {header.length > 0 ? 'Run Date: ' + header[0].run_date : ''}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {header.length > 0 ? 'Run Time: ' + header[0].run_time : ''}
          </Typography>
        </Stack>
        <div style={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={2}>
          <Button
            variant="text"
            component="a"
            size="small"
            color="warning"
            href="/docs/CDK_Expense_Logging_How_To.pdf"
            download="/docs/CDK_Expense_Logging_How_To.pdf"
          >
            CDK How To
          </Button>
          <Button
            size="small"
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            onClick={() => setDialogOpen(true)}
          >
            Upload file
          </Button>
          <Box sx={{ minWidth: 200 }}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="expense-range-label">Expense Range</InputLabel>
              <Select
                labelId="expense-range-label"
                id="expense-range"
                value={expenseRange}
                onChange={(e) => setExpenseRange(parseInt(e.target.value, 10))}
                label="Expense Range"
              >
                <MenuItem value={3}>Last 3 Months</MenuItem>
                <MenuItem value={6}>Last 6 Months</MenuItem>
                <MenuItem value={12}>Last 12 Months</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </Box>
      {processingStatus && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={processingStatus}
        />
      )}

      <DataGridPremium
        sx={{
          backgroundColor: 'background.paper',
        }}
        apiRef={apiRef}
        rows={expenses}
        getRowId={(row) => row.id}
        columns={columns}
        aggregationFunctions={{
          ...GRID_AGGREGATION_FUNCTIONS,
          trendLine: trendLineAggregation,
          description: descriptionAggregation,
          change: averageChangeAggregation,
          mom: monthOvermonthAggregation,
        }}
        initialState={initialState}
        loading={loading}
        disableSelectionOnClick
        groupingColDef={{
          leafField: 'account',
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        hideFooter
        autoHeight
      />

      {isDialogOpen && (
        <DetailDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          rowData={currentRowData}
        />
      )}

      <Dialog
        open={dialogOpen}
        maxWidth={'lg'}
        fullWidth={true}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>Upload File</DialogTitle>
        <DialogContent>
          <input
            type="file"
            accept=".txt"
            id="file-upload"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <label htmlFor="file-upload">
            <Button
              component="span"
              variant="contained"
              color="primary"
              startIcon={<CloudUploadIcon />}
            >
              Choose File
            </Button>
          </label>
          {selectedFile && (
            <div>
              <p>Selected File: {selectedFile.name}</p>

              <LinearProgress variant="determinate" value={uploadProgress} />
            </div>
          )}
          <textarea
            rows={25}
            value={filePreviewUrl}
            placeholder="File preview will appear here..."
            readOnly
            style={{
              marginTop: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '5px',
              width: '100%',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleProcessFile} disabled={!selectedFile}>
            Process File
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
