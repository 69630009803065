import React from 'react';
import { usePagination } from 'react-instantsearch';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';

const CustomPagination = (props) => {
  const { currentRefinement, nbHits, refine } = usePagination(props);

  const onPageChange = (event, newPage) => {
    refine(newPage);
  };

  return (
    <Box
      sx={{
        p: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        borderTop: 1,
        borderColor: 'divider',
      }}
    >
      <TablePagination
        component="div"
        count={nbHits}
        page={currentRefinement}
        rowsPerPage={20}
        rowsPerPageOptions={[]}
        onPageChange={onPageChange}
      />
    </Box>
  );
};

export default CustomPagination;
