import React, { useState, useEffect } from 'react';
import {
  DataGridPremium,
  GridToolbar,
  GridToolbarQuickFilter,
  gridClasses,
  useGridApiRef,
  GRID_AGGREGATION_FUNCTIONS,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';

import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Stack,
  Snackbar,
  Button,
  Paper,
  Divider,
} from '@mui/material';

import { blue, grey } from '@mui/material/colors';

import { SparkLineChart } from '@mui/x-charts/SparkLineChart';

import { subMonths, formatISO } from 'date-fns';

import { accountDescriptions } from './accountDescriptions';
import DetailsDialog from './detailsDialog';

import { API_BASE_URL } from 'config';

import axios from 'axios';

// Define a formatter function for currency values
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export default function ExpensesDataGrid() {
  const apiRef = useGridApiRef();
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [expenseRange, setExpenseRange] = useState(13);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [currentRowData, setCurrentRowData] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Useeffect for error handling
  useEffect(() => {
    if (error) {
      setSnackbarOpen(true);
    }
  }, [error]);

  // Fetch expenses when the component mounts or the expenseRange changes
  useEffect(() => {
    const endDate = new Date();
    const startDate = subMonths(endDate, expenseRange);

    fetchExpenses(formatISO(startDate), formatISO(endDate));
  }, [expenseRange]);

  const fetchExpenses = async (startDate, endDate) => {
    setLoading(true);
    setError('');

    try {
      const params = {
        startDate,
        endDate,
        startAccount: '7000',
        endAccount: '7999',
        dbName: 'C211609', // Replace with the actual dbName if dynamic
      };

      const response = await axios.get(
        `${API_BASE_URL}/api/glJEDetails/summary`,
        { params },
      );
      setExpenses(response.data); // Assuming the API returns the array directly
    } catch (err) {
      setError('Failed to fetch data');
      console.error('There was an error fetching the expenses:', err);
    } finally {
      setLoading(false);
    }
  };

  // Determine the current month and year
  const currentDate = new Date();
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const currentYear = currentDate.getFullYear();
  const currentMonthField = `${currentMonth}-${currentYear}`;
  const previousMonth = currentDate.getMonth().toString().padStart(2, '0');
  const previousMonthYear =
    previousMonth === '0' ? currentYear - 1 : currentYear;
  const previousPreviousMonth = (currentDate.getMonth() - 1)
    .toString()
    .padStart(2, '0');
  const previousPreviousMonthYear =
    previousPreviousMonth === '0' ? currentYear - 1 : currentYear;

  // Handle dialog open for the account details
  const openDetailDialog = (rowData) => {
    setCurrentRowData(rowData);
    setIsDialogOpen(true);
  };

  // Function to generate columns for the selected date range
  const generateColumns = (range) => {
    const end = new Date();
    const columns = [
      { field: 'group', headerName: 'Group', width: 150 },
      {
        field: 'accountNumber',
        headerName: 'Account',
        width: 150,
        renderCell: (params) => {
          return (
            <Button
              variant="text"
              color="primary"
              onClick={() => openDetailDialog(params.row)}
            >
              {params.value}
            </Button>
          );
        },
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 275,
        valueGetter: (value, row) => {
          const accountNumber = row.accountNumber;
          // Return the account description based on the account number, accountDescriptions is an array of objects
          const description = accountDescriptions.find(
            (account) => account.Account === accountNumber,
          );
          return description ? description.Description : '';
        },
      },
      { field: 'department', headerName: 'Department', width: 200 },
    ];

    for (let i = 0; i < range; i++) {
      const monthDate = subMonths(end, i);
      // Use zero-prefixed month for consistency in field naming
      const zeroPrefixedMonth = (monthDate.getMonth() + 1)
        .toString()
        .padStart(2, '0');

      const monthYearHeader = `${monthDate.toLocaleString('default', {
        month: 'short',
      })} ${monthDate.getFullYear()}`;

      const column = {
        field: `${zeroPrefixedMonth}-${monthDate.getFullYear()}`,
        headerName: monthYearHeader,
        width: 110,
        valueGetter: (value, row) =>
          row[`${zeroPrefixedMonth}-${monthDate.getFullYear()}`] || 0,
        valueFormatter: (value) => {
          return value
            ? currencyFormatter.format(value)
            : currencyFormatter.format(0);
        },
        type: 'number',
      };

      // Apply the shade class to the current month column
      if (column.field === currentMonthField) {
        column.cellClassName = 'currentMonthShade';
        column.headerClassName = 'currentMonthShade';
        column.headerName += ' (Current)';
        column.width += 50;
      }

      if (column.field === previousMonth + '-' + previousMonthYear) {
        column.cellClassName = 'previousMonthShade';
        column.headerClassName = 'previousMonthShade';
        column.headerName += ' (Prior)';
        column.width += 50;
      }

      columns.push(column);
    }

    // Add a "Change" column after the current month
    const changeColumn = {
      field: 'change',
      headerName: 'Change',
      width: 100,
      cellClassName: 'currentMonthShade',
      headerClassName: 'currentMonthShade',
      renderCell: (params) => {
        let currentValue = 0;
        let previousValue = 0;

        if (params.value) {
          currentValue = params.value[`${currentMonth}-${currentYear}`] || 0;
          previousValue =
            params.value[`${previousMonth}-${previousMonthYear}`] || 0;
        } else {
          currentValue = params.row[`${currentMonth}-${currentYear}`] || 0;
          previousValue =
            params.row[`${previousMonth}-${previousMonthYear}`] || 0;
        }

        // Calculate the percentage change between the previous and previous previous month take into account negative values
        const percentageChange =
          ((currentValue - previousValue) / Math.abs(previousValue)) * 100;

        // Adjust for NaN values
        if (isNaN(percentageChange) || previousValue === 0) {
          return <div variant="body2">0.00%</div>;
        }

        return (
          <div
            variant="body2"
            style={{ color: percentageChange < 0 ? 'red' : 'green' }}
          >
            {percentageChange.toFixed(2)}% {percentageChange < 0 ? '↓' : '↑'}
          </div>
        );
      },
    };

    // Insert the "Change" column between the current and previous month
    const currentMonthIndex = columns.findIndex(
      (column) => column.field === currentMonthField,
    );

    columns.splice(currentMonthIndex + 1, 0, changeColumn);

    // Add a "Change" column after the previous month
    const changeColumnPrevious = {
      field: 'changePrevious',
      headerName: 'Change',
      width: 100,
      cellClassName: 'previousMonthShade',
      headerClassName: 'previousMonthShade',
      renderCell: (params) => {
        let currentValue = 0;
        let previousValue = 0;

        if (params.value) {
          currentValue =
            params.value[`${previousMonth}-${previousMonthYear}`] || 0;
          previousValue =
            params.value[
              `${previousPreviousMonth}-${previousPreviousMonthYear}`
            ] || 0;
        } else {
          currentValue =
            params.row[`${previousMonth}-${previousMonthYear}`] || 0;
          previousValue =
            params.row[
              `${previousPreviousMonth}-${previousPreviousMonthYear}`
            ] || 0;
        }

        // Calculate the percentage change between the previous and previous previous month take into account negative values
        const percentageChange =
          ((currentValue - previousValue) / Math.abs(previousValue)) * 100;

        // Adjust for NaN values
        if (isNaN(percentageChange) || previousValue === 0) {
          return <div variant="body2">0.00%</div>;
        }

        return (
          <div
            variant="body2"
            style={{ color: percentageChange < 0 ? 'red' : 'green' }}
          >
            {percentageChange.toFixed(2)}% {percentageChange < 0 ? '↓' : '↑'}
          </div>
        );
      },
    };

    // Insert the "Previous Change" column between the previous and current month
    const previousMonthIndex = columns.findIndex(
      (column) => column.field === `${previousMonth}-${previousMonthYear}`,
    );

    columns.splice(previousMonthIndex + 1, 0, changeColumnPrevious);

    // Function to calculate the total, excluding the current month
    const calculateTotalExcludingCurrentMonth = (row) => {
      const total = Object.keys(row).reduce((acc, key) => {
        // Exclude the current month from the calculation
        if (
          key.match(/^\d{2}-\d{4}$/) &&
          key !== `${currentMonth}-${currentYear}`
        ) {
          acc += row[key];
        }
        return acc;
      }, 0);

      return total;
    };

    // Function to calculate the average, excluding the current month
    const calculateAverageExcludingCurrentMonth = (row) => {
      const validMonths = Object.keys(row).filter(
        (key) =>
          key.match(/^\d{2}-\d{4}$/) &&
          key !== `${currentMonth}-${currentYear}`,
      );

      const total = calculateTotalExcludingCurrentMonth(row);

      const average = validMonths.length > 0 ? total / validMonths.length : 0;

      return average;
    };

    const calculationColumns = [
      {
        field: 'total',
        headerName: 'Total',
        width: 110,
        type: 'number',
        cellClassName: 'calculationColumns',
        headerClassName: 'calculationColumns',
        valueGetter: (value, row) => calculateTotalExcludingCurrentMonth(row),
        valueFormatter: (value) => currencyFormatter.format(value),
      },
      {
        field: 'average',
        headerName: 'Average',
        width: 110,
        type: 'number',
        cellClassName: 'calculationColumns',
        headerClassName: 'calculationColumns',
        valueGetter: (value, row) => calculateAverageExcludingCurrentMonth(row),
        valueFormatter: (value) => currencyFormatter.format(value),
      },
      {
        field: 'trend',
        headerName: 'Trend',
        width: 150,
        cellClassName: 'calculationColumns',
        headerClassName: 'calculationColumns',
        valueGetter: (value, row) => {
          // Get the cell value for each row, creating an array of values
          const rowData = Object.keys(row).reduce((acc, key) => {
            if (key.match(/^\d{2}-\d{4}$/)) {
              acc.push(row[key]);
            }
            return acc;
          }, []);

          // Return the array of values for the trend line chart but ignore the current month
          return rowData.slice(0, -1);
        },
        renderCell: (params) => {
          const values = params.value;
          return <SparkLineChart data={values} showHighlight showTooltip />;
        },
      },
    ];

    columns.push(...calculationColumns);

    return columns;
  };

  // Generate the columns for the DataGrid based on the expense range
  const columns = generateColumns(expenseRange);

  const departmentLabels = [
    'All',
    'New',
    'Used',
    'Service',
    'Body Shop',
    'Parts',
    'Unknown',
    'Unknown',
    'Unknown',
    'Unknown',
    'Unknown',
  ];

  // Function to transform the data for the DataGrid rows
  const transformDataForGrid = (data) => {
    const groupedData = data.reduce(
      (acc, { _id: { year, month, accountNumber }, totalAmount }) => {
        const key = `${accountNumber}`;
        const zeroPrefixedMonth = month.toString().padStart(2, '0');
        const columnField = `${zeroPrefixedMonth}-${year}`;

        // Creating the group field by taking the first 3 digits of the account number and appending a '0'
        const group = accountNumber.substring(0, 3) + '0';

        // Determining the department based on the last digit of the account number
        const lastDigit = parseInt(accountNumber.slice(-1), 10);
        const department = departmentLabels[lastDigit] || 'Unknown';

        if (!acc[key]) {
          acc[key] = {
            id: accountNumber,
            accountNumber,
            group,
            department,
          };
        }

        acc[key][columnField] =
          (acc[key][columnField] || 0) + parseFloat(totalAmount.$numberDecimal);

        return acc;
      },
      {},
    );

    return Object.values(groupedData);
  };

  // Transform the expenses for the DataGrid
  const rows = transformDataForGrid(expenses);

  // Function to transform columns array into an object
  const transformColumnsToObject = (columns) => {
    const columnObject = {
      group: {},
      accountNumber: {},
      department: {},
      months: {},
    };

    columns.forEach((column) => {
      switch (column.field) {
        case 'group':
        case 'accountNumber':
        case 'department':
          columnObject[column.field] = {
            headerName: column.headerName,
            width: column.width,
          };
          break;
        case 'total':
        case 'variance':
        case 'variancePercent':
          columnObject[column.field] = {
            headerName: column.headerName,
            width: column.width,
            type: column.type,
          };
          break;
        default:
          // Assuming all other fields are month-year fields
          if (column.field.includes('-')) {
            columnObject.months[column.field] = 'sum';
          }
          break;
      }
    });

    return columnObject;
  };

  // Custom aggregation function for the trend line

  const trendLineAggregation = {
    // Get the cell value for each row, creating an object with month fields and their values
    getCellValue: ({ row }) => {
      const rowData = Object.keys(row).reduce((acc, key) => {
        if (key.match(/^\d{2}-\d{4}$/)) {
          acc[key] = row[key];
        }
        return acc;
      }, {});

      return rowData;
    },

    // Create an array of objects with month fields and their values
    apply: ({ values }) => {
      if (!values || values.length === 0) return null;
      const reducedData = values.reduce((acc, value) => {
        Object.keys(value).forEach((key) => {
          acc[key] = (acc[key] || 0) + value[key];
        });
        return acc;
      }, {});

      // Convert the aggregated object into an array of values for the chart
      const resultArray = Object.values(reducedData);

      return resultArray.slice(0, -1);
    },

    // Label for the aggregation, used as the column header or identifier
    label: 'trend',
  };

  // Custom aggregation function for the change column
  const changeAggregation = {
    // Get the this columns values
    getCellValue: ({ row }) => {
      const rowData = Object.keys(row).reduce((acc, key) => {
        if (key.match(/^\d{2}-\d{4}$/)) {
          acc[key] = row[key];
        }
        return acc;
      }, {});

      return rowData;
    },

    // Calculate the change between the current and previous month
    apply: ({ values }) => {
      // Reduce the values of each row preserving the keys
      const reducedData = values.reduce((acc, value) => {
        Object.keys(value).forEach((key) => {
          acc[key] = (acc[key] || 0) + value[key];
        });
        return acc;
      }, {});
      return reducedData;
    },

    // Label for the aggregation, used as the column header or identifier
    label: 'change',
  };

  // Custom aggregation function for the Description column
  const descriptionAggregation = {
    // Get the Account Number of the row
    getCellValue: ({ row }) => {
      return row.group;
    },

    // Apply the aggregation function
    apply: ({ values }) => {
      const description = accountDescriptions.find(
        (account) => account.Account === values[0],
      );
      return description ? description.Description : '';
    },

    // Label for the aggregation, used as the column header or identifier
    label: 'description',
  };

  // Custom aggregation function for the department column
  const departmentAggregation = {
    // Get the department of the row
    getCellValue: ({ row }) => {
      return row.department;
    },

    // Apply the aggregation function
    apply: ({ values }) => {
      return values.join(', ');
    },

    // Label for the aggregation, used as the column header or identifier
    label: 'department',
  };

  //Initial state of the columns
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['group'],
      },
      columns: {
        columnVisibilityModel: {
          group: false,
          accountNumber: false,
        },
      },
      aggregation: {
        model: {
          ...transformColumnsToObject(columns).months,
          description: 'description',
          department: 'department',
          change: 'change',
          changePrevious: 'change',
          total: 'sum',
          average: 'sum',
          trend: 'trendLine',
        },
      },
      pinnedColumns: {
        right: ['total', 'average', 'trend'],
      },
    },
  });

  return (
    <Paper
      sx={{
        height: '100%',
        display: 'flex',
        [`.${gridClasses.cell}.currentMonthShade`]: {
          backgroundColor: blue[100],
          color: blue[800],
        },
        [`.${gridClasses.columnHeader}.currentMonthShade`]: {
          backgroundColor: blue[100],
          color: blue[800],
        },
        [`.${gridClasses.cell}.previousMonthShade`]: {
          backgroundColor: grey[100],
          color: grey[800],
        },
        [`.${gridClasses.columnHeader}.previousMonthShade`]: {
          backgroundColor: grey[100],
          color: grey[800],
        },
        [`.${gridClasses.cell}.calculationColumns`]: {
          backgroundColor: grey[50],
          color: grey[800],
        },
        [`.${gridClasses.columnHeader}.calculationColumns`]: {
          backgroundColor: grey[50],
          color: grey[800],
        },
      }}
    >
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={error}
      />
      <DataGridPremium
        apiRef={apiRef}
        rows={rows}
        getRowId={(row) => row.id}
        columns={columns}
        initialState={initialState}
        groupingColDef={{
          leafField: 'accountNumber',
        }}
        aggregationFunctions={{
          ...GRID_AGGREGATION_FUNCTIONS,
          trendLine: trendLineAggregation,
          change: changeAggregation,
          description: descriptionAggregation,
          department: departmentAggregation,
        }}
        //Hide the aggregation column footer for the description column
        hideAggregationColFooter={['description']}
        loading={loading}
        disableSelectionOnClick
        slots={{
          toolbar: () => {
            return (
              <>
                <Stack direction="row" spacing={2} alignItems="center" p={2}>
                  <GridToolbar />
                  <Box sx={{ flexGrow: 1 }} />
                  <GridToolbarQuickFilter />
                  <Box sx={{ minWidth: 200 }}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel id="expense-range-label">
                        Expense Range
                      </InputLabel>
                      <Select
                        labelId="expense-range-label"
                        id="expense-range"
                        value={expenseRange}
                        onChange={(e) =>
                          setExpenseRange(parseInt(e.target.value, 10))
                        }
                        label="Expense Range"
                      >
                        <MenuItem value={4}>Last 3 Months</MenuItem>
                        <MenuItem value={7}>Last 6 Months</MenuItem>
                        <MenuItem value={10}>Last 9 Months</MenuItem>
                        <MenuItem value={13}>Last 12 Months</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
                <Divider />
              </>
            );
          }, // Hide the default toolbar
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        hideFooter
      />

      {isDialogOpen && (
        <DetailsDialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          rowData={currentRowData}
          expenseRange={expenseRange}
          dbName="C211609"
          apiRef={apiRef}
        />
      )}
    </Paper>
  );
}
