import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingComponent from 'components/misc/LoadingComponent';
import Layout from 'layout/Layout';

const Callback = () => {
  const { error } = useAuth0();

  if (error) {
    console.error('Error:', error);
    return <div>Oops... {error.message}</div>;
  }

  return (
    <Layout>
      <LoadingComponent />
    </Layout>
  );
};

export default Callback;
