export const pricingPlans = [
  { title: 'Standard', value: 'Standard' },
  { title: 'Internet', value: 'Internet' },
  { title: 'Costco', value: 'Costco' },
  { title: 'Fleet', value: 'Fleet' },
];

export const applyDiscountFrom = [
  { title: 'SRP', value: 'SRP' },
  { title: 'Invoice', value: 'Invoice' },
  { title: 'Balance', value: 'Balance' },
];

export const lenders = [
  { title: 'TFS', value: 'TFS' },
  { title: 'House', value: 'House' },
];

export const financeTypes = [
  { title: 'All', value: 'All' },
  { title: 'Special', value: 'SPEC_APR' },
  { title: 'Standard', value: 'STAND_APR' },
];

export const leaseTypes = [
  { title: 'All', value: 'All' },
  { title: 'Special', value: 'SPEC_LEASE' },
  { title: 'Standard', value: 'STAND_LEASE' },
];

export const securityDeposits = [...Array(11).keys()];

export const acqFeeOptions = [
  { title: 'Upfront', value: 'Upfront' },
  { title: 'Capped', value: 'Capped' },
  { title: 'Waived', value: 'Waived' },
];

export const milesPerYear = [
  { title: '7,500', value: '7500' },
  { title: '10,000', value: '10000' },
  { title: '12,000', value: '12000' },
  { title: '15,000', value: '15000' },
];

export const rebateDescriptions = [
  {
    id: 0,
    name: 'Loyalty',
    selected: false,
    vehicleType: ['New'],
    transactionType: ['Finance', 'Lease'],
    amount: 500,
  },
  {
    id: 1,
    name: 'Military',
    selected: false,
    vehicleType: ['New'],
    transactionType: ['Finance', 'Lease'],
    amount: 500,
  },
  {
    id: 2,
    name: 'College Grad',
    selected: false,
    vehicleType: ['New'],
    transactionType: ['Finance', 'Lease'],
    amount: 500,
  },
];

export const weoweDescriptions = [
  {
    id: 0,
    name: 'SWDS Alarm',
    selected: false,
    amount: 2495,
    cost: 995,
    dmsCode: 'ALARM',
    taxable: true,
    credit: 'Front',
  },
  {
    id: 1,
    name: 'Cilajet',
    selected: false,
    amount: 1595,
    cost: 595,
    dmsCode: 'CILA',
    taxable: true,
    credit: 'Front',
  },
  {
    id: 2,
    name: 'Door Edge Guards',
    selected: false,
    amount: 995,
    cost: 395,
    dmsCode: 'DOOR',
    taxable: true,
    credit: 'Front',
  },
  {
    id: 3,
    name: 'Window Tint',
    selected: false,
    amount: 995,
    cost: 395,
    dmsCode: 'M2',
    taxable: true,
    credit: 'Front',
  },
  {
    id: 4,
    name: 'Catayltic Converter Protection',
    selected: false,
    amount: 995,
    cost: 395,
    dmsCode: 'CAT5',
    taxable: true,
    credit: 'Front',
  },
];

export const contractDescriptions = [
  {
    id: 0,
    name: 'VSC 72MO/75K Miles',
    selected: false,
    vehicleType: ['New', 'Used'],
    transactionType: ['Finance', 'Cash'],
    amount: 2495,
    cost: 995,
  },
  {
    id: 1,
    name: 'MPU 5yr/55k Miles',
    selected: false,
    vehicleType: ['New', 'Used'],
    transactionType: ['Finance', 'Lease', 'Cash'],
    amount: 1595,
    cost: 595,
  },
  {
    id: 2,
    name: 'MPU 3yr/36k Miles',
    selected: false,
    vehicleType: ['New', 'Used'],
    transactionType: ['Lease'],
    amount: 1595,
    cost: 595,
  },
  {
    id: 3,
    name: 'Tire & Wheel Protection',
    selected: false,
    vehicleType: ['New', 'Used'],
    transactionType: ['Finance', 'Lease', 'Cash'],
    amount: 1295,
    cost: 495,
  },
  {
    id: 4,
    name: 'GAP',
    selected: false,
    vehicleType: ['New', 'Used'],
    transactionType: ['Finance', 'Lease'],
    amount: 1195,
    cost: 695,
  },
  {
    id: 5,
    name: 'Lease Wear & Tear',
    selected: false,
    vehicleType: ['New', 'Used'],
    transactionType: ['Lease'],
    amount: 995,
    cost: 395,
  },
];

export const initialFormData = {
  pricingplan: 'Standard',
  applydiscountto: 'SRP', //MSRP
  discount: 0,
  creditscore: 720,
  financetype: 'All',
  downpayments: [1000, 2000, 3000],
  financeterms: [54, 60, 66],
  daystofirstpayment: 45,
  financereserve: 2.0,
  lender: 'TFS',
  leasetype: 'All',
  driveoffs: [1000, 2000, 3000],
  leaseterms: [36, 39, 42],
  securitydeposits: 0,
  milesperyear: '12000',
  leasereserve: 0.0004,
  applyacqfeeto: 'Capped',
  taxrate: '9.5',
  state: 'CA',
  county: 'Los Angeles',
  city: 'Valencia',
  contracts: [],
  rebates: [],
  weowes: [],
};

export const transactionTypes = [
  { value: 0, name: 'Retail' },
  { value: 1, name: 'Lease' },
  { value: 2, name: 'Both' },
];

export const vehicleTypes = [
  { value: 0, name: 'New' },
  { value: 1, name: 'Used' },
  { value: 2, name: 'Both' },
];
