import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Box } from '@mui/material';

const Home = () => {
  const { loginWithRedirect } = useAuth0();

  const rootStyles = {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const loginButtonStyles = {
    backgroundColor: 'black',
    color: '#fff',
    padding: '16px 32px',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'black',
    },
  };

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/dashboard',
      },
    });
  };

  return (
    <Box sx={rootStyles}>
      <Button sx={loginButtonStyles} onClick={handleLogin}>
        Login
      </Button>
    </Box>
  );
};

export default Home;
