export const manufacturers = [
  "Ford",
  "General Motors",
  "Toyota",
  "Honda",
  "Volkswagen",
  "BMW",
  "Mercedes-Benz",
  "Hyundai",
  "Kia",
  "Nissan",
  "Tesla",
  "Subaru",
  "Mazda",
  "Lexus",
  "Audi",
  "Chrysler",
  "Dodge",
  "Jeep",
  "Ram",
  "Volvo",
  "Porsche",
  "Jaguar",
  "Land Rover",
  "Acura",
  "Infiniti"
];
