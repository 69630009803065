import React from 'react';
import { Grid, TextField } from '@mui/material';

const CustomerForm = ({ formData, onChange }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          name="CustNo"
          label="Customer Number"
          value={formData.CustNo}
          onChange={(e) => onChange('CustNo', e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          fullWidth
          name="Name"
          label="Name"
          value={formData.Name}
          onChange={(e) => onChange('Name', e.target.value)}
          required
        />
      </Grid>
    </Grid>
  );
};

export default CustomerForm;
