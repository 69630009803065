import React from 'react';
import { formatCurrency } from 'utils/numberUtils';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

const PaymentRender = ({
  basePayment,
  monthlyPayment,
  moneyFactor,
  rebates,
}) => (
  <div
    style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}
  >
    <Typography variant="caption">{formatCurrency(rebates)}</Typography>
    <Tooltip
      title={formatCurrency(basePayment)}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
      arrow
    >
      <Typography variant="button" fontWeight="bold">
        {formatCurrency(monthlyPayment)}
      </Typography>
    </Tooltip>
    <Typography variant="caption">{moneyFactor}</Typography>
  </div>
);

const LeaseTable = ({ data }) => {
  const Lease = data;

  const terms = Array.from(new Set(Lease.map((item) => item.term)));
  const downPayments = Array.from(
    new Set(Lease.map((item) => item.downPayment)),
  );

  // Create a map for miles per year (mpy) data
  const mpyDataMap = {};
  Lease.forEach((item) => {
    if (!mpyDataMap[item.term]) {
      mpyDataMap[item.term] = item.milesperyear; // Assuming milesperyear exists on the item
    }
  });

  // Organize payments into a map
  const paymentsMap = {};
  Lease.forEach((item) => {
    if (!paymentsMap[item.term]) {
      paymentsMap[item.term] = {};
    }
    paymentsMap[item.term][item.downPayment] = {
      basePayment: item.basePayment,
      monthlyPayment: item.monthlyPayment,
      moneyFactor: item.moneyFactor, // Assuming moneyFactor exists on the item
      rebates: item.rebates,
      residual: item.residual,
    };
  });

  // Function to calculate opacity based on monthly payment
  const calculateOpacity = (monthlyPayment, paymentsArray) => {
    const sortedPayments = paymentsArray
      .map((item) => item.monthlyPayment)
      .sort((a, b) => a - b);
    const index = sortedPayments.indexOf(monthlyPayment);
    return (index + 1) / sortedPayments.length;
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Term / OOP</TableCell>
          {downPayments.map((dp) => (
            <TableCell key={dp} align="center">
              <Typography variant="button" fontWeight="bold">
                {formatCurrency(dp)}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {terms.map((term, idx) => (
          <TableRow key={idx}>
            <TableCell
              component="th"
              scope="row"
              sx={{
                padding: 0,
                paddingLeft: 2,
                verticalAlign: 'top',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="caption">Rebate</Typography>
                {mpyDataMap[term] && (
                  <Typography variant="button" fontWeight="bold">
                    {term} mo
                  </Typography>
                )}
                {mpyDataMap[term] && (
                  <Typography variant="caption">
                    {`${mpyDataMap[term]} Miles`}
                  </Typography>
                )}
              </div>
            </TableCell>
            {downPayments.map((dp) => (
              <TableCell
                key={dp}
                align="center"
                sx={{
                  padding: 0,
                  verticalAlign: 'top',
                  backgroundColor: alpha(
                    blue[500],
                    calculateOpacity(
                      paymentsMap[term][dp].monthlyPayment,
                      Lease,
                    ),
                  ),
                }}
              >
                {paymentsMap[term] && paymentsMap[term][dp] ? (
                  <PaymentRender {...paymentsMap[term][dp]} />
                ) : (
                  '-'
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default LeaseTable;
