import * as React from 'react';

// DataGridPro components
import {
  DataGridPro,
  GridToolbar,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';

// Material UI components
import { Box } from '@mui/material';

import columns from './columns';
import SummaryTable from '../summaryTable';
import DetailPanelContent from '../detailPanelContent';

const groupByPosition = (tableData) => {
  return tableData.reduce((result, deal) => {
    const { CRMFIMgrName, DealType, FrontGross, BackGross, TotalGross } = deal;

    const dealValue = 1; // Deals are counted as 1 for Sales Managers
    const isUsed = DealType === 'Used'; // Check if deal is Used
    const isFinance = deal.SaleType === 'Finance'; // Check if deal is Finance

    if (!result[CRMFIMgrName]) {
      result[CRMFIMgrName] = {
        totalDeals: 0,
        totalNew: 0,
        totalUsed: 0,
        totalFrontGross: 0,
        totalBackGross: 0,
        totalGrossProfit: 0,
        totalUnitsSold: 0, // New property for total units sold
      };
    }

    result[CRMFIMgrName].totalDeals += dealValue;
    if (isUsed) {
      result[CRMFIMgrName].totalUsed += dealValue;
    } else {
      result[CRMFIMgrName].totalNew += dealValue;
    }

    // Adjust how gross numbers are accumulated based on isFinance
    result[CRMFIMgrName].totalFrontGross += isFinance
      ? parseFloat(FrontGross) / 2
      : parseFloat(FrontGross);

    result[CRMFIMgrName].totalBackGross += isFinance
      ? parseFloat(BackGross) / 2
      : parseFloat(BackGross);

    result[CRMFIMgrName].totalGrossProfit += isFinance
      ? parseFloat(TotalGross) / 2
      : parseFloat(TotalGross);

    // Increment total units sold
    result[CRMFIMgrName].totalUnitsSold += dealValue;

    return result;
  }, {});
};

const SalesManagerTable = ({ tableData, isLoading }) => {
  const getDetailPanelContent = React.useCallback(
    ({ row }) => {
      row.name = 'CRMFIMgrName';
      return <DetailPanelContent position={row} data={tableData} />;
    },
    [tableData],
  );

  const getDetailPanelHeight = React.useCallback(() => 'auto', []);

  const groupedData = React.useMemo(
    () => groupByPosition(tableData),
    [tableData],
  );

  const rows = Object.keys(groupedData).map((key) => {
    const { totalUnitsSold, ...rest } = groupedData[key];
    const dayOfMonth = new Date().getDate();
    const daysInMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0,
    ).getDate();

    return {
      id: key,
      ...rest,
      unitPace: (totalUnitsSold / dayOfMonth) * daysInMonth,
    };
  });

  // Column grouping model
  const columnGroupingModel = [
    {
      groupId: 'Units',
      children: [
        { field: 'totalNew' },
        { field: 'totalUsed' },
        { field: 'totalDeals' },
        { field: 'unitPace' },
      ],
    },
    {
      groupId: 'Gross',
      children: [
        { field: 'totalFrontGross' },
        { field: 'totalBackGross' },
        { field: 'totalGrossProfit' },
      ],
    },
    {
      groupId: 'PVR',
      children: [
        { field: 'frontGrossPVR' },
        { field: 'backGrossPVR' },
        { field: 'totalGrossPVR' },
      ],
    },
  ];

  return (
    <DataGridPro
      sx={{
        border: 'none',
        borderRadius: 0,
      }}
      rows={rows}
      columns={columns} // Use the columns configuration for Sales Managers
      loading={isLoading}
      density="compact"
      slots={{
        toolbar: () => {
          return (
            <Box display="flex" flexDirection="column" padding={2}>
              <Box display="flex" alignItems="baseline">
                <GridToolbar />
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarQuickFilter />
              </Box>
              <Box padding={1}>
                <SummaryTable rows={rows} isLoading={isLoading} />
              </Box>
            </Box>
          );
        },
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      disableRowSelectionOnClick
      experimentalFeatures={{ columnGrouping: true }}
      columnGroupingModel={columnGroupingModel}
      rowThreshold={0}
      getDetailPanelHeight={getDetailPanelHeight}
      getDetailPanelContent={getDetailPanelContent}
    />
  );
};

export default SalesManagerTable;
