import React, { useState } from 'react';
import { Button } from '@mui/material';

import DeskingDialog from './deskingdrawer';

const DeskingSettingsButton = ({ formData, handleFormData }) => {
  const [open, setOpen] = useState(false);

  const handleOpenDeskingSettings = () => {
    setOpen(true);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenDeskingSettings}
        sx={{
          textWrap: 'nowrap',
        }}
      >
        Desking Settings
      </Button>

      <DeskingDialog
        open={open}
        setOpen={setOpen}
        formData={formData}
        handleFormData={handleFormData}
      />
    </>
  );
};

export default DeskingSettingsButton;
