export const accountDescriptions = [
  { Account: '7000', Description: 'SALES COMPENSATION' },
  { Account: '7001', Description: 'NEW SALES COMPENSATION' },
  { Account: '7002', Description: 'USD SALES COMPENSATION' },
  { Account: '7003', Description: 'SRV SALES COMPENSATION' },
  { Account: '7005', Description: 'P&A SALES COMPENSATION' },
  { Account: '7010', Description: 'SUPRV COMPENSATION' },
  { Account: '7011', Description: 'NEW SUPRV COMPENSATION' },
  { Account: '7012', Description: 'USD SUPRV COMPENSATION' },
  { Account: '7013', Description: 'SRV SUPRV COMPENSATION' },
  { Account: '7015', Description: 'P&A SUPRV COMPENSATION' },
  { Account: '7020', Description: 'NEW DELIVERY EXP' },
  { Account: '7021', Description: 'NEW DELIVERY EXP' },
  { Account: '7030', Description: 'FIN, INS & S.C. COMM' },
  { Account: '7031', Description: 'NEW FIN, INS & S.C. COMM' },
  { Account: '7032', Description: 'USD FIN, INS & S.C. COMM' },
  { Account: '7040', Description: 'ADVERTISING' },
  { Account: '7041', Description: 'ADVERTISING - NEW' },
  { Account: '7042', Description: 'ADVERTISING - USED' },
  { Account: '7043', Description: 'ADVERTISING - SERVICE' },
  { Account: '7045', Description: 'ADVERTISING - PARTS' },
  { Account: '7050', Description: 'INTEREST' },
  { Account: '7050', Description: 'INTEREST FLOORING REBATE' },
  { Account: '7051', Description: 'NEW INTEREST FLOOR PLAN' },
  { Account: '7070', Description: 'ADVERTISING MEDIA' },
  { Account: '7071', Description: 'NEW ADVERTISING MEDIA' },
  { Account: '7072', Description: 'USD ADVERTISING MEDIA' },
  { Account: '7080', Description: 'ADVERTISING DIGITAL' },
  { Account: '7081', Description: 'NEW ADVERTISING DIGITAL' },
  { Account: '7082', Description: 'USD ADVERTISING DIGITAL' },
  { Account: '7083', Description: 'SRV ADVERTISING DIGITAL' },
  { Account: '7085', Description: 'P&A ADVERTISING DIGITAL' },
  { Account: '7090', Description: 'ADVERTISING - EVENTS/PROMO' },
  { Account: '7091', Description: 'NEW ADVERTISING EVENTS/PROMO' },
  { Account: '7092', Description: 'USD ADVERTISING EVENTS/PROMO' },
  { Account: '7093', Description: 'SRV ADVERTISING EVENTS/PROMO' },
  { Account: '7095', Description: 'P&A ADVERTISING EVENTS/PROMO' },
  { Account: '7096', Description: 'DETAIL ADVERTISING EVENTS/PROMO' },
  { Account: '7110', Description: 'POLICY ADJ' },
  { Account: '7111', Description: 'NEW POLICY ADJ' },
  { Account: '7112', Description: 'USD POLICY ADJ' },
  { Account: '7113', Description: 'SRV POLICY ADJ' },
  { Account: '7115', Description: 'P&A POLICY ADJ' },
  { Account: '7120', Description: 'CLAIMS ADJ' },
  { Account: '7125', Description: 'P&A CLAIMS ADJ' },
  { Account: '7126', Description: 'DETAIL CLAIMS ADJ' },
  { Account: '7140', Description: 'DEMO & VEH EXP' },
  { Account: '7141', Description: 'NEW DEMO & VEH EXP' },
  { Account: '7142', Description: 'USD DEMO & VEH EXP' },
  { Account: '7143', Description: 'SRV DEMO & VEH EXP' },
  { Account: '7145', Description: 'P&A DEMO & VEH EXP' },
  { Account: '7150', Description: 'INVENTORY MAINTENANCE' },
  { Account: '7151', Description: 'NEW INVENTORY MAINTENANCE' },
  { Account: '7152', Description: 'USD INVENTORY MAINTENANCE' },
  { Account: '7155', Description: 'P&A INVENTORY MAINTENANCE' },
  { Account: '7160', Description: 'PERSONNEL TRAINING' },
  { Account: '7161', Description: 'NEW PERSONNEL TRAINING' },
  { Account: '7162', Description: 'USD PERSONNEL TRAINING' },
  { Account: '7163', Description: 'SRV PERSONNEL TRAINING' },
  { Account: '7165', Description: 'P&A PERSONNEL TRAINING' },
  { Account: '7170', Description: 'OUTSIDE SERVICES' },
  { Account: '7171', Description: 'NEW OUTSIDE SERVICES' },
  { Account: '7172', Description: 'USD OUTSIDE SERVICES' },
  { Account: '7173', Description: 'SRV OUTSIDE SERVICES' },
  { Account: '7175', Description: 'P&A OUTSIDE SERVICES' },
  { Account: '7180', Description: 'P&A FREIGHT EXP' },
  { Account: '7185', Description: 'P&A FREIGHT EXP' },
  { Account: '7190', Description: 'SUPPLIES & SML TOOLS' },
  { Account: '7191', Description: 'NEW SUPPLIES & SML TOOLS' },
  { Account: '7192', Description: 'USD SUPPLIES & SML TOOLS' },
  { Account: '7193', Description: 'SRV SUPPLIES & SML TOOLS' },
  { Account: '7195', Description: 'P&A SUPPLIES & SML TOOLS' },
  { Account: '7200', Description: 'LAUNDRY & UNIFORMS' },
  { Account: '7203', Description: 'SRV LAUNDRY & UNIFORMS' },
  { Account: '7205', Description: 'P&A LAUNDRY & UNIFORMS' },
  { Account: '7210', Description: 'DEPR EQUIP & VEH' },
  { Account: '7211', Description: 'NEW DEPR EQUIP & VEH' },
  { Account: '7213', Description: 'SRV DEPR EQUIP & VEH' },
  { Account: '7215', Description: 'P&A DEPR EQUIP & VEH' },
  { Account: '7220', Description: 'MAINT & REPAIR EQUIP' },
  { Account: '7221', Description: 'NEW MAINT & REPAIR EQUIP' },
  { Account: '7222', Description: 'USD MAINT & REPAIR EQUIP' },
  { Account: '7223', Description: 'SRV MAINT & REPAIR EQUIP' },
  { Account: '7225', Description: 'P&A MAINT & REPAIR EQUIP' },
  { Account: '7230', Description: 'MISCELLANEOUS' },
  { Account: '7231', Description: 'NEW MISCELLANEOUS' },
  { Account: '7232', Description: 'USD MISCELLANEOUS' },
  { Account: '7233', Description: 'SRV MISCELLANEOUS' },
  { Account: '7235', Description: 'P&A MISCELLANEOUS' },
  { Account: '7240', Description: 'SUPERVISION SALARIES - SERVICE' },
  { Account: '7243', Description: 'SUPERVISION SALARIES - SERVICE' },
  { Account: '7250', Description: 'SALARIES & WAGES' },
  { Account: '7251', Description: 'NEW SALARIES & WAGES' },
  { Account: '7253', Description: 'SRV SALARIES & WAGES' },
  { Account: '7255', Description: 'P&A SALARIES & WAGES' },
  { Account: '7260', Description: 'CLERICAL SALARIES' },
  { Account: '7261', Description: 'NEW CLERICAL SALARIES' },
  { Account: '7262', Description: 'USD CLERICAL SALARIES' },
  { Account: '7263', Description: 'SRV CLERICAL SALARIES' },
  { Account: '7265', Description: 'P&A CLERICAL SALARIES' },
  { Account: '7270', Description: 'VACATION & TIME OFF PAY' },
  { Account: '7271', Description: 'NEW VACATION & TIME OFF PAY' },
  { Account: '7272', Description: 'USD VACATION & TIME OFF PAY' },
  { Account: '7273', Description: 'SRV VACATION & TIME OFF PAY' },
  { Account: '7275', Description: 'P&A VACATION & TIME OFF PAY' },
  { Account: '7330', Description: 'MEETING MEALS' },
  { Account: '7331', Description: 'NEW MEETING MEALS' },
  { Account: '7332', Description: 'USD MEETING MEALS' },
  { Account: '7333', Description: 'SRV MEETING MEALS' },
  { Account: '7340', Description: 'PROPERTY TAX' },
  { Account: '7341', Description: 'NEW PROPERTY TAX' },
  { Account: '7342', Description: 'USD PROPERTY TAX' },
  { Account: '7343', Description: 'SRV PROPERTY TAX' },
  { Account: '7345', Description: 'P&A PROPERTY TAX' },
  { Account: '7400', Description: 'LSE EXP-RL EST/RENT EQUIV' },
  { Account: '7401', Description: 'NEW LSE EXP-RL EST/RENT EQUIV' },
  { Account: '7402', Description: 'USD LSE EXP-RL EST/RENT EQUIV' },
  { Account: '7403', Description: 'SRV LSE EXP-RL EST/RENT EQUIV' },
  { Account: '7405', Description: 'P&A LSE EXP-RL EST/RENT EQUIV' },
  { Account: '7410', Description: 'SALARIES/WAGE ADM/GEN' },
  { Account: '7411', Description: 'NEW SALARIES/WAGE ADM/GEN' },
  { Account: '7412', Description: 'USD SALARIES/WAGE ADM/GEN' },
  { Account: '7413', Description: 'SRV SALARIES/WAGE ADM/GEN' },
  { Account: '7415', Description: 'P&A SALARIES/WAGE ADM/GEN' },
  { Account: '7420', Description: 'OWNERS SALARIES' },
  { Account: '7421', Description: 'NEW OWNERS SALARIES' },
  { Account: '7422', Description: 'USD OWNERS SALARIES' },
  { Account: '7423', Description: 'SRV OWNERS SALARIES' },
  { Account: '7425', Description: 'P&A OWNERS SALARIES' },
  { Account: '7430', Description: 'PAYROLL TAXES' },
  { Account: '7431', Description: 'NEW PAYROLL TAXES' },
  { Account: '7432', Description: 'USD PAYROLL TAXES' },
  { Account: '7433', Description: 'SRV PAYROLL TAXES' },
  { Account: '7435', Description: 'P&A PAYROLL TAXES' },
  { Account: '7440', Description: 'EMPLOYEE BENEFITS' },
  { Account: '7441', Description: 'NEW EMPLOYEE BENEFITS' },
  { Account: '7442', Description: 'USD EMPLOYEE BENEFITS' },
  { Account: '7443', Description: 'SRV EMPLOYEE BENEFITS' },
  { Account: '7445', Description: 'P&A EMPLOYEE BENEFITS' },
  { Account: '7460', Description: 'ADVERTISING GEN/INST' },
  { Account: '7461', Description: 'NEW ADVERTISING GEN/INST' },
  { Account: '7462', Description: 'USD ADVERTISING GEN/INST' },
  { Account: '7463', Description: 'SRV ADVERTISING GEN/INST' },
  { Account: '7465', Description: 'P&A ADVERTISING GEN/INST' },
  { Account: '7470', Description: 'STAT/OFFICE SUPPLIES' },
  { Account: '7471', Description: 'NEW STAT/OFFICE SUPPLIES' },
  { Account: '7472', Description: 'USD STAT/OFFICE SUPPLIES' },
  { Account: '7473', Description: 'SRV STAT/OFFICE SUPPLIES' },
  { Account: '7475', Description: 'P&A STAT/OFFICE SUPPLIES' },
  { Account: '7480', Description: 'DATA PROCESSING SERV' },
  { Account: '7481', Description: 'NEW DATA PROCESSING SERV' },
  { Account: '7482', Description: 'USD DATA PROCESSING SERV' },
  { Account: '7483', Description: 'SRV DATA PROCESSING SERV' },
  { Account: '7485', Description: 'P&A DATA PROCESSING SERV' },
  { Account: '7490', Description: 'OUTSIDE SERVICES' },
  { Account: '7491', Description: 'NEW OUTSIDE SERVICES' },
  { Account: '7492', Description: 'USD OUTSIDE SERVICES' },
  { Account: '7493', Description: 'SRV OUTSIDE SERVICES' },
  { Account: '7495', Description: 'P&A OUTSIDE SERVICES' },
  { Account: '7500', Description: 'COMPANY VEH EXP' },
  { Account: '7501', Description: 'NEW COMPANY VEH EXP' },
  { Account: '7505', Description: 'P&A COMPANY VEH EXP' },
  { Account: '7510', Description: 'CONTRIBUTIONS' },
  { Account: '7511', Description: 'NEW CONTRIBUTIONS' },
  { Account: '7512', Description: 'USD CONTRIBUTIONS' },
  { Account: '7513', Description: 'SRV CONTRIBUTIONS' },
  { Account: '7515', Description: 'P&A CONTRIBUTIONS' },
  { Account: '7520', Description: 'DUES/SUBSCRIPTIONS' },
  { Account: '7521', Description: 'NEW DUES/SUBSCRIPTIONS' },
  { Account: '7522', Description: 'USD DUES/SUBSCRIPTIONS' },
  { Account: '7523', Description: 'SRV DUES/SUBSCRIPTIONS' },
  { Account: '7525', Description: 'P&A DUES/SUBSCRIPTIONS' },
  { Account: '7530', Description: 'TELEPHONE' },
  { Account: '7531', Description: 'NEW TELEPHONE' },
  { Account: '7532', Description: 'USD TELEPHONE' },
  { Account: '7533', Description: 'SRV TELEPHONE' },
  { Account: '7535', Description: 'P&A TELEPHONE' },
  { Account: '7540', Description: 'LEGAL/AUDITING EXP' },
  { Account: '7541', Description: 'NEW LEGAL/AUDITING EXP' },
  { Account: '7542', Description: 'USD LEGAL/AUDITING EXP' },
  { Account: '7543', Description: 'SRV LEGAL/AUDITING EXP' },
  { Account: '7545', Description: 'P&A LEGAL/AUDITING EXP' },
  { Account: '7550', Description: 'POSTAGE' },
  { Account: '7551', Description: 'NEW POSTAGE' },
  { Account: '7552', Description: 'USD POSTAGE' },
  { Account: '7553', Description: 'SRV POSTAGE' },
  { Account: '7555', Description: 'P&A POSTAGE' },
  { Account: '7560', Description: 'TRAVEL/ENTERTAINMENT' },
  { Account: '7561', Description: 'NEW TRAVEL/ENTERTAINMENT' },
  { Account: '7562', Description: 'USD TRAVEL/ENTERTAINMENT' },
  { Account: '7563', Description: 'SRV TRAVEL/ENTERTAINMENT' },
  { Account: '7565', Description: 'P&A TRAVEL/ENTERTAINMENT' },
  { Account: '7570', Description: 'HEAT LGT POWER WATER' },
  { Account: '7571', Description: 'NEW HEAT LGT POWER WATER' },
  { Account: '7572', Description: 'USD HEAT LGT POWER WATER' },
  { Account: '7573', Description: 'SRV HEAT LGT POWER WATER' },
  { Account: '7575', Description: 'P&A HEAT LGT POWER WATER' },
  { Account: '7580', Description: 'NEW DEPR/MAINT FURN/FIX' },
  { Account: '7581', Description: 'NEW DEPR/MAINT FURN/FIX' },
  { Account: '7590', Description: 'INS OTHER THAN BLDGS' },
  { Account: '7591', Description: 'NEW INS OTHER THAN BLDGS' },
  { Account: '7592', Description: 'USD INS OTHER THAN BLDGS' },
  { Account: '7593', Description: 'SRV INS OTHER THAN BLDGS' },
  { Account: '7595', Description: 'P&A INS OTHER THAN BLDGS' },
  { Account: '7600', Description: 'TAXES NOT REAL/INC' },
  { Account: '7601', Description: 'NEW TAXES NOT REAL/INC' },
  { Account: '7602', Description: 'USD TAXES NOT REAL/INC' },
  { Account: '7603', Description: 'SRV TAXES NOT REAL/INC' },
  { Account: '7605', Description: 'P&A TAXES NOT REAL/INC' },
  { Account: '7610', Description: 'INTEREST OTHER' },
  { Account: '7611', Description: 'NEW INTEREST OTHER' },
  { Account: '7612', Description: 'USD INTEREST OTHER' },
  { Account: '7613', Description: 'SRV INTEREST OTHER' },
  { Account: '7615', Description: 'P&A INTEREST OTHER' },
  { Account: '7630', Description: 'LSE EXP-NON REAL ESTATE' },
  { Account: '7631', Description: 'NEW LSE EXP-NON REAL ESTATE' },
  { Account: '7632', Description: 'USD LSE EXP-NON REAL ESTATE' },
  { Account: '7633', Description: 'SRV LSE EXP-NON REAL ESTATE' },
  { Account: '7635', Description: 'P&A LSE EXP-NON REAL ESTATE' },
];
