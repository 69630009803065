import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useFetchSalesData from 'hooks/useFetchSalesData';
import { Grid } from '@mui/material';

import CoverSheetSummaryTable from './CoversheetSummaryTable';
import UnitSummaryTable from './UnitSummaryTable';
import GrossSummaryTable from './GrossSummaryTable';
import WeeklySalesChart from './WeeklySalesChart';
import PaceReport from './SalesManagerPacingReport';
import ReservationsGraph from './ReservationsGraph';
import AgingReservationsChart from './AgingReservationChart';
import TopSalesByUnits from './TopSalesByUnits';
import TopSalesByGross from './TopSalesByGross';
import PacingBarChart from './PacingBarChart';
import RequiredDailyPaceChart from './RequiredDailyPaceChart';
import WeekendUnitRecap from './WeekendUnitRecap';
import WeekendGrossRecap from './WeekendGrossRecap';
import NewMonthlyCumulativeSalesChart from './NewMonthlyCumulativeSalesChart';
import UsedMonthlyCumulativeSalesChart from './UsedMonthlyCumulativeSalesChart';
import MonthlyCumulativeGrossChart from './MonthlyCumulativeGrossChart';

import dayjs from 'dayjs';

const Dashboard = () => {
  const { user, isAuthenticated } = useAuth0();
  const [dataRange] = useState([dayjs().startOf('month'), dayjs()]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const {
    data: fetchedData,
    loading: fetchLoading,
    error: fetchError,
  } = useFetchSalesData(user?.dealerid, dataRange[0], dataRange[1]);

  useEffect(() => {
    if (isAuthenticated && user?.dealerid) {
      setData(fetchedData);
      setLoading(fetchLoading);
      setError(fetchError);
    }
  }, [isAuthenticated, user?.dealerid, fetchedData, fetchLoading, fetchError]);

  return (
    isAuthenticated && (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PacingBarChart data={data} loading={loading} error={error} />
        </Grid>
        <Grid item xs={12}>
          <CoverSheetSummaryTable />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RequiredDailyPaceChart data={data} loading={loading} error={error} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <UnitSummaryTable data={data} loading={loading} error={error} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <GrossSummaryTable data={data} loading={loading} error={error} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <WeeklySalesChart />
        </Grid>
        <Grid item xs={12} sm={4}>
          <WeekendUnitRecap />
        </Grid>
        <Grid item xs={12} sm={4}>
          <WeekendGrossRecap />
        </Grid>
        <Grid item xs={12} sm={4}>
          <NewMonthlyCumulativeSalesChart
            data={data}
            loading={loading}
            error={error}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <UsedMonthlyCumulativeSalesChart
            data={data}
            loading={loading}
            error={error}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MonthlyCumulativeGrossChart
            data={data}
            loading={loading}
            error={error}
          />
        </Grid>
        <Grid item xs={12}>
          <PaceReport data={data} loading={loading} error={error} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReservationsGraph data={data} loading={loading} error={error} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AgingReservationsChart />
        </Grid>
        <Grid item xs={12}>
          <TopSalesByUnits data={data} loading={loading} error={error} />
        </Grid>
        <Grid item xs={12}>
          <TopSalesByGross data={data} loading={loading} error={error} />
        </Grid>
      </Grid>
    )
  );
};

export default Dashboard;
