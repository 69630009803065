import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  DataGridPremium,
  GridToolbar,
  GridToolbarQuickFilter,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import {
  Box,
  Divider,
  Stack,
  Paper,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import dayjs from 'dayjs';
import CalendarDateRangePicker from 'components/DateRangePicker';
import { API_BASE_URL } from 'config';
import axios from 'axios';

const TechnicianHoursTable = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [dateRange, setDateRange] = useState([
    dayjs().startOf('month'),
    dayjs(),
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const apiRef = useGridApiRef();

  const processData = useCallback((rawData) => {
    const techData = {};
    const daysWorked = {};

    rawData.forEach((ro) => {
      const roDate = ro.ClosedDate.split('T')[0];
      ro.hrsTechNo.forEach((techNo, index) => {
        if (!techData[techNo]) {
          techData[techNo] = {
            soldHours: 0,
            customerHours: 0,
            warrantyHours: 0,
            ispHours: 0,
            cost: 0,
            sale: 0,
            roCount: 0,
            laborTypes: {},
          };
          daysWorked[techNo] = new Set();
        }
        const hours = Number(ro.hrsSoldHours[index]) || 0;
        techData[techNo].soldHours += hours;
        techData[techNo].cost += Number(ro.hrsCost[index]) || 0;
        techData[techNo].sale += Number(ro.hrsSale[index]) || 0;
        techData[techNo].roCount += 1;
        daysWorked[techNo].add(roDate);

        const laborType = ro.hrsLaborType[index];
        switch (laborType) {
          case 'ISP':
            techData[techNo].ispHours += hours;
            break;
          case 'W':
            techData[techNo].warrantyHours =
              (techData[techNo].warrantyHours || 0) + hours;
            break;
          case 'C':
            techData[techNo].customerHours =
              (techData[techNo].customerHours || 0) + hours;
            break;
          default:
            techData[techNo].laborTypes[laborType] =
              (techData[techNo].laborTypes[laborType] || 0) + hours;
            break;
        }
      });
    });

    return Object.entries(techData).map(([techNo, data]) => {
      const totalDaysWorked = daysWorked[techNo].size;
      const totalPossibleHours = totalDaysWorked * 8; // 8-hour workday assumption
      const efficiency = ((data.soldHours / totalPossibleHours) * 100).toFixed(
        2,
      );

      return {
        techNo,
        soldHours: data.soldHours,
        customerHours: data.customerHours,
        warrantyHours: data.warrantyHours,
        ispHours: data.ispHours,
        avgSoldHoursPerDay: (data.soldHours / totalDaysWorked).toFixed(2),
        efficiency: efficiency,
        utilization: Math.min(100, efficiency).toFixed(2), // Capped at 100%
        cost: data.cost,
        sale: data.sale,
        profit: data.sale - data.cost,
        roCount: data.roCount,
        daysWorked: totalDaysWorked,
        laborTypes: Object.entries(data.laborTypes)
          .map(([type, hours]) => `${type}: ${hours.toFixed(2)}`)
          .join(', '),
      };
    });
  }, []);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${API_BASE_URL}/api/service-ro-closed`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            dealerID: user.dealerid,
            startDate: dateRange[0].format('YYYY-MM-DD'),
            endDate: dateRange[1].format('YYYY-MM-DD'),
          },
        },
      );
      const processedData = processData(response.data);
      setData(processedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(
        'Failed to fetch technician hours data. Please try again later.',
      );
    } finally {
      setIsLoading(false);
    }
  }, [dateRange, getAccessTokenSilently, user.dealerid, processData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const columns = useMemo(
    () => [
      { field: 'techNo', headerName: 'Tech No', width: 100 },
      {
        field: 'roCount',
        headerName: 'RO Count',
        width: 100,
        align: 'center',
        type: 'number',
      },
      {
        field: 'daysWorked',
        headerName: 'Days Worked',
        align: 'center',
        width: 100,
        type: 'number',
      },
      {
        field: 'soldHours',
        headerName: 'Sold Hours',
        width: 120,
        type: 'number',
        valueFormatter: (value) => value.toFixed(2),
      },
      {
        field: 'avgSoldHoursPerDay',
        headerName: 'Avg Sold Hours/Day',
        width: 170,
        type: 'number',
      },
      {
        field: 'efficiency',
        headerName: 'Efficiency %',
        width: 130,
        type: 'number',
        valueFormatter: (value) => `${value}%`,
      },
      {
        field: 'utilization',
        headerName: 'Utilization %',
        width: 130,
        type: 'number',
        valueFormatter: (value) => `${value}%`,
      },
      {
        field: 'cost',
        headerName: 'Cost',
        width: 120,
        type: 'number',
        valueFormatter: (value) => `$${value.toFixed(2)}`,
      },
      {
        field: 'sale',
        headerName: 'Sale',
        width: 120,
        type: 'number',
        valueFormatter: (value) => `$${value.toFixed(2)}`,
      },
      {
        field: 'profit',
        headerName: 'Profit',
        width: 120,
        type: 'number',
        valueFormatter: (value) => `$${value.toFixed(2)}`,
      },
      {
        field: 'customerHours',
        headerName: 'Customer Hrs',
        width: 130,
        type: 'number',
        valueFormatter: (value) => value.toFixed(2),
      },
      {
        field: 'warrantyHours',
        headerName: 'Warranty Hrs',
        width: 130,
        type: 'number',
        valueFormatter: (value) => value.toFixed(2),
      },
      {
        field: 'ispHours',
        headerName: 'Internal Hrs',
        width: 130,
        type: 'number',
        valueFormatter: (value) => value.toFixed(2),
      },
      {
        field: 'laborTypes',
        headerName: 'Other Labor Types',
        minWidth: 300,
        flex: 1,
      },
    ],
    [],
  );

  return (
    isAuthenticated && (
      <Paper
        sx={{
          height: 'calc(100vh - 164px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 2,
          }}
        >
          <Typography variant="h6">Technician Performance Summary</Typography>
          <CalendarDateRangePicker
            dateRange={dateRange}
            onDateRangeChange={handleDateRangeChange}
          />
        </Box>
        <DataGridPremium
          pagination
          pageSizeOptions={[25, 50, 100]}
          apiRef={apiRef}
          sx={{
            borderRadius: 0,
            border: 'none',
          }}
          rows={data}
          columns={columns}
          loading={isLoading}
          getRowId={(row) => row.techNo}
          slots={{
            toolbar: () => (
              <>
                <Stack direction="row" spacing={2} alignItems="center" p={2}>
                  <GridToolbar />
                  <Box sx={{ flexGrow: 1 }} />
                  <GridToolbarQuickFilter />
                </Stack>
                <Divider />
              </>
            ),
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'soldHours', sort: 'desc' }],
            },
          }}
          disableRowSelectionOnClick
        />
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
        >
          <Alert
            onClose={() => setError(null)}
            severity="error"
            sx={{ width: '100%' }}
          >
            {error}
          </Alert>
        </Snackbar>
      </Paper>
    )
  );
};

export default TechnicianHoursTable;
