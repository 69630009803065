import * as React from 'react';
import {
  ThemeProvider,
  createTheme,
  styled,
  useTheme,
} from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Avatar,
  Box,
  CssBaseline,
  Drawer,
  Fab,
  Button,
  IconButton,
  Toolbar,
  Typography,
  ToggleButton,
  Stack,
  Tooltip,
  GlobalStyles,
  Menu,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import * as Icons from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import SettingsDialog from 'components/settings';
import MenuItems from './MenuItems';
import ScrollTop from './ScrollTop';
import { orange } from '@mui/material/colors';

// Constants
const drawerWidth = 240;
const miniDrawerWidth = 64;

// Theme creation
const lightTheme = createTheme();
const darkTheme = createTheme({
  palette: { mode: 'dark' },
});

// Styled components
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 4,
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'open' && prop !== 'isSmallScreen' && prop !== 'isMini',
})(({ theme, open, isSmallScreen, isMini }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${
    isSmallScreen ? 0 : isMini ? miniDrawerWidth : drawerWidth
  }px)`,
  marginLeft: isSmallScreen ? 0 : isMini ? miniDrawerWidth : drawerWidth,
  ...(open &&
    !isSmallScreen && {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  ...(isSmallScreen && {
    marginLeft: 0,
    width: '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

// Global styles
const globalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      body: {
        background:
          theme.palette.mode === 'light'
            ? `linear-gradient(to bottom, ${theme.palette.grey[50]}, ${theme.palette.grey[200]})`
            : `linear-gradient(to bottom, ${theme.palette.grey[900]}, ${theme.palette.grey[800]})`,
        minHeight: '100vh',
        margin: 0,
        padding: 0,
        lineHeight: 1.5,
        fontWeight: 400,
        fontSize: '0.875rem',
      },
    })}
  />
);

// Main Layout component
const Layout = (props) => {
  // State
  const [openSettingsDialog, setOpenSettingsDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [themeMode, setThemeMode] = React.useState('light');
  const [drawerMode, setDrawerMode] = React.useState('sales');
  const [open, setOpen] = React.useState(true);
  const [isMini, setIsMini] = React.useState(false);
  const [rotateIcon, setRotateIcon] = React.useState(true);

  // Hooks
  const { user, logout, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Memoized values
  const openProfileMenu = Boolean(anchorEl);

  const toggleModes = React.useMemo(
    () => [
      { value: 'sales', label: 'Sales', icon: Icons.SellTwoTone },
      { value: 'service', label: 'Service', icon: Icons.CarRepairTwoTone },
      {
        value: 'accounting',
        label: 'Accounting',
        icon: Icons.AccountBalanceTwoTone,
      },
      {
        value: 'experiments',
        label: 'Experiments',
        icon: Icons.ScienceTwoTone,
      },
    ],
    [],
  );

  const routeToModeMap = React.useMemo(
    () => ({
      '/dashboard': 'sales',
      '/sales': 'sales',
      '/service': 'service',
      '/accounting': 'accounting',
      '/experiment': 'experiments',
    }),
    [],
  );

  const currentMode = React.useMemo(
    () => toggleModes.find((mode) => mode.value === drawerMode),
    [toggleModes, drawerMode],
  );

  // Effects
  React.useEffect(() => {
    const path = '/' + location.pathname.split('/')[1];
    const newMode = routeToModeMap[path] || 'sales';
    setDrawerMode(newMode);
  }, [location.pathname, routeToModeMap]);

  // Callbacks
  const userHasRole = React.useCallback(
    (role) =>
      isAuthenticated &&
      user?.['https://www.dealeros.io/roles']?.includes(role),
    [isAuthenticated, user],
  );

  React.useEffect(() => {
    if (userHasRole('salesperson')) {
      navigate('/sales/quickswipe');
    }
  }, [user, isAuthenticated, navigate, userHasRole]);

  // const userCanAccessQuickswipe = React.useCallback(
  //   () => userHasRole('salesperson'),
  //   [userHasRole],
  // );
  const userCanSeeExperiments = React.useCallback(
    () => userHasRole('admin'),
    [userHasRole],
  );
  const userCanSeeSettings = React.useCallback(
    () => userHasRole('admin'),
    [userHasRole],
  );
  const userCanSeeFinanceCommission = React.useCallback(
    () => userHasRole('admin') || userHasRole('finance'),
    [userHasRole],
  );
  const userCanSeeSalesManagerCommission = React.useCallback(
    () => userHasRole('admin') || userHasRole('salesmanager'),
    [userHasRole],
  );

  const handleClickProfileMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseProfileMenu = () => setAnchorEl(null);

  const handleDrawerToggle = () => {
    setRotateIcon((prev) => !prev);
    if (isSmallScreen) {
      setOpen(!open);
    } else {
      setIsMini(!isMini);
    }
  };

  const handleSettingsDialogOpen = React.useCallback(() => {
    setOpenSettingsDialog(true);
  }, []);

  const toggleDrawerMode = React.useCallback(() => {
    setDrawerMode((prevMode) => {
      const currentIndex = toggleModes.findIndex(
        (mode) => mode.value === prevMode,
      );
      const nextIndex = (currentIndex + 1) % toggleModes.length;
      return toggleModes[nextIndex].value;
    });
  }, [toggleModes]);

  const returnHome = React.useCallback(() => {
    navigate('/dashboard');
  }, [navigate]);

  // List creation functions
  const createSalesList = React.useCallback(
    () => [
      {
        to: '/admin',
        label: 'Admin',
        Icon: Icons.AdminPanelSettingsTwoTone,
        visible: userHasRole('admin'),
      },
      {
        to: '/dashboard',
        label: 'Dashboard',
        Icon: Icons.DashboardTwoTone,
        visible: !userHasRole('salesperson'),
      },
      {
        to: '/sales/coversheet',
        label: 'Coversheet',
        Icon: Icons.FactCheckTwoTone,
        visible: !userHasRole('salesperson'),
      },
      {
        label: 'Desking',
        Icon: Icons.EditTwoTone,
        onClick: () =>
          window.open(
            '/sales/desking',
            'DeskingWindow',
            'width=1200,height=800',
          ),
        visible: !userHasRole('salesperson') && userCanSeeExperiments(),
      },
      {
        label: 'Commissions',
        Icon: Icons.LocalAtmTwoTone,
        visible: !userHasRole('salesperson'),
        items: [
          {
            to: '/commissions/salesmanagers',
            label: 'Manager Rankings',
            department: 'sales',
            Icon: Icons.MoneyTwoTone,
            visible: userCanSeeSalesManagerCommission,
          },
          {
            to: '/commissions/financemanagers',
            label: 'Finance Rankings',
            department: 'sales',
            Icon: Icons.MoneyTwoTone,
            visible: userCanSeeFinanceCommission,
          },
          {
            to: '/commissions/reports',
            label: 'Commission Reports',
            department: 'sales',
            Icon: Icons.LocalAtmTwoTone,
            visible: userCanSeeSalesManagerCommission,
          },
        ],
      },
      {
        label: 'Logs',
        Icon: Icons.AppRegistration,
        visible: !userHasRole('salesperson'),
        items: [
          {
            to: '/log/cardeals',
            label: 'Deals',
            department: 'sales',
            Icon: Icons.LocalOfferTwoTone,
            visible: !userHasRole('salesperson'),
          },
          {
            to: '/log/coversheetlog',
            label: 'Coversheet',
            department: 'sales',
            Icon: Icons.AssignmentTwoTone,
            visible: !userHasRole('salesperson'),
          },
          {
            to: '/log/reservations',
            label: 'Reserved',
            department: 'sales',
            Icon: Icons.BookOnlineTwoTone,
            visible: !userHasRole('salesperson'),
          },
          {
            to: '/log/gaslog',
            label: 'Gas Log',
            department: 'sales',
            Icon: Icons.LocalGasStationTwoTone,
            visible: !userHasRole('salesperson'),
          },
        ],
      },
      {
        label: 'Reports',
        Icon: Icons.AssessmentTwoTone,
        visible: !userHasRole('salesperson'),
        items: [
          {
            to: '/reports/reportbuilder',
            label: 'Report Builder',
            department: 'sales',
            Icon: Icons.AssessmentTwoTone,
            visible: !userHasRole('salesperson'),
          },
          {
            to: '/reports/pace',
            label: 'Pace',
            department: 'sales',
            Icon: Icons.TrendingUpTwoTone,
            visible: !userHasRole('salesperson'),
          },
          {
            to: '/reports/weekendsummary',
            label: 'Weekend Summary',
            department: 'sales',
            Icon: Icons.TrendingUpTwoTone,
            visible: !userHasRole('salesperson'),
          },
        ],
      },
      {
        to: '/sales/inventory',
        label: 'Inventory',
        Icon: Icons.DirectionsCarTwoTone,
        visible: !userHasRole('salesperson') && userCanSeeExperiments(),
      },
      {
        to: '/sales/trades',
        label: 'Trades',
        Icon: Icons.CompareArrowsTwoTone,
        visible: !userHasRole('salesperson'),
      }
    ],
    [
      userHasRole,
      userCanSeeExperiments,
      userCanSeeSalesManagerCommission,
      userCanSeeFinanceCommission,
    ],
  );

  const createServiceList = React.useCallback(
    () => [
      {
        to: '/service/closedrepairorders',
        label: 'Closed ROs',
        Icon: Icons.CarRepairTwoTone,
        visible: !userHasRole('salesperson'),
      },
      {
        to: '/service/technicianhours',
        label: 'Technician Hours',
        Icon: Icons.BuildTwoTone,
        visible: !userHasRole('salesperson'),
      },
      {
        to: '/service/repairorders',
        label: "RTRO's",
        Icon: Icons.PlumbingTwoTone,
        visible: !userHasRole('salesperson'),
      },
      {
        to: '/service/purples',
        label: 'Purples',
        Icon: Icons.PrecisionManufacturingTwoTone,
        visible: !userHasRole('salesperson'),
      },
    ],
    [userHasRole],
  );

  const createAccountingList = React.useCallback(
    () => [
      {
        to: '/accounting/expenses',
        label: 'Expenses',
        Icon: Icons.ScienceTwoTone,
        visible: !userHasRole('salesperson') && userCanSeeExperiments(),
      },
    ],
    [userHasRole, userCanSeeExperiments],
  );

  const createExperimentList = React.useCallback(
    () => [
      {
        to: '/experiment/csvupload',
        label: 'CSV Upload',
        Icon: Icons.CloudUploadTwoTone,
        visible: !userHasRole('salesperson'),
      },
    ],
    [userHasRole],
  );

  // Filter visible items
  const filterVisibleItems = React.useCallback((items) => {
    return items
      .filter((item) => item.visible === undefined || item.visible)
      .map((item) => {
        if (item.items) {
          return {
            ...item,
            items: filterVisibleItems(item.items),
          };
        }
        return item;
      });
  }, []);

  // Create lists
  const salesList = React.useMemo(() => createSalesList(), [createSalesList]);
  const serviceList = React.useMemo(
    () => createServiceList(),
    [createServiceList],
  );
  const accountingList = React.useMemo(
    () => createAccountingList(),
    [createAccountingList],
  );
  const experimentList = React.useMemo(
    () => createExperimentList(),
    [createExperimentList],
  );

  const headerNameMap = React.useMemo(() => {
    const allListItems = [
      ...salesList,
      ...serviceList,
      ...accountingList,
      ...experimentList,
    ];
    return allListItems.reduce((acc, item) => {
      if (item.to) {
        acc[item.to] = item.label;
      }
      if (item.items) {
        item.items.forEach((subItem) => {
          if (subItem.to) {
            acc[subItem.to] = subItem.label;
          }
        });
      }
      return acc;
    }, {});
  }, [salesList, serviceList, accountingList, experimentList]);

  const HeaderName = React.memo(() => {
    const location = useLocation();
    return (
      <Typography
        component="div"
        variant="body1"
        color="inherit"
        sx={{ fontWeight: 'bold' }}
        gutterBottom
        noWrap
      >
        {headerNameMap[location.pathname] || 'Home'}
      </Typography>
    );
  });

  // Render
  return (
    <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
      {globalStyles}
      <Box sx={{ display: 'flex', width: '100%' }}>
        <CssBaseline />

        <AppBar
          position="fixed"
          open={open}
          isSmallScreen={isSmallScreen}
          isMini={isMini}
        >
          <Toolbar sx={{ flexWrap: 'wrap' }}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ flexGrow: 1 }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                {rotateIcon ? (
                  <Icons.MenuOpen />
                ) : (
                  <Icons.MenuOpen style={{ transform: 'rotate(180deg)' }} />
                )}
              </IconButton>
              {!userHasRole('salesperson') && !isSmallScreen && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Box>
                    <Tooltip title="Select Drawer Mode" placement="right">
                      <ToggleButton
                        value={drawerMode}
                        onChange={toggleDrawerMode}
                        size="small"
                        sx={{ width: '100%' }}
                      >
                        <currentMode.icon />
                        <Box sx={{ ml: 1 }}>{currentMode.label}</Box>
                      </ToggleButton>
                    </Tooltip>
                  </Box>
                  <HeaderName />
                </Stack>
              )}
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton
                color="inherit"
                onClick={() =>
                  setThemeMode((prev) => (prev === 'light' ? 'dark' : 'light'))
                }
                size="small"
              >
                {themeMode === 'light' ? (
                  <Icons.Brightness4TwoTone />
                ) : (
                  <Icons.Brightness7TwoTone />
                )}
              </IconButton>
              <Tooltip title="Settings">
                <Button
                  id="profile-button"
                  aria-controls={openProfileMenu ? 'profile-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openProfileMenu ? 'true' : undefined}
                  onClick={handleClickProfileMenu}
                  startIcon={
                    <Avatar
                      alt={user?.name}
                      src={user?.picture}
                      sx={{ width: 28, height: 28 }}
                    />
                  }
                  size="small"
                  sx={{ color: 'inherit' }}
                >
                  {isSmallScreen ? '' : user?.name}
                </Button>
              </Tooltip>

              <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                open={openProfileMenu}
                onClose={handleCloseProfileMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {isAuthenticated && (
                  <MenuItem
                    onClick={() => logout({ returnTo: window.location.origin })}
                  >
                    Logout
                  </MenuItem>
                )}
                {userHasRole('admin') && (
                  <MenuItem onClick={() => navigate('/profile')}>
                    Profile
                  </MenuItem>
                )}
                {!userHasRole('salesperson') && userCanSeeSettings() && (
                  <MenuItem onClick={handleSettingsDialogOpen}>
                    Settings
                  </MenuItem>
                )}
              </Menu>
            </Stack>
          </Toolbar>
        </AppBar>

        <Drawer
          variant={isSmallScreen ? 'temporary' : 'permanent'}
          open={isSmallScreen ? open : true}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            width: isSmallScreen
              ? drawerWidth
              : isMini
              ? miniDrawerWidth
              : drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: isSmallScreen
                ? drawerWidth
                : isMini
                ? miniDrawerWidth
                : drawerWidth,
              boxSizing: 'border-box',
              overflowX: 'hidden',
            },
          }}
        >
          <DrawerHeader>
            <IconButton onClick={returnHome}>
              <Avatar
                sx={{
                  bgcolor: orange[500],
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
                alt="D"
              >
                D
              </Avatar>
            </IconButton>
            {(isSmallScreen || !isMini) && (
              <Typography variant="h6" color="inherit" noWrap sx={{ ml: 2 }}>
                <span style={{ fontWeight: 'bold' }}>Dealer</span>OS
              </Typography>
            )}
          </DrawerHeader>
          {!userHasRole('salesperson') && isSmallScreen && (
            <Box sx={{ p: 1 }}>
              <Tooltip title="Select Drawer Mode" placement="right">
                <ToggleButton
                  value={drawerMode}
                  onChange={toggleDrawerMode}
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <currentMode.icon />
                  <Box sx={{ ml: 1 }}>{currentMode.label}</Box>
                </ToggleButton>
              </Tooltip>
            </Box>
          )}
          {drawerMode === 'sales' && (
            <MenuItems
              items={filterVisibleItems(salesList)}
              open={isSmallScreen || !isMini}
            />
          )}
          {drawerMode === 'service' && (
            <MenuItems
              items={filterVisibleItems(serviceList)}
              open={isSmallScreen || !isMini}
            />
          )}
          {drawerMode === 'accounting' && (
            <MenuItems
              items={filterVisibleItems(accountingList)}
              open={isSmallScreen || !isMini}
            />
          )}
          {drawerMode === 'experiments' && (
            <MenuItems
              items={filterVisibleItems(experimentList)}
              open={isSmallScreen || !isMini}
            />
          )}
        </Drawer>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: `calc(100% - ${
              isSmallScreen ? 0 : isMini ? miniDrawerWidth : drawerWidth
            }px)`,
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
        >
          <DrawerHeader id="back-to-top-anchor" />
          {props.children}
        </Box>
        <SettingsDialog
          open={openSettingsDialog}
          setOpen={setOpenSettingsDialog}
        />
        <ScrollTop {...props}>
          <Fab size="small" aria-label="scroll back to top">
            <Icons.KeyboardArrowUp />
          </Fab>
        </ScrollTop>
      </Box>
    </ThemeProvider>
  );
};

export default React.memo(Layout);
