import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Paper,
} from '@mui/material';
import { API_BASE_URL } from 'config';

const AddIncentiveDialog = ({ open, onClose, onSubmit, selectedLender, editingIncentive, isSubmitting }) => {
  const [years, setYears] = useState([]);
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [modelNumbersWithTrims, setModelNumbersWithTrims] = useState([]);
  
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMake, setSelectedMake] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedModelNumbers, setSelectedModelNumbers] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState('');
  const [expireDate, setExpireDate] = useState('');

  useEffect(() => {
    fetchYears();
  }, []);

  useEffect(() => {
    if (open) {
      if (editingIncentive) {
        setSelectedYear(editingIncentive.model_year);
        setSelectedMake(editingIncentive.make);
        setSelectedModel(editingIncentive.model_name);
        setSelectedModelNumbers(editingIncentive.model_numbers);
        setEffectiveDate(editingIncentive.effective_date.split('T')[0]);
        setExpireDate(editingIncentive.expire_date.split('T')[0]);
        
        // Fetch makes, models, and model numbers based on the editing incentive
        fetchMakes(editingIncentive.model_year);
        fetchModels(editingIncentive.model_year, editingIncentive.make);
        fetchModelNumbers(editingIncentive.model_year, editingIncentive.make, editingIncentive.model_name);
      } else {
        resetForm();
      }
    }
  }, [open, editingIncentive]);

  useEffect(() => {
    if (selectedYear) {
      fetchMakes(selectedYear);
    }
  }, [selectedYear]);

  useEffect(() => {
    if (selectedYear && selectedMake) {
      fetchModels(selectedYear, selectedMake);
    }
  }, [selectedYear, selectedMake]);

  useEffect(() => {
    if (selectedYear && selectedMake && selectedModel) {
      fetchModelNumbers(selectedYear, selectedMake, selectedModel);
    }
  }, [selectedYear, selectedMake, selectedModel]);

  const fetchYears = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/models/details`);
      setYears(response.data.years);
    } catch (error) {
      console.error('Error fetching years:', error);
    }
  };

  const fetchMakes = async (year) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/models/details?year=${year}`);
      setMakes(response.data.makes || []);
    } catch (error) {
      console.error('Error fetching makes:', error);
    }
  };

  const fetchModels = async (year, make) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/models/details?year=${year}&make=${make}`);
      setModels(response.data.models || []);
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };

  const fetchModelNumbers = async (year, make, model) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/models/details?year=${year}&make=${make}&model=${model}`);
      setModelNumbersWithTrims(response.data.modelNumbers || []);
    } catch (error) {
      console.error('Error fetching model numbers:', error);
    }
  };

  const handleSubmit = () => {
    const incentiveData = {
      model_year: selectedYear,
      make: selectedMake,
      model_name: selectedModel,
      model_numbers: selectedModelNumbers,
      effective_date: effectiveDate,
      expire_date: expireDate,
      lenderId: selectedLender._id,
    };
    
    if (editingIncentive) {
      incentiveData._id = editingIncentive._id;
    }
    
    onSubmit(incentiveData);
  };

  const resetForm = () => {
    setSelectedYear('');
    setSelectedMake('');
    setSelectedModel('');
    setSelectedModelNumbers([]);
    setModelNumbersWithTrims([]);
    setEffectiveDate('');
    setExpireDate('');
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleModelNumberChange = (modelNumber) => {
    setSelectedModelNumbers(prev => 
      prev.includes(modelNumber) 
        ? prev.filter(num => num !== modelNumber)
        : [...prev, modelNumber]
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{editingIncentive ? 'Edit Incentive' : 'Add Incentive'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Effective Date"
              type="date"
              fullWidth
              margin="normal"
              value={effectiveDate}
              onChange={(e) => setEffectiveDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Expire Date"
              type="date"
              fullWidth
              margin="normal"
              value={expireDate}
              onChange={(e) => setExpireDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: '16px' }}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="year-label">Year</InputLabel>
              <Select
                labelId="year-label"
                value={selectedYear}
                onChange={(e) => {
                  setSelectedYear(e.target.value);
                  setSelectedMake('');
                  setSelectedModel('');
                  setSelectedModelNumbers([]);
                  setModelNumbersWithTrims([]);
                }}
                label="Year"
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="make-label">Make</InputLabel>
              <Select
                labelId="make-label"
                value={selectedMake}
                onChange={(e) => {
                  setSelectedMake(e.target.value);
                  setSelectedModel('');
                  setSelectedModelNumbers([]);
                  setModelNumbersWithTrims([]);
                }}
                label="Make"
                disabled={!selectedYear}
              >
                {makes.map((make) => (
                  <MenuItem key={make} value={make}>{make}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="model-label">Model</InputLabel>
              <Select
                labelId="model-label"
                value={selectedModel}
                onChange={(e) => {
                  setSelectedModel(e.target.value);
                  setSelectedModelNumbers([]);
                }}
                label="Model"
                disabled={!selectedMake}
              >
                {models.map((model) => (
                  <MenuItem key={model} value={model}>{model}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {selectedModel && modelNumbersWithTrims.length > 0 && (
          <Paper elevation={3} style={{ marginTop: '24px', padding: '16px' }}>
            <Typography variant="h6" gutterBottom>Model Numbers</Typography>
            <FormGroup>
              <Grid container spacing={2}>
                {modelNumbersWithTrims.map(({ model_no, trim }) => (
                  <Grid item xs={6} key={model_no}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedModelNumbers.includes(model_no)}
                          onChange={() => handleModelNumberChange(model_no)}
                        />
                      }
                      label={`${model_no} - ${trim}`}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </Paper>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>Cancel</Button>
        <Button 
          onClick={handleSubmit} 
          variant="contained" 
          color="primary"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : (editingIncentive ? 'Update' : 'Submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddIncentiveDialog;