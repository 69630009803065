import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Container,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Toolbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { styled } from '@mui/system';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EventNoteIcon from '@mui/icons-material/EventNote';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CalculateIcon from '@mui/icons-material/Calculate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

import { API_BASE_URL } from 'config';

import FixedNavBar from './FixedNavBar';
import LeaseSummary from './LeaseSummary';

import './print.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 600,
    },
  },
});

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'box-shadow 0.3s',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  '& > svg': {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

const AutoLeaseCalculator = () => {
  const [inputs, setInputs] = useState({
    electric: false,
    bodyStyle: 'Car',
    numberOfTires: 5,
    weight: '',
    msrp: 0,
    sellingPrice: 0,
    cashDown: 0,
    rebate: '',
    tradeEquity: '',
    residualType: 'dollar',
    residualValue: 0,
    moneyFactor: 0,
    term: 36,
    acquisitionFee: 650,
    weOweTotal: 0,
    contractsTotal: 0,
    county: 'Los Angeles',
    city: 'Valencia',
  });

  const [calculatedValues, setCalculatedValues] = useState({
    residualDollar: 0,
    residualPercentage: 0,
    basePayment: 0,
    taxAmount: 0,
    totalPayment: 0,
    totalDMVFees: 0,
    totalDealerFees: 0,
    taxRate: 0,
    dmvFeesBreakdown: {},
    dealerFeesBreakdown: {},
    driveOff: 0,
    capCost: 0,
    capReduction: 0,
  });

  const [taxRates, setTaxRates] = useState([]);
  const [counties, setCounties] = useState([]);
  const [cities, setCities] = useState([]);
  const [showSummary, setShowSummary] = useState(false);

  useEffect(() => {
    fetchTaxRates();
  }, []);

  useEffect(() => {
    if (inputs.county) {
      const citiesInCounty = taxRates.filter(
        (rate) => rate.County.toLowerCase() === inputs.county.toLowerCase(),
      );
      setCities(
        Array.from(new Set(citiesInCounty.map((rate) => rate.City))).sort(),
      );
    }
  }, [inputs.county, taxRates]);

  useEffect(() => {
    calculateLease();
  }, [inputs]);

  const fetchTaxRates = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/fees/alltaxrates`);
      setTaxRates(response.data);
      const sortedCounties = Array.from(
        new Set(response.data.map((rate) => rate.County)),
      ).sort();
      setCounties(sortedCounties);
      setInputs((prev) => ({
        ...prev,
        county: 'Los Angeles',
        city: 'Los Angeles',
      }));
    } catch (error) {
      console.error('Error fetching tax rates:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value === 'true' }));
  };

  const calculateLease = async () => {
    const sellingPrice =
      parseFloat(inputs.sellingPrice) + parseFloat(inputs.weOweTotal) || 0;
    const msrp = parseFloat(inputs.msrp) || 0;
    const term = parseInt(inputs.term) || 1;
    const moneyFactor = parseFloat(inputs.moneyFactor) || 0;
    const cashDown = parseFloat(inputs.cashDown) || 0;
    const rebate = parseFloat(inputs.rebate) || 0;
    const tradeEquity = parseFloat(inputs.tradeEquity) || 0;
    const acquisitionFee = parseFloat(inputs.acquisitionFee) || 0;
    const contractsTotal = parseFloat(inputs.contractsTotal) || 0;

    let residualDollar, residualPercentage;
    if (inputs.residualType === 'percentage') {
      residualPercentage = parseFloat(inputs.residualValue) || 0;
      residualDollar = (msrp * residualPercentage) / 100;
    } else {
      residualDollar = parseFloat(inputs.residualValue) || 0;
      residualPercentage = (residualDollar / msrp) * 100;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/api/fees/`, {
        vehicles: [
          {
            vehicleType: 'New',
            bodyStyle: inputs.bodyStyle,
            electric: inputs.electric,
            numberOfTires: parseInt(inputs.numberOfTires),
            weight: parseFloat(inputs.weight) || undefined,
            purchasePrice: sellingPrice,
            state: 'CA',
            county: inputs.county,
            city: inputs.city,
          },
        ],
      });

      const {
        totalFees,
        totalDMVFees,
        totalDealerFees,
        salesTaxRate,
        dmvFees,
        dealerFees,
      } = response.data[0];

      let addToCapCost = acquisitionFee + contractsTotal;

      // Initial Cap Cost Calculation
      let capCost = sellingPrice + addToCapCost;
      let depreciation = (capCost - residualDollar) / term;
      let rentCharge = (capCost + residualDollar) * moneyFactor;
      let basePayment = depreciation + rentCharge;
      let taxAmount = basePayment * salesTaxRate;
      let totalPayment = basePayment + taxAmount;
      let driveOff = totalPayment + totalFees;

      // Initial Cap Reduction Calculation
      let capReduction;
      let capReductionTax;
      let capReductionTotal;

      let previousPayment;
      let iterationCount = 0;

      // Iterate until the drive-off amount converges
      do {
        previousPayment = totalPayment;

        if (tradeEquity >= 0) {
          capReduction = cashDown + rebate + tradeEquity - driveOff;
        } else {
          capReduction = cashDown + rebate - driveOff;
        }

        capReductionTax = capReduction * salesTaxRate;
        capReductionTotal = capReduction - capReductionTax;

        if (tradeEquity >= 0) {
          capCost = sellingPrice + addToCapCost - capReductionTotal;
        } else {
          capCost =
            sellingPrice + addToCapCost - capReductionTotal - tradeEquity;
        }
        depreciation = (capCost - residualDollar) / term;
        rentCharge = (capCost + residualDollar) * moneyFactor;
        basePayment = depreciation + rentCharge;
        taxAmount = basePayment * salesTaxRate;
        totalPayment = basePayment + taxAmount;
        driveOff = totalPayment + totalFees;

        iterationCount++;
      } while (
        Math.abs(totalPayment - previousPayment) > 0.01 &&
        iterationCount < 100
      );

      setCalculatedValues({
        residualDollar,
        residualPercentage,
        basePayment,
        taxAmount,
        totalPayment,
        totalDMVFees,
        totalDealerFees,
        totalFees,
        taxRate: salesTaxRate * 100,
        dmvFeesBreakdown: dmvFees || {},
        dealerFeesBreakdown: dealerFees || {},
        driveOff,
        capCost,
        capReduction,
      });
    } catch (error) {
      console.error('Error calculating fees:', error);
      setCalculatedValues((prev) => ({
        ...prev,
        basePayment: 0,
        taxAmount: 0,
        totalPayment: 0,
        totalDMVFees: 0,
        totalDealerFees: 0,
        totalFees: 0,
        taxRate: 0,
        dmvFeesBreakdown: {},
        dealerFeesBreakdown: {},
        driveOff: 0,
        capCost: 0,
        capReduction: 0,
      }));
    }
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  const handleOpenSummary = () => {
    setShowSummary(true);
  };

  const handleCloseSummary = () => {
    setShowSummary(false);
  };

  // Prepare lease data for summary
  const leaseData = {
    ...inputs,
    ...calculatedValues,
  };

  if (showSummary) {
    return <LeaseSummary leaseData={leaseData} onClose={handleCloseSummary} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FixedNavBar
        cashDown={
          inputs.cashDown + inputs.rebate + inputs.tradeEquity <
          parseFloat(calculatedValues.driveOff)
            ? parseFloat(calculatedValues.driveOff)
            : parseFloat(inputs.cashDown)
        }
        totalPayment={calculatedValues.totalPayment}
      />
      <Toolbar />
      <Container maxWidth="lg">
        <Box sx={{ paddingY: 3 }}>
          <Grid container spacing={1}>
            {/* Vehicle Details */}
            <Grid item xs={12} md={6} lg={6}>
              <StyledCard>
                <CardContent>
                  <IconWrapper>
                    <DirectionsCarIcon />
                    <Typography variant="h6">Vehicle Details</Typography>
                  </IconWrapper>
                  <FormControl component="fieldset" sx={{ mb: 2 }}>
                    <FormLabel component="legend">Electric Vehicle?</FormLabel>
                    <RadioGroup
                      row
                      name="electric"
                      value={inputs.electric.toString()}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Body Style</InputLabel>
                    <Select
                      name="bodyStyle"
                      value={inputs.bodyStyle}
                      label="Body Style"
                      onChange={handleInputChange}
                      onFocus={handleFocus}
                    >
                      <MenuItem value="Car">Car</MenuItem>
                      <MenuItem value="Truck">Truck</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    label="Number of Tires"
                    name="numberOfTires"
                    type="number"
                    value={inputs.numberOfTires}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                  />
                  {(inputs.bodyStyle === 'Truck' || inputs.electric) && (
                    <TextField
                      fullWidth
                      label="Weight (lbs)"
                      name="weight"
                      type="number"
                      value={inputs.weight}
                      onChange={handleInputChange}
                      onFocus={handleFocus}
                    />
                  )}
                </CardContent>
              </StyledCard>
            </Grid>

            {/* Pricing */}
            <Grid item xs={12} md={6} lg={6}>
              <StyledCard>
                <CardContent>
                  <IconWrapper>
                    <AttachMoneyIcon />
                    <Typography variant="h6">Pricing</Typography>
                  </IconWrapper>
                  <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    label="MSRP"
                    name="msrp"
                    type="number"
                    value={inputs.msrp}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                  />
                  <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    label="Selling Price"
                    name="sellingPrice"
                    type="number"
                    value={inputs.sellingPrice}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                  />
                  <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    label="Cash Down"
                    name="cashDown"
                    type="number"
                    value={inputs.cashDown}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                  />
                  <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    label="Rebate"
                    name="rebate"
                    type="number"
                    value={inputs.rebate}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                  />
                  <TextField
                    fullWidth
                    label="Trade Equity"
                    name="tradeEquity"
                    type="number"
                    value={inputs.tradeEquity}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                  />
                </CardContent>
              </StyledCard>
            </Grid>

            {/* Lease Terms */}
            <Grid item xs={12} md={6} lg={4}>
              <StyledCard>
                <CardContent>
                  <IconWrapper>
                    <EventNoteIcon />
                    <Typography variant="h6">Lease Terms</Typography>
                  </IconWrapper>
                  <FormControl component="fieldset" fullWidth sx={{ mb: 2 }}>
                    <FormLabel component="legend">Residual</FormLabel>
                    <RadioGroup
                      row
                      name="residualType"
                      value={inputs.residualType}
                      onChange={handleInputChange}
                      onFocus={handleFocus}
                    >
                      <FormControlLabel
                        value="percentage"
                        control={<Radio />}
                        label="Percentage"
                      />
                      <FormControlLabel
                        value="dollar"
                        control={<Radio />}
                        label="Dollar"
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    label={
                      inputs.residualType === 'percentage'
                        ? 'Residual %'
                        : 'Residual $'
                    }
                    name="residualValue"
                    type="number"
                    value={inputs.residualValue}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                  />
                  <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    label="Money Factor"
                    name="moneyFactor"
                    type="number"
                    inputProps={{ step: '0.0001' }}
                    value={inputs.moneyFactor}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                  />
                  <TextField
                    fullWidth
                    label="Term (months)"
                    name="term"
                    type="number"
                    value={inputs.term}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                  />
                </CardContent>
              </StyledCard>
            </Grid>

            {/* Location */}
            <Grid item xs={12} md={6} lg={4}>
              <StyledCard>
                <CardContent>
                  <IconWrapper>
                    <LocationOnIcon />
                    <Typography variant="h6">Location</Typography>
                    <div style={{ flex: 1 }}></div>
                    <Typography variant="caption">
                      Tax Rate:{' '}
                      {typeof calculatedValues.taxRate === 'number'
                        ? calculatedValues.taxRate.toFixed(2)
                        : 0}
                      %
                    </Typography>
                  </IconWrapper>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>County</InputLabel>
                    <Select
                      name="county"
                      value={inputs.county}
                      label="County"
                      onChange={handleInputChange}
                    >
                      {counties.map((county) => (
                        <MenuItem key={county} value={county}>
                          {county}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>City</InputLabel>
                    <Select
                      name="city"
                      value={inputs.city}
                      label="City"
                      onChange={handleInputChange}
                    >
                      {cities.map((city) => (
                        <MenuItem key={city} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>
              </StyledCard>
            </Grid>

            {/* Capped Fees */}
            <Grid item xs={12} md={6} lg={4}>
              <StyledCard>
                <CardContent>
                  <IconWrapper>
                    <ReceiptIcon />
                    <Typography variant="h6">Capped Fees</Typography>
                  </IconWrapper>
                  <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    label="Acquisition Fee"
                    name="acquisitionFee"
                    type="number"
                    value={inputs.acquisitionFee}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                  />
                  <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    label="WeOwe Total"
                    name="weOweTotal"
                    type="number"
                    value={inputs.weOweTotal}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                  />
                  <TextField
                    fullWidth
                    label="Contracts Total"
                    name="contractsTotal"
                    type="number"
                    value={inputs.contractsTotal}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                  />
                </CardContent>
              </StyledCard>
            </Grid>

            {/* Fees */}
            <Grid item xs={12} md={6}>
              <StyledCard>
                <CardContent>
                  <IconWrapper>
                    <ReceiptIcon />
                    <Typography variant="h6">Upfront Fees</Typography>
                  </IconWrapper>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="subtitle2">
                            DMV Fees: $
                            {calculatedValues.totalDMVFees?.toFixed(2)}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {Object.keys(calculatedValues.dmvFeesBreakdown)
                            .length > 0 ? (
                            <List dense>
                              {Object.entries(
                                calculatedValues.dmvFeesBreakdown,
                              ).map(([fee, amount]) => (
                                <ListItem key={fee}>
                                  <ListItemText
                                    primary={
                                      <Typography
                                        variant="caption"
                                        sx={{ fontWeight: 'bold' }}
                                      >
                                        {fee}:
                                      </Typography>
                                    }
                                  />
                                  <Typography variant="caption" align="right">
                                    ${amount?.toFixed(2)}
                                  </Typography>
                                </ListItem>
                              ))}
                            </List>
                          ) : (
                            <Typography>No fees calculated yet.</Typography>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography variant="subtitle2">
                            Dealer Fees: $
                            {calculatedValues.totalDealerFees?.toFixed(2)}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {Object.keys(calculatedValues.dealerFeesBreakdown)
                            .length > 0 ? (
                            <List dense>
                              {Object.entries(
                                calculatedValues.dealerFeesBreakdown,
                              ).map(([fee, amount]) => (
                                <ListItem key={fee}>
                                  <ListItemText
                                    primary={
                                      <Typography
                                        variant="caption"
                                        sx={{ fontWeight: 'bold' }}
                                      >
                                        {fee}:
                                      </Typography>
                                    }
                                  />
                                  <Typography variant="caption" align="right">
                                    ${amount?.toFixed(2)}
                                  </Typography>
                                </ListItem>
                              ))}
                            </List>
                          ) : (
                            <Typography>No fees calculated yet.</Typography>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyledCard>
            </Grid>

            {/* Results */}
            <Grid item xs={12} md={6}>
              <StyledCard>
                <CardContent>
                  <IconWrapper>
                    <CalculateIcon />
                    <Typography variant="h6">Lease Calculation</Typography>
                  </IconWrapper>
                  <List dense>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 'bold' }}
                          >
                            Cap Cost:
                          </Typography>
                        }
                      />
                      <Typography variant="body1" align="right">
                        $
                        {isNaN(calculatedValues.capCost)
                          ? '-'
                          : calculatedValues.capCost?.toFixed(2)}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 'bold' }}
                          >
                            Base Payment:
                          </Typography>
                        }
                      />
                      <Typography variant="body1" align="right">
                        $
                        {isNaN(calculatedValues.basePayment)
                          ? '-'
                          : calculatedValues.basePayment?.toFixed(2)}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 'bold' }}
                          >
                            Monthly Tax:
                          </Typography>
                        }
                      />
                      <Typography variant="body1" align="right">
                        $
                        {isNaN(calculatedValues.taxAmount)
                          ? '-'
                          : calculatedValues.taxAmount?.toFixed(2)}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 'bold' }}
                          >
                            Total Monthly Payment:
                          </Typography>
                        }
                      />
                      <Typography variant="body1" align="right">
                        $
                        {isNaN(calculatedValues.totalPayment)
                          ? '-'
                          : calculatedValues.totalPayment?.toFixed(2)}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 'bold' }}
                          >
                            Minimum Due at Signing:
                          </Typography>
                        }
                      />
                      <Typography variant="body1" align="right">
                        $
                        {isNaN(calculatedValues.driveOff)
                          ? '-'
                          : calculatedValues.driveOff?.toFixed(2)}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 'bold' }}
                          >
                            Out-of-Pocket:
                          </Typography>
                        }
                      />
                      <Typography variant="body1" align="right">
                        ${isNaN(inputs.cashDown) ? '-' : inputs.cashDown}
                      </Typography>
                    </ListItem>
                  </List>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenSummary}
                    sx={{ mt: 2 }}
                  >
                    View Lease Summary
                  </Button>
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default AutoLeaseCalculator;
