import React from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Box,
} from '@mui/material';
import { styled } from '@mui/system';

const SummaryCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .MuiCardContent-root': {
    flexGrow: 1,
  },
}));

const SummaryItem = ({ label, value }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
    <Typography variant="body2" color="text.secondary">
      {label}:
    </Typography>
    <Typography variant="body2" fontWeight="bold">
      {value}
    </Typography>
  </Box>
);

const formatCurrency = (value) => {
  if (typeof value === 'number') {
    return `$${value.toFixed(2)}`;
  }
  return value || 'N/A';
};

const formatPercentage = (value) => {
  if (typeof value === 'number') {
    return `${value.toFixed(2)}%`;
  }
  return value || 'N/A';
};

const LeaseSummary = ({ leaseData, onClose }) => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom align="center">
        Lease Summary
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SummaryCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Vehicle Details
              </Typography>
              <SummaryItem
                label="Body Style"
                value={leaseData.bodyStyle || 'N/A'}
              />
              <SummaryItem
                label="Type"
                value={leaseData.electric ? 'Electric' : 'Non-Electric'}
              />
              <SummaryItem
                label="MSRP"
                value={formatCurrency(leaseData.msrp)}
              />
              <SummaryItem
                label="Selling Price"
                value={formatCurrency(leaseData.sellingPrice)}
              />
            </CardContent>
          </SummaryCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <SummaryCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Lease Terms
              </Typography>
              <SummaryItem
                label="Term"
                value={`${leaseData.term || 'N/A'} months`}
              />
              <SummaryItem
                label="Money Factor"
                value={leaseData.moneyFactor || 'N/A'}
              />
              <SummaryItem
                label="Residual Value"
                value={`${formatCurrency(
                  leaseData.residualDollar,
                )} (${formatPercentage(leaseData.residualPercentage)})`}
              />
            </CardContent>
          </SummaryCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <SummaryCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Cost Breakdown
              </Typography>
              <SummaryItem
                label="Cap Cost"
                value={formatCurrency(leaseData.capCost)}
              />
              <SummaryItem
                label="Cap Cost Reduction"
                value={formatCurrency(leaseData.capReduction)}
              />
              <SummaryItem
                label="Trade Equity"
                value={formatCurrency(leaseData.tradeEquity)}
              />
              <SummaryItem
                label="Rebates"
                value={formatCurrency(leaseData.rebate)}
              />
              <SummaryItem
                label="We Owes"
                value={formatCurrency(leaseData.weOweTotal)}
              />
              <SummaryItem
                label="Contracts"
                value={formatCurrency(leaseData.contractsTotal)}
              />
            </CardContent>
          </SummaryCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <SummaryCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Payment Details
              </Typography>
              <SummaryItem
                label="Base Monthly Payment"
                value={formatCurrency(leaseData.basePayment)}
              />
              <SummaryItem
                label="Monthly Tax"
                value={formatCurrency(leaseData.taxAmount)}
              />
              <SummaryItem
                label="Total Monthly Payment"
                value={formatCurrency(leaseData.totalPayment)}
              />
              <SummaryItem
                label="Due at Signing"
                value={formatCurrency(leaseData.driveOff)}
              />
            </CardContent>
          </SummaryCard>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePrint}
            fullWidth
          >
            Print Summary
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            fullWidth
          >
            Back to Calculator
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeaseSummary;
