import React, { useMemo } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import {
  startOfDay,
  getDaysInMonth,
  getDate,
  getMonth,
  getYear,
  sub,
} from 'date-fns';
import { fetchData } from 'api/fisaleshistoryAPI';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFoot from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { round } from 'math.js';
import { formatCurrency } from 'utils/numberUtils';

const queryClient = new QueryClient();

const useSummaryData = (user, dateRange) => {
  const { from, to } = dateRange;
  return useQuery(
    ['summaryData', dateRange],
    () =>
      fetchData(user?.dealerid, from, to).then((result) => {
        return result.filter(
          (item) =>
            ['F', 'B'].includes(item.FiWipStatusCode) &&
            item.DealType !== 'Wholesale',
        );
      }),
    { enabled: Boolean(user?.dealerid) },
  );
};

const MetricsRow = ({
  title,
  count,
  pacingCount,
  frontGross,
  pacingFrontGross,
  backGross,
  pacingBackGross,
  totalGross,
  pacingTotalGross,
}) => (
  <TableRow>
    <TableCell>{title}</TableCell>
    <TableCell sx={{ textAlign: 'right' }}>{count}</TableCell>
    {pacingCount !== undefined && (
      <TableCell sx={{ textAlign: 'right' }}>{pacingCount}</TableCell>
    )}
    <TableCell sx={{ textAlign: 'right' }}>
      {formatCurrency(frontGross)}
    </TableCell>
    <TableCell sx={{ textAlign: 'right' }}>
      {formatCurrency(pacingFrontGross)}
    </TableCell>
    <TableCell sx={{ textAlign: 'right' }}>
      {formatCurrency(backGross)}
    </TableCell>
    <TableCell sx={{ textAlign: 'right' }}>
      {formatCurrency(pacingBackGross)}
    </TableCell>
    <TableCell sx={{ textAlign: 'right' }}>
      {formatCurrency(totalGross)}
    </TableCell>
    <TableCell sx={{ textAlign: 'right' }}>
      {formatCurrency(pacingTotalGross)}
    </TableCell>
  </TableRow>
);

const DataTable = ({ tableData }) => {
  const { isAuthenticated, user } = useAuth0();

  const dateRange = useMemo(() => {
    const currentDate = new Date();
    const previousDay = sub(currentDate, { days: 1 }); // Subtract one day from the current date

    return {
      from: startOfDay(currentDate),
      to: previousDay, // Use previousDay instead of currentDate
    };
  }, []);

  const { data: summaryData = [] } = useSummaryData(user, dateRange);
  const data = tableData !== undefined ? tableData : summaryData;

  const currentMonth = getMonth(new Date());
  const currentYear = getYear(new Date());
  const isCurrentMonth =
    dateRange.from.getMonth() === currentMonth &&
    dateRange.from.getFullYear() === currentYear;

  const calculateGross = (type, dealType) =>
    data
      .filter((item) => item.FIDealType === dealType)
      .reduce((total, item) => total + Number(item[type]), 0);

  const calculateCount = (dealType) =>
    data.filter((item) => item.FIDealType === dealType).length;

  const grossMetrics = (type, dealType) =>
    isCurrentMonth
      ? round(
          (calculateGross(type, dealType) / getDate(dateRange.to)) *
            getDaysInMonth(dateRange.to),
        )
      : 0;

  const unitMetrics = (dealType) => {
    const count = calculateCount(dealType);
    return isCurrentMonth
      ? round((count / getDate(dateRange.to)) * getDaysInMonth(dateRange.to))
      : 0;
  };

  const summary = {
    newCount: calculateCount('New'),
    newPacingCount: unitMetrics('New'),
    usedCount: calculateCount('Used'),
    usedPacingCount: unitMetrics('Used'),

    newFrontGross: calculateGross('FrontGross', 'New'),
    newFrontGrossPacing: grossMetrics('FrontGross', 'New'),
    newBackGross: calculateGross('BackGross', 'New'),
    newBackGrossPacing: grossMetrics('BackGross', 'New'),
    newTotalGross: calculateGross('TotalGross', 'New'),
    newTotalGrossPacing: grossMetrics('TotalGross', 'New'),

    usedFrontGross: calculateGross('FrontGross', 'Used'),
    usedFrontGrossPacing: grossMetrics('FrontGross', 'Used'),
    usedBackGross: calculateGross('BackGross', 'Used'),
    usedBackGrossPacing: grossMetrics('BackGross', 'Used'),
    usedTotalGross: calculateGross('TotalGross', 'Used'),
    usedTotalGrossPacing: grossMetrics('TotalGross', 'Used'),

    totalCount: calculateCount('New') + calculateCount('Used'),
    totalPacingCount: unitMetrics('New') + unitMetrics('Used'),

    totalFrontGross:
      calculateGross('FrontGross', 'New') +
      calculateGross('FrontGross', 'Used'),
    totalFrontGrossPacing:
      grossMetrics('FrontGross', 'New') + grossMetrics('FrontGross', 'Used'),

    totalBackGross:
      calculateGross('BackGross', 'New') + calculateGross('BackGross', 'Used'),
    totalBackGrossPacing:
      grossMetrics('BackGross', 'New') + grossMetrics('BackGross', 'Used'),

    grandTotalGross:
      calculateGross('TotalGross', 'New') +
      calculateGross('TotalGross', 'Used'),
    grandTotalGrossPacing:
      grossMetrics('TotalGross', 'New') + grossMetrics('TotalGross', 'Used'),
  };

  return (
    isAuthenticated && (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="stable1a-content"
          id="stable1a-header"
          sx={{
            fontWeight: 'bold',
          }}
        >
          Summary Table
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Metric</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>Units</TableCell>
                  {isCurrentMonth && (
                    <TableCell sx={{ textAlign: 'right' }}>Pacing</TableCell>
                  )}
                  <TableCell sx={{ textAlign: 'right' }}>Front Gross</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    Pace Front Gross
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>Back Gross</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    Back Gross Pace
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>Total Gross</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    Total Gross Pace
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <MetricsRow
                  title="New"
                  count={summary.newCount}
                  pacingCount={
                    isCurrentMonth ? summary.newPacingCount : undefined
                  }
                  frontGross={summary.newFrontGross}
                  pacingFrontGross={
                    isCurrentMonth ? summary.newFrontGrossPacing : undefined
                  }
                  backGross={summary.newBackGross}
                  pacingBackGross={
                    isCurrentMonth ? summary.newBackGrossPacing : undefined
                  }
                  totalGross={summary.newTotalGross}
                  pacingTotalGross={
                    isCurrentMonth ? summary.newTotalGrossPacing : undefined
                  }
                />
                <MetricsRow
                  title="Used"
                  count={summary.usedCount}
                  pacingCount={
                    isCurrentMonth ? summary.usedPacingCount : undefined
                  }
                  frontGross={summary.usedFrontGross}
                  pacingFrontGross={
                    isCurrentMonth ? summary.usedFrontGrossPacing : undefined
                  }
                  backGross={summary.usedBackGross}
                  pacingBackGross={
                    isCurrentMonth ? summary.usedBackGrossPacing : undefined
                  }
                  totalGross={summary.usedTotalGross}
                  pacingTotalGross={
                    isCurrentMonth ? summary.usedTotalGrossPacing : undefined
                  }
                />
              </TableBody>
              <TableFoot>
                <MetricsRow
                  title="Total Gross"
                  count={summary.totalCount}
                  pacingCount={
                    isCurrentMonth ? summary.totalPacingCount : undefined
                  }
                  frontGross={summary.totalFrontGross}
                  pacingFrontGross={
                    isCurrentMonth ? summary.totalFrontGrossPacing : undefined
                  }
                  backGross={summary.totalBackGross}
                  pacingBackGross={
                    isCurrentMonth ? summary.totalBackGrossPacing : undefined
                  }
                  totalGross={summary.grandTotalGross}
                  pacingTotalGross={
                    isCurrentMonth ? summary.grandTotalGrossPacing : undefined
                  }
                />
              </TableFoot>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    )
  );
};

const SummaryTable = ({ tableData }) => (
  <QueryClientProvider client={queryClient}>
    <DataTable tableData={tableData} />
  </QueryClientProvider>
);

export default SummaryTable;
