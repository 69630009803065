import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Typography,
  LinearProgress,
  Stack,
  Paper,
  CircularProgress,
} from '@mui/material';
import { green, yellow, orange, red } from '@mui/material/colors';
import dayjs from 'dayjs';

const NEW_VEHICLES_GOAL = 250;
const USED_VEHICLES_GOAL = 150;
const TOTAL_GROSS_GOAL = 1350000;

const calculatePace = (value) => {
  const today = dayjs();
  const startOfMonth = today.startOf('month');
  const totalDaysInMonth = today.daysInMonth();
  const daysElapsed = today.diff(startOfMonth, 'day');
  const pace = (value / daysElapsed) * totalDaysInMonth;
  return Math.round(pace);
};

const getColor = (pace, goal) => {
  if (pace >= goal) {
    return green[500];
  } else if (pace >= goal * 0.8) {
    return yellow[500];
  } else if (pace >= goal * 0.6) {
    return orange[500];
  } else {
    return red[500];
  }
};

const getLighterColor = (color) => {
  switch (color) {
    case green[500]:
      return green[200];
    case yellow[500]:
      return yellow[200];
    case orange[500]:
      return orange[200];
    case red[500]:
      return red[200];
    default:
      return color;
  }
};

const PacingBarChart = ({ data, loading }) => {
  const { isAuthenticated } = useAuth0();

  const newUnits = data.filter((item) => item.DealType === 'New').length;
  const usedUnits = data.filter((item) => item.DealType === 'Used').length;

  const newUnitsPace = calculatePace(newUnits);
  const usedUnitsPace = calculatePace(usedUnits);

  const totalGross = data.reduce(
    (acc, item) => acc + parseFloat(item.TotalGross || 0),
    0,
  );
  const totalGrossPace = calculatePace(totalGross);

  const updatedData = [
    {
      label: 'New Units',
      value: newUnits,
      pace: newUnitsPace,
      goal: NEW_VEHICLES_GOAL,
    },
    {
      label: 'Used Units',
      value: usedUnits,
      pace: usedUnitsPace,
      goal: USED_VEHICLES_GOAL,
    },
    {
      label: 'Total Gross',
      value: totalGross,
      pace: totalGrossPace,
      goal: TOTAL_GROSS_GOAL,
    },
  ];

  return (
    isAuthenticated && (
      <Paper sx={{ p: 2 }} elevation={0} variant="outlined">
        <Stack spacing={2}>
          <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
            marginBottom={2}
          >
            <Typography variant="h6" sx={{ fontSize: '1rem' }}>
              Sales Department Pacing Report
            </Typography>
            <div style={{ flexGrow: 1 }} />
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              justifyContent="flex-end"
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    backgroundColor: green[500],
                    marginRight: 1,
                  }}
                />
                <Typography variant="caption">Pace ≥ Goal</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    backgroundColor: yellow[500],
                    marginRight: 1,
                  }}
                />
                <Typography variant="caption">Pace ≥ 80% Goal</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    backgroundColor: orange[500],
                    marginRight: 1,
                  }}
                />
                <Typography variant="caption">Pace ≥ 60% Goal</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    backgroundColor: red[500],
                    marginRight: 1,
                  }}
                />
                <Typography variant="caption">Pace &lt; 60% Goal</Typography>
              </Box>
            </Stack>
          </Stack>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <Stack spacing={2}>
              {updatedData.map((item) => (
                <Box key={`${item.label}-${item.value}`}>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent="space-between"
                  >
                    <Typography variant="button">{item.label}</Typography>
                    <Typography variant="button">
                      {item.value.toFixed(2)}{' '}
                      {item.label === 'Total Gross' ? 'gross' : 'sold'}, pacing{' '}
                      {item.pace.toFixed(2)} ({item.goal} goal)
                    </Typography>
                  </Stack>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <LinearProgress
                        variant="buffer"
                        value={(item.value / item.goal) * 100}
                        valueBuffer={(item.pace / item.goal) * 100}
                        sx={{
                          '& .MuiLinearProgress-bar1Buffer': {
                            backgroundColor: getColor(item.pace, item.goal),
                          },
                          '& .MuiLinearProgress-bar2Buffer': {
                            backgroundColor: getLighterColor(
                              getColor(item.pace, item.goal),
                            ),
                          },
                          '& .MuiLinearProgress-dashed': {
                            animation: 'none',
                            backgroundImage: `radial-gradient(${getColor(
                              item.pace,
                              item.goal,
                            )} 0%, ${getColor(
                              item.pace,
                              item.goal,
                            )} 16%, transparent 42%) !important`,
                          },
                        }}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                      >{`${Math.round(
                        (item.value / item.goal) * 100,
                      )}%`}</Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Stack>
          )}
        </Stack>
      </Paper>
    )
  );
};

export default PacingBarChart;
