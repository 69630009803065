import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import SearchMdIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import Input from '@mui/material/Input';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useSearchBox } from 'react-instantsearch';
import { filters } from './setups';
import CustomRefinementList from './desking-list-refinements';
import CustomCurrentRefinements from './desking-list-current-refinements';
import DeskingSettingsButton from './desking-settings-button';

const VehicleListSearch = ({
  sortOrder,
  setSortOrder,
  formData,
  handleFormData,
}) => {
  const { refine } = useSearchBox();
  const inputRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    inputRef.current?.blur();
  };

  const handleReset = (event) => {
    event.preventDefault();
    refine('');
    inputRef.current?.focus();
  };

  const handleChange = (event) => {
    refine(event.currentTarget.value);
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingX: 2,
          paddingY: 1,
        }}
      >
        <Stack
          component="form"
          direction="row"
          onSubmit={handleSubmit}
          onReset={handleReset}
          spacing={2}
        >
          <SearchMdIcon />
          <Input
            defaultValue=""
            disableUnderline
            fullWidth
            placeholder="Search for vehicle..."
            sx={{ fontSize: '14px', fontWeight: 500 }}
            onChange={handleChange}
            inputRef={inputRef}
          />
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <div>
          <DeskingSettingsButton
            formData={formData}
            handleFormData={handleFormData}
          />
        </div>
      </Box>
      <Divider />
      <CustomCurrentRefinements
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
      />
      <Divider />
      <Stack
        direction="row"
        flexWrap="wrap"
        spacing={1}
        sx={{ paddingX: 2, paddingY: 1 }}
      >
        {filters.map((item, index) => (
          <CustomRefinementList
            attribute={item.filter}
            label={item.label}
            key={`${item.id}_${index}`}
          />
        ))}
      </Stack>
      <Divider />
    </div>
  );
};

VehicleListSearch.propTypes = {
  sortOrder: PropTypes.string.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  handleFormData: PropTypes.func.isRequired,
};

export default VehicleListSearch;
