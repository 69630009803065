import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { contractDescriptions } from '../constants';

const vehicleTypeOptions = ['New', 'Used'];
const transactionTypeOptions = ['Finance', 'Lease', 'Cash'];

export default function Contracts({ deskingData, handleFieldChange }) {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState([]);
  const [contracts, setContracts] = useState([...contractDescriptions]);
  const [contractId, setContractId] = useState(null); // Track the ID of the contract being edited
  const [contractName, setContractName] = useState('');
  const [isSelected, setIsSelected] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [amount, setAmount] = useState(0);
  const [cost, setCost] = useState(0);

  useEffect(() => {
    const updatedContracts = contractDescriptions.map((ct) => {
      const deskingDataContract = deskingData.contracts.find(
        (dc) => dc.id === ct.id,
      );
      return deskingDataContract ? { ...ct, ...deskingDataContract } : ct;
    });

    setContracts(updatedContracts);

    // Set checked state based on deskingData
    const checkedContracts = deskingData.contracts
      .filter((contract) => contract.selected)
      .map((contract) => contract.id);

    setChecked(checkedContracts);
  }, [deskingData.contracts]);

  const handleOpen = (contract) => {
    setContractId(contract.id);
    setContractName(contract.name);
    setIsSelected(contract.selected);
    setVehicleTypes(contract.vehicleType);
    setTransactionTypes(contract.transactionType);
    setAmount(contract.amount);
    setCost(contract.cost);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const existingContractIndex = contracts.findIndex(
      (contract) => contract.id === contractId,
    );

    let updatedContracts =
      existingContractIndex !== -1
        ? contracts.map((contract) =>
            contract.id === contractId
              ? {
                  ...contract,
                  name: contractName,
                  selected: isSelected,
                  vehicleType: vehicleTypes,
                  transactionType: transactionTypes,
                  amount: amount,
                  cost: cost,
                }
              : contract,
          )
        : [
            ...contracts,
            {
              id: contractId,
              name: contractName,
              selected: isSelected,
              vehicleType: vehicleTypes,
              transactionType: transactionTypes,
              amount: amount,
              cost: cost,
            },
          ];

    setContracts(updatedContracts);
    handleFieldChange('contracts', updatedContracts);
    handleClose();
  };

  const handleToggle = (id) => () => {
    const currentIndex = checked.indexOf(id);
    const newChecked = [...checked];

    // Update checked state
    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);

    // Update contracts state
    const updatedContracts = contracts.map((contract) => {
      if (contract.id === id) {
        return { ...contract, selected: !contract.selected };
      }
      return contract;
    });

    setContracts(updatedContracts);

    const selectedContracts = updatedContracts.filter(
      (contract) => contract.selected,
    );
    handleFieldChange('contracts', selectedContracts);
  };

  if (contracts.length === 0) {
    return null;
  }

  return (
    <>
      <Box>
        <List>
          {contracts.map((contract) => {
            const labelId = `checkbox-list-label-${contract.id}`;
            return (
              <ListItem
                key={contract.id}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="more-options"
                    onClick={() => handleOpen(contract)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
                disablePadding
              >
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(contract.id)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.includes(contract.id)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={`${contract.name} - $${contract.amount}`}
                    secondary={`${contract.vehicleType},${contract.transactionType}`}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add or Update Contract Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill in the details to add or update a contract type.
          </DialogContentText>
          <TextField
            autoFocus
            value={contractName}
            onChange={(e) => setContractName(e.target.value)}
            margin="dense"
            id="name"
            label="Contract Name"
            fullWidth
            variant="standard"
          />

          <Box mt={2}>
            <Autocomplete
              multiple
              value={vehicleTypes}
              onChange={(event, newValue) => setVehicleTypes(newValue)}
              options={vehicleTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  variant="standard"
                  label="Vehicle Types"
                  placeholder="Choose..."
                  fullWidth
                />
              )}
            />
          </Box>

          <Box mt={2}>
            <Autocomplete
              multiple
              value={transactionTypes}
              onChange={(event, newValue) => setTransactionTypes(newValue)}
              options={transactionTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  variant="standard"
                  label="Transaction Types"
                  placeholder="Choose..."
                  fullWidth
                />
              )}
            />
          </Box>

          <Box mt={2}>
            <TextField
              value={amount}
              onChange={(e) => setAmount(Number(e.target.value))}
              margin="dense"
              id="amount"
              label="Contract Amount"
              type="number"
              fullWidth
              variant="standard"
            />
          </Box>

          <Box mt={2}>
            <TextField
              value={cost}
              onChange={(e) => setCost(Number(e.target.value))}
              margin="dense"
              id="cost"
              label="Contract Cost"
              type="number"
              fullWidth
              variant="standard"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            {' '}
            {/* Connect the Save button to the handleSave function */}
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
