// --- LIBRARY IMPORTS ---
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth0 } from '@auth0/auth0-react';
import { Formik, Form, Field } from 'formik';

// --- LOCAL IMPORTS ---
import { API_BASE_URL } from 'config';
import { useEmployees } from 'hooks/useEmployees';
import { useTeams } from 'hooks/useTeams';
import { rolePermissions } from 'constants/rolePermissions';
import LoadingComponent from 'components/misc/LoadingComponent';

// MUI Components
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import {
  TableContainer,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Switch,
  FormHelperText,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Clear as ClearIcon,
  Check as CheckIcon,
  Add as AddIcon,
} from '@mui/icons-material';

// Constants and Utilities
const SORTED_ROLES = rolePermissions.slice().sort((a, b) => {
  return a.role.localeCompare(b.role);
});

// Components
const ActionButtons = ({ onEdit, onDelete }) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <EditIcon
      color="primary"
      onClick={onEdit}
      style={{ cursor: 'pointer', marginRight: 8 }}
    />
    <DeleteIcon
      color="error"
      onClick={onDelete}
      style={{ cursor: 'pointer' }}
    />
  </div>
);

const EmployeeTable = () => {
  const { isAuthenticated, user } = useAuth0();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteEmployeeId, setDeleteEmployeeId] = useState(null);
  const [editEmployeeId, setEditEmployeeId] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [employeesData, setEmployeesData] = useState(null);
  const [showInactive, setShowInactive] = useState(false);

  // Fetch employees
  const {
    data: employeesApiData,
    isLoading: employeesLoading,
    error: employeesError,
    refetch: refetchEmployees,
  } = useEmployees(user, isAuthenticated);

  if (employeesError) {
    console.error('Error fetching employees:', employeesError.message);
  }

  // Fetch teams data outside of useEffect
  const { data: teamsAPIData } = useTeams(user.dealerid);

  useEffect(() => {
    if (!employeesLoading) {
      setEmployeesData(employeesApiData);
    }
  }, [employeesApiData, employeesLoading]);

  const columns = [
    {
      field: 'isActive',
      headerName: 'Active/Inactive',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {params.value ? (
            <CheckIcon color="success" />
          ) : (
            <ClearIcon color="error" />
          )}
        </div>
      ),
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 1,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1.5,
    },
    {
      field: 'accessRole',
      headerName: 'Access Role',
      flex: 1,
    },
    {
      field: 'dmsID',
      headerName: 'DMS ID',
      flex: 1,
    },
    {
      field: 'team',
      headerName: 'Team',
      flex: 1,
      valueGetter: (params) => params.row.team?.name || 'N/A',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <ActionButtons
          onEdit={() => handleEdit(params.row)}
          onDelete={() => handleDelete(params.row._id)}
        />
      ),
    },
  ];

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    accessRole: Yup.string().required('Access Role is required'),
  });

  const handleOpenAddDialog = () => {
    setEditEmployeeId(null);
    setInitialValues({}); // Set initial values to empty
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setEditEmployeeId(null);
    setOpenAddDialog(false);
  };

  // Handle adding a new employee
  const handleAddEmployee = async (values) => {
    try {
      const employeeData = {
        ...values,
        dealerID: user.dealerid, // Include dealerID from user
        sub: user.sub, // Include sub from user
      };

      const response = await fetch(`${API_BASE_URL}/api/employees`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(employeeData),
      });

      if (response.ok) {
        console.log('Employee added successfully');
        refetchEmployees(); // Using refetch function from useEmployees
        handleCloseAddDialog();
      } else {
        const responseData = await response.json();
        console.error('Failed to add employee:', responseData.message);
      }
    } catch (error) {
      console.error('Error while adding employee:', error.message);
    }
  };

  const handleEdit = (employee) => {
    console.log('Editing employee:', employee);
    const initialValues = {
      firstName: employee?.firstName || '',
      lastName: employee?.lastName || '',
      email: employee?.email || '',
      accessRole: employee?.accessRole || '',
      dmsID: employee?.dmsID || '',
      team: employee?.team?._id || '',
      isActive: employee?.isActive || false,
      sub: employee?.sub || '', // Include sub if editing
    };

    setEditEmployeeId(employee._id);
    setInitialValues(initialValues);
    setOpenAddDialog(true);
  };

  const handleEditEmployee = async (employeeId, values) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/employees/${employeeId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        },
      );

      if (response.ok) {
        console.log('Employee updated successfully');
        refetchEmployees();
        handleCloseAddDialog();
      } else {
        const responseData = await response.json();
        console.error('Failed to update employee:', responseData.message);
      }
    } catch (error) {
      console.error('Error while updating employee:', error.message);
    }
  };

  const handleDelete = (id) => {
    setConfirmDeleteOpen(true);
    setDeleteEmployeeId(id);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/employees/${deleteEmployeeId}`,
        {
          method: 'DELETE',
        },
      );

      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(responseData.message);
      }
      refetchEmployees();
      setConfirmDeleteOpen(false);
      console.log('Employee deleted successfully');
    } catch (error) {
      console.error('Failed to delete employee:', error.message);
    }
  };

  const handleShowInactiveToggle = () => {
    setShowInactive(!showInactive);
  };

  return (
    isAuthenticated && (
      <>
        <TableContainer>
          {!employeesLoading && employeesData ? (
            <DataGridPro
              density="compact"
              rows={
                showInactive
                  ? employeesData
                  : employeesData.filter((employee) => employee.isActive)
              }
              columns={columns}
              getRowId={(row) => row._id}
              slots={{
                toolbar: () => (
                  <GridToolbarContainer sx={{ paddingBottom: 2, padding: 2 }}>
                    <Button
                      onClick={handleOpenAddDialog}
                      startIcon={<AddIcon />}
                      variant="text"
                      size="small"
                    >
                      Add Employee
                    </Button>
                    <Button
                      onClick={handleShowInactiveToggle}
                      variant="text"
                      color={showInactive ? 'success' : 'error'}
                      size="small"
                    >
                      {showInactive ? 'Hide Inactive' : 'Show Inactive'}
                    </Button>
                    <div style={{ flexGrow: 1 }} />
                    <GridToolbarQuickFilter />
                  </GridToolbarContainer>
                ),
              }}
              loading={employeesLoading}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              disableRowSelectionOnClick
            />
          ) : (
            <LoadingComponent />
          )}
        </TableContainer>

        {/* Add Employee Dialog */}
        <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
          <DialogTitle>
            {editEmployeeId ? 'Edit Employee' : 'Add Employee'}
          </DialogTitle>
          <DialogContent sx={{ paddingTop: '10px !important' }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log('Submitting form:', values);
                if (editEmployeeId) {
                  handleEditEmployee(editEmployeeId, { ...values });
                } else {
                  handleAddEmployee(values);
                }
                setInitialValues({});
              }}
            >
              {({ handleSubmit, values, errors, touched, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        as={TextField}
                        fullWidth
                        variant="outlined"
                        value={values.firstName || ''}
                        error={touched.firstName && !!errors.firstName}
                        onBlur={(e) =>
                          setFieldValue('firstName', e.target.value.trim())
                        }
                      />
                      <FormHelperText
                        error={touched.firstName && !!errors.firstName}
                      >
                        {touched.firstName && errors.firstName}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        type="text"
                        placeholder="Last Name"
                        name="lastName"
                        as={TextField}
                        fullWidth
                        variant="outlined"
                        value={values.lastName || ''}
                        error={touched.lastName && !!errors.lastName}
                        onBlur={(e) =>
                          setFieldValue('lastName', e.target.value.trim())
                        }
                      />
                      <FormHelperText
                        error={touched.lastName && !!errors.lastName}
                      >
                        {touched.lastName && errors.lastName}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        type="email"
                        placeholder="Email"
                        name="email"
                        as={TextField}
                        fullWidth
                        variant="outlined"
                        value={values.email || ''}
                        error={touched.email && !!errors.email}
                        onBlur={(e) =>
                          setFieldValue('email', e.target.value.trim())
                        }
                      />
                      <FormHelperText error={touched.email && !!errors.email}>
                        {touched.email && errors.email}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="accessRole">Access Role</InputLabel>
                        <Field
                          as={Select}
                          name="accessRole"
                          label="Access Role"
                          error={touched.accessRole && !!errors.accessRole}
                          value={values.accessRole || ''}
                          onBlur={(e) =>
                            setFieldValue('accessRole', e.target.value.trim())
                          }
                        >
                          <MenuItem value="">None</MenuItem>
                          {SORTED_ROLES.map((permission) => (
                            <MenuItem
                              key={permission.role}
                              value={permission.role}
                            >
                              {permission.role}
                            </MenuItem>
                          ))}
                        </Field>
                        <FormHelperText
                          error={touched.accessRole && !!errors.accessRole}
                        >
                          {touched.accessRole && errors.accessRole}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        type="text"
                        placeholder="DMS ID"
                        name="dmsID"
                        as={TextField}
                        fullWidth
                        variant="outlined"
                        value={values.dmsID || ''}
                        onBlur={(e) =>
                          setFieldValue('dmsID', e.target.value.trim())
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="team">Team</InputLabel>
                        <Field
                          as={Select}
                          name="team"
                          label="Team"
                          value={values.team || ''}
                          onBlur={(e) =>
                            setFieldValue('team', e.target.value.trim())
                          }
                        >
                          <MenuItem value="">None</MenuItem>
                          {teamsAPIData &&
                            teamsAPIData.map((team) => (
                              <MenuItem key={team._id} value={team._id}>
                                {team.name}
                              </MenuItem>
                            ))}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        type="text"
                        placeholder="Connect user to Auth0"
                        name="sub"
                        as={TextField}
                        fullWidth
                        variant="outlined"
                        value={values.sub || ''}
                        error={touched.sub && !!errors.sub}
                        onBlur={(e) =>
                          setFieldValue('sub', e.target.value.trim())
                        }
                      />
                      <FormHelperText error={touched.sub && !!errors.sub}>
                        {touched.sub && errors.sub}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Grid container alignItems="center">
                      {editEmployeeId && (
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Switch
                                name="isActive"
                                checked={values.isActive}
                                onChange={(e) => {
                                  const newValue = e.target.checked;
                                  console.log('New value:', newValue);
                                  setFieldValue('isActive', newValue);
                                }}
                                color="primary"
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        style={{
                          flexGrow: 1,
                          textAlign: 'right',
                          marginLeft: '16px',
                        }}
                      >
                        <Button
                          style={{ marginRight: '8px' }}
                          onClick={handleCloseAddDialog}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          {editEmployeeId ? 'Save' : 'Add'}
                        </Button>
                      </Grid>
                    </Grid>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={confirmDeleteOpen}
          onClose={() => setConfirmDeleteOpen(false)}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this employee?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
            <Button onClick={handleConfirmDelete} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  );
};

export default EmployeeTable;
