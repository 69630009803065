import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import {
  Autocomplete,
  Divider,
  Chip,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Switch,
  CircularProgress,
  Snackbar,
  Alert,
  Stack,
} from '@mui/material';
import { DataGridPremium, GridToolbarContainer } from '@mui/x-data-grid-premium';

import SearchInput from './SearchInput';
import { API_BASE_URL } from 'config';
import { useTeams } from 'hooks/useTeams';
import { rolePermissions, DEPARTMENTS } from 'constants/rolePermissions';

const AdminDashboard = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { data: teams, isLoading: teamsLoading } = useTeams(user?.dealerId);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null); // Define selectedUser and setSelectedUser
  const dealerId = user?.dealerId;

  // Define the getDefaultDepartments function
  const getDefaultDepartments = (roles) => {
    const defaultDepts = new Set();
    roles.forEach((role) => {
      const roleInfo = rolePermissions.find((rp) => rp.role === role);
      if (roleInfo && roleInfo.departments) {
        roleInfo.departments.forEach((dept) => defaultDepts.add(dept));
      }
    });
    return Array.from(defaultDepts);
  };

  // Fetch users with debounced search
  const fetchUsers = useCallback(
    async (page, pageSize, sortModel, filterModel, search) => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${API_BASE_URL}/admin/users`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            page: page + 1, // API expects 1-based index
            per_page: pageSize,
            sort: sortModel.length > 0 ? `${sortModel[0].field}:${sortModel[0].sort}` : '',
            filter: JSON.stringify(filterModel.items),
            search: search,
          },
        });
        setUsers(response.data.users || []);
        setTotalUsers(response.data.total || 0);
      } catch (error) {
        console.error('Error fetching users:', error);
        setError('Failed to fetch users');
        setUsers([]);
        setTotalUsers(0);
      } finally {
        setIsLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  useEffect(() => {
    fetchUsers(paginationModel.page, paginationModel.pageSize, sortModel, filterModel, searchTerm);
  }, [fetchUsers, paginationModel, sortModel, filterModel, searchTerm]);

  const handleSearch = useCallback((term) => {
    setSearchTerm(term);
    fetchUsers(0, paginationModel.pageSize, sortModel, filterModel, term);
  }, [fetchUsers, paginationModel.pageSize, sortModel, filterModel]);

  const handleOpenDialog = useCallback((user = null) => {
    setSelectedUser(
      user
        ? {
            ...user,
            user_metadata: {
              first_name: user.user_metadata?.first_name || '',
              last_name: user.user_metadata?.last_name || '',
            },
            app_metadata: {
              roles: user.app_metadata?.roles || [],
              employeeNumber: user.app_metadata?.employeeNumber || '',
              teamId: user.app_metadata?.teamId || '',
              isActive: user.app_metadata?.isActive !== false,
              departments: user.app_metadata?.departments || [],
            },
          }
        : {
            email: '',
            user_metadata: { first_name: '', last_name: '' },
            app_metadata: {
              roles: [],
              employeeNumber: '',
              teamId: '',
              isActive: true,
              departments: [],
            },
          }
    );
    setIsNewUser(!user);
    setIsDialogOpen(true);
    setFormErrors({});
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
    setSelectedUser(null);
    setFormErrors({});
  }, []);

  const handleRoleChange = useCallback((event, newValue) => {
    const defaultDepts = getDefaultDepartments(newValue);
    setSelectedUser((prevUser) => ({
      ...prevUser,
      app_metadata: {
        ...prevUser.app_metadata,
        roles: newValue,
        departments: Array.from(new Set([...defaultDepts, ...(prevUser.app_metadata.departments || [])])),
      },
    }));
  }, []);

  const handleDepartmentChange = useCallback((event, newValue) => {
    setSelectedUser((prevUser) => ({
      ...prevUser,
      app_metadata: {
        ...prevUser.app_metadata,
        departments: newValue,
      },
    }));
  }, []);

  const validateForm = useCallback(() => {
    const errors = {};
    if (!selectedUser.user_metadata?.first_name)
      errors.first_name = 'First name is required';
    if (!selectedUser.user_metadata?.last_name)
      errors.last_name = 'Last name is required';
    if (!selectedUser.email) errors.email = 'Email is required';
    if (isNewUser && !selectedUser.password)
      errors.password = 'Password is required';
    if (!selectedUser.app_metadata?.roles) errors.roles = 'Role is required';
    if (
      !selectedUser.app_metadata?.departments ||
      selectedUser.app_metadata?.departments.length === 0
    )
      errors.departments = 'At least one department is required';
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }, [selectedUser, isNewUser]);

  const handleSubmit = useCallback(async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const token = await getAccessTokenSilently();
      const userData = {
        email: selectedUser.email,
        first_name: selectedUser.user_metadata?.first_name,
        last_name: selectedUser.user_metadata?.last_name,
        roles: selectedUser.app_metadata?.roles,
        employeeNumber: selectedUser.app_metadata?.employeeNumber,
        dealerId: dealerId,
        teamId: selectedUser.app_metadata?.teamId,
        isActive: selectedUser.app_metadata?.isActive !== false,
        departments: selectedUser.app_metadata?.departments,
      };

      console.log('userData', userData);

      let updatedUser;
      if (isNewUser) {
        userData.password = selectedUser.password;
        const response = await axios.post(
          `${API_BASE_URL}/admin/users`,
          userData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        updatedUser = response.data.data;
        setUsers((prevUsers) => [...prevUsers, updatedUser]);
        setSuccess('User created successfully');
      } else {
        const response = await axios.patch(
          `${API_BASE_URL}/admin/users/${selectedUser.user_id}`,
          userData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        updatedUser = response.data.data;
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.user_id === updatedUser.user_id ? updatedUser : user
          )
        );
        setSuccess('User updated successfully');
      }
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving user:', error);
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('Failed to save user');
      }
    } finally {
      setIsSubmitting(false);
    }
  }, [validateForm, getAccessTokenSilently, selectedUser, isNewUser, dealerId, handleCloseDialog]);

  const handleInputChange = useCallback((e, metadataType = '') => {
    const { name, value } = e.target;
    setSelectedUser((prevUser) => {
      if (metadataType) {
        return {
          ...prevUser,
          [metadataType]: {
            ...prevUser[metadataType],
            [name]: value,
          },
        };
      }
      return { ...prevUser, [name]: value };
    });
  }, []);

  const handleDeleteUser = useCallback(
    async (userId) => {
      if (window.confirm('Are you sure you want to delete this user?')) {
        setIsLoading(true);
        try {
          const token = await getAccessTokenSilently();
          await axios.delete(`${API_BASE_URL}/admin/users/${userId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setSuccess('User deleted successfully');
          setUsers((prevUsers) => prevUsers.filter((user) => user.user_id !== userId));
        } catch (error) {
          console.error('Error deleting user:', error);
          setError('Failed to delete user');
        } finally {
          setIsLoading(false);
        }
      }
    },
    [getAccessTokenSilently]
  );

  const columns = useMemo(
    () => [
      {
        field: 'isActive',
        headerName: 'Status',
        flex: 1,
        valueGetter: (value, row) =>
          row.app_metadata?.isActive !== false ? 'Active' : 'Blocked',
        renderCell: (params) => (
          <Chip
            label={
              params.row.app_metadata?.isActive !== false ? 'Active' : 'Blocked'
            }
            color={
              params.row.app_metadata?.isActive !== false ? 'success' : 'error'
            }
            size="small"
          />
        ),
      },
      {
        field: 'first_name',
        headerName: 'First Name',
        flex: 1,
        valueGetter: (value, row) => row.user_metadata?.first_name || '',
      },
      {
        field: 'last_name',
        headerName: 'Last Name',
        flex: 1,
        valueGetter: (value, row) => row.user_metadata?.last_name || '',
      },
      {
        field: 'email',
        headerName: 'Email',
        minWidth: 300,
        flex: 1,
      },
      {
        field: 'roles',
        headerName: 'Role(s)',
        flex: 1,
        valueGetter: (value, row) => row.app_metadata?.roles?.join(', ') || '',
      },
      {
        field: 'departments',
        headerName: 'Departments',
        flex: 1,
        valueGetter: (value, row) =>
          row.app_metadata?.departments?.join(', ') || '',
      },
      {
        field: 'employeeNumber',
        headerName: 'Employee Number',
        flex: 1,
        valueGetter: (value, row) => row.app_metadata?.employeeNumber || '',
      },
      {
        field: 'teamId',
        headerName: 'Team',
        flex: 1,
        valueGetter: (value, row) => {
          const teamId = row.app_metadata?.teamId;
          const team = teams.find((t) => t._id === teamId);
          return team ? team.name : '';
        },
      },
      {
        field: 'created_at',
        headerName: 'Created At',
        flex: 1,
        valueFormatter: (value) => new Date(value).toLocaleDateString(),
      },
      {
        field: 'last_login',
        headerName: 'Last Login',
        flex: 1,
        valueFormatter: (value) =>
          value ? new Date(value).toLocaleDateString() : 'Never',
      },
      { field: 'logins_count', headerName: 'Login Count', flex: 1 },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 250,
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <>
            <Button onClick={() => handleOpenDialog(params.row)}>Edit</Button>
            <Button
              onClick={() => handleDeleteUser(params.row.user_id)}
              color="secondary"
            >
              Delete
            </Button>
          </>
        ),
      },
    ],
    [teams, handleDeleteUser, handleOpenDialog]
  );

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        spacing={2}
        padding={2}
        width="100%"
      >
        <Typography variant="h6">User Management</Typography>
        <div style={{ flexGrow: 1 }} />
        <SearchInput onSearch={handleSearch} initialSearchValue={searchTerm} />
        <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>
          Add New User
        </Button>
      </Stack>
      <Divider />
    </GridToolbarContainer>
  );

  if (isLoading && users.length === 0) {
    return <CircularProgress />;
  }

  return (
    <>
      <DataGridPremium
        sx={{
          backgroundColor: 'background.paper',
          boxShadow: 1,
          border: 0,
        }}
        rows={users || []}
        columns={columns}
        pagination
        loading={isLoading}
        getRowId={(row) => row.user_id}
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={setSortModel}
        onFilterModelChange={setFilterModel}
        pageSizeOptions={[25, 50, 100]}
        rowCount={totalUsers}
        slots={{
          toolbar: CustomToolbar,
        }}
        autoHeight
      />

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {isNewUser ? 'Add New User' : 'Edit User'}
          <FormControlLabel
            control={
              <Switch
                checked={selectedUser?.app_metadata?.isActive !== false}
                onChange={(e) =>
                  handleInputChange(
                    {
                      target: { name: 'isActive', value: e.target.checked },
                    },
                    'app_metadata'
                  )
                }
                name="isActive"
              />
            }
            label="Active"
          />
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="first_name"
            label="First Name"
            type="text"
            fullWidth
            value={selectedUser?.user_metadata?.first_name || ''}
            onChange={(e) => handleInputChange(e, 'user_metadata')}
            error={!!formErrors.first_name}
            helperText={formErrors.first_name}
          />
          <TextField
            margin="dense"
            name="last_name"
            label="Last Name"
            type="text"
            fullWidth
            value={selectedUser?.user_metadata?.last_name || ''}
            onChange={(e) => handleInputChange(e, 'user_metadata')}
            error={!!formErrors.last_name}
            helperText={formErrors.last_name}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={selectedUser?.email || ''}
            onChange={handleInputChange}
            disabled={!isNewUser}
            error={!!formErrors.email}
            helperText={formErrors.email}
          />
          {isNewUser && (
            <TextField
              margin="dense"
              name="password"
              label="Password"
              type="password"
              fullWidth
              value={selectedUser?.password || ''}
              onChange={handleInputChange}
              error={!!formErrors.password}
              helperText={formErrors.password}
            />
          )}
          <TextField
            margin="dense"
            name="employeeNumber"
            label="Employee Number"
            type="text"
            fullWidth
            value={selectedUser?.app_metadata?.employeeNumber || ''}
            onChange={(e) => handleInputChange(e, 'app_metadata')}
          />
          <Autocomplete
            options={teams}
            getOptionLabel={(option) => option.name}
            value={
              teams.find((t) => t._id === selectedUser?.app_metadata?.teamId) ||
              null
            }
            onChange={(event, newValue) => {
              handleInputChange(
                {
                  target: { name: 'teamId', value: newValue?._id },
                },
                'app_metadata'
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Team" margin="dense" />
            )}
            loading={teamsLoading}
          />
          <Autocomplete
            multiple
            options={rolePermissions.map((rp) => rp.role)}
            value={selectedUser?.app_metadata?.roles || []}
            onChange={handleRoleChange}
            renderInput={(params) => (
              <TextField {...params} label="Roles" margin="dense" />
            )}
          />
          <Autocomplete
            multiple
            options={Object.values(DEPARTMENTS)}
            value={selectedUser?.app_metadata?.departments || []}
            onChange={handleDepartmentChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Departments"
                margin="dense"
                error={!!formErrors.departments}
                helperText={formErrors.departments}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={isSubmitting}>
            {isSubmitting ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
      >
        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!success}
        autoHideDuration={6000}
        onClose={() => setSuccess('')}
      >
        <Alert onClose={() => setSuccess('')} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AdminDashboard;
