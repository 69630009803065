import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  TextField,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { API_BASE_URL } from 'config';

const SearchCoversheets = ({ onSelect, dealerID }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const formatPhoneForSearch = (phone) => {
    // Remove all non-digit characters
    return phone.replace(/\D/g, '');
  };

  const handleSearch = async () => {
    try {
      let formattedSearchTerm = searchTerm;

      // If the search term looks like a phone number, format it
      if (/^[\d\s().-]+$/.test(searchTerm)) {
        formattedSearchTerm = formatPhoneForSearch(searchTerm);
      }

      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${API_BASE_URL}/api/coversheets/search?dealerID=${dealerID}&searchTerm=${formattedSearchTerm}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.ok) {
        const data = await response.json();
        setSearchResults(data);
        setOpenDialog(true);
      } else {
        console.error('Search failed');
      }
    } catch (error) {
      console.error('Error during search:', error);
    }
  };

  const handleSelect = (result) => {
    // Format the date to YYYY-MM-DD
    const formattedResult = {
      ...result,
      date: result.date ? result.date.split('T')[0] : '',
    };
    onSelect(formattedResult);
    setOpenDialog(false);
    setSearchTerm('');
    setSearchResults([]);
  };

  return (
    <>
      <TextField
        label="Search Coversheets"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSearch}>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
        size="small"
      />
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Search Results</DialogTitle>
        <DialogContent>
          <List>
            {searchResults.map((result, index) => (
              <ListItem button key={index} onClick={() => handleSelect(result)}>
                <ListItemText
                  primary={`${result.dealno} - ${result.buyername}`}
                  secondary={`${result.year} ${result.make} ${result.model} - ${result.phone}`}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SearchCoversheets;
