import React, { useState } from 'react';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
// import { useAuth0 } from '@auth0/auth0-react';

const AddGasLogForm = ({ dealerID, onUpdate }) => {
  // const { user, isAuthenticated } = useAuth0();

  const [formData, setFormData] = useState({
    dealerID,
    date: '',
    receiptno: '',
    cardid: '',
    stockno: '',
    gallons: '',
    price: '',
    total: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const calculateTotal = () => {
    const gallons = parseFloat(formData.gallons) || 0;
    const price = parseFloat(formData.price) || 0;
    const total = gallons * price;
    setFormData((prevData) => ({
      ...prevData,
      total: total.toFixed(2),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_BASE_URL}/api/gaslog`, formData);
      onUpdate(); // Call the onUpdate callback to trigger updating the table
      // Reset form data after successful submission
      setFormData({
        dealerID,
        date: '',
        receiptno: '',
        cardid: '',
        stockno: '',
        gallons: '',
        price: '',
        total: '',
      });
    } catch (error) {
      console.error('Error adding gas log:', error);
    }
  };

  const handleClear = () => {
    setFormData({
      dealerID,
      date: new Date().toISOString().split('T')[0],
      receiptno: '',
      cardid: '',
      stockno: '',
      gallons: '',
      price: '',
      total: '',
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="column" spacing={2} useFlexGap>
        {/* Hidden input for dealerID */}
        <input type="hidden" name="dealerID" value={formData.dealerID} />

        {/* Date */}
        <TextField
          name="date"
          label="Date"
          type="date"
          variant="outlined"
          size="small"
          value={formData.date}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />

        {/* Receipt No */}
        <TextField
          name="receiptno"
          label="Receipt No"
          size="small"
          value={formData.receiptno}
          onChange={handleChange}
        />

        {/* Card ID */}
        <TextField
          name="cardid"
          label="Card ID"
          size="small"
          value={formData.cardid}
          onChange={handleChange}
        />

        {/* Stock No */}
        <TextField
          name="stockno"
          label="Stock No"
          size="small"
          value={formData.stockno}
          onChange={handleChange}
        />

        {/* Gallons */}
        <TextField
          name="gallons"
          label="Gallons"
          type="number"
          size="small"
          value={formData.gallons}
          onChange={handleChange}
          onBlur={calculateTotal}
        />

        {/* Price */}
        <TextField
          name="price"
          label="Price"
          type="number"
          size="small"
          value={formData.price}
          onChange={handleChange}
          onBlur={calculateTotal}
        />

        {/* Total */}
        <TextField
          name="total"
          label="Total"
          type="number"
          size="small"
          value={formData.total}
          onChange={handleChange}
          InputProps={{
            readOnly: true,
          }}
        />

        <Stack direction="row" spacing={1}>
          {/* Submit Button */}
          <Button type="submit" variant="contained" color="primary">
            Add
          </Button>

          {/* Clear Button */}
          <Button variant="contained" color="secondary" onClick={handleClear}>
            Clear
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default AddGasLogForm;
