import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Typography, Paper, Stack, Box, CircularProgress } from '@mui/material';
import { round } from 'math.js';
import dayjs from 'dayjs';

const UnitSummaryTable = ({ data, loading, error }) => {
  const { isAuthenticated } = useAuth0();

  const calculateCount = (dealType) =>
    data.filter((item) => item.FIDealType === dealType).length;

  const summaryMetrics = (dealType) => {
    const count = calculateCount(dealType);
    return round((count / dayjs().date()) * dayjs().daysInMonth());
  };

  const summary = {
    newCount: calculateCount('New'),
    newPacingCount: summaryMetrics('New'),
    usedCount: calculateCount('Used'),
    usedPacingCount: summaryMetrics('Used'),
    totalUnitCount: data.length,
    totalUnitPacingCount: summaryMetrics('New') + summaryMetrics('Used'),
  };

  const xLabels = ['New', 'Used', 'Total'];
  const yData = [summary.newCount, summary.usedCount, summary.totalUnitCount];
  const yPacingData = [
    summary.newPacingCount,
    summary.usedPacingCount,
    summary.totalUnitPacingCount,
  ].filter((pacing) => pacing !== null);

  return (
    isAuthenticated && (
      <Paper sx={{ p: 2, height: '100%' }} elevation={0} variant="outlined">
        <Stack spacing={2}>
          <Typography variant="h6" sx={{ fontSize: '1rem' }}>
            Monthly Unit Summary
          </Typography>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography color="error">Error: {error.message}</Typography>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <BarChart
                series={[
                  {
                    data: yData,
                    label: 'Units',
                    type: 'bar',
                  },
                  {
                    data: yPacingData,
                    label: 'Pacing',
                    type: 'bar',
                  },
                ]}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: xLabels,
                  },
                ]}
                height={300}
                width={500}
                barLabel="value"
              />
            </Box>
          )}
        </Stack>
      </Paper>
    )
  );
};

export default UnitSummaryTable;
