import * as React from 'react';
import {
  DataGridPro,
  GridToolbar,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';

// Material UI components
import { Box } from '@mui/material';

import columns from './columns';
import SummaryTable from '../summaryTable';
import DetailPanelContent from './detailPanelContent';

const groupByPosition = (tableData) => {
  return tableData.reduce((result, deal) => {
    const { CRMSP1Name, CRMSP2Name, CRMSP3Name, DealType } = deal;

    const FrontGross = deal.FrontGross ? deal.FrontGross : 0;
    const BackGross = deal.BackGross ? deal.BackGross : 0;
    const TotalGross = deal.TotalGross ? deal.TotalGross : 0;
    const FIIncome = deal.FIIncome ? deal.FIIncome : 0;

    const salesPeople = [CRMSP1Name, CRMSP2Name, CRMSP3Name].filter(
      (name) => name,
    ); // Filter out empty names

    const dealValue = 1 / salesPeople.length; // Calculate the deal value based on the number of salespeople
    console.log('dealValue:', dealValue);
    const isUsed = DealType === 'Used'; // Check if deal is Used

    salesPeople.forEach((salesPerson) => {
      if (!result[salesPerson]) {
        result[salesPerson] = {
          totalDeals: 0,
          totalNew: 0,
          totalUsed: 0,
          totalFrontGross: 0,
          totalFIIncome: 0,
          totalBackGross: 0,
          totalProductGross: 0, // New property for total product gross
          totalGrossProfit: 0,
          totalUnitsSold: 0, // New property for total units sold
        };
      }

      result[salesPerson].totalDeals += dealValue;
      if (isUsed) {
        result[salesPerson].totalUsed += dealValue;
      } else {
        result[salesPerson].totalNew += dealValue;
      }

      // Adjust how gross numbers are accumulated based on isFinance
      result[salesPerson].totalFrontGross += parseFloat(FrontGross) * dealValue;

      result[salesPerson].totalFIIncome += parseFloat(FIIncome) * dealValue;

      result[salesPerson].totalProductGross +=
        parseFloat(BackGross - FIIncome) * dealValue;

      result[salesPerson].totalBackGross += parseFloat(BackGross) * dealValue;

      result[salesPerson].totalGrossProfit +=
        parseFloat(TotalGross) * dealValue;

      // Increment total units sold
      result[salesPerson].totalUnitsSold += dealValue;
    });

    return result;
  }, {});
};

const SalespersonTable = ({ tableData, isLoading }) => {
  const getDetailPanelContent = React.useCallback(
    ({ row }) => {
      return <DetailPanelContent salesperson={row} data={tableData} />;
    },
    [tableData],
  );

  const getDetailPanelHeight = React.useCallback(() => 'auto', []);

  const groupedData = React.useMemo(
    () => groupByPosition(tableData),
    [tableData],
  );

  const rows = Object.keys(groupedData).map((key) => {
    const { totalUnitsSold } = groupedData[key];
    const dayOfMonth = new Date().getDate(); // Get the day of the month
    const daysInMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0,
    ).getDate(); // Get the total days in the month

    return {
      id: key,
      ...groupedData[key],
      unitPace: (totalUnitsSold / dayOfMonth) * daysInMonth,
    };
  });

  const columnGroupingModel = [
    {
      groupId: 'Units',
      children: [
        { field: 'totalNew' },
        { field: 'totalUsed' },
        { field: 'totalDeals' },
        { field: 'unitPace' },
      ],
    },
    {
      groupId: 'Gross',
      children: [
        { field: 'totalFrontGross' },
        { field: 'totalFIIncome' },
        { field: 'totalProductGross' },
        { field: 'totalBackGross' },
        { field: 'totalGrossProfit' },
      ],
    },
    {
      groupId: 'PVR',
      children: [
        { field: 'frontGrossPVR' },
        { field: 'backGrossPVR' },
        { field: 'totalGrossPVR' },
      ],
    },
  ];

  return (
    <DataGridPro
      sx={{
        border: 'none',
        borderRadius: 0,
      }}
      rows={rows}
      columns={columns}
      loading={isLoading}
      density="compact"
      slots={{
        toolbar: () => {
          return (
            <Box display="flex" flexDirection="column" padding={2}>
              <Box display="flex" alignItems="baseline">
                <GridToolbar />
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarQuickFilter />
              </Box>
              <Box padding={1}>
                <SummaryTable rows={rows} isLoading={isLoading} />
              </Box>
            </Box>
          );
        },
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      disableRowSelectionOnClick
      experimentalFeatures={{ columnGrouping: true }}
      columnGroupingModel={columnGroupingModel}
      rowThreshold={0}
      getDetailPanelHeight={getDetailPanelHeight}
      getDetailPanelContent={getDetailPanelContent}
    />
  );
};

export default SalespersonTable;
