import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  TextField,
  InputAdornment,
  CircularProgress,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTaxData } from 'hooks/useTaxData';

export default function TaxesAndFees({ deskingData, handleFieldChange }) {
  const { taxdata, isLoading, isError } = useTaxData();
  const { taxrate } = deskingData;

  const [selectedCounty, setSelectedCounty] = useState(deskingData.county);
  const [selectedCity, setSelectedCity] = useState(deskingData.city);

  const transformedTaxRates = (taxdata || []).reduce((acc, curr) => {
    if (!acc[curr.County]) {
      acc[curr.County] = [];
    }
    acc[curr.County].push(curr);
    return acc;
  }, {});

  const availableCities =
    selectedCounty && transformedTaxRates[selectedCounty]
      ? transformedTaxRates[selectedCounty]
      : [];

  useEffect(() => {
    setSelectedCounty(deskingData.county);
    setSelectedCity(deskingData.city);
  }, [deskingData]);

  const handleCountyChange = (event, newValue) => {
    setSelectedCounty(newValue);
    setSelectedCity(null);

    // Update the parent component's state for county
    handleFieldChange('county', newValue);
    // Reset the city in the parent component's state
    handleFieldChange('city', '');
  };

  const handleCityChange = (event, newValue) => {
    const city = newValue ? newValue.City : null;
    setSelectedCity(city);

    if (newValue) {
      // Update the parent component's state for city and tax rate
      handleFieldChange('city', city);
      handleFieldChange('taxrate', newValue.Rate);
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Typography color="error" textAlign="center">
        Error fetching tax rates. Please try again later.
      </Typography>
    );
  }

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            id="county"
            name="county"
            options={Object.keys(transformedTaxRates)}
            getOptionLabel={(option) => option}
            value={selectedCounty}
            onChange={handleCountyChange}
            renderInput={(params) => (
              <TextField {...params} label="County" variant="outlined" />
            )}
            autoSelect
            autoComplete
          />
        </Grid>
        <Grid item xs={8}>
          <Autocomplete
            id="city"
            name="city"
            options={availableCities}
            getOptionLabel={(option) => option.City}
            value={
              availableCities.find((city) => city.City === selectedCity) || null
            }
            onChange={handleCityChange}
            renderInput={(params) => (
              <TextField {...params} label="City" variant="outlined" />
            )}
            autoSelect
            autoComplete
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="taxrateValue"
            name="taxrateValue"
            label="Tax Rate"
            variant="outlined"
            value={taxrate}
            onChange={(event) =>
              handleFieldChange('taxrate', parseFloat(event.target.value))
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            type="number"
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
}
