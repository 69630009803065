import React, { useState } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ContractForm = ({
  formData,
  handleAddContract,
  handleRemoveContract,
}) => {
  const [contract, setContract] = useState({
    type: 'MBIIncome',
    cost: '',
    sale: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContract((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddContract(contract);
    setContract({ type: 'MBIIncome', cost: '', sale: '' });
  };

  const getTypeLabel = (type) => {
    switch (type) {
      case 'MBIIncome':
        return 'Warranty';
      case 'Ins2Income':
        return 'GAP';
      case 'Ins3Income':
        return 'Maintenance';
      default:
        return 'Unknown Type';
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                name="type"
                value={contract.type}
                onChange={handleChange}
                label="Type"
              >
                <MenuItem value="MBIIncome">Warranty</MenuItem>
                <MenuItem value="Ins2Income">GAP</MenuItem>
                <MenuItem value="Ins3Income">Maintenance</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              name="cost"
              label="Cost"
              type="number"
              value={contract.cost}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              name="sale"
              label="Sale"
              type="number"
              value={contract.sale}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Add Contract
            </Button>
          </Grid>
        </Grid>
      </form>
      <List>
        {formData.Contracts.map((contractItem, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={getTypeLabel(contractItem.type)}
              secondary={`Cost: $${contractItem.cost}, Sale: $${contractItem.sale}`}
            />

            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => handleRemoveContract(index)}
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Typography variant="subtitle1">
        Warranty Gross Sales: ${formData.MBIIncome}
      </Typography>
      <Typography variant="subtitle1">
        GAP Gross Sales: ${formData.Ins2Income}
      </Typography>
      <Typography variant="subtitle1">
        Maintenance Gross Sales: ${formData.Ins3Income}
      </Typography>
    </>
  );
};

export default ContractForm;
