import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import dayjs from 'dayjs';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import { Paper, Typography, Stack } from '@mui/material';

const WeekendGrossRecap = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const today = dayjs();
        let weekends = [];

        for (let i = 0; i < 5; i++) {
          const friday = today
            .subtract(i * 7 + ((today.day() + 2) % 7), 'day')
            .startOf('day');
          const sunday = friday.add(2, 'day').endOf('day');
          weekends.push({ friday, sunday });
        }

        const fetchWeekendData = async (startDate, endDate) => {
          const token = await getAccessTokenSilently();
          const response = await axios.get(
            `${API_BASE_URL}/api/deals/${user.dealerid}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
              },
            },
          );

          const data = response.data;

          const filteredData = data.filter((item) => {
            const dealDate = dayjs(item.ContractDate);
            return dealDate.isBetween(startDate, endDate, null, '[]');
          });

          const frontGross = filteredData.reduce(
            (acc, item) => acc + parseFloat(item.FrontGross || 0),
            0,
          );
          const backGross = filteredData.reduce(
            (acc, item) => acc + parseFloat(item.BackGross || 0),
            0,
          );

          return {
            period: `${startDate.format('MM/DD')} - ${endDate.format('MM/DD')}`,
            frontGross: Math.round(frontGross),
            backGross: Math.round(backGross),
          };
        };

        const results = await Promise.all(
          weekends.map((weekend) =>
            fetchWeekendData(weekend.friday, weekend.sunday),
          ),
        );

        setData(results.reverse());
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user, getAccessTokenSilently]);

  return (
    isAuthenticated && (
      <Paper sx={{ p: 2, height: '100%' }} elevation={0} variant="outlined">
        <Stack spacing={2}>
          <Typography variant="h6" sx={{ fontSize: '1rem' }}>
            Weekend Gross Recap
          </Typography>
          {isLoading ? (
            <Typography variant="body1">Loading...</Typography>
          ) : (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="period" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="frontGross"
                  stackId="gross"
                  fill="#d62728"
                  name="Front Gross"
                />
                <Bar
                  dataKey="backGross"
                  stackId="gross"
                  fill="#8a2be2"
                  name="Back Gross"
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </Stack>
      </Paper>
    )
  );
};

export default WeekendGrossRecap;
