import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
  Divider,
  Grid,
} from '@mui/material';

const RepairOrderDialog = ({ open, onClose, repairOrder }) => {
  if (!repairOrder) {
    return null;
  }

  const {
    roNumber,
    bookedDate,
    bookedTime,
    closedDate,
    customer,
    mileage,
    vehicle,
    serviceAdvisor,
    operations,
    payments,
    totals,
    tagNo,
  } = repairOrder;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Repair Order Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Repair Order Detail</Typography>
            <Divider />
            <Typography>RO Number: {roNumber}</Typography>
            <Typography>Status: OPENED</Typography>
            <Typography>Service Advisor: {serviceAdvisor}</Typography>
            <Typography>Tag Number: {tagNo || 'N/A'}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Customer Information</Typography>
            <Divider />
            <Typography>
              <strong>Name:</strong> {customer?.name1} {customer?.name2}
            </Typography>
            <Typography>
              <strong>Phone(s):</strong>{' '}
              {customer?.phoneNumbers
                ?.map((phone) => `${phone.number} (${phone.description})`)
                .join(', ') || 'N/A'}
            </Typography>
            <Typography>
              <strong>Email:</strong>{' '}
              {customer?.emailAddresses?.[0]?.address || 'N/A'}
            </Typography>
            <Typography>
              <strong>Address:</strong>{' '}
              {customer?.address?.cityStateZip || 'N/A'}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Vehicle Information</Typography>
            <Divider />
            <Typography>
              <strong>Make:</strong> {vehicle?.makeDesc}
            </Typography>
            <Typography>
              <strong>Model:</strong> {vehicle?.modelDesc}
            </Typography>
            <Typography>
              <strong>Year:</strong> {vehicle?.year}
            </Typography>
            <Typography>
              <strong>VIN:</strong> {vehicle?.vin}
            </Typography>
            <Typography>
              <strong>Mileage:</strong> {mileage}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Service Details</Typography>
            <Divider />
            <Typography>
              <strong>Booker:</strong> {bookedDate} {bookedTime}
            </Typography>
            <Typography>
              <strong>Estimated Completion:</strong> {closedDate}
            </Typography>
            <Typography>
              <strong>Complaint:</strong>{' '}
              {operations?.[0]?.line?.serviceRequest || 'N/A'}
            </Typography>
            <Typography>
              <strong>Total Cost:</strong> ${totals?.[0]?.roSale || 'N/A'}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant="h6">Operations</Typography>
              <Divider />
              {operations?.map((operation, index) => (
                <Box key={index} mb={2}>
                  <Typography variant="subtitle1">
                    {operation.line.lineCode} - {operation.line.serviceRequest}
                  </Typography>
                  {operation.line.laborOperations?.map((laborOp, idx) => (
                    <Box key={idx} ml={2} mb={1}>
                      <Typography>
                        Operation Code: {laborOp.opCode} - {laborOp.opCodeDesc}
                      </Typography>
                      <Typography>
                        Technicians:{' '}
                        {laborOp.technicianIds?.join(', ') || 'N/A'}
                      </Typography>
                      <Typography>Sale: ${laborOp.sale}</Typography>
                      <Typography>Cost: ${laborOp.cost}</Typography>
                    </Box>
                  ))}
                </Box>
              )) || 'N/A'}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant="h6">Payments</Typography>
              <Divider />
              {payments?.map((payment, index) => (
                <Box key={index} mb={1}>
                  <Typography>
                    Payment Amount: ${payment.paymentAmount}
                  </Typography>
                  <Typography>Payment Type: {payment.code}</Typography>
                </Box>
              )) || 'N/A'}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant="h6">Totals</Typography>
              <Divider />
              <Grid container spacing={2}>
                {totals?.map((total, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Box mb={1}>
                      <Typography>
                        <strong>Pay Type:</strong> {total.payType}
                      </Typography>
                      <Typography>
                        <strong>RO Sale:</strong> ${total.roSale}
                      </Typography>
                      <Typography>
                        <strong>RO Cost:</strong> ${total.roCost}
                      </Typography>
                      <Typography>
                        <strong>Labor Sale:</strong> ${total.laborSale}
                      </Typography>
                      <Typography>
                        <strong>Labor Cost:</strong> ${total.laborCost}
                      </Typography>
                      <Typography>
                        <strong>Parts Sale:</strong> ${total.partsSale}
                      </Typography>
                      <Typography>
                        <strong>Parts Cost:</strong> ${total.partsCost}
                      </Typography>
                    </Box>
                  </Grid>
                )) || 'N/A'}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RepairOrderDialog;
