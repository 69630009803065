import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { weoweDescriptions } from '../constants';

export default function Weowes({ deskingData, handleFieldChange }) {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState([]);
  const [weowes, setWeowes] = useState([...weoweDescriptions]);
  const [weoweId, setWeoweId] = useState(null); // Use weoweId for editing
  const [weoweName, setWeoweName] = useState('');
  const [isSelected, setIsSelected] = useState(false);
  const [taxable, setTaxable] = useState(true);
  const [credit, setCredit] = useState('Front');
  const [amount, setAmount] = useState(0);
  const [cost, setCost] = useState(0);

  useEffect(() => {
    const updatedWeowes = weoweDescriptions.map((wd) => {
      const deskingDataWeowe = deskingData.weowes.find((dc) => dc.id === wd.id);
      return deskingDataWeowe ? { ...wd, ...deskingDataWeowe } : wd;
    });

    setWeowes(updatedWeowes);

    // Set checked state based on deskingData
    const checkedWeowes = deskingData.weowes
      .filter((weowe) => weowe.selected)
      .map((weowe) => weowe.id);

    setChecked(checkedWeowes);
  }, [deskingData.weowes]);

  const handleOpen = (weowe) => {
    setWeoweId(weowe.id);
    setWeoweName(weowe.name);
    setIsSelected(weowe.selected);
    setTaxable(weowe.taxable);
    setCredit(weowe.credit);
    setAmount(weowe.amount);
    setCost(weowe.cost);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const existingWeoweIndex = weowes.findIndex(
      (weowe) => weowe.id === weoweId,
    );

    const updatedWeowes =
      existingWeoweIndex !== -1
        ? weowes.map((weowe, index) =>
            index === existingWeoweIndex
              ? {
                  ...weowe,
                  name: weoweName,
                  selected: isSelected,
                  taxable: taxable,
                  credit: credit,
                  amount: amount,
                  cost: cost,
                }
              : weowe,
          )
        : [
            ...weowes,
            {
              id: weoweId, // Add new weowe with new ID
              name: weoweName,
              selected: isSelected,
              taxable: taxable,
              credit: credit,
              amount: amount,
              cost: cost,
            },
          ];

    setWeowes(updatedWeowes);
    handleFieldChange('weowes', updatedWeowes);
    handleClose();
  };

  const handleToggle = (id) => () => {
    const newChecked = [...checked];
    let isSelectedNow = false;

    const updatedWeowes = weowes.map((weowe) => {
      if (weowe.id === id) {
        isSelectedNow = !weowe.selected;
        return { ...weowe, selected: isSelectedNow };
      }
      return weowe;
    });

    setWeowes(updatedWeowes);

    if (isSelectedNow) {
      newChecked.push(id);
    } else {
      const currentIndex = checked.indexOf(id);
      if (currentIndex !== -1) {
        newChecked.splice(currentIndex, 1);
      }
    }
    setChecked(newChecked);

    const selectedWeowes = updatedWeowes.filter((weowe) => weowe.selected);
    handleFieldChange('weowes', selectedWeowes);
  };

  return (
    <>
      <Box>
        <List>
          {weowes.map((weowe) => {
            const labelId = `checkbox-list-label-${weowe.id}`;
            return (
              <ListItem
                key={weowe.id}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="more-options"
                    onClick={() => handleOpen(weowe)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
                disablePadding
                sx={{ '&:hover': { backgroundColor: 'action.hover' } }}
              >
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(weowe.id)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.includes(weowe.id)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={`${weowe.name} - $${weowe.amount}`}
                    secondary={`${weowe.credit}`}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Add or Update Weowe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill in the details to add or update a We Owe.
          </DialogContentText>
          <TextField
            autoFocus
            value={weoweName}
            onChange={(e) => setWeoweName(e.target.value)}
            margin="dense"
            id="name"
            label="Weowe Name"
            fullWidth
            variant="standard"
          />

          <Box mt={2}>
            <Switch
              checked={taxable}
              onChange={(e) => setTaxable(e.target.checked)}
              color="primary"
              name="taxable"
              inputProps={{ 'aria-label': 'Taxable Switch' }}
            />
            <span>Taxable</span>
          </Box>

          <Box mt={2}>
            <TextField
              select
              value={credit}
              onChange={(e) => setCredit(e.target.value)}
              margin="dense"
              id="credit"
              label="Credit"
              fullWidth
              variant="standard"
              SelectProps={{
                native: true,
              }}
            >
              <option value="Front">Front</option>
              <option value="Back">Back</option>
            </TextField>
          </Box>

          <Box mt={2}>
            <TextField
              value={amount}
              onChange={(e) => setAmount(Number(e.target.value))}
              margin="dense"
              id="amount"
              label="Weowe Amount"
              type="number"
              fullWidth
              variant="standard"
            />
          </Box>

          <Box mt={2}>
            <TextField
              value={cost}
              onChange={(e) => setCost(Number(e.target.value))}
              margin="dense"
              id="cost"
              label="Weowe Cost"
              type="number"
              fullWidth
              variant="standard"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            {' '}
            {/* Connect the Save button to the handleSave function */}
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
