import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { DataGridPro, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import axios from 'axios';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';

import { API_BASE_URL } from 'config';
import { useLenders } from 'hooks/useLenders';

const ResidualsGrid = () => {
  const { user } = useAuth0();
  const { lenders } = useLenders(user?.dealerid);
  const [selectedLender, setSelectedLender] = useState(null);
  const [terms, setTerms] = useState([]);
  const [residuals, setResiduals] = useState([]);
  const [loading, setLoading] = useState(false);

  // Set the default selected lender when lenders are loaded
  useEffect(() => {
    if (lenders.length > 0) {
      setSelectedLender(
        lenders.find((l) => l.Active === true && l.ActiveForLease),
      );
    }
  }, [lenders]);

  // Function to handle lender change
  const handleLenderChange = (event) => {
    const lender = lenders.find((l) => l._id === event.target.value);
    setSelectedLender(lender);
  };

  // Fetch residuals when selectedLender changes
  useEffect(() => {
    if (!selectedLender) return;

    const fetchResiduals = async () => {
      setLoading(true);
      try {
        const queryString = `?years=${selectedLender.SupportedYears.join(
          ',',
        )}&makes=${
          selectedLender.PrimaryManufacturer
        }&terms=${selectedLender.ResidualTerms.join(',')}&lenderId=${
          selectedLender._id
        }`;

        const response = await axios.get(
          `${API_BASE_URL}/api/residuals/search${queryString}`,
        );
        setResiduals(response.data || []);
        setTerms(selectedLender.ResidualTerms);
      } catch (error) {
        console.error('Error fetching residuals:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResiduals();
  }, [selectedLender]); // Dependency on selectedLender

  // Dynamic Columns based on terms
  const termColumns = terms.map((term) => ({
    field: `term_${term}`,
    headerName: `${term} Mo`,
    type: 'number',
    width: 80,
    align: 'center',
    headerAlign: 'center',
    disableColumnMenu: true,
    editable: true,
    valueGetter: (params) => {
      const residualObj = params.row.residuals.find((res) => res.term === term);

      // Check for null and return an appropriate representation
      if (residualObj) {
        return residualObj.residual !== null ? residualObj.residual : '-';
      }
      return ''; // Empty string for no data
    },
  }));

  const columns = [
    { field: 'year', headerName: 'Year', width: 75 },
    { field: 'model', headerName: 'Model', width: 200 },
    { field: 'trim', headerName: 'Trim', width: 200 },
    { field: 'model_no', headerName: 'Model Number', width: 125 },
    ...termColumns,
  ];

  const handleCellEditCommit = async (params) => {
    try {
      setLoading(true);
      const { id, field, value } = params;

      const newResidual = Number(value);

      // Extract the term number from the field
      const termNumber = parseInt(field.split('_')[1]);

      // Find the residuals array for the specific model and lender
      const currentResiduals =
        residuals.find((res) => res.id === id)?.residuals || [];

      // Update the specific residual value in the array
      const updatedResidualsArray = currentResiduals.map((residual) => {
        if (residual.term === termNumber) {
          return { ...residual, residual: newResidual };
        }
        return residual;
      });

      // Payload for the API
      const updatedData = {
        year: params.row.year,
        model_no: params.row.model_no,
        residuals: updatedResidualsArray,
        lenderId: selectedLender._id,
      };

      // API call to the upsert endpoint
      await axios.post(`${API_BASE_URL}/api/residuals/`, updatedData);

      // Update the residuals state with the new value
      setResiduals((prevResiduals) =>
        prevResiduals.map((res) => {
          if (res.id === id) {
            return { ...res, residuals: updatedResidualsArray };
          }
          return res;
        }),
      );
    } catch (error) {
      console.error('Error updating residual:', error);
      // Handle error (show notification, etc.)
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGridPro
        rowHeight={25}
        rows={residuals}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.id}
        onCellEditCommit={handleCellEditCommit} // Ensure correct event handler
        initialState={{
          pinnedColumns: { left: ['year', 'model', 'trim', 'model_no'] },
        }}
        pagination
        autoPageSize
        slots={{
          toolbar: () => (
            <Box
              display="flex"
              alignItems="center"
              p={2}
              borderBottom={1}
              borderColor="divider"
            >
              <Box display="flex" alignItems="baseline">
                <Stack spacing={2} direction="row">
                  <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                    <InputLabel id="select-lender">Select Lender</InputLabel>
                    <Select
                      labelId="select-lender"
                      label="Select Lender"
                      value={selectedLender?._id || ''}
                      onChange={handleLenderChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Select Lender' }}
                    >
                      <MenuItem value={null}>
                        <em>All Lenders</em>
                      </MenuItem>
                      {lenders
                        .filter((l) => l.Active === true && l.ActiveForLease)
                        .map((lender) => (
                          <MenuItem key={lender._id} value={lender._id}>
                            {lender.Name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <Box>
                <GridToolbarQuickFilter />
              </Box>
            </Box>
          ),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
};

export default ResidualsGrid;
