import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import LoadingComponent from './misc/LoadingComponent';
import Layout from '../layout/Layout';

export const AuthenticationGuard = ({ component: Component }) => {
  console.log('AuthenticationGuard');
  const WrappedComponent = withAuthenticationRequired(Component, {
    onRedirecting: () => <LoadingComponent />,
  });

  return (
    <Layout>
      <WrappedComponent />
    </Layout>
  );
};
