export const pricingPlans = [
  { title: 'Standard', value: 'Standard' },
  { title: 'Internet', value: 'Internet' },
  { title: 'Costco', value: 'Costco' },
  { title: 'Fleet', value: 'Fleet' },
];

export const applyDiscountFrom = [
  { title: 'MSRP', value: 'Price1' },
  { title: 'Invoice', value: 'Price2' },
  { title: 'Balance', value: 'Balance' },
];

export const lenders = [
  { title: 'TFS', value: 'TFS' },
  { title: 'House', value: 'House' },
];

export const financeTypes = [
  { title: 'All', value: 'All' },
  { title: 'Special', value: 'SPEC_APR' },
  { title: 'Standard', value: 'STAND_APR' },
];

export const leaseTypes = [
  { title: 'All', value: 'All' },
  { title: 'Special', value: 'SPEC_LEASE' },
  { title: 'Standard', value: 'STAND_LEASE' },
];

export const securityDeposits = [...Array(11).keys()];

export const acqFeeOptions = [
  { title: 'Upfront', value: 'Upfront' },
  { title: 'Capped', value: 'Capped' },
  { title: 'Waived', value: 'Waived' },
];

export const milesPerYear = [
  { title: '7,500', value: '7500' },
  { title: '10,000', value: '10000' },
  { title: '12,000', value: '12000' },
  { title: '15,000', value: '15000' },
];

export const initialFormData = {
  pricingplan: 'Standard',
  applydiscountto: 'Price1', //MSRP
  discount: 0,
  creditscore: 720,
  financetype: 'All',
  downpayments: [1000, 2000, 3000],
  financeterms: [54, 60, 66],
  daystofirstpayment: 45,
  financereserve: 2.0,
  lender: 'TFS',
  leasetype: 'All',
  driveoffs: [1000, 2000, 3000],
  leaseterms: [36, 39, 42],
  securitydeposits: 0,
  milesperyear: '12000',
  leasereserve: 0.0004,
  applyacqfeeto: 'Capped',
  taxrateValue: 0.095,
  taxrateCity: 'Valencia (Santa Clarita)',
};

export const transactionTypes = [
  { value: 0, name: 'Retail' },
  { value: 1, name: 'Lease' },
  { value: 2, name: 'Both' },
];

export const vehicleTypes = [
  { value: 0, name: 'New' },
  { value: 1, name: 'Used' },
  { value: 2, name: 'Both' },
];

export const dmvFees = [
  {
    name: 'Registration Fee',
    value: 68,
  },
  {
    name: 'CHP Fee',
    value: 30,
  },
  {
    name: 'Plate Fee',
    value: 1,
  },
  {
    name: 'Transfer Fee',
    value: 15,
  },
  {
    name: 'Smog Abatement Fee',
    value: 20,
  },
];

export const tiFee = [
  {
    min: 0,
    max: 4999,
    value: 29,
  },
  {
    min: 5000,
    max: 24999,
    value: 59,
  },
  {
    min: 25000,
    max: 34999,
    value: 118,
  },
  {
    min: 35000,
    max: 59999,
    value: 177,
  },
  {
    min: 60000,
    max: null,
    value: 206,
  },
];

export const vlFee = 0.65;

export const tireFee = 1.75;
