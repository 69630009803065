import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useEmployees } from 'hooks/useEmployees';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import {
  Box,
  Typography,
  Stack,
  Paper,
  Divider,
  Button,
  Snackbar,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import CalendarDateRangePicker from 'components/DateRangePicker';
import { API_BASE_URL } from 'config';
import axios from 'axios';

dayjs.extend(isoWeek);

const getDefaultDateRange = () => {
  const today = dayjs();
  const currentDayOfWeek = today.day();

  // If it's Friday, Saturday, or Sunday, use the current weekend
  if (
    currentDayOfWeek === 5 ||
    currentDayOfWeek === 6 ||
    currentDayOfWeek === 0
  ) {
    const friday = currentDayOfWeek === 5 ? today : today.day(5);
    const sunday = currentDayOfWeek === 0 ? today : today.day(7);
    return [friday.startOf('day'), sunday.endOf('day')];
  } else {
    // Otherwise, use the previous weekend
    const lastFriday = today.day(5).subtract(1, 'week');
    const lastSunday = today.day(7).subtract(1, 'week');
    return [lastFriday.startOf('day'), lastSunday.endOf('day')];
  }
};

const WeekendSummaryTables = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState({});
  const [dateRange, setDateRange] = useState(getDefaultDateRange());
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const Employees = useEmployees(user, isAuthenticated);
  const SalesManagers = Employees.data
    .filter(
      (employee) =>
        employee.isActive && employee.accessRole === 'Sales Manager',
    )
    .map((employee) => `${employee.firstName} ${employee.lastName}`);
  SalesManagers.push('House');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${API_BASE_URL}/api/coversheets`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            dealerID: user?.dealerid,
            startDate: dateRange[0].format('YYYY-MM-DD'),
            endDate: dateRange[1].format('YYYY-MM-DD'),
          },
        });

        // Group data by date
        const allDates = response.data.reduce((acc, item) => {
          const date = new Date(item.date).toISOString().split('T')[0];
          console.log(item.date, date);
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(item);
          return acc;
        }, {});

        // Ensure all dates in the range are represented
        let currentDate = dateRange[0].startOf('day');
        while (
          currentDate.isBefore(dateRange[1]) ||
          currentDate.isSame(dateRange[1], 'day')
        ) {
          const dateKey = currentDate.format('YYYY-MM-DD');
          if (!allDates[dateKey]) {
            allDates[dateKey] = [];
          }
          currentDate = currentDate.add(1, 'day');
        }

        setTableData(allDates);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (user?.dealerid) {
      fetchData();
    }
  }, [dateRange, user?.dealerid, getAccessTokenSilently]);

  const processDailyData = (data) => {
    const summary = SalesManagers.map((salesManager) => {
      let newInStockCount = 0;
      let priorReserved = 0;
      let newInTransitCount = 0;
      let usedCount = 0;
      let FrontGrossSum = 0;
      let BackGrossSum = 0;
      let TotalGrossSum = 0;

      data.forEach((item) => {
        if (item.salesmanager === salesManager) {
          if (item.vehicletype === 'NEW' && item.vehiclestatus === 'IN STOCK')
            newInStockCount++;
          if (item.vehicletype === 'NEW' && item.reserved) priorReserved++;
          if (item.vehicletype === 'USED') usedCount++;

          if (item.vehiclestatus !== 'IN TRANSIT') {
            FrontGrossSum += parseFloat(item.fisalesmodel?.FrontGross) || 0;
            BackGrossSum += parseFloat(item.fisalesmodel?.BackGross) || 0;
            TotalGrossSum += parseFloat(item.fisalesmodel?.TotalGross) || 0;
          }
        }

        if (
          item.salesmanager === salesManager &&
          item.vehicletype === 'NEW' &&
          item.vehiclestatus === 'IN TRANSIT'
        ) {
          newInTransitCount++;
        }
      });

      return {
        SalesManager: salesManager,
        NewInStock: newInStockCount,
        PriorReserved: priorReserved,
        NewInTransit: newInTransitCount,
        Used: usedCount,
        FrontGrossSum,
        BackGrossSum,
        TotalGrossSum,
      };
    });

    // Add daily summary row
    const dailySummary = summary.reduce(
      (acc, row) => ({
        SalesManager: 'Daily Summary',
        NewInStock: acc.NewInStock + row.NewInStock,
        PriorReserved: acc.PriorReserved + row.PriorReserved,
        NewInTransit: acc.NewInTransit + row.NewInTransit,
        Used: acc.Used + row.Used,
        FrontGrossSum: acc.FrontGrossSum + row.FrontGrossSum,
        BackGrossSum: acc.BackGrossSum + row.BackGrossSum,
        TotalGrossSum: acc.TotalGrossSum + row.TotalGrossSum,
      }),
      {
        SalesManager: 'Daily Summary',
        NewInStock: 0,
        PriorReserved: 0,
        NewInTransit: 0,
        Used: 0,
        FrontGrossSum: 0,
        BackGrossSum: 0,
        TotalGrossSum: 0,
      },
    );

    return [...summary, dailySummary];
  };

  const columns = [
    {
      field: 'SalesManager',
      headerName: 'Sales Manager',
      headerAlign: 'left',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'NewInStock',
      headerName: 'New/In Stock',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      type: 'number',
    },
    {
      field: 'PriorReserved',
      headerName: 'F Status/Delivered',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      type: 'number',
    },
    {
      field: 'NewInTransit',
      headerName: 'F Status/Deposit',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      type: 'number',
    },
    {
      field: 'Used',
      headerName: 'Used',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      type: 'number',
    },
    {
      field: 'Total',
      headerName: 'Total',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      type: 'number',
      valueGetter: (value, row) => row.NewInStock + row.Used,
    },
    {
      field: 'FrontGrossSum',
      headerName: 'Front GP',
      headerAlign: 'right',
      flex: 1,
      minWidth: 150,
      type: 'number',
      valueFormatter: (value) =>
        Number(value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
    },
    {
      field: 'BackGrossSum',
      headerName: 'Back GP',
      headerAlign: 'right',
      flex: 1,
      minWidth: 150,
      type: 'number',
      valueFormatter: (value) =>
        Number(value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
    },
    {
      field: 'TotalGrossSum',
      headerName: 'Total GP',
      headerAlign: 'right',
      flex: 1,
      minWidth: 150,
      type: 'number',
      valueFormatter: (value) =>
        Number(value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
    },
  ];

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const calculateGrandTotal = () => {
    return Object.values(tableData).reduce(
      (acc, dailyData) => {
        const dailySummary = processDailyData(dailyData).slice(-1)[0]; // Get the daily summary
        return {
          SalesManager: 'Grand Total',
          NewInStock: acc.NewInStock + dailySummary.NewInStock,
          PriorReserved: acc.PriorReserved + dailySummary.PriorReserved,
          NewInTransit: acc.NewInTransit + dailySummary.NewInTransit,
          Used: acc.Used + dailySummary.Used,
          FrontGrossSum: acc.FrontGrossSum + dailySummary.FrontGrossSum,
          BackGrossSum: acc.BackGrossSum + dailySummary.BackGrossSum,
          TotalGrossSum: acc.TotalGrossSum + dailySummary.TotalGrossSum,
        };
      },
      {
        SalesManager: 'Grand Total',
        NewInStock: 0,
        PriorReserved: 0,
        NewInTransit: 0,
        Used: 0,
        FrontGrossSum: 0,
        BackGrossSum: 0,
        TotalGrossSum: 0,
      },
    );
  };

  const formatDataForClipboard = () => {
    let formattedHtml = `
      <html>
      <head>
        <style>
          table { border-collapse: collapse; width: 100%; margin-bottom: 20px; }
          th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
          th { background-color: #f2f2f2; }
          .date-header { background-color: #e6e6e6; font-weight: bold; }
          .total-row { font-weight: bold; background-color: #f2f2f2; }
        </style>
      </head>
      <body>
        <h2>Summary Tables</h2>
    `;

    Object.entries(tableData)
      .sort(([dateA], [dateB]) => dayjs(dateA).diff(dayjs(dateB)))
      .forEach(([date, dailyData]) => {
        formattedHtml += `
          <table>
            <tr class="date-header"><td colspan="9">${dayjs(date).format(
              'MMMM D, YYYY',
            )}</td></tr>
            <tr>
              <th>Sales Manager</th>
              <th>New/In Stock</th>
              <th>F Status/Delivered</th>
              <th>F Status/Deposit</th>
              <th>Used</th>
              <th>Total</th>
              <th>Front GP</th>
              <th>Back GP</th>
              <th>Total GP</th>
            </tr>
        `;

        processDailyData(dailyData).forEach((row) => {
          formattedHtml += `
            <tr>
              <td>${row.SalesManager}</td>
              <td>${row.NewInStock}</td>
              <td>${row.PriorReserved}</td>
              <td>${row.NewInTransit}</td>
              <td>${row.Used}</td>
              <td>${row.NewInStock + row.Used}</td>
              <td>${row.FrontGrossSum.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}</td>
              <td>${row.BackGrossSum.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}</td>
              <td>${row.TotalGrossSum.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}</td>
            </tr>
          `;
        });

        formattedHtml += `</table>`;
      });

    const grandTotal = calculateGrandTotal();
    formattedHtml += `
      <table>
        <tr class="date-header"><td colspan="9">Grand Total</td></tr>
        <tr>
          <th>Sales Manager</th>
          <th>New/In Stock</th>
          <th>F Status/Delivered</th>
          <th>F Status/Deposit</th>
          <th>Used</th>
          <th>Total</th>
          <th>Front GP</th>
          <th>Back GP</th>
          <th>Total GP</th>
        </tr>
        <tr class="total-row">
          <td>Total</td>
          <td>${grandTotal.NewInStock}</td>
          <td>${grandTotal.PriorReserved}</td>
          <td>${grandTotal.NewInTransit}</td>
          <td>${grandTotal.Used}</td>
          <td>${grandTotal.NewInStock + grandTotal.Used}</td>
          <td>${grandTotal.FrontGrossSum.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}</td>
          <td>${grandTotal.BackGrossSum.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}</td>
          <td>${grandTotal.TotalGrossSum.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}</td>
        </tr>
      </table>
      </body>
      </html>
    `;

    return formattedHtml;
  };

  const handleCopyToClipboard = () => {
    const formattedHtml = formatDataForClipboard();
    const blob = new Blob([formattedHtml], { type: 'text/html' });
    const clipboardItem = new ClipboardItem({ 'text/html': blob });

    navigator.clipboard.write([clipboardItem]).then(() => {
      setSnackbarOpen(true);
    });
  };

  return (
    isAuthenticated && (
      <Paper sx={{ p: 2 }}>
        <Stack spacing={1}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Summary Tables</Typography>
            <Stack direction="row" spacing={2}>
              <CalendarDateRangePicker
                dateRange={dateRange}
                onDateRangeChange={handleDateRangeChange}
              />
              <Button
                variant="contained"
                startIcon={<ContentCopyIcon />}
                onClick={handleCopyToClipboard}
              >
                Copy to Clipboard
              </Button>
            </Stack>
          </Stack>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (
            <>
              {Object.entries(tableData)
                .sort(([dateA], [dateB]) => dayjs(dateA).diff(dayjs(dateB)))
                .map(([date, dailyData]) => {
                  return (
                    <Box key={date}>
                      <Typography variant="h6" gutterBottom>
                        {dayjs(date).format('MMMM D, YYYY')}
                      </Typography>
                      <DataGridPremium
                        density="compact"
                        rows={processDailyData(dailyData).map(
                          (item, index) => ({
                            id:
                              index === processDailyData(dailyData).length - 1
                                ? 'dailySummary'
                                : item.SalesManager,
                            ...item,
                          }),
                        )}
                        columns={columns}
                        autoHeight
                        hideFooter
                        sx={{
                          backgroundColor: 'background.paper',
                          boxShadow: 1,
                          border: 0,
                          '& .MuiDataGrid-row': {
                            border: 0,
                            borderRadius: 1,
                          },
                          '& .MuiDataGrid-row:last-child': {
                            fontWeight: 'bold',
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                          },
                        }}
                      />
                      <Divider sx={{ my: 2 }} />
                    </Box>
                  );
                })}
              <Typography variant="h6" gutterBottom>
                Grand Total
              </Typography>
              <DataGridPremium
                density="compact"
                rows={[{ id: 'grandTotal', ...calculateGrandTotal() }]}
                columns={columns}
                autoHeight
                hideFooter
                sx={{
                  backgroundColor: 'background.paper',
                  boxShadow: 1,
                  border: 0,
                  '& .MuiDataGrid-row': {
                    border: 0,
                    borderRadius: 1,
                    fontWeight: 'bold',
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  },
                }}
              />
            </>
          )}
        </Stack>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message="Copied to clipboard"
        />
      </Paper>
    )
  );
};

export default WeekendSummaryTables;
