import * as React from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';

export default function DetailsPopover({ row, fieldName }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const amount = row[fieldName]?.amount || 0;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // Define a formatter for currency values
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  return (
    <div>
      <Button aria-describedby={id} variant="text" onClick={handleClick}>
        {currencyFormatter.format(amount)}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Refer</TableCell>
              <TableCell>Journal ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {row[fieldName]?.descriptions?.map((description, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{description.description}</TableCell>
                <TableCell>
                  {currencyFormatter.format(description.amount)}
                </TableCell>
                <TableCell>{description.refer}</TableCell>
                <TableCell>{description.journalid}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Popover>
    </div>
  );
}
