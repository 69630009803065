// FeesGrid.js
import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Button, CircularProgress } from '@mui/material';
import { useFees } from 'hooks/useFees';
import CreateFeeDialog from './CreateFeeDialog';
import UpdateFeeDialog from './UpdateFeeDialog';
import DeleteFeeDialog from './DeleteFeeDialog';

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const FeesGrid = () => {
  const { user } = useAuth0();
  console.log(user);
  const { fees, loading, error, refetchFees } = useFees();

  // State to manage dialog visibility
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  // State to store data for editing and deleting fees
  const [editFeeData, setEditFeeData] = useState(null);
  const [deleteFeeData, setDeleteFeeData] = useState(null);

  // Function to open the Create dialog
  const handleOpenCreateDialog = () => {
    setCreateDialogOpen(true);
  };

  // Function to open the Update dialog
  const handleOpenUpdateDialog = (fee) => {
    setEditFeeData(fee);
    setUpdateDialogOpen(true);
  };

  // Function to open the Delete dialog
  const handleOpenDeleteDialog = (fee) => {
    setDeleteFeeData(fee);
    setDeleteDialogOpen(true);
  };

  const columns = [
    {
      field: 'Actions',
      headerName: 'Actions',
      width: 120,
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() => handleOpenUpdateDialog(params.row)}
            color="primary"
            aria-label="Edit"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleOpenDeleteDialog(params.row)}
            color="secondary"
            aria-label="Delete"
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
    { field: 'Description', headerName: 'Description', flex: 2 },
    { field: 'VehicleType', headerName: 'Vehicle Type', flex: 1 },
    { field: 'TransactionType', headerName: 'Transaction Type', flex: 1 },
    { field: 'Value', headerName: 'Value', type: 'number', flex: 1 },
    { field: 'InCap', headerName: 'Upfront (Lease)', flex: 1 },
    { field: 'TaxInCap', headerName: 'Cap Tax (Lease)', flex: 1 },
  ];

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h2>Fees Management</h2>
        <Button
          onClick={handleOpenCreateDialog}
          variant="contained"
          color="primary"
        >
          Create Fee
        </Button>
      </div>

      {/* DataGridPro */}
      <div style={{ height: 600, width: '100%' }}>
        <DataGridPro
          getRowId={(row) => row._id}
          rows={fees}
          columns={columns}
          sx={{
            backgroundColor: 'background.paper',
          }}
        />
      </div>

      {/* Dialogs */}
      {createDialogOpen && (
        <CreateFeeDialog
          open={createDialogOpen}
          onClose={() => setCreateDialogOpen(false)}
          refetchFees={refetchFees}
          dealerID={user.dealerid}
        />
      )}
      {updateDialogOpen && (
        <UpdateFeeDialog
          open={updateDialogOpen}
          onClose={() => setUpdateDialogOpen(false)}
          fee={editFeeData}
          refetchFees={refetchFees}
        />
      )}
      {deleteDialogOpen && (
        <DeleteFeeDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          fee={deleteFeeData}
          refetchFees={refetchFees}
        />
      )}
    </div>
  );
};

export default FeesGrid;
