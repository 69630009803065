import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from 'recharts';
import { Typography, Button, Stack, Paper } from '@mui/material';

import dayjs from 'dayjs';
import CalendarDateRangePicker from 'components/DateRangePicker';

import { API_BASE_URL } from 'config';
import axios from 'axios';

const CarDealTables = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [dateRange, setDateRange] = useState([
    dayjs().startOf('month'),
    dayjs(),
  ]);
  const [vehicleType, setVehicleType] = useState('Total');
  const [graphData, setGraphData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${API_BASE_URL}/api/deals/${user.dealerid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              startDate: dateRange[0].format('YYYY-MM-DD'),
              endDate: dateRange[1].format('YYYY-MM-DD'),
            },
          },
        );

        const data = response.data;

        const employeeData = data
          .filter(
            (item) =>
              (item.FiWipStatusCode === 'F' || item.FiWipStatusCode === 'B') &&
              item.DealType !== 'Wholesale',
          )
          .reduce((acc, row) => {
            const { CRMSP1Name, CRMSP2Name, CRMSP3Name } = row;

            const salesPeople = [CRMSP1Name, CRMSP2Name, CRMSP3Name].filter(
              (name) => name,
            );

            const dealValue = 1 / salesPeople.length;

            salesPeople.forEach((salesPerson) => {
              if (salesPerson) {
                if (!acc[salesPerson]) {
                  acc[salesPerson] = {
                    NewVehicles: 0,
                    UsedVehicles: 0,
                  };
                }

                acc[salesPerson].NewVehicles +=
                  row.FIDealType === 'New' ? dealValue : 0;
                acc[salesPerson].UsedVehicles +=
                  row.FIDealType === 'Used' ? dealValue : 0;
              }
            });

            return acc;
          }, {});

        const groupedDataArray = Object.entries(employeeData).map(
          ([salesPerson, data]) => ({
            salesPerson,
            New: data.NewVehicles,
            Used: data.UsedVehicles,
            Total: data.NewVehicles + data.UsedVehicles,
          }),
        );

        const sortedData = groupedDataArray.sort(
          (a, b) => b[vehicleType] - a[vehicleType],
        );

        setGraphData(sortedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dateRange, user.dealerid, getAccessTokenSilently, user, vehicleType]);

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const handleVehicleTypeChange = (newVehicleType) => {
    setVehicleType(newVehicleType);
  };

  const getColor = (buttonStatus) =>
    vehicleType === buttonStatus ? 'primary' : 'inherit';

  return (
    isAuthenticated && (
      <Paper sx={{ p: 2, height: '100%' }} elevation={0} variant="outlined">
        <Stack spacing={2}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h6" sx={{ fontSize: '1rem' }}>
              Top Salespeople by Units Sold
            </Typography>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              alignItems="center"
            >
              <CalendarDateRangePicker
                dateRange={dateRange}
                onDateRangeChange={handleDateRangeChange}
              />
              <Stack direction="row">
                <Button
                  onClick={() => handleVehicleTypeChange('New')}
                  variant={vehicleType === 'New' ? 'outlined' : 'text'}
                  color={getColor('New')}
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  New
                </Button>
                <Button
                  onClick={() => handleVehicleTypeChange('Used')}
                  variant={vehicleType === 'Used' ? 'outlined' : 'text'}
                  color={getColor('Used')}
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  Used
                </Button>
                <Button
                  onClick={() => handleVehicleTypeChange('Total')}
                  variant={vehicleType === 'Total' ? 'outlined' : 'text'}
                  color={getColor('Total')}
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  Total
                </Button>
              </Stack>
            </Stack>
          </Stack>
          {isLoading ? (
            <Typography variant="body1">Loading...</Typography>
          ) : (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={graphData}>
                <CartesianGrid stroke="3 3" />
                <XAxis dataKey="salesPerson" />
                <YAxis />
                <Tooltip />
                <Legend />
                {vehicleType === 'New' && <Bar dataKey="New" fill="#8884d8" />}
                {vehicleType === 'Used' && (
                  <Bar dataKey="Used" fill="#82ca9d" />
                )}
                {vehicleType === 'Total' && (
                  <>
                    <Bar dataKey="New" stackId="units" fill="#8884d8" />
                    <Bar dataKey="Used" stackId="units" fill="#82ca9d" />
                  </>
                )}
              </BarChart>
            </ResponsiveContainer>
          )}
        </Stack>
      </Paper>
    )
  );
};

export default CarDealTables;
