import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  ReferenceLine,
} from 'recharts';
import { Typography, Button, Stack, Paper } from '@mui/material';

import dayjs from 'dayjs';
import CalendarDateRangePicker from 'components/DateRangePicker';

import { API_BASE_URL } from 'config';
import axios from 'axios';

const CarDealTables = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [dateRange, setDateRange] = useState([
    dayjs().startOf('month'),
    dayjs(),
  ]);
  const [grossType, setGrossType] = useState('TotalGross');
  const [graphData, setGraphData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${API_BASE_URL}/api/deals/${user.dealerid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              startDate: dateRange[0].format('YYYY-MM-DD'),
              endDate: dateRange[1].format('YYYY-MM-DD'),
            },
          },
        );

        const data = response.data;

        const employeeData = data
          .filter(
            (item) =>
              (item.FiWipStatusCode === 'F' || item.FiWipStatusCode === 'B') &&
              item.DealType !== 'Wholesale',
          )
          .reduce((acc, row) => {
            const { CRMSP1Name, CRMSP2Name, CRMSP3Name } = row;

            const salesPeople = [CRMSP1Name, CRMSP2Name, CRMSP3Name].filter(
              (name) => name,
            );

            const dealValue = 1 / salesPeople.length;

            salesPeople.forEach((salesPerson) => {
              if (salesPerson) {
                if (!acc[salesPerson]) {
                  acc[salesPerson] = {
                    FrontGross: 0,
                    BackGross: 0,
                    TotalGross: 0,
                  };
                }

                acc[salesPerson].FrontGross += row.FrontGross * dealValue;
                acc[salesPerson].BackGross += row.BackGross * dealValue;
                acc[salesPerson].TotalGross += row.TotalGross * dealValue;
              }
            });

            return acc;
          }, {});

        const groupedDataArray = Object.entries(employeeData).map(
          ([salesPerson, data]) => ({
            salesPerson,
            FrontGross: parseFloat(data.FrontGross.toFixed(2)),
            BackGross: parseFloat(data.BackGross.toFixed(2)),
            TotalGross: parseFloat(data.TotalGross.toFixed(2)),
          }),
        );

        const sortedData = groupedDataArray.sort(
          (a, b) => b[grossType] - a[grossType],
        );

        setGraphData(sortedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dateRange, user?.dealerid, grossType, getAccessTokenSilently]);

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const handleGrossTypeChange = (newGrossType) => {
    setGrossType(newGrossType);
  };

  const getColor = (buttonStatus) =>
    grossType === buttonStatus ? 'primary' : 'inherit';

  return (
    isAuthenticated && (
      <Paper sx={{ p: 2, height: '100%' }} elevation={0} variant="outlined">
        <Stack spacing={2}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h6" sx={{ fontSize: '1rem' }}>
              Top Salespeople by Gross
            </Typography>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              alignItems="center"
            >
              <CalendarDateRangePicker
                dateRange={dateRange}
                onDateRangeChange={handleDateRangeChange}
              />
              <Stack direction="row">
                <Button
                  onClick={() => handleGrossTypeChange('FrontGross')}
                  variant={grossType === 'FrontGross' ? 'outlined' : 'text'}
                  color={getColor('FrontGross')}
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  Front
                </Button>
                <Button
                  onClick={() => handleGrossTypeChange('BackGross')}
                  variant={grossType === 'BackGross' ? 'outlined' : 'text'}
                  color={getColor('BackGross')}
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={() => handleGrossTypeChange('TotalGross')}
                  variant={grossType === 'TotalGross' ? 'outlined' : 'text'}
                  color={getColor('TotalGross')}
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  Total
                </Button>
              </Stack>
            </Stack>
          </Stack>
          {isLoading ? (
            <Typography variant="body1">Loading...</Typography>
          ) : (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={graphData}>
                <CartesianGrid stroke="3 3" />
                <XAxis dataKey="salesPerson" />
                <YAxis />
                <Tooltip />
                <Legend />
                <ReferenceLine y={0} stroke="#000" />
                {grossType === 'FrontGross' && (
                  <Bar dataKey="FrontGross" fill="#8884d8" />
                )}
                {grossType === 'BackGross' && (
                  <Bar dataKey="BackGross" fill="#82ca9d" />
                )}
                {grossType === 'TotalGross' && (
                  <>
                    <Bar dataKey="FrontGross" stackId="units" fill="#8884d8" />
                    <Bar dataKey="BackGross" stackId="units" fill="#82ca9d" />
                  </>
                )}
              </BarChart>
            </ResponsiveContainer>
          )}
        </Stack>
      </Paper>
    )
  );
};

export default CarDealTables;
