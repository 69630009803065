// checklistItems.js

export const dealChecklistItems = [
  { name: 'salesmenu', label: 'SALES MENU' },
  { name: 'dealscreen', label: 'DEAL SCREEN' },
  { name: 'gscreen', label: 'G SCREEN' },
  { name: 'inoutform', label: 'IN/OUT FORM' },
  { name: 'rebateforms', label: 'REBATE FORMS' },
  { name: 'taxverification', label: 'TAX RATE VERIFICATION' },
];

export const customerChecklistItems = [
  { name: 'driverslicense', label: 'DRIVERS LICENSE' },
  { name: 'insurance', label: 'INSURANCE' },
  { name: 'creditapp', label: 'CREDIT APP' },
  { name: 'creditreport', label: 'CREDIT REPORT' },
  { name: 'ofac', label: 'OFAC' },
  { name: 'redflags', label: 'IDOne Verification' },
];

export const newChecklistItems = [
  { name: 'invoice', label: 'INVOICE' },
  { name: 'addendum', label: 'SIGNED ADDENDUM' },
  { name: 'connectedservices', label: 'CONNECTED SERVICES' },
  { name: 'pdichecklist', label: 'PDI CHECKLIST' },
  { name: 'partialautomationdisclosure', label: 'AUTOMATION DISCLOSURE' },
  { name: 'newrecallverification', label: 'RECALL VERIFICATION' },
];

export const usedChecklistItems = [
  { name: 'booksheet', label: 'BOOKSHEET' },
  { name: 'buyersguide', label: 'BUYERS GUIDE' },
  { name: 'carfax', label: 'CARFAX' },
  { name: 'ksr', label: 'KSR | NMVTIS' },
  { name: 'cpochecklist', label: 'CPO CHECKLIST' },
  { name: 'usedrecallverification', label: 'RECALL VERIFICATION' },
  { name: 'dmvcalc', label: 'DMV CALCULATION' },
];

export const taxableChecklistItems = [
  { name: 'presold1', descName: 'presold1Desc', label: 'KARR Alarm' },
  { name: 'presold2', descName: 'presold2Desc', label: 'Cilajet' },
  { name: 'presold3', descName: 'presold3Desc', label: 'CAT Protection' },
  { name: 'presold4', descName: 'presold4Desc', label: '' },
  { name: 'presold5', descName: 'presold5Desc', label: '' },
];

export const nontaxableChecklistItems = [
  {
    name: 'presold6',
    descName: 'presold6Desc',
    label: 'Vehicle Service Contract',
  },
  { name: 'presold7', descName: 'presold7Desc', label: 'GAP' },
  { name: 'presold8', descName: 'presold8Desc', label: 'Prepaid Maintenance' },
  { name: 'presold9', descName: 'presold9Desc', label: 'Tire & Wheel' },
  { name: 'presold10', descName: 'presold10Desc', label: 'Lease Wear & Tear' },
];

export const stipChecklistItems = [
  { name: 'stip1', descName: 'stip1Desc', label: 'Proof of Income' },
  { name: 'stip2', descName: 'stip2Desc', label: 'Proof of Residence' },
  { name: 'stip3', descName: 'stip3Desc', label: 'SSA-89 Form' },
  { name: 'stip4', descName: 'stip4Desc', label: '' },
  { name: 'stip5', descName: 'stip5Desc', label: '' },
];

export const tradeChecklistItems = [
  { name: 'tradecarfax', label: 'CARFAX' },
  { name: 'tradeksr', label: 'KSR' },
  { name: 'tradenmvtis', label: 'NMVTIS' },
  { name: 'tradebooksheet', label: 'BOOKSHEET' },
  { name: 'tradepayoff', label: 'PAYOFF' },
  { name: 'tradeappraisal', label: 'APPRAISAL' },
  { name: 'traderegistration', label: 'REGISTRATION' },
  { name: 'traderecall', label: 'RECALL' },
];
