import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: theme.shadows[1],
}));

const CenteredToolbar = styled(Toolbar)({
  justifyContent: 'center',
});

const PaymentInfo = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 2),
  fontWeight: 'bold',
  textAlign: 'center',
}));

const FixedNavBar = ({ cashDown, totalPayment }) => {
  return (
    <StyledAppBar position="fixed">
      <CenteredToolbar>
        <Box display="flex" justifyContent="center" alignItems="center">
          <PaymentInfo variant="body1">
            Out-of-Pocket: ${cashDown ? cashDown.toFixed(2) : 0}
          </PaymentInfo>
          <PaymentInfo variant="body1">
            Payment w/Tax: ${totalPayment ? totalPayment.toFixed(2) : 0}
          </PaymentInfo>
        </Box>
      </CenteredToolbar>
    </StyledAppBar>
  );
};

export default FixedNavBar;
