import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Stack,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useEmployees } from 'hooks/useEmployees';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import dayjs from 'dayjs';

const CoverSheetSummaryTable = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [dateRange, setDateRange] = useState('Today');
  const [status, setStatus] = useState('All');
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const getDateRange = (range) => {
    const today = dayjs().startOf('day');
    switch (range) {
      case 'Today':
        return [today, today];
      case 'Yesterday':
        return [today.subtract(1, 'day'), today.subtract(1, 'day')];
      case 'Week':
        return [today.startOf('week'), today];
      case 'Month':
        return [today.startOf('month'), today];
      default:
        return [today, today];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${API_BASE_URL}/api/coversheets`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            dealerID: user?.dealerid,
            startDate: getDateRange(dateRange)[0].format('YYYY-MM-DD'),
            endDate: getDateRange(dateRange)[1].format('YYYY-MM-DD'),
          },
        });
        setTableData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (user?.dealerid) {
      fetchData();
    }
  }, [dateRange, user?.dealerid, getAccessTokenSilently, user]);

  const Employees = useEmployees(user, isAuthenticated);
  const SalesManagers = Employees.data
    .filter(
      (employee) =>
        employee.isActive && employee.accessRole === 'Sales Manager',
    )
    .map((employee) => `${employee.firstName} ${employee.lastName}`);
  SalesManagers.push('House');

  let updatedData = tableData;

  if (status === 'Pending') {
    updatedData = tableData.filter((deal) => !deal.fisalesmodel);
  } else if (status === 'Booked') {
    updatedData = tableData.filter((deal) => !!deal.fisalesmodel);
  }

  const summary = SalesManagers.map((salesManager) => {
    let newInStockCount = 0;
    let priorReserved = 0;
    let newInTransitCount = 0;
    let usedCount = 0;
    let FrontGrossSum = 0;
    let BackGrossSum = 0;
    let TotalGrossSum = 0;

    updatedData.forEach((item) => {
      if (item.salesmanager === salesManager) {
        if (item.vehicletype === 'NEW' && item.vehiclestatus === 'IN STOCK')
          newInStockCount++;
        if (item.vehicletype === 'NEW' && item.reserved) priorReserved++;
        if (item.vehicletype === 'USED') usedCount++;

        if (item.vehiclestatus !== 'IN TRANSIT') {
          FrontGrossSum += parseFloat(item.fisalesmodel?.FrontGross) || 0;
          BackGrossSum += parseFloat(item.fisalesmodel?.BackGross) || 0;
          TotalGrossSum += parseFloat(item.fisalesmodel?.TotalGross) || 0;
        }
      }

      if (
        item.salesmanager === salesManager &&
        item.vehicletype === 'NEW' &&
        item.vehiclestatus === 'IN TRANSIT'
      ) {
        newInTransitCount++;
      }
    });

    return {
      SalesManager: salesManager,
      NewInStock: newInStockCount,
      PriorReserved: priorReserved,
      NewInTransit: newInTransitCount,
      Used: usedCount,
      FrontGrossSum,
      BackGrossSum,
      TotalGrossSum,
    };
  });

  const columns = [
    {
      field: 'SalesManager',
      headerName: 'Sales Manager',
      headerAlign: 'left',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'NewInStock',
      headerName: 'New/In Stock',
      flex: 1,
      minWidth: 100,
      type: 'number',
    },
    {
      field: 'PriorReserved',
      headerName: 'Prior Reserved',
      flex: 1,
      minWidth: 100,
      type: 'number',
    },
    {
      field: 'NewInTransit',
      headerName: 'New/In Transit',
      flex: 1,
      minWidth: 100,
      type: 'number',
    },
    {
      field: 'Used',
      headerName: 'Used',
      flex: 1,
      minWidth: 100,
      type: 'number',
    },
    {
      field: 'Total',
      headerName: 'Total',
      flex: 1,
      minWidth: 100,
      type: 'number',
      valueGetter: (value, row) => row.NewInStock + row.Used,
    },
    {
      field: 'FrontGrossSum',
      headerName: 'Front GP',
      headerAlign: 'right',
      flex: 1,
      minWidth: 150,
      type: 'number',
      valueFormatter: (value) =>
        Number(value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
    },
    {
      field: 'BackGrossSum',
      headerName: 'Back GP',
      headerAlign: 'right',
      flex: 1,
      minWidth: 150,
      type: 'number',
      valueFormatter: (value) =>
        Number(value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
    },
    {
      field: 'TotalGrossSum',
      headerName: 'Total GP',
      headerAlign: 'right',
      flex: 1,
      minWidth: 150,
      type: 'number',
      valueFormatter: (value) =>
        Number(value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
    },
  ];

  const rows = summary.map((item) => ({ id: item.SalesManager, ...item }));

  // Add daily summary row
  const dailySummary = {
    id: 'Daily Summary',
    SalesManager: 'Daily Summary',
    NewInStock: rows.reduce((acc, row) => acc + row.NewInStock, 0),
    PriorReserved: rows.reduce((acc, row) => acc + row.PriorReserved, 0),
    NewInTransit: rows.reduce((acc, row) => acc + row.NewInTransit, 0),
    Used: rows.reduce((acc, row) => acc + row.Used, 0),
    FrontGrossSum: rows.reduce((acc, row) => acc + row.FrontGrossSum, 0),
    BackGrossSum: rows.reduce((acc, row) => acc + row.BackGrossSum, 0),
    TotalGrossSum: rows.reduce((acc, row) => acc + row.TotalGrossSum, 0),
  };

  rows.push(dailySummary);

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
  };

  const handleDateRangeChange = (event) => {
    setDateRange(event.target.value);
  };

  const getColor = (buttonStatus) =>
    status === buttonStatus ? 'primary' : 'inherit';

  return (
    isAuthenticated && (
      <>
        <DataGridPremium
          density="compact"
          sx={{
            backgroundColor: 'background.paper',
            boxShadow: 1,
            border: 0,
            '& .MuiDataGrid-row': {
              border: 0,
              borderRadius: 1,
            },
            '& .MuiDataGrid-row:last-child': {
              fontWeight: 'bold',
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
          rows={rows}
          columns={columns}
          loading={loading}
          slots={{
            toolbar: () => (
              <>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  alignItems="center"
                  spacing={2}
                  padding={2}
                >
                  <Typography variant="h6" sx={{ fontSize: '1rem' }}>
                    Coversheet Summary
                  </Typography>
                  <div style={{ flexGrow: 1 }} />
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <FormControl sx={{ minWidth: 120 }} size="small">
                      <Select
                        value={dateRange}
                        onChange={handleDateRangeChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Date Range' }}
                      >
                        <MenuItem value="Today">Today</MenuItem>
                        <MenuItem value="Yesterday">Yesterday</MenuItem>
                        <MenuItem value="Week">Week</MenuItem>
                        <MenuItem value="Month">Month</MenuItem>
                      </Select>
                    </FormControl>
                    <Stack direction="row" spacing={1}>
                      <Button
                        onClick={() => handleStatusChange('Pending')}
                        variant={status === 'Pending' ? 'outlined' : 'text'}
                        color={getColor('Pending')}
                        sx={{
                          textTransform: 'none',
                        }}
                      >
                        Pending
                      </Button>
                      <Button
                        onClick={() => handleStatusChange('Booked')}
                        variant={status === 'Booked' ? 'outlined' : 'text'}
                        color={getColor('Booked')}
                        sx={{
                          textTransform: 'none',
                        }}
                      >
                        Booked
                      </Button>
                      <Button
                        onClick={() => handleStatusChange('All')}
                        variant={status === 'All' ? 'outlined' : 'text'}
                        color={getColor('All')}
                        sx={{
                          textTransform: 'none',
                        }}
                      >
                        All
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </>
            ),
          }}
          hideFooter
          autoHeight
        />
      </>
    )
  );
};

export default CoverSheetSummaryTable;
