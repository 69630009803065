import React, { useState } from 'react';
import axios from 'axios';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { API_BASE_URL } from 'config';

const LeaseTable = ({ data }) => {
  const [leaseData, setLeaseData] = useState(data);

  // Handle cell edits
  const handleCellEdit = (params) => {
    const updatedLeaseData = leaseData.map((lease) => {
      if (lease.id === params.id) {
        return {
          ...lease,
          [params.field]: params.value,
        };
      }
      return lease;
    });

    // Update the local state immediately
    setLeaseData(updatedLeaseData);

    // Send the updated data to your backend
    const updatedLease = updatedLeaseData.find(
      (lease) => lease.id === params.id,
    );
    axios
      .put(`${API_BASE_URL}/api/incentives/${params.id}`, updatedLease)
      .then((response) => {
        // Handle success
        console.log('Lease data updated:', response.data);
      })
      .catch((error) => {
        console.error('Error updating lease data:', error);
      });
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1, editable: true },
  ];

  // Extract unique rate terms
  const uniqueTerms = [
    ...new Set(data.flatMap((item) => item.rates.map((rate) => rate.term))),
  ];

  // Generate columns for each rate term
  uniqueTerms.forEach((term) => {
    columns.push({
      field: `term_${term}`, // Using the rate term as part of the field name
      headerName: `${term} mo`,
      flex: 1,
      editable: true,
      valueGetter: (params) => {
        // Find the rate with the matching term for the current row
        const rate = params.row.rates.find((r) => r.term === term);
        return rate ? rate.rate : ''; // Display the rate if found, or an empty string
      },
    });
  });

  return (
    <DataGridPro
      density="compact"
      rows={leaseData}
      columns={columns}
      getRowId={(row) => row._id}
      onEditCellChange={handleCellEdit}
      hideFooter
      sx={{ minHeight: '100px' }} // Adjust the height as needed
    />
  );
};

export default LeaseTable;
