import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { DataGridPro, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import axios from 'axios';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';

import { API_BASE_URL } from 'config';
import { useLenders } from 'hooks/useLenders';
import { set } from 'lodash';

const RetailRatesGrid = () => {
  const { user } = useAuth0();
  const { lenders } = useLenders(user?.dealerid);
  const [selectedLender, setSelectedLender] = useState(null);
  const [manufacturer, setManufacturer] = useState(null);
  const [models, setModels] = useState([]);
  const [rates, setRates] = useState([]);
  const [loading, setLoading] = useState(false);

  // Set the default selected lender when lenders are loaded
  useEffect(() => {
    if (lenders.length > 0) {
      setSelectedLender(
        lenders.find((l) => l.Active === true && l.ActiveForRetail)[0],
      );
    }
  }, [lenders]);

  // Function to handle lender change
  const handleLenderChange = (event) => {
    const lender = lenders.find((l) => l._id === event.target.value);
    setSelectedLender(lender);
  };

  // Fetch retail rates when selectedLender changes
  useEffect(() => {
    if (!selectedLender) return;

    console.log('Selected lender:', selectedLender);

    const fetchRates = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/incentives/retailRates?lenderId=${selectedLender._id}`,
        );
        console.log('Fetched rates:', response.data); // Log the fetched data
        setManufacturer(selectedLender.PrimaryManufacturer);
        setRates(response.data || []);
      } catch (error) {
        console.error('Error fetching retail rates:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRates();
  }, [selectedLender]); // Dependency on selectedLender

  // Get the models from the database based upon the lenders primary manufacturer
  useEffect(() => {
    if (!manufacturer) return;

    const fetchModels = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/models/search?make=${manufacturer}&years=${selectedLender.SupportedYears.join(
            ',',
          )}`,
        );
        console.log('Fetched models:', response.data); // Log the fetched data
        setModels(response.data || []);
      } catch (error) {
        console.error('Error fetching models:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchModels();
  }, [manufacturer]); // Dependency on manufacturer

  // Extract unique terms and tiers from rates
  const terms = [
    ...new Set(
      rates.flatMap((rate) =>
        rate.retail.flatMap((r) => r.rates.map((r) => r.term)),
      ),
    ),
  ];
  const tiers = [
    ...new Set(rates.flatMap((rate) => rate.retail.map((r) => r.name))),
  ];

  // Generate columns dynamically based on terms and tiers
  const columns = [
    { field: 'model_year', headerName: 'Year', width: 75 },
    { field: 'model_name', headerName: 'Model', width: 200 },
    { field: 'model_numbers', headerName: 'Model Number', width: 125 },
    ...tiers.flatMap((tier) =>
      terms.map((term) => ({
        field: `${tier}_${term}`,
        headerName: `${tier} ${term} Mo`,
        type: 'number',
        width: 80,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        editable: true,
        valueGetter: (params) => {
          const retailObj = params.row.retail.find((r) => r.name === tier);
          const termRate = retailObj
            ? retailObj.rates.find((r) => r.term === term)
            : null;
          return termRate ? termRate.rate : '-';
        },
      })),
    ),
  ];

  const handleCellEditCommit = async (params) => {
    try {
      setLoading(true);
      const { id, field, value } = params;

      const newRate = Number(value);

      // Extract the tier and term number from the field
      const [tier, termNumber] = field.split('_');
      const term = parseInt(termNumber);

      // Find the rates array for the specific model and lender
      const currentRates = rates.find((rate) => rate._id === id)?.retail || [];

      // Update the specific rate value in the array
      const updatedRatesArray = currentRates.map((retail) => {
        if (retail.name === tier) {
          return {
            ...retail,
            rates: retail.rates.map((r) => {
              if (r.term === term) {
                return { ...r, rate: newRate };
              }
              return r;
            }),
          };
        }
        return retail;
      });

      // Payload for the API
      const updatedData = {
        model_year: params.row.model_year,
        model_name: params.row.model_name,
        model_numbers: params.row.model_numbers,
        retail: updatedRatesArray,
        lenderId: selectedLender._id,
      };

      // API call to the upsert endpoint
      await axios.post(
        `${API_BASE_URL}/api/incentives/updateRetailRates`,
        updatedData,
      );

      // Update the rates state with the new value
      setRates((prevRates) =>
        prevRates.map((rate) => {
          if (rate._id === id) {
            return { ...rate, retail: updatedRatesArray };
          }
          return rate;
        }),
      );
    } catch (error) {
      console.error('Error updating rate:', error);
      // Handle error (show notification, etc.)
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGridPro
        rowHeight={25}
        rows={rates}
        columns={columns}
        loading={loading}
        getRowId={(row) => row._id} // Use the `_id` field as the row identifier
        onCellEditCommit={handleCellEditCommit} // Ensure correct event handler
        initialState={{
          pinnedColumns: {
            left: ['model_year', 'model_name', 'model_numbers'],
          },
        }}
        pagination
        autoPageSize
        slots={{
          toolbar: () => (
            <Box
              display="flex"
              alignItems="center"
              p={2}
              borderBottom={1}
              borderColor="divider"
            >
              <Box display="flex" alignItems="baseline">
                <Stack spacing={2} direction="row">
                  <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                    <InputLabel id="select-lender">Select Lender</InputLabel>
                    <Select
                      labelId="select-lender"
                      label="Select Lender"
                      value={selectedLender?._id || ''}
                      onChange={handleLenderChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Select Lender' }}
                    >
                      <MenuItem value={null}>
                        <em>All Lenders</em>
                      </MenuItem>
                      {lenders
                        .filter((l) => l.Active === true && l.ActiveForRetail)
                        .map((lender) => (
                          <MenuItem key={lender._id} value={lender._id}>
                            {lender.Name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <Box>
                <GridToolbarQuickFilter />
              </Box>
            </Box>
          ),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
};

export default RetailRatesGrid;
