import * as React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { formatCurrency } from 'utils/numberUtils';

const CashOption = ({ data }) => {
  const Cash = data;

  return (
    <List
      dense
      disablePadding
      sx={{
        width: '100%',
        minWidth: 175,
        whiteSpace: 'nowrap',
      }}
    >
      {Cash.map((field, idx) => (
        <ListItem
          key={idx}
          disablePadding
          disableGutters
          dense
          secondaryAction={
            <ListItemText
              primary={formatCurrency(field.value)}
              primaryTypographyProps={{
                variant: 'caption',
              }}
              style={{ textAlign: 'right' }}
            />
          }
        >
          <ListItemText
            primary={field.label + ':'}
            primaryTypographyProps={{
              noWrap: true,
              fontWeight: 'medium',
              variant: 'caption',
              paddingRight: 2,
            }}
            sx={{
              margin: 0.25,
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default CashOption;
