import React from 'react';
import { formatCurrency } from 'utils/numberUtils';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

const PaymentRender = ({ monthlyPayment, interestRate, rebates }) => (
  <div
    style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}
  >
    <Typography variant="caption">{formatCurrency(rebates)}</Typography>
    <Typography variant="button" fontWeight="bold">
      {formatCurrency(monthlyPayment)}
    </Typography>
    <Typography variant="caption">{interestRate}%</Typography>
  </div>
);

const FinanceTable = ({ data }) => {
  const Finance = data;

  const terms = Array.from(new Set(Finance.map((item) => item.term)));
  const downPayments = Array.from(
    new Set(Finance.map((item) => item.downPayment)),
  );

  const paymentsMap = {};

  Finance.forEach((item) => {
    if (!paymentsMap[item.term]) {
      paymentsMap[item.term] = {};
    }
    paymentsMap[item.term][item.downPayment] = {
      monthlyPayment: item.monthlyPayment,
      interestRate: item.interestRate, // Assuming interestRate exists on the item
      rebates: item.rebates,
    };
  });

  // Function to calculate opacity based on monthly payment
  const calculateOpacity = (monthlyPayment, paymentsArray) => {
    const sortedPayments = paymentsArray
      .map((item) => item.monthlyPayment)
      .sort((a, b) => a - b);
    const index = sortedPayments.indexOf(monthlyPayment);
    return (index + 1) / sortedPayments.length;
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Term / Cash Down</TableCell>
          {downPayments.map((dp) => (
            <TableCell key={dp} align="center">
              <Typography variant="button" fontWeight="bold">
                {formatCurrency(dp)}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {terms.map((term, idx) => (
          <TableRow key={idx}>
            <TableCell
              component="th"
              scope="row"
              sx={{
                padding: 0,
                paddingLeft: 2,
                verticalAlign: 'top',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="caption">Rebate</Typography>
                <Typography variant="button" fontWeight="bold">
                  {`${term} mo`}
                </Typography>
              </div>
            </TableCell>
            {downPayments.map((dp) => (
              <TableCell
                key={dp}
                align="center"
                sx={{
                  padding: 0,
                  verticalAlign: 'top',
                  backgroundColor: alpha(
                    blue[500],
                    calculateOpacity(
                      paymentsMap[term][dp].monthlyPayment,
                      Finance,
                    ),
                  ),
                }}
              >
                {paymentsMap[term] && paymentsMap[term][dp] ? (
                  <PaymentRender {...paymentsMap[term][dp]} />
                ) : (
                  '-'
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default FinanceTable;
