import { formatCurrency } from 'utils/numberUtils';

const columns = [
  { field: 'id', headerName: 'Team', flex: 2 },
  { field: 'totalNew', headerName: 'New', type: 'number', flex: 1 },
  { field: 'totalUsed', headerName: 'Used', type: 'number', flex: 1 },
  { field: 'totalDeals', headerName: 'Total', type: 'number', flex: 1 },
  {
    field: 'unitPace',
    headerName: 'Pace',
    type: 'number',
    valueFormatter: ({ value }) => value.toFixed(2), // Format to 2 decimal places
    flex: 1,
  },
  {
    field: 'totalFrontGross',
    headerName: 'Front',
    type: 'number',
    valueFormatter: (params) => formatCurrency(params.value),
    flex: 1,
  },
  {
    field: 'totalBackGross',
    headerName: 'Back',
    type: 'number',
    valueFormatter: (params) => formatCurrency(params.value),
    flex: 1,
  },
  {
    field: 'totalGrossProfit',
    headerName: 'Total',
    type: 'number',
    valueFormatter: (params) => formatCurrency(params.value),
    flex: 1,
  },
  {
    field: 'frontGrossPVR',
    headerName: 'Front',
    type: 'number',
    valueGetter: (params) => {
      return params.row.totalDeals === 0
        ? 0
        : params.row.totalFrontGross / params.row.totalDeals;
    },
    valueFormatter: (params) => {
      if (params.id === 'Totals' || params.id === 'Averages') {
        return ''; // or return a specific value/formatting for these rows
      }
      return formatCurrency(params.value);
    },
  },
  {
    field: 'backGrossPVR',
    headerName: 'Back',
    type: 'number',
    valueGetter: (params) =>
      params.row.totalDeals === 0
        ? 0
        : params.row.totalBackGross / params.row.totalDeals,
    valueFormatter: (params) => {
      if (params.id === 'Totals' || params.id === 'Averages') {
        return ''; // or return a specific value/formatting for these rows
      }
      return formatCurrency(params.value);
    },
  },
  {
    field: 'totalGrossPVR',
    headerName: 'Total',
    type: 'number',
    valueGetter: (params) =>
      params.row.totalDeals === 0
        ? 0
        : params.row.totalGrossProfit / params.row.totalDeals,
    valueFormatter: (params) => {
      if (params.id === 'Totals' || params.id === 'Averages') {
        return ''; // or return a specific value/formatting for these rows
      }
      return formatCurrency(params.value);
    },
  },
];

export default columns;
