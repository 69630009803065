import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import SummaryTable from './summary';
import CoverSheetLogTable from './details';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import CalendarDateRangePicker from 'components/DateRangePicker';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import { Divider } from '@mui/material';

const CoverSheetLog = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [tabValue, setTabValue] = useState('1');
  const [dateRange, setDateRange] = useState([
    dayjs().startOf('month'),
    dayjs(),
  ]);
  const [loading, setLoading] = useState(false);
  const [coversheetData, setCoversheetData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${API_BASE_URL}/api/coversheets`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            dealerID: user?.dealerid,
            startDate: dateRange[0].format('YYYY-MM-DD'),
            endDate: dateRange[1].format('YYYY-MM-DD'),
          },
        });
        const data = response.data;
        setCoversheetData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [dateRange, user?.dealerid, getAccessTokenSilently]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const tabs = [
    { value: '1', label: 'Summary', component: SummaryTable },
    { value: '2', label: 'Details', component: CoverSheetLogTable },
  ];

  return (
    isAuthenticated && (
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack direction="row" spacing={2} padding={1}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map(({ value, label }) => (
              <Tab key={value} label={label} value={value} />
            ))}
          </Tabs>
          <Box flexGrow={1} />
          <CalendarDateRangePicker
            dateRange={dateRange}
            onDateRangeChange={handleDateRangeChange}
          />
        </Stack>
        <Divider />
        {tabs.map(({ value, component: Component }) =>
          tabValue === value ? (
            <Component
              key={value}
              tableData={coversheetData}
              setTableData={setCoversheetData}
              dateRange={dateRange}
              isLoading={loading}
            />
          ) : null,
        )}
      </Paper>
    )
  );
};

export default CoverSheetLog;
