import axios from 'axios';
import { API_BASE_URL } from 'config';

export const getCalculationData = async (vehicles) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/calculate`, vehicles, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Axios automatically parses the JSON response, so no need to call .json()
    return response.data;
  } catch (err) {
    // Axios wraps the response error in the `response` property of the error object
    if (err.response) {
      // The server responded with a status code outside the 2xx range
      throw new Error(err.response.data.msg || 'Network response was not ok');
    } else {
      // Something else triggered the error (e.g., network error, timeout)
      throw err;
    }
  }
};
