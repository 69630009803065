import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Avatar,
  CircularProgress,
} from '@mui/material';

const UserProfile = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [profile, setProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      // In a real application, you would fetch the profile from your backend
      // Here we're just using the Auth0 user object and adding some mock fields
      setProfile({
        ...user,
        jobTitle: 'Sales Manager',
        department: 'Sales',
        hireDate: '2021-01-01',
      });
    } catch (error) {
      console.error('Error fetching profile:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently();
      // In a real application, you would send the updated profile to your backend
      console.log('Updating profile with token:', token);
      console.log('Updated profile:', profile);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem' }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Avatar
              src={profile?.picture}
              alt={profile?.name}
              sx={{ width: 100, height: 100 }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h4">{profile?.name}</Typography>
            <Typography variant="subtitle1">{profile?.email}</Typography>
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Job Title"
            name="jobTitle"
            value={profile?.jobTitle || ''}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Department"
            name="department"
            value={profile?.department || ''}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Hire Date"
            name="hireDate"
            type="date"
            value={profile?.hireDate || ''}
            onChange={handleInputChange}
            disabled={!isEditing}
            InputLabelProps={{ shrink: true }}
          />

          {isEditing ? (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: '1rem' }}
            >
              Save Changes
            </Button>
          ) : (
            <Button
              onClick={() => setIsEditing(true)}
              variant="contained"
              color="primary"
              style={{ marginTop: '1rem' }}
            >
              Edit Profile
            </Button>
          )}
        </form>
      </Paper>
    </Container>
  );
};

export default UserProfile;
