import React, { useState, useEffect } from 'react';
import Image01Icon from '@mui/icons-material/Image';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
  Tooltip,
  Stack,
  LinearProgress,
  Paper,
} from '@mui/material';
import {
  SpeedOutlined as SpeedOutlinedIcon,
  HourglassEmptyOutlined as HourglassEmptyOutlinedIcon,
  BuildOutlined as BuildOutlinedIcon,
  DirectionsCarOutlined as DirectionsCarOutlinedIcon,
  FilterTiltShiftOutlined as FilterTiltShiftOutlinedIcon,
  LocalGasStationOutlined as LocalGasStationOutlinedIcon,
  FormatPaintTwoTone as FormatPaintTwoToneIcon,
  ConfirmationNumberTwoTone as ConfirmationNumberTwoToneIcon,
} from '@mui/icons-material';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';

import { blue, orange, yellow } from '@mui/material/colors';

import FinanceTable from './components/financeTable';
import LeaseTable from './components/leaseTable';
import CashOption from './components/cashOption';

import { useHits } from 'react-instantsearch';
import { getCalculationData } from 'api/fetchCalculationAPI';

import VehicleListSearch from './desking-list-search';
import CustomPagination from './desking-list-pagination';

const avatarColors = {
  N: blue[500],
  U: orange[500],
  C: yellow[500],
};

const VehicleListTable = ({
  inventory,
  formData,
  sortOrder,
  setSortOrder,
  handleFormData,
}) => {
  const { hits } = useHits();
  const [modifiedVehicle, setModifiedVehicle] = useState([]);

  useEffect(() => {
    const fetchVehicleData = async () => {
      if (!hits) return;

      const modifiedHits = await Promise.all(
        hits.map(async (vehicle) => {
          const modifiedVehicle = { ...vehicle, loading: true };

          const matchingInventoryItem = inventory.find(
            (invItem) => invItem.VIN === vehicle.vin,
          );

          if (matchingInventoryItem) {
            modifiedVehicle.InDMS = true;
            modifiedVehicle.GVW = matchingInventoryItem.GVW;
            modifiedVehicle.Invoice = matchingInventoryItem.Invoice;
            modifiedVehicle.Balance = matchingInventoryItem.Balance;
          }

          const calculationRequest = {
            data: {
              SRP: modifiedVehicle.algoliaPrice,
              Invoice: modifiedVehicle.Invoice,
              Balance: modifiedVehicle.Balance,
              vehicleType: modifiedVehicle.type,
              bodyStyle: modifiedVehicle.body,
              modelYear: modifiedVehicle.year,
              numberOfTires: 5,
            },
            settings: formData,
          };

          try {
            const data = await getCalculationData(calculationRequest);

            modifiedVehicle.Cash = data.cash || [];
            modifiedVehicle.Lease = data.lease || [];
            modifiedVehicle.Finance = data.finance || [];
            modifiedVehicle.loading = false;
          } catch (error) {
            console.error('Error fetching calculation data', error);
            modifiedVehicle.loading = false;
          }

          return modifiedVehicle;
        }),
      );

      setModifiedVehicle(modifiedHits);
    };

    fetchVehicleData();
  }, [hits, inventory, formData]);

  const columns = [
    {
      field: 'vehicle',
      headerName: 'Vehicle',
      width: 300,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: 'flex',
              position: 'relative',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
              borderRadius: '4px',
            }}
          >
            <img
              srcSet={`${params.row.thumbnail}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${params.row.thumbnail}?w=248&fit=crop&auto=format`}
              alt={<Image01Icon />}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '4px',
                boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                padding: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                width: '100%',
                //Set the top border radius to match the image
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  sx={{
                    bgcolor: avatarColors[params.row.type[0]] || 'grey',
                  }}
                >
                  {params.row.type[0]}
                </Avatar>
                <Stack
                  direction="column"
                  spacing={0}
                  marginLeft={1}
                  sx={{ minWidth: 0, width: '100%' }}
                >
                  <Tooltip title={params.row.title_vrp} placement="top">
                    <Typography
                      variant="h6"
                      sx={{ color: 'white', lineHeight: 1 }}
                    >
                      {params.row.year} {params.row.make} {params.row.model}{' '}
                      {params.row.trim}
                    </Typography>
                  </Tooltip>
                  <Typography
                    noWrap
                    variant="caption"
                    sx={{ color: 'white', p: 0 }}
                  >
                    {params.row.vin}
                  </Typography>
                  <Typography noWrap variant="caption" sx={{ color: 'white' }}>
                    {params.row.stock}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'details',
      headerName: 'Key Features',
      width: 275,
      renderCell: (params) => {
        return (
          <List
            disablePadding
            dense
            sx={{
              width: '100%',
            }}
          >
            <ListItem disablePadding>
              <ListItemIcon>
                <Tooltip title="Color">
                  <FormatPaintTwoToneIcon
                    sx={{
                      color:
                        params.row.ext_color_generic !== 'White'
                          ? params.row.ext_color_generic
                          : '',
                    }}
                  />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={`${params.row.ext_color} / ${params.row.int_color}`}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <Tooltip title="Model #">
                  <ConfirmationNumberTwoToneIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={`${params.row.model_code}`} />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <Tooltip title="Engine">
                  <BuildOutlinedIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={`${params.row.engine_description}`} />
            </ListItem>
            {params.row.type !== 'New' && (
              <ListItem disablePadding>
                <ListItemIcon>
                  <Tooltip title="Odometer">
                    <SpeedOutlinedIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={`${params.row.miles.toLocaleString()} Miles`}
                />
              </ListItem>
            )}
            <ListItem disablePadding>
              <ListItemIcon>
                <Tooltip title="Drivetrain">
                  <DirectionsCarOutlinedIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={`${params.row.drivetrain}`} />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <Tooltip title="Transmission">
                  <FilterTiltShiftOutlinedIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={`${params.row.transmission_description}`}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <Tooltip title="Miles Per Gallon">
                  <LocalGasStationOutlinedIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={`${params.row.city_mpg}/${params.row.hw_mpg} est.mpg`}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <Tooltip title="Days in Stock">
                  <HourglassEmptyOutlinedIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={`${params.row.days_in_stock} ${
                  params.row.days_in_stock === 1 ? 'Day' : 'Days'
                } In Stock`}
              />
            </ListItem>
          </List>
        );
      },
    },
    {
      field: 'cashOption',
      headerName: 'Cash Option',
      width: 200,
      valueGetter: (params) => {
        const balanceItem = params.row.Cash
          ? params.row.Cash.find((item) => item.label === 'Balance')
          : null;
        return balanceItem ? balanceItem.value : null;
      },
      renderCell: (params) => {
        return params.row.Cash ? <CashOption data={params.row.Cash} /> : null;
      },
    },
    {
      field: 'financeOptions',
      headerName: 'Finance Options',
      flex: 1,
      minWidth: 500,
      valueGetter: (params) => {
        const getLowestPayment = (data) => {
          if (!data || data.length === 0) {
            return null;
          }
          let lowestPayment = Infinity;
          for (const item of data) {
            if (item.monthlyPayment < lowestPayment) {
              lowestPayment = item.monthlyPayment;
            }
          }
          return lowestPayment !== Infinity ? lowestPayment : null;
        };

        return params.row.Finance ? getLowestPayment(params.row.Finance) : null;
      },
      renderCell: (params) => {
        return params.row.Finance ? (
          <FinanceTable data={params.row.Finance} />
        ) : null;
      },
    },
    {
      field: 'leaseOptions',
      headerName: 'Lease Options',
      flex: 1,
      minWidth: 500,
      valueGetter: (params) => {
        const getLowestMonthlyPayment = (data) => {
          if (!data || data.length === 0) {
            return null;
          }
          let lowestPayment = Infinity;
          for (const item of data) {
            if (item.monthlyPayment < lowestPayment) {
              lowestPayment = item.monthlyPayment;
            }
          }
          return lowestPayment !== Infinity ? lowestPayment : null;
        };

        return params.row.Lease
          ? getLowestMonthlyPayment(params.row.Lease)
          : null;
      },
      renderCell: (params) => {
        return params.row.Lease ? <LeaseTable data={params.row.Lease} /> : null;
      },
    },
  ];

  return (
    <Paper
      sx={{
        height: '100%',
      }}
    >
      <DataGridPro
        sx={{
          border: 'none',
          [`& .${gridClasses.cell}`]: {
            display: 'flex', // Use flex layout for the cell content
            flexDirection: 'column', // Arrange cell content vertically
            justifyContent: 'flex-start', // Align content to the start (top) of the cell
            p: 1,
          },
          [`& .${gridClasses.columnHeader}`]: {
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: '600',
            },
          },
        }}
        slots={{
          loadingOverlay: LinearProgress,
          footer: CustomPagination,
          toolbar: VehicleListSearch,
        }}
        slotProps={{
          toolbar: {
            sortOrder,
            setSortOrder,
            formData,
            handleFormData,
          },
        }}
        rows={modifiedVehicle}
        getRowId={(row) => row.objectID}
        columns={columns}
        loading={modifiedVehicle.length === 0}
        getRowHeight={() => 'auto'}
        checkboxSelection={false}
        disableSelectionOnClick
      />
    </Paper>
  );
};

export default VehicleListTable;
