import React from 'react';
import {
  TextField,
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from '@mui/material';

const Trades = ({
  formData,
  handleChange,
  handleCurrencyBlur,
  handleCheckboxChange,
}) => {
  return (
    <>
      <Grid container spacing={2}>
        {/* Trade 1 */}
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Stock No."
            name="tradestock1"
            value={formData.tradestock1}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextField
            fullWidth
            label="Year"
            name="tradeyear1"
            value={formData.tradeyear1}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Make"
            name="trademake1"
            value={formData.trademake1}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Model"
            name="trademodel1"
            value={formData.trademodel1}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextField
            fullWidth
            label="Miles"
            name="trademiles1"
            value={formData.trademiles1}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextField
            fullWidth
            label="Allow"
            name="tradeallow1"
            value={formData.tradeallow1}
            onChange={handleChange}
            onBlur={handleCurrencyBlur}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextField
            fullWidth
            label="ACV"
            name="tradeacv1"
            value={formData.tradeacv1}
            onChange={handleChange}
            onBlur={handleCurrencyBlur}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextField
            fullWidth
            label="Payoff"
            name="tradepayoff1"
            value={formData.tradepayoff1}
            onChange={handleChange}
            onBlur={handleCurrencyBlur}
          />
        </Grid>

        {/* Trade 2 */}
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Stock No."
            name="tradestock2"
            value={formData.tradestock2}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextField
            fullWidth
            label="Year"
            name="tradeyear2"
            value={formData.tradeyear2}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Make"
            name="trademake2"
            value={formData.trademake2}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Model"
            name="trademodel2"
            value={formData.trademodel2}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextField
            fullWidth
            label="Miles"
            name="trademiles2"
            value={formData.trademiles2}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextField
            fullWidth
            label="Allow"
            name="tradeallow2"
            value={formData.tradeallow2}
            onChange={handleChange}
            onBlur={handleCurrencyBlur}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextField
            fullWidth
            label="ACV"
            name="tradeacv2"
            value={formData.tradeacv2}
            onChange={handleChange}
            onBlur={handleCurrencyBlur}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextField
            fullWidth
            label="Payoff"
            name="tradepayoff2"
            value={formData.tradepayoff2}
            onChange={handleChange}
            onBlur={handleCurrencyBlur}
          />
        </Grid>
      </Grid>

      <FormLabel component="legend" sx={{ mt: 2 }}>
        Trade Documents:
      </FormLabel>
      <FormControl
        component="fieldset"
        sx={{
          width: '100%',
          mt: 1,
        }}
        variant="standard"
      >
        <FormGroup row>
          <Grid container spacing={2} justifyContent="flex-start">
            <Grid item>
              <FormControlLabel
                label="CARFAX"
                control={
                  <Checkbox
                    checked={formData.tradecarfax}
                    onChange={handleCheckboxChange}
                    name="tradecarfax"
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="KSR"
                control={
                  <Checkbox
                    checked={formData.tradeksr}
                    onChange={handleCheckboxChange}
                    name="tradeksr"
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="NMVTIS"
                control={
                  <Checkbox
                    checked={formData.tradenmvtis}
                    onChange={handleCheckboxChange}
                    name="tradenmvtis"
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="BOOKSHEET"
                control={
                  <Checkbox
                    checked={formData.tradebooksheet}
                    onChange={handleCheckboxChange}
                    name="tradebooksheet"
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="PAYOFF"
                control={
                  <Checkbox
                    checked={formData.tradepayoff}
                    onChange={handleCheckboxChange}
                    name="tradepayoff"
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="APPRAISAL"
                control={
                  <Checkbox
                    checked={formData.tradeappraisal}
                    onChange={handleCheckboxChange}
                    name="tradeappraisal"
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="REGISTRATION"
                control={
                  <Checkbox
                    checked={formData.traderegistration}
                    onChange={handleCheckboxChange}
                    name="traderegistration"
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="RECALL"
                control={
                  <Checkbox
                    checked={formData.traderecall}
                    onChange={handleCheckboxChange}
                    name="traderecall"
                  />
                }
              />
            </Grid>
          </Grid>
        </FormGroup>
      </FormControl>
    </>
  );
};

export default Trades;
