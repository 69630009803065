import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Paper, Stack } from '@mui/material';

import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import dayjs from 'dayjs';
import CalendarDateRangePicker from 'components/DateRangePicker';

import { API_BASE_URL } from 'config';
import axios from 'axios'; // Assuming axios is used for HTTP requests

import AddGasLogForm from './addGasLogForm';

const GasLog = () => {
  const { isAuthenticated, user } = useAuth0();
  const [dateRange, setDateRange] = React.useState([
    dayjs().startOf('year'),
    dayjs(),
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_BASE_URL}/api/gaslog`, {
        params: {
          dealerID: user?.dealerid,
          startDate: dateRange[0].format('YYYY-MM-DD'),
          endDate: dateRange[1].format('YYYY-MM-DD'),
        },
      });
      const data = response.data;
      setRows(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [dateRange, user?.dealerid]);

  // Event Handlers
  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const handleDelete = (row) => {
    console.log('Delete row:', row);
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this record?',
    );
    if (confirmDelete) {
      // Delete the record
      axios
        .delete(`${API_BASE_URL}/api/gaslog/${row._id}`)
        .then(() => {
          fetchData();
        })
        .catch((error) => {
          console.error('Error deleting record:', error);
        });
    }
  };

  const columns = [
    { field: 'dealerID', headerName: 'Dealer ID', flex: 1 },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      groupable: true,
      valueFormatter: (value) => dayjs(value).format('MM/DD/YYYY'),
    },
    { field: 'receiptno', headerName: 'Receipt No.', flex: 1 },
    { field: 'cardid', headerName: 'Card ID', flex: 1, groupable: true },
    { field: 'stockno', headerName: 'Stock No.', flex: 1 },
    {
      field: 'gallons',
      headerName: 'Gallons',
      flex: 1,
      availableAggregationFunctions: ['sum', 'avg'],
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
      availableAggregationFunctions: ['sum', 'avg'],
    },
    {
      field: 'total',
      headerName: 'Total',
      flex: 1,
      availableAggregationFunctions: ['sum', 'avg'],
    },
    { field: 'updated_date', headerName: 'Updated Date', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <Box>
          <IconButton
            aria-label="delete"
            onClick={() => handleDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        dealerID: false,
        updated_date: false,
      },
    },
    aggregation: {
      model: {
        cardid: 'sum',
        gallons: 'sum',
        price: 'avg',
        total: 'sum',
      },
    },
    sorting: {
      sortModel: [
        {
          field: 'date',
          sort: 'desc',
        },
      ],
    },
  };

  // JSX rendering
  return (
    isAuthenticated && (
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center" p={2}>
          <Typography variant="h6">Gas Log</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <CalendarDateRangePicker
            dateRange={dateRange}
            onDateRangeChange={handleDateRangeChange}
          />
        </Stack>
        {/* Hide the table on small */}
        <Grid container spacing={2} p={2}>
          <Grid
            item
            xs={0}
            sm={10}
            sx={{
              display: { xs: 'none', sm: 'block' },
            }}
          >
            <DataGridPremium
              rows={rows}
              columns={columns}
              loading={isLoading}
              initialState={initialState}
              getRowId={(row) => row._id} // Define the unique 'id' property for each row
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              // checkboxSelection
              disableRowSelectionOnClick
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Box
              sx={{
                padding: 2,
                border: 1,
                borderColor: 'divider',
                borderRadius: 1,
              }}
            >
              <Typography variant="h6" gutterBottom mb={2}>
                Add Gas Log
              </Typography>
              <AddGasLogForm dealerID={user?.dealerid} onUpdate={fetchData} />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    )
  );
};

export default GasLog;
