import React from 'react';
import { TextField } from '@mui/material';

const Notes = ({ formData, handleChange }) => {
  return (
    <TextField
      fullWidth
      name="notes"
      value={formData.notes}
      onChange={handleChange}
      label="Notes"
      multiline
      rows={6}
    />
  );
};

export default Notes;
