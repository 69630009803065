import React from 'react';
import { Box, Button, Chip, Stack, Typography } from '@mui/material'; // Import missing Typography component

import {
  useCurrentRefinements,
  useClearRefinements,
} from 'react-instantsearch';

import CustomSort from './desking-list-sort'; // Import missing CustomSort component

const CustomCurrentRefinements = ({ sortOrder, setSortOrder }) => {
  const { items, refine } = useCurrentRefinements();

  const getKey = (indexName, attribute, value, index) =>
    `${indexName}-${attribute}-${value}-${index}`;

  const showClearAllButton = items.some((item) => item.refinements.length > 0);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingX: 2,
        paddingY: 1,
      }}
    >
      {items.length ? (
        <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1}>
          {showClearAllButton && <CustomClearRefinements />}
          {items.map((item) =>
            item.refinements.map((refinement, index) => (
              <Chip
                key={getKey(
                  item.indexName,
                  refinement.attribute,
                  refinement.value,
                  index,
                )}
                label={
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      '& span': {
                        fontWeight: 600,
                      },
                    }}
                  >
                    <>
                      <span>{refinement.value}</span>
                    </>
                  </Box>
                }
                onDelete={() => refine(refinement)}
                variant="outlined"
              />
            )),
          )}
        </Stack>
      ) : (
        <Typography color="text.secondary" variant="subtitle2">
          No filters applied
        </Typography>
      )}
      <CustomSort sortOrder={sortOrder} setSortOrder={setSortOrder} />
    </Box>
  );
};

const CustomClearRefinements = () => {
  const { refine } = useClearRefinements();

  return (
    <Button
      onClick={() => refine()}
      variant="contained"
      color="error"
      size="small"
      sx={{ borderRadius: 25, whiteSpace: 'nowrap' }}
    >
      Clear All
    </Button>
  );
};

export default CustomCurrentRefinements;
