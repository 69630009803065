import React, { useState, useCallback, useMemo } from 'react';
import { API_BASE_URL } from 'config';
import { useAuth0 } from '@auth0/auth0-react';
import { useLenders } from 'hooks/useLenders';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Switch,
  FormControlLabel,
  Box,
  IconButton,
  Snackbar,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const LendersDataGrid = () => {
  const { user } = useAuth0();
  const { lenders, loading, refetchLenders } = useLenders(user?.dealerid);

  const [snackbar, setSnackbar] = useState({ message: '', open: false });
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editingLender, setEditingLender] = useState(null);

  const handleEdit = useCallback((rowData) => {
    setOpenEditDialog(true);
    setEditingLender(rowData);
  }, []);

  const handleFieldChange = (field, value) => {
    setEditingLender((prevLender) => ({
      ...prevLender,
      [field]: value,
    }));
  };

  // Instead of declaring deleteLender separately, define it inside handleDelete
  const handleDelete = useCallback(
    async (id) => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/lenders/${id}`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
        });

        if (response.ok) {
          setSnackbar('Lender deleted');
          refetchLenders();
        } else {
          throw new Error('Error deleting lender');
        }
      } catch (error) {
        setSnackbar(`Error deleting lender: ${error.message}`);
      }
    },
    [refetchLenders],
  );

  const handleSaveEdit = async () => {
    if (!editingLender) return;
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/lenders/${editingLender._id}`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(editingLender),
        },
      );

      if (response.ok) {
        setSnackbar('Lender updated');
        refetchLenders();
        setOpenEditDialog(false);
      } else {
        throw new Error('Error updating lender');
      }
    } catch (error) {
      setSnackbar({
        message: `Error updating lender: ${error.message}`,
        open: true,
      });
    }
  };

  const columns = useMemo(
    () => [
      { field: '_id', headerName: 'ID', hide: true },
      { field: 'Name', headerName: 'Name', width: 200 },
      { field: 'ShortCode', headerName: 'Short Code', width: 150 },
      { field: 'Description', headerName: 'Description', width: 200 },
      { field: 'Active', headerName: 'Active', width: 120, type: 'boolean' },
      {
        field: 'ActiveForLease',
        headerName: 'Active for Lease',
        width: 160,
        type: 'boolean',
      },
      {
        field: 'ActiveForRetail',
        headerName: 'Active for Retail',
        width: 160,
        type: 'boolean',
      },
      createActionsColumn(handleEdit, handleDelete),
    ],
    [handleEdit, handleDelete],
  );

  return (
    <>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          getRowId={(row) => row._id}
          rows={lenders}
          columns={columns}
          loading={loading}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
      {/* Edit Dialog */}
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Lender</DialogTitle>
        <DialogContent
          sx={{
            paddingTop: '10px !important',
          }}
        >
          {editingLender && (
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Name"
                    value={editingLender.Name}
                    onChange={(e) => handleFieldChange('Name', e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Short Code"
                    value={editingLender.ShortCode}
                    onChange={(e) =>
                      handleFieldChange('ShortCode', e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    value={editingLender.Description}
                    onChange={(e) =>
                      handleFieldChange('Description', e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={editingLender.Active}
                        onChange={(e) =>
                          handleFieldChange('Active', e.target.checked)
                        }
                      />
                    }
                    label="Active"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={editingLender.ActiveForLease}
                        onChange={(e) =>
                          handleFieldChange('ActiveForLease', e.target.checked)
                        }
                      />
                    }
                    label="Active for Lease"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={editingLender.ActiveForRetail}
                        onChange={(e) =>
                          handleFieldChange('ActiveForRetail', e.target.checked)
                        }
                      />
                    }
                    label="Active for Retail"
                  />
                </Grid>
                {/* Grid items for additional fields */}
              </Grid>
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveEdit}>Save</Button>
          <Button onClick={() => setOpenEditDialog(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        message={snackbar.message}
        action={
          <Button
            color="secondary"
            size="small"
            onClick={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          >
            CLOSE
          </Button>
        }
      />
    </>
  );
};

const createActionsColumn = (handleEdit, handleDelete) => ({
  field: 'actions',
  headerName: 'Actions',
  width: 150,
  renderCell: (params) => (
    <Box display="flex" gap={2}>
      <IconButton color="primary" onClick={() => handleEdit(params.row)}>
        <EditIcon />
      </IconButton>
      <IconButton
        color="secondary"
        onClick={() => handleDelete(params.row._id)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  ),
});

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
};

export default LendersDataGrid;
