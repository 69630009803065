import React from 'react';
import { TextField, Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const PersonnelInformation = ({
  formData,
  handleChange,
  handleBlur,
  errorFields,
  SalesPeople = [],
  SalesManagers = [],
  TeamLeaders = [],
}) => {
  const handleAutocompleteChange = (name) => (event, newValue) => {
    handleChange({
      target: {
        name,
        value: newValue || '',
      },
    });
  };

  const handleAutocompleteBlur = (name) => () => {
    handleBlur(name);
  };

  const filterOptions = (options, { inputValue }) => {
    return options.filter(
      (option) =>
        option && option.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3}>
        <Autocomplete
          fullWidth
          options={SalesPeople}
          value={formData.sp1 || ''}
          onChange={handleAutocompleteChange('sp1')}
          onBlur={handleAutocompleteBlur('sp1')}
          filterOptions={filterOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Salesperson 1"
              error={errorFields.includes('sp1')}
              helperText={
                errorFields.includes('sp1') ? 'This field is required' : ''
              }
              required
            />
          )}
          autoComplete
          autoSelect
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Autocomplete
          fullWidth
          options={SalesPeople}
          value={formData.sp2 || ''}
          onChange={handleAutocompleteChange('sp2')}
          filterOptions={filterOptions}
          renderInput={(params) => (
            <TextField {...params} label="Salesperson 2" />
          )}
          autoComplete
          autoSelect
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Autocomplete
          fullWidth
          options={SalesManagers}
          value={formData.salesmanager || ''}
          onChange={handleAutocompleteChange('salesmanager')}
          onBlur={handleAutocompleteBlur('salesmanager')}
          filterOptions={filterOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sales Manager"
              error={errorFields.includes('salesmanager')}
              helperText={
                errorFields.includes('salesmanager')
                  ? 'This field is required'
                  : ''
              }
              required
            />
          )}
          autoComplete
          autoSelect
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Autocomplete
          fullWidth
          options={TeamLeaders}
          value={formData.teamleaders || ''}
          onChange={handleAutocompleteChange('teamleaders')}
          filterOptions={filterOptions}
          renderInput={(params) => <TextField {...params} label="Closer" />}
          autoComplete
          autoSelect
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          label="F&I Manager"
          fullWidth
          name="fimanager"
          id="fimanager"
          value={formData.fimanager || ''}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};

export default PersonnelInformation;
