import { useState, useEffect } from 'react';
import axios from 'axios'; // Import Axios
import { API_BASE_URL } from 'config';

export const useInventory = (cdkid) => {
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/inventory/${cdkid}`);
        // With Axios, the response data is accessed directly with .data
        setInventory(response.data);
      } catch (err) {
        // Axios error handling
        setError(
          err.message || 'An error occurred while fetching the inventory',
        );
      } finally {
        setLoading(false);
      }
    };

    fetchInventory();
  }, [cdkid]);

  return { inventory, loading, error };
};
