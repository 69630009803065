import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  DataGridPremium,
  GridToolbar,
  GridToolbarQuickFilter,
  useGridApiRef,
  useGridSelector,
  useGridApiContext,
  gridRowsLookupSelector,
  gridDetailPanelExpandedRowIdsSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';

import {
  Box,
  Divider,
  Paper,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Stack,
} from '@mui/material';

import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import dayjs from 'dayjs';
import CalendarDateRangePicker from 'components/DateRangePicker';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import { render } from '@testing-library/react';
import { type } from '@testing-library/user-event/dist/type';

const Desklog = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  const apiRef = useGridApiRef();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${API_BASE_URL}/api/customers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            dealerid: user?.dealerid,
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
          },
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user?.dealerid) {
      fetchData();
    }
  }, [dateRange, getAccessTokenSilently, user?.dealerid]);

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const handleLeadStatusChange = async (customerId, leadStatus) => {
    try {
      const token = await getAccessTokenSilently();
      await axios.put(
        `${API_BASE_URL}/api/customers/${customerId}/leadStatus`,
        { leadStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setData((prevData) =>
        prevData.map((customer) =>
          customer._id === customerId ? { ...customer, leadStatus } : customer,
        ),
      );
    } catch (error) {
      console.error('Error updating lead status:', error);
    }
  };

  const handleSoldChange = async (customerId, sold) => {
    try {
      const token = await getAccessTokenSilently();
      await axios.put(
        `${API_BASE_URL}/api/customers/${customerId}/sold`,
        { sold },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setData((prevData) =>
        prevData.map((customer) =>
          customer._id === customerId ? { ...customer, sold } : customer,
        ),
      );
    } catch (error) {
      console.error('Error updating sold status:', error);
    }
  };

  const handleAddComment = useCallback(
    async (customerId) => {
      if (customerId && newComment.trim()) {
        try {
          const token = await getAccessTokenSilently();
          await axios.post(
            `${API_BASE_URL}/api/customers/${customerId}/comments`,
            { user: user.name, text: newComment.trim() },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          setData((prevData) =>
            prevData.map((customer) =>
              customer._id === customerId
                ? {
                    ...customer,
                    comments: [
                      ...customer.comments,
                      {
                        user: user.name,
                        text: newComment.trim(),
                        timestamp: new Date(),
                      },
                    ],
                  }
                : customer,
            ),
          );
          setNewComment('');
          setSelectedCustomerId(null);
        } catch (error) {
          console.error('Error adding comment:', error);
        }
      }
    },
    [newComment, getAccessTokenSilently, user.name],
  );

  const getDetailPanelContent = useCallback(
    ({ row }) => (
      <Box
        sx={{
          p: 2,
          background: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
        }}
      >
        <Typography variant="h6">Comments</Typography>
        {row.comments
          .filter((comment) => comment.text && comment.text.trim() !== '')
          .map((comment, index) => (
            <Box key={index} mb={2}>
              <Typography variant="subtitle2" fontWeight="bold">
                {comment.user} ({new Date(comment.timestamp).toLocaleString()}):
              </Typography>
              <Typography variant="body2">{comment.text}</Typography>
            </Box>
          ))}

        {selectedCustomerId === row._id && (
          <>
            <TextField
              fullWidth
              multiline
              minRows={3}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Add a comment"
            />
            <Box mt={2} display="flex" justifyContent="flex-start">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAddComment(row._id)}
                disabled={!newComment.trim()}
              >
                Save
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setSelectedCustomerId(null);
                  setNewComment('');
                }}
                sx={{ ml: 2 }}
              >
                Cancel
              </Button>
            </Box>
          </>
        )}
        {selectedCustomerId !== row._id && (
          <Button
            variant="text"
            onClick={() => {
              setSelectedCustomerId(row._id);
              setNewComment('');
            }}
          >
            Add Comment
          </Button>
        )}
      </Box>
    ),
    [newComment, selectedCustomerId, handleAddComment],
  );

  function CustomDetailPanelHeader() {
    const apiRef = useGridApiContext();

    const expandedRowIds = useGridSelector(
      apiRef,
      gridDetailPanelExpandedRowIdsSelector,
    );
    const rowsWithDetailPanels = useGridSelector(
      apiRef,
      gridDetailPanelExpandedRowsContentCacheSelector,
    );

    const noDetailPanelsOpen = expandedRowIds.length === 0;

    const expandOrCollapseAll = () => {
      const dataRowIdToModelLookup = gridRowsLookupSelector(apiRef);
      const allRowIdsWithDetailPanels = Object.keys(rowsWithDetailPanels).map(
        (key) => apiRef.current.getRowId(dataRowIdToModelLookup[key]),
      );

      apiRef.current.setExpandedDetailPanels(
        noDetailPanelsOpen ? allRowIdsWithDetailPanels : [],
      );
    };

    const Icon = noDetailPanelsOpen ? UnfoldMoreIcon : UnfoldLessIcon;

    return (
      <IconButton
        size="small"
        tabIndex={-1}
        onClick={expandOrCollapseAll}
        aria-label={noDetailPanelsOpen ? 'Expand All' : 'Collapse All'}
      >
        <Icon fontSize="inherit" />
      </IconButton>
    );
  }

  const columns = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderHeader: () => <CustomDetailPanelHeader />,
    },
    {
      field: 'updateDate',
      headerName: 'Date',
      width: 150,
      type: 'date',
      valueGetter: (value) => {
        return new Date(value);
      },
    },
    {
      field: 'time',
      headerName: 'Time',
      width: 150,
      valueGetter: (value, row) => {
        const date = row.updateDate;
        // Separate the time from the date
        const time = date.split('T')[1];
        // Remove the milliseconds from the time
        return time.split('.')[0];
      },
    },
    { field: 'fname', headerName: 'First Name', width: 150 },
    { field: 'lname', headerName: 'Last Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'source', headerName: 'Source', width: 150 },
    {
      field: 'salesperson',
      headerName: 'Salesperson',
      width: 150,
      valueGetter: (value, row) =>
        `${row.salesperson?.firstName || ''} ${
          row.salesperson?.lastName || ''
        }`,
    },
    { field: 'vehicleType', headerName: 'Type', width: 75 },
    { field: 'vehicle', headerName: 'Vehicle', width: 150 },
    {
      field: 'leadStatus',
      headerName: 'Lead Status',
      minWidth: 325,
      flex: 1,
      renderCell: ({ row }) => (
        <Box>
          {['MI', 'NA', 'TD', 'WU', 'FI'].map((status) => (
            <FormControlLabel
              key={status}
              control={
                <Checkbox
                  checked={row.leadStatus.includes(status)}
                  onChange={(e) => {
                    const newStatus = e.target.checked
                      ? [...row.leadStatus, status]
                      : row.leadStatus.filter((s) => s !== status);
                    handleLeadStatusChange(row._id, newStatus);
                  }}
                />
              }
              label={status}
            />
          ))}
        </Box>
      ),
    },
    {
      field: 'sold',
      headerName: 'Sold',
      width: 100,
      renderCell: ({ row }) => (
        <Checkbox
          checked={row.sold}
          onChange={(e) => handleSoldChange(row._id, e.target.checked)}
        />
      ),
    },
  ];

  const initialState = {
    sorting: {
      sortModel: [{ field: 'updateDate', sort: 'asc' }],
    },
  };

  return (
    isAuthenticated && (
      <Paper
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 2,
          }}
        >
          <Typography variant="h6">Desklog</Typography>
          <CalendarDateRangePicker
            dateRange={dateRange}
            onDateRangeChange={handleDateRangeChange}
          />
        </Box>
        <DataGridPremium
          apiRef={apiRef}
          sx={{
            borderRadius: 0,
            border: 'none',
          }}
          density="compact"
          rows={data}
          columns={columns}
          loading={isLoading}
          initialState={initialState}
          getRowId={(row) => row._id}
          slots={{
            toolbar: () => {
              return (
                <>
                  <Stack direction="row" spacing={2} alignItems="center" p={2}>
                    <GridToolbar />
                    <Box sx={{ flexGrow: 1 }} />
                    <GridToolbarQuickFilter />
                  </Stack>
                  <Divider />
                </>
              );
            },
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 'auto'}
          disableRowSelectionOnClick
        />
      </Paper>
    )
  );
};

export default Desklog;
