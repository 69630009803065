const Sources = [
  'Walk-In',
  'Website',
  'Referral',
  'Social Media',
  'Other',
  'Internet',
  'Previous',
  'Service',
  'Event',
  'Phone',
  'BDC',
];

export default Sources;
