import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Button, Select, MenuItem, CircularProgress } from '@mui/material';

import AddTierDialog from './tierDialog';

import { useLenders } from 'hooks/useLenders';
import { useTiers } from 'hooks/useTiers'; // This is an assumed hook. Create it if it doesn't exist.

const Tiers = () => {
  const { user } = useAuth0();
  const {
    lenders,
    loading: lendersLoading,
    error: lendersError,
  } = useLenders(user?.dealerid); // <-- Ensure you have the useLenders hook and it returns the right values
  const [selectedLenderID, setSelectedLenderID] = useState(null);
  const {
    tiers,
    loading: tiersLoading,
    error: tiersError,
  } = useTiers(selectedLenderID);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleSaveTier = (tierData) => {
    // Your logic to save the tier goes here
    console.log(tierData);
    setDialogOpen(false);
  };

  const columns = [
    { field: 'name', headerName: 'Tier Name', flex: 1 },
    { field: 'minCreditScore', headerName: 'Min Credit Score', flex: 1 },
    { field: 'maxCreditScore', headerName: 'Max Credit Score', flex: 1 },
    {
      field: 'termsAvailable',
      headerName: 'Available Terms',
      width: 250,
      valueGetter: (params) => params.value.join(', '),
    },
  ];

  useEffect(() => {
    if (lenders && lenders.length > 0 && !selectedLenderID) {
      setSelectedLenderID(lenders[0]._id);
    }
  }, [lenders, selectedLenderID]);

  return (
    <div>
      {lendersLoading || tiersLoading ? (
        <CircularProgress /> // Display a spinner when data is loading
      ) : (
        <>
          <h2>
            View Tiers for{' '}
            {lenders.find((l) => l._id === selectedLenderID)?.Name || 'Lender'}
          </h2>

          {/* Error handling */}
          {lendersError && (
            <p>Error fetching lenders: {lendersError.message}</p>
          )}
          {tiersError && <p>Error fetching tiers: {tiersError.message}</p>}

          {/* Drop-down to select a Lender */}
          <Select
            value={selectedLenderID}
            onChange={(e) => setSelectedLenderID(e.target.value)}
            style={{ marginBottom: '20px' }}
          >
            {lenders.map((lender) => (
              <MenuItem key={lender._id} value={lender._id}>
                {lender.Name}
              </MenuItem>
            ))}
          </Select>

          {tiers.length === 0 && (
            <div>
              <p>No tiers available for the selected lender.</p>
              <Button
                onClick={() => setDialogOpen(true)}
                variant="contained"
                color="primary"
                style={{ marginBottom: '20px' }}
              >
                Add Tiers
              </Button>
            </div>
          )}

          <div style={{ height: 600, width: '100%' }}>
            <DataGridPro rows={tiers} columns={columns} />
          </div>

          {/* Dialog for adding tiers */}
          <AddTierDialog
            open={isDialogOpen}
            onClose={() => setDialogOpen(false)}
            onSave={handleSaveTier}
          />
        </>
      )}
    </div>
  );
};

export default Tiers;
