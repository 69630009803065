import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';

const TradeForm = ({ formData, onChange }) => {
  // Ensure there are always two trade inputs
  const trades = formData.trades?.length
    ? formData.trades
    : [
        { acv: '', allowance: '' },
        { acv: '', allowance: '' },
      ];

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    onChange(index, name, value);
  };

  return (
    <React.Fragment>
      {trades.map((trade, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={12}>
            <Typography variant="h6">Trade {index + 1}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="acv"
              label="ACV"
              type="number"
              value={trade.acv}
              onChange={(e) => handleChange(index, e)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="allowance"
              label="Allowance"
              type="number"
              value={trade.allowance}
              onChange={(e) => handleChange(index, e)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Typography variant="subtitle1" style={{ marginTop: '20px' }}>
          Trade Difference: ${formData.tradeDifference?.toFixed(2)}
        </Typography>
      </Grid>
    </React.Fragment>
  );
};

export default TradeForm;
