import React from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  Checkbox,
  TextField,
} from '@mui/material';

const ProductsAndStips = ({
  formData,
  handleChange,
  handleCheckboxChange,
  taxableItems,
  nontaxableItems,
  stipItems,
}) => {
  const renderChecklist = (items, title) => (
    <Card elevation={0} variant="outlined">
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'body1', fontWeight: 'bold' }}
      />
      <CardContent>
        <List dense disablePadding>
          {items.map((item, index) => (
            <ListItem key={index}>
              <Checkbox
                edge="start"
                name={item.name}
                checked={formData[item.name]}
                onChange={handleCheckboxChange}
              />
              <TextField
                variant="standard"
                fullWidth
                id={`${item.descName}`}
                name={item.descName}
                value={formData[item.descName]}
                onChange={handleChange}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        {renderChecklist(taxableItems, 'Taxable Products')}
      </Grid>
      <Grid item xs={12} md={4}>
        {renderChecklist(nontaxableItems, 'Non-Taxable Products')}
      </Grid>
      <Grid item xs={12} md={4}>
        {renderChecklist(stipItems, 'Stips')}
      </Grid>
    </Grid>
  );
};

export default ProductsAndStips;
