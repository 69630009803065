import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from 'config';

export const useTeams = (dealerID) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);

  const fecthTeams = async (dealerID) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/teams/${dealerID}`);
      setData(response.data);
    } catch (error) {
      setError(error);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (isMounted) {
        await fecthTeams(dealerID);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [dealerID]);

  const refetch = () => {
    fecthTeams(dealerID);
  };

  return { data, isLoading, error, refetch };
};
