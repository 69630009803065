import { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { API_BASE_URL } from 'config';

const useFetchSalesData = (dealerId, startDate, endDate) => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${API_BASE_URL}/api/deals/${dealerId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              startDate: startDate.format('YYYY-MM-DD'),
              endDate: endDate.format('YYYY-MM-DD'),
            },
          },
        );

        const filteredData = response.data.filter(
          (item) =>
            ['F', 'B'].includes(item.FiWipStatusCode) &&
            item.DealType !== 'Wholesale',
        );

        setData(filteredData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (dealerId && startDate && endDate) {
      fetchData();
    }
  }, [dealerId, startDate, endDate, getAccessTokenSilently]);

  return { data, loading, error };
};

export default useFetchSalesData;
