import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';

function AddTierDialog({ open, onClose, onSave }) {
  const [tierName, setTierName] = useState('');
  const [minCreditScore, setMinCreditScore] = useState('');
  const [maxCreditScore, setMaxCreditScore] = useState('');
  const [termsAvailable, setTermsAvailable] = useState('');

  const handleAdd = () => {
    const tierData = {
      name: tierName,
      minCreditScore: parseInt(minCreditScore, 10),
      maxCreditScore: parseInt(maxCreditScore, 10),
      termsAvailable: termsAvailable.split(',').map(Number), // Convert comma-separated values to an array of numbers
    };

    onSave(tierData);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add a New Tier</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Tier Name"
          value={tierName}
          onChange={(e) => setTierName(e.target.value)}
          style={{ marginBottom: 16 }}
        />
        <TextField
          fullWidth
          label="Minimum Credit Score"
          value={minCreditScore}
          onChange={(e) => setMinCreditScore(e.target.value)}
          style={{ marginBottom: 16 }}
        />
        <TextField
          fullWidth
          label="Maximum Credit Score"
          value={maxCreditScore}
          onChange={(e) => setMaxCreditScore(e.target.value)}
          style={{ marginBottom: 16 }}
        />
        <TextField
          fullWidth
          label="Terms Available (comma-separated)"
          value={termsAvailable}
          onChange={(e) => setTermsAvailable(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAdd} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddTierDialog;
