import React, { useEffect, useState, useMemo } from 'react';
import { useGridApiRef, DataGridPro } from '@mui/x-data-grid-pro';
import { startOfMonth, endOfMonth, getDate, getDaysInMonth } from 'date-fns';
import axios from 'axios';
import {
  Box,
  Stack,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import { yellow, grey } from '@mui/material/colors';
import columns from './columns';
import { formatCurrency } from 'utils/numberUtils';
import { API_BASE_URL } from 'config';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

const shortcutsItems = [
  {
    label: 'Month to Date',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today];
    },
  },
  {
    label: 'Previous Month',
    getValue: () => {
      const today = dayjs();
      const startOfPrevMonth = today.subtract(1, 'month').startOf('month');
      const endOfPrevMonth = today.subtract(1, 'month').endOf('month');
      return [startOfPrevMonth, endOfPrevMonth];
    },
  },

  {
    label: 'This Week',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week'), today.endOf('week')];
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: 'Current Month',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    },
  },
  { label: 'Reset', getValue: () => [dayjs().startOf('month'), dayjs()] },
];

function SalesManagerCommission() {
  const [data, setData] = useState([]);
  const [totalGrossProfit, setTotalGrossProfit] = useState(0);
  const [dealStatus, setDealStatus] = useState('Blended');
  const [dateRange, setDateRange] = React.useState([
    dayjs().startOf('month'),
    dayjs(),
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = React.useState(null);

  const apiRef = useGridApiRef();

  const handleCloseSnackbar = () => setSnackbar(null);

  const calculateGrossPace = (gross) => {
    const today = new Date();
    return (gross / getDate(today)) * getDaysInMonth(today);
  };

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      const { id, totalPhone } = newRow;
      const currentYearMonth = dateRange[0].format('YYYY-MM');
      const editedPhoneRank = {
        name: id, // The ID of the row
        yearMonth: currentYearMonth, // Use the dynamically set current year and month
        value: totalPhone, // The updated phone rank value
      };
      console.log('editedPhoneRank', editedPhoneRank);

      // Send a POST request to save phone edits
      const response = await axios.post(
        `${API_BASE_URL}/api/commissions/savePhoneEdits/606beccd6bab7103f8c3f84c`, // Need to un hardcode this....
        [editedPhoneRank],
      );

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }

      setSnackbar({
        children: 'Phone Rank successfully saved',
        severity: 'success',
      });

      return newRow;
    },
    [dateRange],
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/commissions/606beccd6bab7103f8c3f84c`, // Need to un hardcode this....
          {
            params: {
              startDate: dateRange[0].format('YYYY-MM-DD'),
              endDate: dateRange[1].format('YYYY-MM-DD'),
              dealStatus,
            },
          },
        );

        setTotalGrossProfit(response.data[0]?.dealerProfit ?? 0);
        setData(response.data ?? []);
      } catch (error) {
        console.error('Error fetching deals:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dateRange, dealStatus]);

  const checkIfSameMonth = (range) => {
    const today = new Date();
    return range[0] >= startOfMonth(today) && range[1] <= endOfMonth(today);
  };

  const columnGroupingModel = useMemo(
    () => [
      {
        groupId: 'Units',
        children: [
          { field: 'totalNew' },
          { field: 'totalUsed' },
          { field: 'totalUnits' },
          { field: 'unitPace' },
        ],
      },
      {
        groupId: 'Gross',
        children: [
          { field: 'totalFrontGross' },
          { field: 'totalBackGross' },
          { field: 'totalGrossProfit' },
          { field: 'totalGrossPVR' },
        ],
      },
      {
        groupId: 'Phone',
        children: [{ field: 'totalPhone' }],
      },
      {
        groupId: 'Rank Categories',
        children: [
          { field: 'totalUsed_rank' },
          { field: 'totalUnits_rank' },
          { field: 'totalBackGross_rank' },
          { field: 'totalGrossProfit_rank' },
          { field: 'totalGrossPVR_rank' },
          { field: 'realtimePhone_rank' },
        ],
      },
      {
        groupId: 'Total',
        children: [{ field: 'updated_rank' }],
      },
      {
        groupId: 'Estimated Commission',
        children: [
          { field: 'Percentage' },
          { field: 'Amount' },
          { field: 'Pacing' },
        ],
      },
    ],
    [],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper>
        <Stack direction="row" spacing={2} padding={1} alignItems="center">
          <DateRangePicker
            value={dateRange}
            onChange={(newValue) => setDateRange(newValue)}
            localeText={{
              start: 'Start',
              end: 'End',
            }}
            slotProps={{
              shortcuts: {
                items: shortcutsItems,
              },
              textField: { size: 'small' },
            }}
          />
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="deal-status-label">Deal Status</InputLabel>
              <Select
                labelId="deal-status-label"
                id="deal-status-select"
                value={dealStatus}
                label="Deal Status"
                onChange={(e) => setDealStatus(e.target.value)}
              >
                <MenuItem value="Blended">Blended</MenuItem>
                <MenuItem value="F">Finalized</MenuItem>
                <MenuItem value="B">Non-Finalized</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {/* Add an export button here */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => apiRef.current.exportDataAsCsv()}
          >
            Export
          </Button>
          <Box sx={{ flex: 1 }} />
          <Box>
            <Typography variant="h6">
              Total Gross Profit: {formatCurrency(totalGrossProfit)}
            </Typography>
            {checkIfSameMonth(dateRange) && (
              <Typography variant="subtitle1">
                Pacing: {formatCurrency(calculateGrossPace(totalGrossProfit))}
              </Typography>
            )}
          </Box>
        </Stack>
        <Box
          sx={{
            '& .phone-cell': {
              backgroundColor: yellow[100],
              color: 'black',
            },
            '& .rank-cell-light-bold': {
              backgroundColor: grey[100],
              fontWeight: 'bold',
              color: 'black',
            },
            '& .rank-cell-medium-bold': {
              backgroundColor: grey[200],
              fontWeight: 'bold',
              color: 'black',
            },
            '& .rank-cell-medium': {
              backgroundColor: grey[200],
              color: 'black',
            },
          }}
        >
          <DataGridPro
            apiRef={apiRef}
            sx={{
              borderRadius: 0,
              border: 0,
              borderTop: 1,
              borderColor: 'divider',
            }}
            autoHeight
            rows={data}
            columns={columns}
            getRowId={(row) => row.id}
            loading={isLoading}
            density="compact"
            columnVisibilityModel={{ Pacing: checkIfSameMonth(dateRange) }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'updated_rank', sort: 'asc' }],
              },
            }}
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={columnGroupingModel}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            hideFooter
          />
        </Box>
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Paper>
    </LocalizationProvider>
  );
}

export default SalesManagerCommission;
