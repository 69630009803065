import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  Button,
  Tabs,
  Tab,
  Box,
  Typography,
  AppBar,
  Toolbar,
} from '@mui/material';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';

import LendersDataGrid from './Lenders';
import Incentives from './Incentives';
import Employees from './Employees';
import Teams from './Teams';
import Residuals from './Residuals';
import Vehicles from './Vehicles';
import RetailRatesGrid from './RetailRatesGrid';

const mainTabs = [
  {
    label: 'General',
    icon: <SettingsOutlinedIcon />,
    subTabs: ['Dealership'],
  },
  {
    label: 'Desking',
    icon: <EditOutlinedIcon />,
    subTabs: [
      'Pricing Plans',
      'Lender Settings',
      'Taxes & Fees',
      'Finance Settings',
      'Lease Settings',
      'Trade Settings',
      'Default Weowes',
      'Default Contracts',
    ],
  },
  {
    label: 'Lenders',
    icon: <AccountBalanceOutlinedIcon />,
    subTabs: ['Lenders', 'Rates & Incentives', 'Residuals', 'Retail Rates'],
  },
  {
    label: 'People',
    icon: <PeopleOutlinedIcon />,
    subTabs: ['Employees', 'Teams'],
  },
  {
    label: 'Vehicles',
    icon: <DirectionsCarOutlinedIcon />,
    subTabs: ['Models'],
  },
];

const settingsData = {
  General: ['General Setting 1 Content', 'General Setting 2 Content'],
  Desking: ['Desking Setting A Content', 'Desking Setting B Content'],
  Lenders: [
    <LendersDataGrid />,
    <Incentives />,
    <Residuals />,
    <RetailRatesGrid />,
  ],
  People: [<Employees />, <Teams />],
  Vehicles: [<Vehicles />],
};

const SettingsDialog = ({ open, setOpen }) => {
  const [mainTab, setMainTab] = useState(0); // State for main tabs
  const [subTab, setSubTab] = useState(0); // State for subtabs

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Box
          sx={{
            display: 'flex',
            padding: 0,
            minHeight: '100%',
            height: '90vh',
            backgroundColor: 'divider',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '200px',
              width: '100%',
            }}
          >
            <DialogTitle id="form-dialog-title">Settings</DialogTitle>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={mainTab}
              onChange={(event, newMainTab) => {
                setMainTab(newMainTab);
                setSubTab(0); // Reset sub-tab to the first one when changing main tabs.
              }}
              aria-label="General Settings"
              sx={{
                p: 2,
                '& .MuiTabs-indicator': {
                  left: 0, // Move the indicator to the left
                },
                '& .MuiTab-root': {
                  justifyContent: 'flex-start', // Align text and icon to the left
                  textAlign: 'left',
                },
              }}
            >
              {mainTabs.map((tab, index) => (
                <Tab
                  icon={tab.icon}
                  iconPosition="start"
                  key={index}
                  label={tab.label}
                  sx={{
                    textTransform: 'none',
                    minHeight: 0,
                  }}
                />
              ))}
            </Tabs>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '200px',
              width: '100%',
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={subTab}
              onChange={(event, newSubTab) => setSubTab(newSubTab)}
              aria-label="Sub Settings"
              sx={{
                p: 2,
                '& .MuiTabs-indicator': {
                  left: 0, // Move the indicator to the left
                },
                '& .MuiTab-root': {
                  justifyContent: 'flex-start', // Align text and icon to the left
                  textAlign: 'left', // Align text to the left
                },
              }}
            >
              {mainTabs[mainTab].subTabs.map((subTabLabel, index) => (
                <Tab
                  key={index}
                  label={subTabLabel}
                  iconPosition="start"
                  onClick={() => setSubTab(index)}
                  sx={{
                    textTransform: 'none',
                    minHeight: 0,
                  }}
                />
              ))}
            </Tabs>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              backgroundColor: 'background.paper',
              overflow: 'auto',
            }}
          >
            <AppBar
              position="static"
              color="transparent"
              elevation={0}
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
              <Toolbar>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                >
                  {mainTabs[mainTab].subTabs[subTab]}
                </Typography>
                <Button autoFocus color="inherit" onClick={handleClose}>
                  Close
                </Button>
              </Toolbar>
            </AppBar>
            <Box
              component="main"
              sx={{ p: 2, overflowY: 'auto', height: '100%' }}
            >
              {settingsData[mainTabs[mainTab].label][subTab]}
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default SettingsDialog;
