import { useState, useEffect } from 'react';
import { API_BASE_URL } from 'config';

export const useModels = (make) => {
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        let url = `${API_BASE_URL}/api/models/list`;
        if (make) {
          url += `?manufacturer=${make}`;
        }
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setModels(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchModels();
  }, [make]);

  return { models, loading, error };
};
