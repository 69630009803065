import { useState, useEffect, useCallback } from 'react';
import { API_BASE_URL } from 'config';

export const useLenders = (dealerID) => {
  const [lenders, setLenders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchLenders = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/api/lenders/${dealerID}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setLenders(data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [dealerID]);

  useEffect(() => {
    fetchLenders();
  }, [fetchLenders]);

  return { lenders, loading, error, refetchLenders: fetchLenders };
};
