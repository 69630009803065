import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const AdjustmentForm = ({ onAdd }) => {
  const [adjustment, setAdjustment] = useState({
    description: '',
    amount: '',
    type: 'front',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdjustment((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd(adjustment);
    setAdjustment({ description: '', amount: '', type: 'front' });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            name="description"
            label="Description"
            value={adjustment.description}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            name="amount"
            label="Amount"
            type="number"
            value={adjustment.amount}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={adjustment.type}
              label="Type"
              onChange={handleChange}
            >
              <MenuItem value="front">Front</MenuItem>
              <MenuItem value="back">Back</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Add Adjustment
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const GrossCalculations = ({
  formData,
  adjustments,
  onUpdate,
  handleAddAdjustment,
  handleRemoveAdjustment,
}) => {
  const [CashPrice, setSellingPrice] = useState(formData.CashPrice || '');
  const [FIIncome, setFiIncome] = useState(formData.FIIncome || '');

  const calculateVehicleSalesProfit = () => {
    return parseFloat(CashPrice || 0) - parseFloat(formData.GLVehicleCost || 0);
  };

  const calculateFrontGross = () => {
    const frontAdjustments = adjustments
      .filter((adj) => adj.type === 'front')
      .reduce((sum, adj) => sum + parseFloat(adj.amount || 0), 0);

    const frontWeOwes =
      parseFloat(formData.FrontWeOwesGrossSales || 0) -
      parseFloat(formData.FrontWeOwesGrossCost || 0);

    return (
      parseFloat(CashPrice || 0) -
      parseFloat(formData.GLVehicleCost || 0) +
      frontWeOwes +
      frontAdjustments +
      parseFloat(formData.tradeDifference || 0)
    );
  };

  const calculateBackGross = () => {
    const backAdjustments = adjustments
      .filter((adj) => adj.type === 'back')
      .reduce((sum, adj) => sum + parseFloat(adj.amount || 0), 0);

    const contractIncome =
      parseFloat(formData.MBIIncome || 0) +
      parseFloat(formData.INS2Income || 0) +
      parseFloat(formData.INS3Income || 0);

    return (
      parseFloat(FIIncome || 0) +
      parseFloat(formData.BackWeOwes || 0) +
      contractIncome +
      backAdjustments
    );
  };

  const vehicleSalesProfit = calculateVehicleSalesProfit();
  const FrontGross = calculateFrontGross();
  const BackGross = calculateBackGross();
  const TotalGross = FrontGross + BackGross;

  useEffect(() => {
    onUpdate({
      ...formData,
      CashPrice,
      FIIncome,
      FrontGross,
      BackGross,
      TotalGross,
    });
  }, [
    CashPrice,
    FIIncome,
    FrontGross,
    BackGross,
    TotalGross,
    onUpdate,
    formData,
  ]);

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Adjustments
      </Typography>
      <AdjustmentForm onAdd={handleAddAdjustment} />
      <List>
        {adjustments.map((adjustment, index) => (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleRemoveAdjustment(index)}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText
              primary={adjustment.description}
              secondary={`${
                adjustment.type.charAt(0).toUpperCase() +
                adjustment.type.slice(1)
              } - $${adjustment.amount}`}
            />
          </ListItem>
        ))}
      </List>
      <Typography variant="h6" gutterBottom>
        Gross Calculations
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Front Gross</TableCell>
              <TableCell>Back Gross</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <TextField
                  fullWidth
                  label="Selling Price"
                  type="number"
                  value={CashPrice}
                  name="CashPrice"
                  onChange={(e) => setSellingPrice(e.target.value)}
                  required
                />
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  label="F&I Income (Reserve)"
                  type="number"
                  value={FIIncome}
                  name="FIIncome"
                  onChange={(e) => setFiIncome(e.target.value)}
                  required
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                + Vehicle Sales Profit: ${vehicleSalesProfit.toFixed(2)}
              </TableCell>
              <TableCell>
                + Back WeOwes: $
                {parseFloat(formData.BackWeOwes || 0).toFixed(2)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                + Front WeOwes: $
                {(
                  parseFloat(formData.FrontWeOwesGrossSales || 0) -
                  parseFloat(formData.FrontWeOwesGrossCost || 0)
                ).toFixed(2)}
              </TableCell>
              <TableCell>
                + Contract Income: $
                {(
                  parseFloat(formData.MBIIncome || 0) +
                  parseFloat(formData.INS2Income || 0) +
                  parseFloat(formData.INS3Income || 0)
                ).toFixed(2)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                + Front Adjustments: $
                {adjustments
                  .filter((adj) => adj.type === 'front')
                  .reduce((sum, adj) => sum + parseFloat(adj.amount || 0), 0)
                  .toFixed(2)}
              </TableCell>
              <TableCell>
                + Back Adjustments: $
                {adjustments
                  .filter((adj) => adj.type === 'back')
                  .reduce((sum, adj) => sum + parseFloat(adj.amount || 0), 0)
                  .toFixed(2)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                + Trade Profit: $
                {parseFloat(formData.tradeDifference || 0).toFixed(2)}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                }}
              >
                Front Gross: ${FrontGross.toFixed(2)}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                }}
              >
                Back Gross: ${BackGross.toFixed(2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Typography variant="h6" style={{ margin: '10px' }}>
          Total Gross: ${TotalGross.toFixed(2)}
        </Typography>
      </TableContainer>
    </>
  );
};

export default GrossCalculations;
