import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { API_BASE_URL } from 'config';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem } from '@mui/material';

const RetailTable = ({ incentiveId, lenderId }) => {
  const [retailData, setRetailData] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newRate, setNewRate] = useState({ tier: '', term: '', rate: '' });
  const [isLoading, setIsLoading] = useState(false);

  const fetchRetailData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/incentives/retailRates/${incentiveId}`);
      setRetailData(response.data);
      console.log('Fetched updated retail data:', response.data);
    } catch (error) {
      console.error('Error fetching retail rates:', error);
    } finally {
      setIsLoading(false);
    }
  }, [incentiveId]);

  useEffect(() => {
    fetchRetailData();

    axios.get(`${API_BASE_URL}/api/tiers/${lenderId}`)
      .then(response => setTiers(response.data))
      .catch(error => console.error('Error fetching tiers:', error));
  }, [fetchRetailData, lenderId]);

  const handleCellEdit = async (params) => {
    // First, find the retail item by tierId
    const retailItem = retailData.find((retail) => retail.tier._id === params.id);
    
    if (!retailItem) {
      console.error('Retail item not found for tierId:', params.id);
      return;
    }
    
    // Update the specific rate in the local state
    const updatedRates = retailItem.rates.map(rate => 
      rate.term === Number(params.field.split('_')[1]) ? { ...rate, rate: Number(params.value) } : rate
    );
    
    // Create an updated retail object
    const updatedRetailData = retailData.map(retail => 
      retail.tier._id === params.id ? { ...retail, rates: updatedRates } : retail
    );
    
    setRetailData(updatedRetailData); // Optimistic update for the UI
  
    try {
      // Send the updated rates to the backend
      await axios.put(`${API_BASE_URL}/api/incentives/updateRetailRates/${incentiveId}/${params.id}`, {
        rates: updatedRates
      });
      console.log('Retail data updated successfully');
    } catch (error) {
      console.error('Error updating retail data:', error);
      fetchRetailData(); // Revert to server data if update fails
    }
  };
  

  const handleAddRate = async () => {
    if (!newRate.tier || !newRate.term || !newRate.rate) {
      console.error('All fields must be filled');
      return;
    }

    await fetchRetailData(); // Fetch latest data before adding

    try {
      const response = await axios.post(`${API_BASE_URL}/api/incentives/addRetailRate/${incentiveId}`, {
        tierId: newRate.tier,
        rates: [{ term: Number(newRate.term), rate: Number(newRate.rate) }]
      });
      console.log('New rate added:', response.data);
      fetchRetailData(); // Refresh data after successful addition
    } catch (error) {
      console.error('Error adding new rate:', error);
    }

    setOpenDialog(false);
    setNewRate({ tier: '', term: '', rate: '' });
  };

  const handleDeleteRate = async (tierId, term) => {  
    console.log(retailData);
    const retailItem = retailData.find(item => item.tier._id === tierId);

    if (!retailItem) {
      console.error('Retail item not found after refresh');
      return;
    }

    console.log('Retail item:', retailItem);
    
    const rateToDelete = retailItem.rates.find(rate => rate.term === term);
    if (!rateToDelete) {
      console.error('Rate not found after refresh');
      return;
    }
  
    console.log('Deleting rate:', { tierId: tierId, term, rateId: rateToDelete._id });
  
    try {
      await axios.delete(`${API_BASE_URL}/api/incentives/deleteRetailRate/${incentiveId}/${retailItem.tier._id}/${rateToDelete._id}`);
      console.log('Rate deleted successfully');
  
      // Now update the state after successful deletion
      const updatedRetailData = retailData.map(retail => 
        retail.tier._id === tierId 
          ? { ...retail, rates: retail.rates.filter(rate => rate.term !== term) }
          : retail
      );
      setRetailData(updatedRetailData);
    } catch (error) {
      console.error('Error deleting rate:', error);
      fetchRetailData(); // Revert to server data if delete fails
    }
  };
  
  

  const columns = [
    { field: 'name', headerName: 'Tier', flex: 1, editable: false },
    ...Array.from(new Set(retailData.flatMap(item => (item.rates || []).map(rate => rate.term))))
      .sort((a, b) => a - b)
      .map(term => ({
        field: `term_${term}`,
        headerName: `${term} mo`,
        flex: 1,
        editable: true,
        valueGetter: (value, row) => {
          const rate = row.rates.find(r => r.term === term);
          return rate ? rate.rate : '';
        },
        renderCell: (params) => {
          return (
          <div>
            {params.value}
            {params.value && (
              <Button 
                size="small" 
                onClick={() => handleDeleteRate(params.row.tier._id, term)}
              >
                X
              </Button>
            )}
          </div>
        )}
      }))
  ];

  return (
    <>
      <Button onClick={() => setOpenDialog(true)} disabled={isLoading}>Add New Rate</Button>
      <DataGridPremium
        density="compact"
        rows={retailData}
        columns={columns}
        getRowId={(row) => row._id}
        onCellEditCommit={handleCellEdit}
        hideFooter
        autoHeight
        loading={isLoading}
      />
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add New Rate</DialogTitle>
        <DialogContent>
          <Select
            value={newRate.tier}
            onChange={(e) => setNewRate({ ...newRate, tier: e.target.value })}
            fullWidth
            margin="dense"
          >
            {tiers.map((tier) => (
              <MenuItem key={tier._id} value={tier._id}>{tier.name}</MenuItem>
            ))}
          </Select>
          <TextField
            label="Term (months)"
            type="number"
            value={newRate.term}
            onChange={(e) => setNewRate({ ...newRate, term: e.target.value })}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Rate"
            type="number"
            value={newRate.rate}
            onChange={(e) => setNewRate({ ...newRate, rate: e.target.value })}
            fullWidth
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleAddRate}>Add</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RetailTable;