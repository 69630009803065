import React from 'react';
import { Typography, Divider } from '@mui/material';

const RecapContent = ({ formData }) => {
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value || 0);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };

  const RecapSection = ({ title, fields }) => (
    <>
      <Typography
        variant="h6"
        style={{ marginTop: '20px', marginBottom: '10px' }}
      >
        {title}
      </Typography>
      <Divider />
      {fields.map(([label, value]) => (
        <Typography key={label} variant="body2">
          <strong>{label}:</strong>{' '}
          {label.toLowerCase().includes('date')
            ? formatDate(value)
            : typeof value === 'number'
            ? formatCurrency(value)
            : value || ''}
        </Typography>
      ))}
    </>
  );

  return (
    <div id="recap-content">
      <RecapSection
        title="Basic Deal Information"
        fields={[
          ['WAQ Number', formData.WAQNumber],
          ['Host Item ID', formData.HostItemID],
          ['Contract Date', formData.ContractDate],
          ['Accounting Date', formData.AccountingDate],
          ['Sale Type', formData.SaleType],
          ['Deal Type', formData.DealType],
          ['FI Deal Type', formData.FIDealType],
        ]}
      />
      <RecapSection
        title="Customer Information"
        fields={[
          ['Name', formData.Name],
          ['Secondary Name', formData.Name1],
          ['Customer Number', formData.CustNo],
        ]}
      />
      <RecapSection
        title="Vehicle Information"
        fields={[
          ['VIN', formData.VIN],
          ['Year', formData.Year],
          ['Make', formData.Make],
          ['Model', formData.Model],
          ['Stock Number', formData.StockNo],
          ['MSRP', formData.MSRP],
          ['GL Vehicle Cost', formData.GLVehicleCost],
        ]}
      />
      <RecapSection
        title="Financial Information"
        fields={[
          ['Cash Price', formData.CashPrice],
          ['FI Income', formData.FIIncome],
          ['Front Gross', formData.FrontGross],
          ['Back Gross', formData.BackGross],
          ['Total Gross', formData.TotalGross],
          ['Gross Profit', formData.GrossProfit],
        ]}
      />
      <RecapSection
        title="We Owes"
        fields={[
          [
            'Front We Owes Gross Sales',
            formatCurrency(formData.FrontWeOwesGrossSales),
          ],
          [
            'Front We Owes Gross Cost',
            formatCurrency(formData.FrontWeOwesGrossCost),
          ],
          ['Back We Owes', formatCurrency(formData.BackWeOwes)],
        ]}
      />

      <RecapSection
        title="Contracts"
        fields={[
          ['Warranty', formatCurrency(formData.MBIIncome)],
          ['GAP', formatCurrency(formData.Ins2Income)],
          ['Maintenance', formatCurrency(formData.Ins3Income)],
        ]}
      />

      <RecapSection
        title="Sales Information"
        fields={[
          ['SP1 ID', formData.CRMSP1Id],
          ['SP1 Name', formData.CRMSP1Name],
          ['SP1 Sale Credit', formData.CRMSaleCreditSP1],
          ['SP1 Spiff', formatCurrency(formData.CRMSpiffSP1)],
          ['SP1 Commission', formatCurrency(formData.CRMCommisionSP1)],
          ['SP2 ID', formData.CRMSP2Id],
          ['SP2 Name', formData.CRMSP2Name],
          ['SP2 Sale Credit', formData.CRMSaleCreditSP2],
          ['SP2 Spiff', formatCurrency(formData.CRMSpiffSP2)],
          ['SP2 Commission', formatCurrency(formData.CRMCommisionSP2)],
          ['SP3 ID', formData.CRMSP3Id],
          ['SP3 Name', formData.CRMSP3Name],
          ['SP3 Sale Credit', formData.CRMSaleCreditSP3],
          ['SP3 Spiff', formatCurrency(formData.CRMSpiffSP3)],
          ['SP3 Commission', formatCurrency(formData.CRMCommisionSP3)],
        ]}
      />

      <RecapSection
        title="Management Information"
        fields={[
          ['Sales Manager ID', formData.CRMSalesMgrId],
          ['Sales Manager Name', formData.CRMSalesMgrName],
          ['Sales Manager Spiff', formatCurrency(formData.CRMSpiffSalesMgr)],
          ['FI Manager ID', formData.CRMFIMgrId],
          ['FI Manager Name', formData.CRMFIMgrName],
          ['FI Manager Spiff', formatCurrency(formData.CRMSpiffFIMgr)],
          ['FI Manager Sale Credit', formData.CRMSaleCreditFIMgr],
          ['Closing Manager ID', formData.CRMClosingMgrId],
          ['Closing Manager Name', formData.CRMClosingMgrName],
          [
            'Closing Manager Spiff',
            formatCurrency(formData.CRMSpiffClosingMgr),
          ],
          ['Closing Manager Sale Credit', formData.CRMSaleCreditClosingMgr],
        ]}
      />

      <RecapSection
        title="Additional Information"
        fields={[
          ['FI WIP Status Code', formData.FiWipStatusCode],
          ['Finance Source', formData.FinanceSource],
          ['Commission Pack', formatCurrency(formData.CommissionPack)],
        ]}
      />
    </div>
  );
};

export default RecapContent;
