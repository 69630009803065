import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

const CalendarDateRangePicker = ({ dateRange, onDateRangeChange }) => {
  const [date, setDate] = React.useState(dateRange);

  const shortcutsItems = [
    {
      label: 'Today',
      getValue: () => {
        const today = dayjs();
        return [today, today];
      },
    },
    {
      label: 'Yesterday',
      getValue: () => {
        const yesterday = dayjs().subtract(1, 'day');
        return [yesterday, yesterday];
      },
    },
    {
      label: 'Month to Date',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month'), today];
      },
    },
    {
      label: 'Previous Month',
      getValue: () => {
        const today = dayjs();
        const startOfPrevMonth = today.subtract(1, 'month').startOf('month');
        const endOfPrevMonth = today.subtract(1, 'month').endOf('month');
        return [startOfPrevMonth, endOfPrevMonth];
      },
    },

    {
      label: 'This Week',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('week'), today.endOf('week')];
      },
    },
    {
      label: 'Last Week',
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, 'day');
        return [prevWeek.startOf('week'), prevWeek.endOf('week')];
      },
    },
    {
      label: 'Last 7 Days',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, 'day'), today];
      },
    },
    {
      label: 'Current Month',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month'), today.endOf('month')];
      },
    },
    { label: 'Reset', getValue: () => [dayjs().startOf('month'), dayjs()] },
  ];

  useEffect(() => {
    onDateRangeChange(date);
  }, [date, onDateRangeChange]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        value={dateRange}
        onChange={(newValue) => setDate(newValue)}
        localeText={{
          start: 'Start',
          end: 'End',
        }}
        slotProps={{
          shortcuts: {
            items: shortcutsItems,
          },
          textField: { size: 'small' },
        }}
      />
    </LocalizationProvider>
  );
};

export default CalendarDateRangePicker;
