import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from '@mui/material';
import {
  DataGridPremium,
  GridToolbar,
  GRID_AGGREGATION_FUNCTIONS,
} from '@mui/x-data-grid-premium';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const monthCols = [
  {
    field: 'Comment',
    headerName: 'Vendor/Comment',
    width: 500,
    groupable: true,
  },
  { field: 'Period', headerName: 'Period', width: 150, groupable: false },
  { field: 'Date', headerName: 'Date', width: 75, groupable: false },
  { field: 'Refer', headerName: 'Refer', width: 200, groupable: false },
  {
    field: 'Invoice No.',
    headerName: 'Invoice No.',
    width: 200,
    groupable: false,
  },
  { field: 'VendorID', headerName: 'VendorID', width: 100, groupable: false },
  { field: 'SO', headerName: 'SO', width: 75, groupable: false },
  {
    field: 'CurrentAmount',
    headerName: 'Current Amount',
    width: 150,
    type: 'number',
    groupable: false,
    valueFormatter: (value) => {
      if (!value) {
        return value;
      }
      return currencyFormatter.format(value);
    },
  },
  {
    field: 'PriorAmount',
    headerName: 'Prior Amount',
    width: 150,
    type: 'number',
    groupable: true,
    valueFormatter: (value) => {
      if (!value) {
        return value;
      }
      return currencyFormatter.format(value);
    },
  },
];

const vendorAggregation = {
  // Get the description cell value for each row, creating an object with month fields and their values
  getCellValue: ({ row }) => {
    return row.VendorID;
  },

  // Aggregate all cell values across rows, summing up the values for each month
  apply: ({ values }) => {
    //If all values are the same, return the value
    if (values.every((value) => value === values[0])) {
      return values[0];
    }
    //Otherwise, just return
    return;
  },

  // Label for the aggregation, used as the column header or identifier
  label: 'vendorid',
};

const journalAggregation = {
  // Get the description cell value for each row, creating an object with month fields and their values
  getCellValue: ({ row }) => {
    return row.SO;
  },

  // Aggregate all cell values across rows, summing up the values for each month
  apply: ({ values }) => {
    //If all values are the same, return the value
    if (values.every((value) => value === values[0])) {
      return values[0];
    }
    //Otherwise, just return
    return;
  },

  // Label for the aggregation, used as the column header or identifier
  label: 'journal',
};

const GridComponent = ({ rows }) => {
  const initialState = {
    rowGrouping: {
      model: ['Comment', 'Period'],
    },
    columns: {
      columnVisibilityModel: {
        Comment: false,
        Period: false,
        Refer: false,
      },
    },
    aggregation: {
      model: {
        VendorID: 'vendorid',
        CurrentAmount: 'sum',
        PriorAmount: 'sum',
        SO: 'journal',
      },
    },
    sorting: {
      sortModel: [
        { field: 'SO', sort: 'asc' }, // Sort by 'SO' in ascending order
        { field: '__row_group_by_columns_group__', sort: 'asc' }, // Then sort by 'VendorID' in ascending order
      ],
    },
  };

  return (
    <DataGridPremium
      sx={{ backgroundColor: 'background.paper' }}
      initialState={initialState}
      groupingColDef={{
        leafField: ['Refer'],
      }}
      getRowId={(row) => row.id}
      rows={rows}
      columns={monthCols}
      aggregationFunctions={{
        ...GRID_AGGREGATION_FUNCTIONS,
        vendorid: vendorAggregation,
        journal: journalAggregation,
      }}
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      disableRowSelectionOnClick
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      hideFooter
    />
  );
};

// Function to process rows
const processRows = (rows) => {
  console.log('Processing rows: ', rows);
  rows.forEach((row) => {
    row.Comment = row.VendorComment;

    if (row.SO === '60') {
      const [vendorID, ...rest] = row.VendorComment.split(' ');
      row.VendorID = vendorID;
      row.Comment = rest.join(' ');
    } else {
      row.VendorID = '';
    }

    const parts = row.Comment.split(' '); // Split the comment into parts by whitespace
    //Find the index of the part the has the letters 'INV' or 'INVOICE' (case-insensitive)
    let invoiceNumberIndex = parts.findIndex((part) =>
      part.match(/INV|INVOICE/i),
    );
    // If the invoice number is found, set the 'Invoice No.' field to all parts after the invoice number index
    // Otherwise, set the 'Invoice No.' field to an empty string
    if (invoiceNumberIndex !== -1 && invoiceNumberIndex + 1 === parts.length) {
      row['Invoice No.'] = parts[invoiceNumberIndex];
      row.Comment = parts.slice(0, invoiceNumberIndex).join(' ');
    } else if (
      invoiceNumberIndex !== -1 &&
      invoiceNumberIndex + 1 < parts.length
    ) {
      row['Invoice No.'] = parts.slice(invoiceNumberIndex + 1).join(' ');
      row.Comment = parts.slice(0, invoiceNumberIndex).join(' ');
    } else {
      row['Invoice No.'] = '';
    }
  });
};

const DetailDialog = (params) => {
  console.log('DetailDialog params: ', params);
  // Assuming params.row.current and params.row.prior are already populated with the respective data
  const currentRows = params.rowData
    ? params.rowData.current.map((row) => ({
        ...row,
        CurrentDate: row.Date,
        CurrentAmount: parseFloat(row.Amount.replace(/,/g, '')),
        PriorDate: '',
        PriorAmount: 0,
        Period: 'Current',
        VendorComment: row.VendorComment.replace('direct expense ', ''),
      }))
    : [];

  const priorRows = params.rowData
    ? params.rowData.prior.map((row) => ({
        ...row,
        CurrentDate: '',
        CurrentAmount: 0,
        PriorDate: row.Date,
        PriorAmount: parseFloat(row.Amount.replace(/,/g, '')),
        Period: 'Prior',
        VendorComment: row.VendorComment.replace('direct expense ', ''),
      }))
    : [];

  // Combine the current and prior rows
  const combinedRows = [...currentRows, ...priorRows];

  // Process both current and prior rows
  processRows(combinedRows);

  return (
    <Dialog open onClose={params.onClose} fullWidth maxWidth="xl">
      <DialogTitle>
        {params.rowData.account} {params.rowData.description}
      </DialogTitle>
      <DialogContent
        sx={{
          height: '80vh',
        }}
      >
        <GridComponent rows={combinedRows} />
      </DialogContent>
      <DialogActions>
        <Button onClick={params.onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailDialog;
