import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { API_BASE_URL } from 'config';
import { useAuth0 } from '@auth0/auth0-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Grid,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useModels } from 'hooks/useModels';

const Models = () => {
  const { user } = useAuth0();
  const {
    models,
    loading: modelsLoading,
    error: modelsError,
  } = useModels(user?.primaryMake);

  const [rows, setRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const initialFormData = useMemo(
    () => ({
      year: '',
      make: user?.primaryMake,
      model: '',
      modelNo: '',
      bodyStyle: '',
      transmission: '',
    }),
    [user?.primaryMake],
  );

  const [formData, setFormData] = useState({});

  const [operation, setOperation] = useState('create');
  const [selectedModelId, setSelectedModelId] = useState(null);

  useEffect(() => {
    if (models && models.length) {
      setRows(models);
    }
  }, [models]);

  const resetFormData = useCallback(() => {
    setFormData(initialFormData);
  }, [initialFormData]);

  useEffect(() => {
    if (operation === 'update') {
      const modelToUpdate = rows.find((row) => row._id === selectedModelId);
      if (modelToUpdate) {
        setFormData(modelToUpdate);
      }
    } else {
      setSelectedModelId(null);
      resetFormData();
    }
  }, [operation, selectedModelId, rows, resetFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      let url = `${API_BASE_URL}/api/models/create`;
      let method = 'POST';
      let data = { ...formData };

      if (operation === 'update') {
        url = `${API_BASE_URL}/api/models/${formData._id}`;
        method = 'PUT';
      } else {
        delete data._id; // Remove _id field when creating
      }

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const newCar = await response.json();
        if (operation === 'create') {
          setRows([...rows, newCar]);
        } else {
          setRows(rows.map((row) => (row._id === formData._id ? newCar : row)));
        }
        resetFormData(); // Reset form data using the function
        setOpenDialog(false);
        setOperation('create');
      } else {
        // Handle the error returned from the server
        const errorData = await response.json();
        console.error('Error:', errorData.error);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleUpdate = (id) => {
    setSelectedModelId(id);
    setOperation('update');
    setOpenDialog(true);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/models/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        setRows(rows.filter((row) => row._id !== id));
      } else {
        // Handle server errors
        console.error('Error deleting model');
      }
    } catch (error) {
      // Handle network errors
      console.error('Network error:', error.message);
    }
  };

  const columns = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() => handleUpdate(params.row._id)}
            color="primary"
          >
            <EditOutlinedIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(params.row._id)}
            color="secondary"
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </div>
      ),
    },
    { field: 'year', headerName: 'Year', width: 120 },
    { field: 'make', headerName: 'Make', width: 120 },
    { field: 'model', headerName: 'Model', width: 120 },
    {
      field: 'modelNo',
      headerName: 'Model No.',
      width: 150,
    },
    { field: 'bodyStyle', headerName: 'Description', flex: 1 },
    { field: 'transmission', headerName: 'Transmission', width: 120 },
  ];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h2>Manage Models for {user.primaryMake}</h2>
        <Button
          onClick={() => {
            setOperation('create');
            setOpenDialog(true);
          }}
          variant="contained"
          color="primary"
        >
          Add Model
        </Button>
      </div>

      {/* Display loading spinner during data loading */}
      {modelsLoading ? (
        <CircularProgress />
      ) : (
        <>
          {/* Display error if fetching models failed */}
          {modelsError && <p>Error fetching models: {modelsError.message}</p>}

          {/* Display the DataGridPro component */}
          <div style={{ height: 600, width: '100%' }}>
            <DataGridPro
              getRowId={(row) => row._id}
              rows={rows}
              columns={columns} // Make sure 'columns' is defined
              sx={{
                backgroundColor: 'background.paper',
              }}
            />
          </div>
        </>
      )}

      {/* Dialog for adding models */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          {operation === 'create' ? 'Add Model' : 'Update Model'}
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            spacing={3}
            sx={{
              paddingTop: '10px !important',
            }}
          >
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <TextField
                  label="Year"
                  fullWidth
                  name="year"
                  value={formData.year || ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="Make"
                  fullWidth
                  name="make"
                  value={formData.make || ''}
                  readOnly
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <TextField
                  label="Model No"
                  fullWidth
                  name="modelNo"
                  value={formData.modelNo || ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="Model"
                  fullWidth
                  name="model"
                  value={formData.model || ''}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={3}>
              <Grid item xs={8}>
                <TextField
                  label="Trim"
                  fullWidth
                  name="bodyStyle"
                  value={formData.bodyStyle || ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Transmission"
                  fullWidth
                  name="transmission"
                  value={formData.transmission || ''}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {operation === 'create' ? 'Add' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Models;
