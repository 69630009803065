import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts';
import dayjs from 'dayjs';
import { Typography, CircularProgress, Stack, Paper } from '@mui/material';

const TOTAL_GROSS_GOAL = 1350000;

const calculateDailyGoal = (monthlyGoal) => {
  const totalDaysInMonth = dayjs().daysInMonth();
  return monthlyGoal / totalDaysInMonth;
};

const MonthlyCumulativeGrossChart = ({ data, loading }) => {
  const { isAuthenticated } = useAuth0();

  const cumulativeData = [];
  let cumulativeFrontGross = 0;
  let cumulativeBackGross = 0;
  let cumulativeTotalGrossGoal = 0;

  const dailyTotalGrossGoal = calculateDailyGoal(TOTAL_GROSS_GOAL);

  for (let day = 1; day <= dayjs().date(); day++) {
    const currentDate = dayjs()
      .startOf('month')
      .add(day - 1, 'day')
      .format('YYYY-MM-DD');
    const dailySales = data.filter(
      (sale) => dayjs(sale.ContractDate).format('YYYY-MM-DD') === currentDate,
    );

    const frontGross = dailySales.reduce(
      (acc, sale) => acc + parseFloat(sale.FrontGross || 0),
      0,
    );
    const backGross = dailySales.reduce(
      (acc, sale) => acc + parseFloat(sale.BackGross || 0),
      0,
    );

    cumulativeFrontGross += frontGross;
    cumulativeBackGross += backGross;
    cumulativeTotalGrossGoal += dailyTotalGrossGoal;

    cumulativeData.push({
      date: currentDate,
      cumulativeFrontGross,
      cumulativeBackGross,
      cumulativeTotalGrossGoal,
    });
  }
  return (
    isAuthenticated && (
      <Paper sx={{ p: 2, height: '100%' }} elevation={0} variant="outlined">
        <Stack spacing={2}>
          <Typography variant="h6" sx={{ fontSize: '1rem' }}>
            Monthly Cumulative Gross
          </Typography>
          {loading ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ height: 400 }}
            >
              <CircularProgress />
            </Stack>
          ) : (
            <ResponsiveContainer width="100%" height={400}>
              <ComposedChart data={cumulativeData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="cumulativeFrontGross"
                  stackId="1"
                  stroke="#d62728"
                  fill="#d62728"
                  name="Front Gross"
                />
                <Area
                  type="monotone"
                  dataKey="cumulativeBackGross"
                  stackId="1"
                  stroke="#8a2be2"
                  fill="#8a2be2"
                  name="Back Gross"
                />
                <Line
                  type="monotone"
                  dataKey="cumulativeTotalGrossGoal"
                  stroke="#ff0000"
                  name={'Total Gross Goal (' + TOTAL_GROSS_GOAL + ')'}
                  dot={false}
                />
              </ComposedChart>
            </ResponsiveContainer>
          )}
        </Stack>
      </Paper>
    )
  );
};

export default MonthlyCumulativeGrossChart;
