import React from 'react';
import { Box, Grid, MenuItem, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { pricingPlans, applyDiscountFrom } from '../constants';

export default function PricingPlan({ deskingData, handleFieldChange }) {
  const { pricingplan, applydiscountto, discount } = deskingData;

  const handlePricingPlanChange = (event, newValue) => {
    handleFieldChange('pricingplan', newValue ? newValue.title : '');
  };

  const handleTextFieldChange = (field) => (event) => {
    handleFieldChange(field, event.target.value);
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            id="pricingplan"
            name="pricingplan"
            options={pricingPlans}
            getOptionLabel={(option) => option.title}
            value={pricingPlans.find((plan) => plan.title === pricingplan)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Pricing Plan"
                variant="outlined"
                sx={{ mb: 1 }}
              />
            )}
            onChange={handlePricingPlanChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="applydiscountto"
            name="applydiscountto"
            select
            label="Apply Discount To"
            value={applydiscountto}
            variant="outlined"
            fullWidth
            sx={{ mb: 1 }}
            onChange={handleTextFieldChange('applydiscountto')}
          >
            {applyDiscountFrom.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="discount"
            name="discount"
            value={discount}
            label="Discount Amount"
            type="number"
            required
            variant="outlined"
            onChange={handleTextFieldChange('discount')}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
