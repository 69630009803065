import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Toolbar,
  Typography,
  IconButton,
  AppBar,
} from '@mui/material';
import {
  DataGridPremium,
  GridToolbar,
  gridClasses,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import axios from 'axios';
import { subMonths, formatISO, parseISO, getMonth, getYear } from 'date-fns';
import { API_BASE_URL } from 'config';
import { blue } from '@mui/material/colors';

import CloseIcon from '@mui/icons-material/Close';

import DetailsPopover from './DetailsPopover';
import { accountDescriptions } from './accountDescriptions';
import { de, is } from 'date-fns/locale';

// Define a formatter for currency values
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

const DetailsDialog = ({
  open,
  onClose,
  rowData,
  expenseRange,
  dbName,
  apiRef,
}) => {
  const [accountDetails, setAccountDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const fetchExpenseDetails = async () => {
    setLoading(true);
    setError('');

    const endDate = new Date();
    const startDate = subMonths(endDate, expenseRange);

    try {
      const params = {
        startDate: formatISO(startDate),
        endDate: formatISO(endDate),
        startAccount: rowData.accountNumber,
        endAccount: rowData.accountNumber,
        dbName,
      };

      const response = await axios.get(
        `${API_BASE_URL}/api/glJEDetails/filter`,
        { params },
      );

      const transformedData = transformData(response.data);
      setAccountDetails(transformedData);
    } catch (err) {
      setError('Failed to fetch data');
      console.error('Error fetching account details:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchExpenseDetails();
    }
  }, [open]); // Ensure data is fetched only when the dialog is open

  const transformData = (data) => {
    const modifyData = data.map((item) => {
      // Format the date, 2024-05-01T00:00:00.000Z, to 2024-05-01
      const formattedDate = item.accountingdate.split('T')[0];
      const accountingDate = parseISO(formattedDate);
      const year = getYear(accountingDate);
      const month = (getMonth(accountingDate) + 1).toString().padStart(2, '0'); // Add 1 to the month
      const monthField = `${month}-${year}`;

      // Check if the detail description can be split, if it can't return the detail description, if it can return the first split
      const detailDescription =
        item.detaildescription && item.detaildescription.includes(' ')
          ? item.detaildescription.split(' ')[0]
          : item.detaildescription;

      // Check the control number, if it is blank return the first split of the detail description
      const control =
        item.control && item.control.trim() !== ''
          ? item.control
          : detailDescription;

      // Check if the control number is a number, if it is return the control number
      const controlNumber = !isNaN(control) ? control : detailDescription;

      return {
        id: item._id,
        control: controlNumber,
        detailDescription: item.detaildescription,
        refer: item.refer,
        journalid: item.journalid,
        amount: parseFloat(item.postingamount.$numberDecimal) || 0,
        [monthField]: parseFloat(item.postingamount.$numberDecimal) || 0,
      };
    });

    const groupedData = modifyData.reduce((acc, item) => {
      const controlNumber = item.control;
      const monthField = Object.keys(item).find((key) =>
        key.match(/^\d{2}-\d{4}$/),
      );

      if (!monthField) {
        return acc;
      }

      const value = item[monthField];
      const description = {
        description: item.detailDescription,
        amount: value,
        refer: item.refer,
        journalid: item.journalid,
      };

      if (!acc[controlNumber]) {
        acc[controlNumber] = {
          id: item.id,
          control: controlNumber,
          detailDescription: item.detailDescription,
          [monthField]: {
            id: `${controlNumber}-${monthField}`,
            amount: value,
            descriptions: [description],
          },
        };
      } else {
        if (!acc[controlNumber][monthField]) {
          acc[controlNumber][monthField] = {
            id: `${controlNumber}-${monthField}`,
            amount: value,
            descriptions: [description],
          };
        } else {
          acc[controlNumber][monthField].amount += value;
          acc[controlNumber][monthField].descriptions.push(description);
        }
      }

      return acc;
    }, {});

    // Convert the grouped data back to an array and preserve the structure
    const transformedData = Object.values(groupedData).map((item) => {
      const row = {
        id: item.id,
        control: item.control,
        detailDescription: item.detailDescription,
      };

      // Add the month-year fields to the row
      Object.entries(item).forEach(([key, value]) => {
        if (key.match(/^\d{2}-\d{4}$/)) {
          row[key] = value;
        }
      });

      return row;
    });

    return transformedData;
  };

  const generateMonthYearColumns = (range) => {
    const columns = [
      {
        field: 'control',
        headerName: 'Control/VendorID',
        width: 150,
      },
      {
        field: 'detailDescription',
        headerName: 'Description',
        minWidth: 400,
        maxWidth: 1000,
        flex: 1,
      },
    ];

    const end = new Date();
    const currentMonthField = `${(end.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${end.getFullYear()}`;

    for (let i = 0; i < range; i++) {
      const monthDate = subMonths(end, i);
      const zeroPrefixedMonth = (monthDate.getMonth() + 1)
        .toString()
        .padStart(2, '0');
      const monthYearHeader = `${monthDate.toLocaleString('default', {
        month: 'short',
      })} ${monthDate.getFullYear()}`;

      const column = {
        field: `${zeroPrefixedMonth}-${monthDate.getFullYear()}`,
        headerName: monthYearHeader,
        width: 110,
        type: 'number',
        className: 'expense',
        headerAlign: 'center',
        align: 'center',
        valueGetter: (value, row) => {
          if (value) {
            return value.amount;
          } else {
            return 0;
          }
        },
        valueFormatter: (value) => {
          return currencyFormatter.format(value);
        },
        renderCell: (params) => {
          const row = params.row;
          const fieldName = `${zeroPrefixedMonth}-${monthDate.getFullYear()}`;

          if (
            row[fieldName] &&
            Array.isArray(row[fieldName].descriptions) &&
            typeof row[fieldName].amount === 'number'
          ) {
            return <DetailsPopover row={row} fieldName={fieldName} />;
          }
        },
      };

      if (column.field === currentMonthField) {
        column.headerName += ' (Current)';
        column.width += 50;
      }

      columns.push(column);
    }

    return columns;
  };

  const columns = generateMonthYearColumns(expenseRange);

  // Get the account descriptions
  const accountDescription = accountDescriptions.find(
    (item) => item.Account === rowData.accountNumber,
  );

  // Create an object from the columns where the field is the key and the value equals sum on columns with a type of number
  const columnAggregates = columns.reduce((acc, column) => {
    if (column.type === 'number') {
      acc[column.field] = 'sum';
    }
    return acc;
  }, {});

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      aggregation: {
        model: {
          ...columnAggregates,
        },
      },
    },
  });

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {rowData.accountNumber}{' '}
            {accountDescription ? accountDescription.Description : ''}
          </Typography>
          <Button autoFocus color="inherit" onClick={onClose}>
            close
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent
        sx={{
          height: '80vh',
          [`.${gridClasses.cell}.currentMonthShade`]: {
            backgroundColor: blue[100],
            color: blue[800],
          },
        }}
      >
        <DataGridPremium
          apiRef={apiRef}
          getRowId={(row) => row.id}
          initialState={initialState}
          rows={accountDetails}
          columns={columns}
          loading={loading}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          disableRowSelectionOnClick
        />
        {error && <div>Error: {error}</div>}
      </DialogContent>
    </Dialog>
  );
};

export default DetailsDialog;
