// Permission constants
const PERMISSIONS = {
  VIEW_SALES: 'viewSales',
  EDIT_SALES: 'editSales',
  VIEW_SERVICE: 'viewService',
  EDIT_SERVICE: 'editService',
  VIEW_FINANCE: 'viewFinance',
  EDIT_FINANCE: 'editFinance',
  VIEW_ACCOUNTING: 'viewAccounting',
  EDIT_ACCOUNTING: 'editAccounting',
};

// Department constants
const DEPARTMENTS = {
  SALES: 'Sales',
  FINANCE: 'Finance',
  SERVICE: 'Service',
  PARTS: 'Parts',
  ACCOUNTING: 'Accounting',
};

export const rolePermissions = [
  {
    role: 'Finance Director',
    departments: [DEPARTMENTS.FINANCE, DEPARTMENTS.SALES],
    permissions: [PERMISSIONS.VIEW_FINANCE, PERMISSIONS.EDIT_FINANCE, PERMISSIONS.EDIT_SALES, PERMISSIONS.VIEW_SALES],
  },
  {
    role: 'Finance Manager',
    departments: [DEPARTMENTS.FINANCE, DEPARTMENTS.SALES],
    permissions: [PERMISSIONS.VIEW_FINANCE],
  },
  {
    role: 'Finance Assistant',
    departments: [DEPARTMENTS.FINANCE],
    permissions: [PERMISSIONS.VIEW_FINANCE],
  },
  {
    role: 'General Manager',
    departments: [DEPARTMENTS.SALES, DEPARTMENTS.FINANCE, DEPARTMENTS.SERVICE, DEPARTMENTS.PARTS, DEPARTMENTS.ACCOUNTING],
    permissions: [PERMISSIONS.VIEW_SALES, PERMISSIONS.EDIT_SALES, PERMISSIONS.VIEW_FINANCE],
  },
  {
    role: 'General Sales Manager',
    departments: [DEPARTMENTS.SALES, DEPARTMENTS.FINANCE],
    permissions: [PERMISSIONS.VIEW_SALES, PERMISSIONS.EDIT_SALES],
  },
  {
    role: 'Intenet Director',
    departments: [DEPARTMENTS.SALES],
    permissions: [PERMISSIONS.VIEW_SALES, PERMISSIONS.EDIT_SALES],
  },
  {
    role: 'Internet Manager',
    departments: [DEPARTMENTS.SALES],
    permissions: [PERMISSIONS.VIEW_SALES, PERMISSIONS.EDIT_SALES],
  },
  {
    role: 'Sales Consultant',
    departments: [DEPARTMENTS.SALES],
    permissions: [PERMISSIONS.VIEW_SALES],
  },
  {
    role: 'Sales Manager',
    departments: [DEPARTMENTS.SALES, DEPARTMENTS.FINANCE],
    permissions: [PERMISSIONS.VIEW_SALES, PERMISSIONS.EDIT_SALES],
  },
  {
    role: 'Assistant Sales Manager',
    departments: [DEPARTMENTS.SALES],
    permissions: [PERMISSIONS.VIEW_SALES],
  },
  {
    role: 'BDC Agent',
    departments: [DEPARTMENTS.SALES],
    permissions: [PERMISSIONS.VIEW_SALES],
  },
  {
    role: 'BDC Manager',
    departments: [DEPARTMENTS.SALES],
    permissions: [PERMISSIONS.VIEW_SALES],
  },
  {
    role: 'BDC Director',
    departments: [DEPARTMENTS.SALES],
    permissions: [PERMISSIONS.VIEW_SALES, PERMISSIONS.EDIT_SALES],
  },
  {
    role: 'Admin',
    departments: [DEPARTMENTS.FINANCE, DEPARTMENTS.SALES, DEPARTMENTS.SERVICE, DEPARTMENTS.PARTS, DEPARTMENTS.ACCOUNTING],
    permissions: [PERMISSIONS.VIEW_SALES, PERMISSIONS.EDIT_SALES, PERMISSIONS.VIEW_FINANCE, PERMISSIONS.EDIT_FINANCE, PERMISSIONS.VIEW_SERVICE, PERMISSIONS.EDIT_SERVICE, PERMISSIONS.VIEW_ACCOUNTING, PERMISSIONS.EDIT_ACCOUNTING],
  },
  {
    role: 'Fleet Manager',
    departments: [DEPARTMENTS.SALES],
    permissions: [PERMISSIONS.VIEW_SALES, PERMISSIONS.EDIT_SALES],
  },
  {
    role: 'Fleet Assistant',
    departments: [DEPARTMENTS.SALES],
    permissions: [PERMISSIONS.VIEW_SALES],
  },
  {
    role: 'Controller',
    departments: [DEPARTMENTS.ACCOUNTING, DEPARTMENTS.FINANCE, DEPARTMENTS.SALES, DEPARTMENTS.SERVICE, DEPARTMENTS.PARTS],
    permissions: [PERMISSIONS.VIEW_SALES, PERMISSIONS.EDIT_SALES, PERMISSIONS.VIEW_FINANCE, PERMISSIONS.EDIT_FINANCE, PERMISSIONS.VIEW_ACCOUNTING, PERMISSIONS.EDIT_ACCOUNTING, PERMISSIONS.VIEW_SERVICE, PERMISSIONS.EDIT_SERVICE],
  },
  {
    role: 'Accounting Manager',
    departments: [DEPARTMENTS.ACCOUNTING, DEPARTMENTS.FINANCE, DEPARTMENTS.SALES],
    permissions: [PERMISSIONS.VIEW_FINANCE, PERMISSIONS.EDIT_FINANCE, PERMISSIONS.VIEW_ACCOUNTING, PERMISSIONS.EDIT_ACCOUNTING],
  },
  {
    role: 'Accounting Assistant',
    departments: [DEPARTMENTS.ACCOUNTING],
    permissions: [PERMISSIONS.VIEW_FINANCE, PERMISSIONS.VIEW_ACCOUNTING, PERMISSIONS.EDIT_ACCOUNTING],
  },
  {
    role: 'Service Director',
    departments: [DEPARTMENTS.SERVICE, DEPARTMENTS.PARTS],
    permissions: [PERMISSIONS.VIEW_SERVICE, PERMISSIONS.EDIT_SERVICE, PERMISSIONS.VIEW_PARTS],
  },
  {
    role: 'Service Manager',
    departments: [DEPARTMENTS.SERVICE],
    permissions: [PERMISSIONS.VIEW_SERVICE, PERMISSIONS.EDIT_SERVICE],
  },
  {
    role: 'Service Advisor',
    departments: [DEPARTMENTS.SERVICE],
    permissions: [PERMISSIONS.VIEW_SERVICE],
  },
  {
    role: 'Service Technician',
    departments: [DEPARTMENTS.SERVICE],
    permissions: [PERMISSIONS.VIEW_SERVICE],
  },
  {
    role: 'Parts Manager',
    departments: [DEPARTMENTS.PARTS],
    permissions: [PERMISSIONS.VIEW_PARTS],
  },
  {
    role: 'Parts Specialist',
    departments: [DEPARTMENTS.PARTS],
    permissions: [PERMISSIONS.VIEW_PARTS],
  },
  {
    role: 'Parts Driver',
    departments: [DEPARTMENTS.PARTS],
    permissions: [PERMISSIONS.VIEW_PARTS],
  },
  {
    role: 'Parts Assistant',
    departments: [DEPARTMENTS.PARTS],
    permissions: [PERMISSIONS.VIEW_PARTS],
  },
].sort((a, b) => (a.role > b.role ? 1 : -1)); // Sort by role

export const hasPermission = (userRoles, requiredPermission) => {
  return userRoles?.some(role => {
    const roleInfo = rolePermissions.find(r => r.role === role);
    return roleInfo ? roleInfo.permissions.includes(requiredPermission) : false;
  }) || false;
};

export const hasDepartment = (userRoles, requiredDepartment) => {
  return userRoles?.some(role => {
    const roleInfo = rolePermissions.find(r => r.role === role);
    return roleInfo ? roleInfo.departments.includes(requiredDepartment) : false;
  }) || false;
};

export { PERMISSIONS, DEPARTMENTS };