import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Container,
  TextField,
  Button,
  MenuItem,
  Typography,
  Box,
  CircularProgress,
  List,
  ListItemText,
  ListItemButton,
  Snackbar,
  Alert,
} from '@mui/material';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const CustomerLogForm = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const initialFormData = {
    dealerid: '',
    fname: '',
    lname: '',
    email: '',
    phone: '',
    source: '',
    salesperson: '',
    vehicleType: '',
    vehicle: '',
    comments: [],
    sold: false,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [duplicateCheckLoading, setDuplicateCheckLoading] = useState(false);
  const [duplicates, setDuplicates] = useState([]);
  const [editingCustomerId, setEditingCustomerId] = useState(null);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [checkedForDuplicates, setCheckedForDuplicates] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    if (user) {
      setFormData({
        ...initialFormData,
        dealerid: user?.dealerid || '',
        salesperson: user?.sub || '',
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      if (name === 'phone') {
        const phoneNumber = parsePhoneNumberFromString(value, 'US');
        return {
          ...prevFormData,
          [name]: phoneNumber ? phoneNumber.formatNational() : value,
        };
      }
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  };

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleCheckDuplicates = async () => {
    setDuplicateCheckLoading(true);
    setError(null);
    setCheckedForDuplicates(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${API_BASE_URL}/api/customers/check-duplicates`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            email: formData.email,
            phone: formData.phone,
            fname: formData.fname,
            lname: formData.lname,
          },
        },
      );
      const sortedDuplicates = response.data.sort(
        (a, b) => b.matchPercentage - a.matchPercentage,
      );
      setDuplicates(sortedDuplicates);

      if (sortedDuplicates.length === 0) {
        setSnackbarMessage(
          'No potential duplicates found. You can continue to add a new customer.',
        );
        setSnackbarSeverity('info');
        setOpenSnackbar(true);
        setShowAdditionalFields(true);
      } else {
        setShowAdditionalFields(false);
      }
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    } finally {
      setDuplicateCheckLoading(false);
    }
  };

  const handleSelectCustomer = (customer) => {
    setFormData({
      ...customer,
      salesperson: user?.sub || '',
      updateDate: new Date(),
    });
    setEditingCustomerId(customer._id);
    setDuplicates([]);
    setShowAdditionalFields(true);
  };

  const handleContinueAdding = () => {
    setShowAdditionalFields(true);
    setDuplicates([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const updatedComments = newComment.trim()
      ? [
          ...formData.comments,
          {
            user: user.name,
            text: newComment.trim(),
            timestamp: new Date(),
          },
        ]
      : formData.comments;

    try {
      const token = await getAccessTokenSilently();
      const apiUrl = editingCustomerId
        ? `${API_BASE_URL}/api/customers/${editingCustomerId}`
        : `${API_BASE_URL}/api/customers`;

      const method = editingCustomerId ? 'put' : 'post';

      await axios({
        method,
        url: apiUrl,
        data: { ...formData, comments: updatedComments },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Reset form
      setFormData({
        ...initialFormData,
        dealerid: user?.dealerid || '',
        salesperson: user?.sub || '',
      });
      setNewComment('');
      setEditingCustomerId(null);
      setShowAdditionalFields(false);
      setCheckedForDuplicates(false);

      // Show success message
      setSnackbarMessage('Customer logged successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setSnackbarMessage('');
  };

  if (!isAuthenticated) {
    return (
      <Typography variant="h6">Please log in to log a customer.</Typography>
    );
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Log Customer
      </Typography>
      <form onSubmit={handleSubmit} autoComplete="off">
        <TextField
          label="First Name"
          name="fname"
          value={formData.fname}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
          autoComplete="off"
        />
        <TextField
          label="Last Name"
          name="lname"
          value={formData.lname}
          onChange={handleChange}
          fullWidth
          margin="normal"
          autoComplete="off"
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          autoComplete="off"
        />
        <TextField
          label="Phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          fullWidth
          margin="normal"
          autoComplete="off"
        />
        <Button
          onClick={handleCheckDuplicates}
          variant="outlined"
          disabled={duplicateCheckLoading}
        >
          {duplicateCheckLoading ? (
            <CircularProgress size={24} />
          ) : (
            'Check for Duplicates'
          )}
        </Button>
        {checkedForDuplicates &&
          !duplicateCheckLoading &&
          duplicates.length === 0 && (
            <Box my={2}>
              <Typography variant="h6">No matches found</Typography>
            </Box>
          )}
        {duplicates.length > 0 && (
          <Box my={2}>
            <Typography variant="h6">Potential Duplicates Found:</Typography>
            <List>
              {duplicates.map((duplicate) => (
                <ListItemButton
                  onClick={() => handleSelectCustomer(duplicate)}
                  key={duplicate._id}
                  sx={{ border: '1px solid #ccc', borderRadius: 1, my: 1 }}
                >
                  <ListItemText
                    primary={`${duplicate.fname} ${duplicate.lname}`}
                    secondary={`${duplicate.email} - ${duplicate.phone} - ${duplicate.matchPercentage}% match`}
                  />
                </ListItemButton>
              ))}
            </List>
            <Button
              variant="contained"
              color="primary"
              onClick={handleContinueAdding}
            >
              Continue to Add New Customer
            </Button>
          </Box>
        )}
        {showAdditionalFields && (
          <>
            <TextField
              label="Source"
              name="source"
              select
              value={formData.source}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
              autoComplete="off"
            >
              <MenuItem value="walk-in">Walk-in</MenuItem>
              <MenuItem value="phone">Phone</MenuItem>
              <MenuItem value="internet">Internet</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
            <TextField
              label="Vehicle Type"
              name="vehicleType"
              select
              value={formData.vehicleType}
              onChange={handleChange}
              fullWidth
              margin="normal"
              autoComplete="off"
            >
              <MenuItem value="New">New</MenuItem>
              <MenuItem value="Used">Used</MenuItem>
            </TextField>
            <TextField
              label="Vehicle"
              name="vehicle"
              value={formData.vehicle}
              onChange={handleChange}
              fullWidth
              margin="normal"
              autoComplete="off"
            />
            <TextField
              label="Comments"
              name="newComment"
              value={newComment}
              onChange={handleCommentChange}
              fullWidth
              multiline
              rows={4}
              margin="normal"
              autoComplete="off"
            />
            <Box display="flex" alignItems="center" margin="normal">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Log Customer'}
              </Button>
            </Box>
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
          </>
        )}
      </form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CustomerLogForm;
