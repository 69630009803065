import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import {
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  DataGridPremium,
  GridToolbar,
  GridActionsCellItem,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { API_BASE_URL } from 'config';
import { useLenders } from 'hooks/useLenders';

import AddIncentiveDialog from './AddIncentiveDialog';

import RebateTable from './RebateTable';
import RetailTable from './RetailTable';
import LeaseTable from './LeaseTable';

const Incentives = () => {
  const { user } = useAuth0();
  const { lenders } = useLenders(user?.dealerid);
  const [selectedLender, setSelectedLender] = useState(null);
  const [incentives, setIncentives] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openIncentiveDialog, setOpenIncentiveDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState(null);
  const [dialogData, setDialogData] = useState(null);
  const [editingIncentive, setEditingIncentive] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedIncentive, setSelectedIncentive] = useState(null);

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (lenders.length > 0) {
      const initialLender = lenders.find(l => l.Active === true && l.ActiveForRetail);
      setSelectedLender(initialLender || null);
    }
  }, [lenders]);

  const handleLenderChange = (event) => {
    const lenderId = event.target.value;
    const selectedLender = lenders.find((l) => l._id === lenderId);
    setSelectedLender(selectedLender || null);
  };

  useEffect(() => {
    if (!selectedLender) return;

    const fetchIncentives = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/api/incentives/`, {
          params: { lenderId: selectedLender._id },
        });
        console.log('Incentives:', response.data);
        setIncentives(response.data || []);
      } catch (error) {
        console.error('Error fetching incentives:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchIncentives();
  }, [selectedLender]);

  const handleOpenDialog = (type, data, incentive) => {
    setDialogType(type);
    setDialogData(data);
    setSelectedIncentive(incentive);
    setOpenDialog(true);
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogType(null);
    setDialogData(null);
  };

  const handleOpenIncentiveDialog = () => {
    setOpenIncentiveDialog(true);
  };

  const handleCloseIncentiveDialog = () => {
    setOpenIncentiveDialog(false);
    setEditingIncentive(null);
  };
  
  const handleSubmitIncentive = async (newIncentive) => {
    setIsSubmitting(true);
    try {
      let response;
      if (editingIncentive) {
        // Update existing incentive
        response = await axios.put(`${API_BASE_URL}/api/incentives/${editingIncentive._id}`, newIncentive);
        setIncentives(incentives.map(inc => inc._id === editingIncentive._id ? response.data : inc));
      } else {
        // Create new incentive
        response = await axios.post(`${API_BASE_URL}/api/incentives/create`, newIncentive);
        setIncentives([...incentives, response.data]);
      }
      handleCloseIncentiveDialog();
    } catch (error) {
      console.error('Error submitting incentive:', error);
      // You might want to show an error message to the user here
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCellEdit = useCallback((params) => {
    const incentive = incentives.find(inc => inc._id === params.id);
    setEditingIncentive(incentive);
    setOpenIncentiveDialog(true);
  }, [incentives]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/incentives/${id}`);
      setIncentives(incentives.filter((incentive) => incentive._id !== id));
    } catch (error) {
      console.error('Error deleting incentive:', error);
    }
  };

  const columns = [
    { 
      field: 'effective_date',
      headerName: 'Effective Date',
      width: 150,
      type: 'date',
      valueGetter: (value, row) => value ? new Date(value) : null,
    },
    { 
      field: 'expire_date',
      headerName: 'Expiration Date',
      width: 150,
      type: 'date',
      valueGetter: (value, row) => value ? new Date(value) : null,
    },
    { 
      field: 'model_year',
      headerName: 'Year',
      width: 100,
    },
    { field: 'make', headerName: 'Make', width: 150 },
    { field: 'model_name', headerName: 'Model', width: 200 },
    { 
      field: 'model_numbers',
      headerName: 'Model Numbers',
      width: 200,
      editable: true,
      valueGetter: (value, row) => Array.isArray(value) ? value.join(', ') : value,
    },
    {
      field: 'retail',
      headerName: 'Retail',
      width: 150,
      editable: false,
      renderCell: (params) => {
        const value = params.value;
        if (value && Array.isArray(value) && value.length > 0) {
          return (
            <Button 
              onClick={() => handleOpenDialog('retail', value, params.row)}
              size='small'
            >
              View Retail
            </Button>
          );
        }
        return 'N/A';
      },
    },
    { 
      field: 'lease',
      headerName: 'Lease',
      width: 150,
      editable: false,
      renderCell: (params) => {
        const value = params.value;
        if (value && Array.isArray(value) && value.length > 0) {
          return (
            <Button 
              onClick={() => handleOpenDialog('lease', value)}
              size='small'
            >
              View Lease
            </Button>
          );
        }
        return 'N/A';
      },
    },
    {
      field: 'rebates',
      headerName: 'Rebates',
      width: 150,
      editable: false,
      renderCell: (params) => {
        const value = params.value;
        if (value && Array.isArray(value) && value.length > 0) {
          return (
            <Button 
              onClick={() => handleOpenDialog('rebates', value)}
              size='small'
            >
              View Rebates
            </Button>
          );
        }
        return 'N/A';
      },
    },    
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => handleCellEdit({ id })}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDelete(id)}
          color="inherit"
        />,
      ],
    },
  ];

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
      <FormControl sx={{ minWidth: 240 }} size="small">
        <InputLabel id="select-lender">Select Lender</InputLabel>
        <Select
          labelId="select-lender"
          label="Select Lender"
          value={selectedLender?._id || ''}
          onChange={handleLenderChange}
          displayEmpty
        >
          <MenuItem value=""><em>All Lenders</em></MenuItem>
          {lenders
            .filter((l) => l.Active === true && l.ActiveForRetail)
            .map((lender) => (
              <MenuItem key={lender._id} value={lender._id}>
                {lender.Name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenIncentiveDialog}
        >
          Add Incentive
        </Button>
      </Box>

      <DataGridPremium
        rows={incentives}
        columns={columns}
        getRowId={(row) => row._id}
        loading={loading}
        apiRef={apiRef}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        initialState={{
          pinnedColumns: { right: ['actions'] },
        }}
        autoHeight
      />

      <AddIncentiveDialog
        open={openIncentiveDialog}
        onClose={handleCloseIncentiveDialog}
        onSubmit={handleSubmitIncentive}
        selectedLender={selectedLender}
        editingIncentive={editingIncentive}
        isSubmitting={isSubmitting}
      />

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>{dialogType === 'lease' ? 'Lease Details' : dialogType === 'rebates' ? 'Rebate Details' : 'Retail Details'}</DialogTitle>
        <DialogContent>
          {dialogType === 'lease' && <LeaseTable data={dialogData} />}
          {dialogType === 'rebates' && <RebateTable data={dialogData} />}
          {dialogType === 'retail' && (
            <RetailTable 
              data={dialogData} 
              lenderId={selectedLender?._id} 
              incentiveId={selectedIncentive?._id}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default Incentives;