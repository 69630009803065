import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  DataGridPremium,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import { startOfMonth, endOfMonth, getDate, getDaysInMonth } from 'date-fns';
import axios from 'axios';
import {
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Snackbar,
  Alert,
  Button,
} from '@mui/material';
import clsx from 'clsx';
import { yellow, grey } from '@mui/material/colors';
import { formatCurrency } from 'utils/numberUtils';
import { API_BASE_URL } from 'config';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

function FinanceManagerCommission() {
  const { user } = useAuth0();
  const [data, setData] = useState([]);
  const [totalGrossProfit, setTotalGrossProfit] = useState(0);
  const [dealStatus, setDealStatus] = useState('Blended');
  const [dateRange, setDateRange] = useState([
    dayjs().startOf('month'),
    dayjs(),
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(null);

  const apiRef = useGridApiRef();

  const handleCloseSnackbar = () => setSnackbar(null);

  const formatPercentage = (value) => {
    if (value === null) return null;
    return parseFloat(value).toFixed(2) + '%';
  };

  const checkIfSameMonth = (range) => {
    const today = new Date();
    return range[0] >= startOfMonth(today) && range[1] <= endOfMonth(today);
  };

  const calculatePenetration = (count, units) => {
    if (count === 0 || units === 0) {
      return null;
    }
    return ((count / units) * 100).toFixed(2);
  };

  const calculateGrossPace = (gross) => {
    const today = new Date();
    return (gross / getDate(today)) * getDaysInMonth(today);
  };

  const handleProcessRowUpdateError = useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/ficommissions/${user.dealerid}`,
          {
            params: {
              startDate: dateRange[0].format('YYYY-MM-DD'),
              endDate: dateRange[1].format('YYYY-MM-DD'),
              dealStatus,
            },
          },
        );

        setTotalGrossProfit(response.data[0]?.dealerProfit ?? 0);
        setData(response.data ?? []);
      } catch (error) {
        console.error('Error fetching deals:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dateRange, dealStatus, user]);

  const mergedData = useMemo(() => {
    // Remove the summary from the original data if it exists
    const regularData = data.filter((row) => row.id !== 'summary');

    // Create the summary row
    const summaryRow = {
      id: 'summary',
      CRMFIMgrName: 'Summary',
      totalUnits: regularData.reduce((acc, row) => acc + row.totalUnits, 0),
      mbiIncomeCount: regularData.reduce(
        (acc, row) => acc + row.mbiIncomeCount,
        0,
      ),
      mbiIncome: regularData.reduce((acc, row) => acc + row.mbiIncome, 0),
      ins2IncomeCount: regularData.reduce(
        (acc, row) => acc + row.ins2IncomeCount,
        0,
      ),
      ins2Income: regularData.reduce((acc, row) => acc + row.ins2Income, 0),
      ins3IncomeCount: regularData.reduce(
        (acc, row) => acc + row.ins3IncomeCount,
        0,
      ),
      ins3Income: regularData.reduce((acc, row) => acc + row.ins3Income, 0),
      backWeOwesCount: regularData.reduce(
        (acc, row) => acc + row.backWeOwesCount,
        0,
      ),
      backWeOwes: regularData.reduce((acc, row) => acc + row.backWeOwes, 0),
      reserve: regularData.reduce((acc, row) => acc + row.reserve, 0),
      productGross: regularData.reduce((acc, row) => acc + row.productGross, 0),
      productPVR:
        regularData.reduce((acc, row) => acc + row.productGross, 0) /
        regularData.reduce((acc, row) => acc + row.totalUnits, 0),
      Amount: regularData.reduce((acc, row) => acc + row.Amount, 0),
      Pacing: regularData.reduce((acc, row) => acc + row.Pacing, 0),
    };

    // Return the regular data followed by the summary row
    return [...regularData, summaryRow];
  }, [data]);

  const columns = [
    {
      field: 'CRMFIMgrName',
      headerName: 'Employee',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'totalUnits',
      headerName: 'Total',
      headerAlign: 'center',
      align: 'center',
      headerClassName: () => clsx('border-right fw-bold'),
      cellClassName: () => clsx('border-right fw-bold'),
      type: 'number',
      width: 60,
      disableColumnMenu: true,
    },
    {
      field: 'mbiIncomeCount',
      headerName: 'Units',
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      width: 60,
      disableColumnMenu: true,
    },
    {
      field: 'mbiIncomePenetration',
      headerName: '%',
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      valueGetter: (value, row) =>
        calculatePenetration(row.mbiIncomeCount, row.totalUnits),
      valueFormatter: (value) => formatPercentage(value),
    },
    {
      field: 'mbiIncomePenetration_rank',
      headerName: 'Rank',
      width: 60,
      headerAlign: 'center',
      headerClassName: () => clsx('border-right fw-bold'),
      cellClassName: () => clsx('border-right fw-bold'),
      align: 'center',
      type: 'number',
    },
    {
      field: 'ins2IncomeCount',
      headerName: 'Units',
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      width: 60,
      disableColumnMenu: true,
    },
    {
      field: 'ins2IncomePenetration',
      headerName: '%',
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      valueGetter: (value, row) =>
        calculatePenetration(row.ins2IncomeCount, row.totalUnits),
      valueFormatter: (value) => formatPercentage(value),
    },
    {
      field: 'ins2IncomePenetration_rank',
      headerName: 'Rank',
      width: 60,
      headerAlign: 'center',
      headerClassName: () => clsx('border-right fw-bold'),
      cellClassName: () => clsx('border-right fw-bold'),
      align: 'center',
      type: 'number',
    },
    {
      field: 'ins3IncomeCount',
      headerName: 'Units',
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      width: 60,
      disableColumnMenu: true,
    },
    {
      field: 'ins3IncomePenetration',
      headerName: '%',
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      valueGetter: (value, row) =>
        calculatePenetration(row.ins3IncomeCount, row.totalUnits),
      valueFormatter: (value) => formatPercentage(value),
    },
    {
      field: 'ins3IncomePenetration_rank',
      headerName: 'Rank',
      width: 60,
      headerAlign: 'center',
      headerClassName: () => clsx('border-right fw-bold'),
      cellClassName: () => clsx('border-right fw-bold'),
      align: 'center',
      type: 'number',
    },
    {
      field: 'backWeOwesCount',
      headerName: 'Units',
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      width: 60,
      disableColumnMenu: true,
    },
    {
      field: 'backWeOwesPenetration',
      headerName: '%',
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      valueGetter: (value, row) =>
        calculatePenetration(row.backWeOwesCount, row.totalUnits),
      valueFormatter: (value) => formatPercentage(value),
    },
    {
      field: 'backWeOwesPenetration_rank',
      headerName: 'Rank',
      width: 60,
      headerAlign: 'center',
      headerClassName: () => clsx('border-right fw-bold'),
      cellClassName: () => clsx('border-right fw-bold'),
      align: 'center',
      type: 'number',
    },
    {
      field: 'productGross',
      headerName: 'Product',
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      valueFormatter: (value) => formatCurrency(value),
      disableColumnMenu: true,
    },
    {
      field: 'productGross_rank',
      headerName: 'Rank',
      headerClassName: () => clsx('border-right fw-bold'),
      cellClassName: () => clsx('border-right fw-bold'),
      width: 60,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
    },
    {
      field: 'productPVR',
      headerName: 'PVR',
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      valueFormatter: (value) => formatCurrency(value),
      disableColumnMenu: true,
    },
    {
      field: 'productPVR_rank',
      headerName: 'Rank',
      width: 60,
      headerAlign: 'center',
      headerClassName: () => clsx('border-right fw-bold'),
      cellClassName: () => clsx('border-right fw-bold'),
      align: 'center',
      type: 'number',
    },
    {
      field: 'updated_rank',
      headerName: 'Rank',
      type: 'number',
      width: 90,
      headerAlign: 'center',
      align: 'center',
      cellClassName: () => clsx('bg-yellow fw-bold'),
      headerClassName: () => clsx('bg-yellow'),
      disableColumnMenu: true,
      valueGetter: (value, row) => {
        if (row.id === 'summary') {
          return Infinity; // Ensures summary row is always last when sorting
        }
        const dealRank =
          (row.ins2IncomePenetration_rank ?? 0) +
          (row.ins3IncomePenetration_rank ?? 0) +
          (row.mbiIncomePenetration_rank ?? 0) +
          (row.backWeOwesPenetration_rank ?? 0) +
          (row.productGross_rank ?? 0) +
          (row.productPVR_rank ?? 0);
        const modifyByGross = (row.productGross_rank ?? 0) / 10;
        const modifiedRank = dealRank + modifyByGross;
        return modifiedRank;
      },
    },
    {
      field: 'Percentage',
      headerName: '%',
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      width: 75,
      headerClassName: () => clsx('bg-md-grey'),
      cellClassName: () => clsx('bg-md-grey'),
      disableColumnMenu: true,
      valueGetter: (value, row) => {
        if (row.id === 'summary') return null;
        const percentages = [13.5, 12.5, 12, 11.25, 10.5, 10];
        const data = apiRef.current.getSortedRows();
        data.sort((a, b) => a.updated_rank - b.updated_rank);
        const rank = data.findIndex((item) => item.id === row.id);
        row.Percentage = percentages[rank];
        return percentages[rank];
      },
    },
    {
      field: 'Bonus',
      headerName: 'Bonus %',
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      width: 75,
      headerClassName: () => clsx('bg-md-grey'),
      cellClassName: () => clsx('bg-md-grey'),
      disableColumnMenu: true,
      valueGetter: (value, row) => {
        if (row.id === 'summary') return null;
        const mbiBonus = row.mbiIncomePenetration >= 58 ? 1 : 0;
        const ins2Bonus = row.ins2IncomePenetration >= 50 ? 1 : 0;
        row.Bonus = mbiBonus + ins2Bonus;
        return mbiBonus + ins2Bonus;
      },
      valueFormatter: (value) => formatPercentage(value),
    },
    {
      field: 'Amount',
      headerName: 'Amount',
      type: 'number',
      width: 100,
      headerClassName: () => clsx('bg-md-grey'),
      cellClassName: () => clsx('bg-md-grey fw-bold'),
      disableColumnMenu: true,
      valueGetter: (value, row) => {
        if (row.id === 'summary') {
          // Calculate the total amount for the summary row
          const totalAmount = data.reduce((acc, row) => acc + row.Amount, 0);
          row.Amount = totalAmount;
          return totalAmount;
        }
        const productGross = row.productGross;
        const percentage = row.Percentage;
        const bonus = row.Bonus;
        const amount = (productGross * (percentage + bonus)) / 100;
        row.Amount = amount;
        return amount;
      },
      valueFormatter: (value) => formatCurrency(value),
    },
    {
      field: 'Pacing',
      headerName: 'Pacing',
      type: 'number',
      width: 100,
      headerClassName: () => clsx('bg-md-grey'),
      cellClassName: () => clsx('bg-md-grey'),
      disableColumnMenu: true,
      valueGetter: (value, row) => {
        if (row.id === 'summary') {
          // Calculate the total pacing for the summary row
          const totalPacing = data.reduce((acc, row) => acc + row.Pacing, 0);
          row.Pacing = totalPacing;
          return totalPacing;
        }
        const currentDate = new Date();
        const currentDay = currentDate.getDate();
        const daysInMonth = getDaysInMonth(currentDate);
        const backGross = row.totalBackGross;
        const reserve = row.reserve;
        const profit = backGross - reserve;
        const amount = (profit * (row.Percentage + row.Bonus)) / 100;
        const pacing = (amount / currentDay) * daysInMonth;
        row.Pacing = pacing;
        return pacing;
      },
      valueFormatter: (value) => formatCurrency(value),
    },
    {
      field: 'PayPerDeal',
      headerName: 'Per Deal',
      type: 'number',
      width: 100,
      headerClassName: () => clsx('bg-md-grey'),
      cellClassName: () => clsx('bg-md-grey'),
      disableColumnMenu: true,
      valueGetter: (value, row) => {
        if (row.id === 'summary') {
          // Calculate the total pay per deal for the summary row
          const totalAmount = data.reduce((acc, row) => acc + row.Amount, 0);
          const totalUnits = data.reduce((acc, row) => acc + row.totalUnits, 0);
          const payPerDeal = totalAmount / totalUnits;
          row.PayPerDeal = payPerDeal;
          return payPerDeal;
        }
        const amount = row.Amount;
        const totalUnits = row.totalUnits;
        const payPerDeal = amount / totalUnits;
        row.PayPerDeal = payPerDeal;
        return payPerDeal;
      },
      valueFormatter: (value) => formatCurrency(value),
    }
  ];

  const columnGroupingModel = useMemo(
    () => [
      {
        groupId: 'Units',
        headerClassName: () => clsx('border-right'),
        children: [{ field: 'totalUnits' }],
      },
      {
        groupId: 'Warranty (1% Bonus >= 58%)',
        headerClassName: () => clsx('border-right'),
        children: [
          { field: 'mbiIncomeCount' },
          { field: 'mbiIncomePenetration' },
          { field: 'mbiIncomePenetration_rank' },
        ],
      },
      {
        groupId: 'GAP (1% Bonus >= 50%)',
        headerClassName: () => clsx('border-right'),
        children: [
          { field: 'ins2IncomeCount' },
          { field: 'ins2IncomePenetration' },
          { field: 'ins2IncomePenetration_rank' },
        ],
      },
      {
        groupId: 'Maintenance',
        headerClassName: () => clsx('border-right'),
        children: [
          { field: 'ins3IncomeCount' },
          { field: 'ins3IncomePenetration' },
          { field: 'ins3IncomePenetration_rank' },
        ],
      },
      {
        groupId: 'Back We Owes',
        headerClassName: () => clsx('border-right'),
        children: [
          { field: 'backWeOwesCount' },
          { field: 'backWeOwesPenetration' },
          { field: 'backWeOwesPenetration_rank' },
        ],
      },
      {
        groupId: 'Product Gross',
        headerClassName: () => clsx('border-right'),
        children: [{ field: 'productGross' }, { field: 'productGross_rank' }],
      },
      {
        groupId: 'Product PVR',
        headerClassName: () => clsx('border-right'),
        children: [{ field: 'productPVR' }, { field: 'productPVR_rank' }],
      },
      {
        groupId: 'Total',
        headerClassName: () => clsx('bg-yellow border-right'),
        children: [{ field: 'updated_rank' }],
      },
      {
        groupId: 'Estimated Commission',
        headerClassName: () => clsx('bg-md-grey'),
        children: [
          { field: 'Percentage' },
          { field: 'Bonus' },
          { field: 'Amount' },
          { field: 'Pacing' },
          { field: 'PayPerDeal' },
        ],
      },
    ],
    [],
  );

  const shortcutsItems = [
    {
      label: 'Month to Date',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month'), today];
      },
    },
    {
      label: 'Previous Month',
      getValue: () => {
        const today = dayjs();
        const startOfPrevMonth = today.subtract(1, 'month').startOf('month');
        const endOfPrevMonth = today.subtract(1, 'month').endOf('month');
        return [startOfPrevMonth, endOfPrevMonth];
      },
    },
    {
      label: 'This Week',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('week'), today.endOf('week')];
      },
    },
    {
      label: 'Last Week',
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, 'day');
        return [prevWeek.startOf('week'), prevWeek.endOf('week')];
      },
    },
    {
      label: 'Last 7 Days',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, 'day'), today];
      },
    },
    {
      label: 'Current Month',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month'), today.endOf('month')];
      },
    },
    { label: 'Reset', getValue: () => [dayjs().startOf('month'), dayjs()] },
  ];

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      sorting: {
        sortModel: [{ field: 'updated_rank', sort: 'asc' }],
      },
      columns: {
        columnVisibilityModel: {
          Pacing: checkIfSameMonth(dateRange),
        },
      },
      pinnedColumns: {
        left: ['CRMFIMgrName'],
      },
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <>
        <DataGridPremium
          sx={{
            backgroundColor: 'background.paper',
            boxShadow: 1,
            border: 0,
            '& .MuiDataGrid-row': {
              border: 0,
              borderRadius: 1,
            },
            '& .summary-row': {
              fontWeight: 'bold',
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '& .fw-bold': { fontWeight: 'bold' },
            '& .border-right': { borderRight: `1px solid ${grey[200]}` },
            '& .border-left': { borderLeft: `1px solid ${grey[200]}` },
            '& .bg-yellow': {
              backgroundColor: yellow[100],
              color: 'black',
            },
            '& .bg-md-grey': {
              backgroundColor: grey[200],
              color: 'black',
            },
          }}
          density="compact"
          apiRef={apiRef}
          rows={mergedData}
          columns={columns}
          getRowId={(row) => row.id}
          loading={isLoading}
          initialState={initialState}
          getRowClassName={(params) =>
            params.id === 'summary' ? 'summary-row' : ''
          }
          slots={{
            toolbar: () => (
              <>
                <Stack
                  direction="row"
                  spacing={2}
                  padding={1}
                  alignItems="center"
                >
                  <DateRangePicker
                    value={dateRange}
                    onChange={(newValue) => setDateRange(newValue)}
                    localeText={{ start: 'Start', end: 'End' }}
                    slotProps={{
                      shortcuts: { items: shortcutsItems },
                      textField: { size: 'small' },
                    }}
                  />
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="deal-status-label">
                        Deal Status
                      </InputLabel>
                      <Select
                        labelId="deal-status-label"
                        id="deal-status-select"
                        value={dealStatus}
                        label="Deal Status"
                        onChange={(e) => setDealStatus(e.target.value)}
                      >
                        <MenuItem value="Blended">Blended</MenuItem>
                        <MenuItem value="F">Finalized</MenuItem>
                        <MenuItem value="B">Non-Finalized</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => apiRef.current.exportDataAsCsv()}
                  >
                    Export
                  </Button>
                  <Box sx={{ flex: 1 }} />
                  <Box>
                    <Typography variant="h6">
                      Total Back Gross: {formatCurrency(totalGrossProfit)}
                    </Typography>
                    {checkIfSameMonth(dateRange) && (
                      <Typography variant="subtitle1">
                        Pacing:{' '}
                        {formatCurrency(calculateGrossPace(totalGrossProfit))}
                      </Typography>
                    )}
                  </Box>
                </Stack>
              </>
            ),
          }}
          columnVisibilityModel={{ Pacing: checkIfSameMonth(dateRange) }}
          experimentalFeatures={{ columnGrouping: true }}
          columnGroupingModel={columnGroupingModel}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          disableRowSelectionOnClick
          hideFooter
          autoHeight
        />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </>
    </LocalizationProvider>
  );
}

export default FinanceManagerCommission;
