import React, { useState } from 'react';
import {
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Upload as UploadIcon } from '@mui/icons-material';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const CSVUploadComponent = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [mainFile, setMainFile] = useState(null);
  const [secondaryFile, setSecondaryFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [previewData, setPreviewData] = useState(null);

  const handleMainFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'text/csv') {
      setMainFile(selectedFile);
      setUploadStatus(null);
      previewFile(selectedFile);
    } else {
      setMainFile(null);
      setPreviewData(null);
      setUploadStatus({
        severity: 'error',
        message: 'Please select a CSV file for the main data',
      });
    }
  };

  const handleSecondaryFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'text/csv') {
      setSecondaryFile(selectedFile);
      setUploadStatus(null);
    } else {
      setSecondaryFile(null);
      setUploadStatus({
        severity: 'error',
        message: 'Please select a CSV file for the secondary data',
      });
    }
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      const lines = content.split('\n');
      const headers = lines[0].split(',');
      const data = lines.slice(1, 6).map((line) => line.split(',')); // Preview first 5 rows
      setPreviewData({ headers, data });
    };
    reader.readAsText(file);
  };

  const handleUpload = async () => {
    if (!mainFile || !secondaryFile) {
      setUploadStatus({
        severity: 'error',
        message: 'Please select both CSV files',
      });
      return;
    }

    if (!user?.dealerid) {
      setUploadStatus({
        severity: 'error',
        message: 'Dealer ID not found. Please contact support.',
      });
      return;
    }

    setUploading(true);
    setUploadStatus(null);

    const formData = new FormData();
    formData.append('mainCsvFile', mainFile);
    formData.append('secondaryCsvFile', secondaryFile);

    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${API_BASE_URL}/api/csv-upload?dealerId=${user.dealerid}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setUploadStatus({
        severity: 'success',
        message: `${response.data.message}. Updated: ${response.data.updated}, Inserted: ${response.data.inserted}`,
      });
    } catch (error) {
      console.error('Upload error:', error);
      setUploadStatus({
        severity: 'error',
        message:
          error.response?.data?.error ||
          'An error occurred during upload. Please check your CSV files for any formatting issues.',
      });
    } finally {
      setUploading(false);
    }
  };

  if (!isAuthenticated) {
    return null;
  }

  if (!user?.dealerid) {
    return (
      <Alert severity="error">
        Dealer ID not found. Please contact support.
      </Alert>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 800, mx: 'auto', mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Upload CSV Files
      </Typography>
      <Box sx={{ mb: 2 }}>
        <input
          accept=".csv"
          style={{ display: 'none' }}
          id="main-file-upload"
          type="file"
          onChange={handleMainFileChange}
        />
        <label htmlFor="main-file-upload">
          <Button
            variant="outlined"
            component="span"
            fullWidth
            startIcon={<UploadIcon />}
          >
            Select Main CSV File
          </Button>
        </label>
        {mainFile && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            Selected main file: {mainFile.name}
          </Typography>
        )}
      </Box>
      <Box sx={{ mb: 2 }}>
        <input
          accept=".csv"
          style={{ display: 'none' }}
          id="secondary-file-upload"
          type="file"
          onChange={handleSecondaryFileChange}
        />
        <label htmlFor="secondary-file-upload">
          <Button
            variant="outlined"
            component="span"
            fullWidth
            startIcon={<UploadIcon />}
          >
            Select Secondary CSV File
          </Button>
        </label>
        {secondaryFile && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            Selected secondary file: {secondaryFile.name}
          </Typography>
        )}
      </Box>
      {previewData && (
        <TableContainer component={Paper} sx={{ mb: 2, maxHeight: 300 }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {previewData.headers.map((header, index) => (
                  <TableCell key={index}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {previewData.data.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex}>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={uploading || !mainFile || !secondaryFile}
        fullWidth
      >
        {uploading ? <CircularProgress size={24} /> : 'Upload and Process'}
      </Button>
      {uploadStatus && (
        <Alert severity={uploadStatus.severity} sx={{ mt: 2 }}>
          {uploadStatus.message}
        </Alert>
      )}
    </Paper>
  );
};

export default CSVUploadComponent;
