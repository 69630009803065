import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { format, parseISO } from 'date-fns';
import {
  DataGridPremium,
  GridToolbar,
  GridToolbarQuickFilter,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import {
  Box,
  Divider,
  Stack,
  Paper,
  Typography,
  Chip,
  Snackbar,
  Alert,
} from '@mui/material';
import dayjs from 'dayjs';
import CalendarDateRangePicker from 'components/DateRangePicker';
import { API_BASE_URL } from 'config';
import axios from 'axios';

const RepairOrderTable = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [dateRange, setDateRange] = useState([dayjs().startOf('day'), dayjs()]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    _id: false,
    OpenDate: false,
    BookedDate: false,
    PostedDate: false,
  });

  const apiRef = useGridApiRef();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${API_BASE_URL}/api/service-ro-closed`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            dealerID: user.dealerid,
            startDate: dateRange[0].format('YYYY-MM-DD'),
            endDate: dateRange[1].format('YYYY-MM-DD'),
          },
        },
      );
      const responseData = response.data;
      // Flatten the vehicle object to display it in the grid
      const processedData = responseData.map((row) => {
        let vehicle = row.vehicle;
        return {
          ...row,
          year: vehicle?.year || '',
          make: vehicle?.makeDesc || '',
          model: vehicle?.modelDesc || '',
          vehId: vehicle?.vehId || '',
        };
      });
      setData(processedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [dateRange, getAccessTokenSilently, user.dealerid]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const columns = useMemo(
    () => [
      {
        field: 'ClosedDate',
        headerName: 'Closed Date',
        width: 125,
        sortable: true,
        renderCell: (params) => {
          const originalDate = params.row.ClosedDate;
          if (!originalDate) return null;
          const parsedDate = parseISO(originalDate.split('T')[0]);
          const formattedDate = format(parsedDate, 'MM/dd/yyyy');
          return <span>{formattedDate}</span>;
        },
      },
      {
        field: 'OpenDate',
        headerName: 'Open Date',
        width: 125,
        sortable: true,
        renderCell: (params) => {
          const originalDate = params.row.OpenDate;
          if (!originalDate) return null;
          const parsedDate = parseISO(originalDate.split('T')[0]);
          const formattedDate = format(parsedDate, 'MM/dd/yyyy');
          return <span>{formattedDate}</span>;
        },
      },
      {
        field: 'BookedDate',
        headerName: 'Booked Date',
        width: 125,
        sortable: true,
        renderCell: (params) => {
          const originalDate = params.row.BookedDate;
          if (!originalDate) return null;
          const parsedDate = parseISO(originalDate.split('T')[0]);
          const formattedDate = format(parsedDate, 'MM/dd/yyyy');
          return <span>{formattedDate}</span>;
        },
      },
      {
        field: 'PostedDate',
        headerName: 'Posted Date',
        width: 125,
        sortable: true,
        renderCell: (params) => {
          const originalDate = params.row.PostedDate;
          if (!originalDate) return null;
          const parsedDate = parseISO(originalDate.split('T')[0]);
          const formattedDate = format(parsedDate, 'MM/dd/yyyy');
          return <span>{formattedDate}</span>;
        },
      },
      {
        field: 'RONumber',
        headerName: 'RO Number',
        width: 100,
        groupable: false,
      },
      { field: 'ServiceAdvisor', headerName: 'Advisor', width: 120 },
      {
        field: 'Cashier',
        headerName: 'Cashier',
        width: 120,
      },
      {
        field: 'BookerNo',
        headerName: 'Booker',
        width: 75,
      },
      {
        field: 'Labor',
        headerName: 'CP Labor',
        width: 120,
        type: 'number',
        valueGetter: (value, row) => {
          const pay = row.totLaborSale;
          return pay ? pay[0] : null;
        },
      },
      {
        field: 'Parts',
        headerName: 'CP Parts',
        width: 120,
        type: 'number',
        valueGetter: (value, row) => {
          const pay = row.totPartsSale;
          return pay ? pay[0] : null;
        },
      },
      {
        field: 'Customer',
        headerName: 'CP Total',
        width: 120,
        type: 'number',
        valueGetter: (value, row) => {
          const pay = row.totRoSale;
          return pay ? pay[0] : null;
        },
      },
      {
        field: 'Warranty',
        headerName: 'Warranty',
        width: 120,
        type: 'number',
        valueGetter: (value, row) => {
          const pay = row.totRoSale;
          return pay ? pay[1] : null;
        },
      },
      {
        field: 'Internal',
        headerName: 'Internal',
        width: 120,
        type: 'number',
        valueGetter: (value, row) => {
          const pay = row.totRoSale;
          return pay ? pay[2] : null;
        },
      },
      {
        field: 'totRoSale',
        headerName: 'Total',
        width: 120,
        type: 'number',
        valueGetter: (value, row) => {
          const pay = row.totRoSale;
          if (!pay) return null;
          // Convert each item in the array to a number and sum them, then round to 2 decimal places
          const total = pay
            .map((x) => Number(x))
            .reduce((a, b) => a + b, 0)
            .toFixed(2);
          return total;
        },
      },
      {
        field: 'TotalHours',
        headerName: 'Total Hours',
        width: 120,
        type: 'number',
        valueGetter: (value, row) => {
          const totalHours = row.hrsSoldHours;
          if (!totalHours) return null;
          // Convert each item in the array to a number and sum them, then round to 2 decimal places
          const total = totalHours
            .map((x) => Number(x))
            .reduce((a, b) => a + b, 0)
            .toFixed(2);
          return total;
        },
      },
      {
        field: 'Tech_Hours',
        headerName: 'Total Hours by Tech',
        minWidth: 300,
        flex: 1,
        filterable: true,
        valueGetter: (value, row) => {
          const techNo = row.hrsTechNo;
          const techHours = row.hrsSoldHours;

          if (!techNo || !techHours) return '';

          const techHoursData = techNo.map((x, i) => ({
            name: x,
            hours: Number(techHours[i]),
          }));

          const totalHours = techHoursData.reduce((acc, cur) => {
            const existing = acc.find((x) => x.name === cur.name);
            if (existing) {
              existing.hours += cur.hours;
            } else {
              acc.push({ name: cur.name, hours: cur.hours });
            }
            return acc;
          }, []);

          // Return a string representation for filtering
          return totalHours
            .map((x) => `${x.name}: ${x.hours.toFixed(2)}`)
            .join(', ');
        },
        renderCell: (params) => {
          const techNo = params.row.hrsTechNo;
          const techHours = params.row.hrsSoldHours;

          if (!techNo || !techHours) return null;

          const techHoursData = techNo.map((x, i) => ({
            name: x,
            hours: Number(techHours[i]),
          }));

          const totalHours = techHoursData.reduce((acc, cur) => {
            const existing = acc.find((x) => x.name === cur.name);
            if (existing) {
              existing.hours += cur.hours;
            } else {
              acc.push({ name: cur.name, hours: cur.hours });
            }
            return acc;
          }, []);

          return (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                gap: 1,
                alignItems: 'center',
                justifyContent: 'flex-start',
                height: '100%',
                width: '100%',
                padding: '4px',
              }}
            >
              {totalHours.map((x) => (
                <Chip
                  key={x.name}
                  label={`${x.name}: ${x.hours.toFixed(2)}`}
                  size="small"
                  variant="outlined"
                />
              ))}
            </Box>
          );
        },
        filterOperators: [
          {
            label: 'Contains',
            value: 'contains',
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.value) {
                return null;
              }
              return (params) => {
                const cellValue = params.value || '';
                return cellValue
                  .toLowerCase()
                  .includes(filterItem.value.toLowerCase());
              };
            },
          },
        ],
      },
    ],
    [],
  );

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    sorting: {
      sortModel: [{ field: 'ClosedDate', sort: 'desc' }],
    },
    pagination: { paginationModel: { pageSize: 50 } },
    density: 'compact',
  });

  return (
    isAuthenticated && (
      <Paper
        sx={{
          height: 'calc(100vh - 164px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 2,
          }}
        >
          <Typography variant="h6">Closed Repair Orders</Typography>
          <CalendarDateRangePicker
            dateRange={dateRange}
            onDateRangeChange={handleDateRangeChange}
          />
        </Box>
        <DataGridPremium
          pagination
          pageSizeOptions={[50, 100, 200, 500, 1000]}
          apiRef={apiRef}
          sx={{
            borderRadius: 0,
            border: 'none',
          }}
          rows={data}
          columns={columns}
          loading={isLoading}
          initialState={initialState}
          getRowId={(row) => row._id}
          slots={{
            toolbar: () => (
              <>
                <Stack direction="row" spacing={2} alignItems="center" p={2}>
                  <GridToolbar />
                  <Box sx={{ flexGrow: 1 }} />
                  <GridToolbarQuickFilter />
                </Stack>
                <Divider />
              </>
            ),
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          disableRowSelectionOnClick
        />
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
        >
          <Alert
            onClose={() => setError(null)}
            severity="error"
            sx={{ width: '100%' }}
          >
            {error}
          </Alert>
        </Snackbar>
      </Paper>
    )
  );
};

export default RepairOrderTable;
