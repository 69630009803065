import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Select,
  MenuItem,
  Stack,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Typography,
} from '@mui/material';
import { DataGridPremium, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

import { API_BASE_URL } from 'config';

const Models = () => {
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);
  const [makes, setMakes] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMake, setSelectedMake] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [loading, setLoading] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedModelToDelete, setSelectedModelToDelete] = useState(null);
  const [newModel, setNewModel] = useState({
    year: '',
    make: '',
    model: '',
    trim: '',
    model_no: '',
  });

  useEffect(() => {
    fetchYears();
  }, []);

  useEffect(() => {
    if (selectedYear) {
      fetchMakes();
    }
  }, [selectedYear]);

  useEffect(() => {
    if (selectedYear && selectedMake) {
      fetchModels();
    }
  }, [selectedYear, selectedMake]);

  const fetchYears = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/models/details`);
      setYears(response.data.years);
    } catch (error) {
      console.error('Error fetching years:', error);
    }
  };

  const fetchMakes = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/models/details?year=${selectedYear}`);
      setMakes(response.data.makes);
    } catch (error) {
      console.error('Error fetching makes:', error);
    }
  };

  const fetchModels = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/models/search?years=${selectedYear}&makes=${selectedMake}`);
      setModels(response.data);
    } catch (error) {
      console.error('Error fetching models:', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { field: 'year', headerName: 'Year', flex: 1 },
    { field: 'make', headerName: 'Make', flex: 1 },
    { field: 'model', headerName: 'Model', flex: 1 },
    { field: 'trim', headerName: 'Trim', flex: 2 },
    { field: 'model_no', headerName: 'Model Number', flex: 1, align: 'center', headerAlign: 'center' },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleEditClick(params.row)} color="primary">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteClick(params.row)} color="secondary">
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setSelectedMake('');
    setSelectedModel('');
  };

  const handleMakeChange = (event) => {
    setSelectedMake(event.target.value);
    setSelectedModel('');
  };

  const handleAddDialogOpen = () => setOpenAddDialog(true);
  const handleAddDialogClose = () => setOpenAddDialog(false);
  const handleEditDialogOpen = () => setOpenEditDialog(true);
  const handleEditDialogClose = () => setOpenEditDialog(false);
  const handleDeleteDialogOpen = () => setOpenDeleteDialog(true);
  const handleDeleteDialogClose = () => setOpenDeleteDialog(false);

  const handleEditClick = (model) => {
    setSelectedModel(model);
    handleEditDialogOpen();
  };

  const handleDeleteClick = (model) => {
    setSelectedModelToDelete(model);
    handleDeleteDialogOpen();
  };

  const handleAddModel = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/models/create`, newModel);
      setModels([...models, response.data]);
      handleAddDialogClose();
      setNewModel({ year: '', make: '', model: '', trim: '', model_no: '' });
    } catch (error) {
      console.error('Error adding model:', error);
    }
  };

  const handleUpdateModel = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/api/models/${selectedModel._id}`, selectedModel);
      setModels(models.map((model) => (model._id === response.data._id ? response.data : model)));
      handleEditDialogClose();
    } catch (error) {
      console.error('Error updating model:', error);
    }
  };

  const handleDeleteModel = async () => {
    try {
      await axios.delete(`${API_BASE_URL}/api/models/${selectedModelToDelete._id}`);
      setModels(models.filter((model) => model._id !== selectedModelToDelete._id));
      handleDeleteDialogClose();
    } catch (error) {
      console.error('Error deleting model:', error);
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box display="flex" alignItems="center" p={2} width="100%">
          <Stack spacing={2} direction="row">
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="select-year">Year</InputLabel>
              <Select
                labelId="select-year"
                value={selectedYear}
                onChange={handleYearChange}
                label="Year"
              >
                <MenuItem value="">
                  <em>All Years</em>
                </MenuItem>
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="select-make">Make</InputLabel>
              <Select
                labelId="select-make"
                value={selectedMake}
                onChange={handleMakeChange}
                label="Make"
                disabled={!selectedYear}
              >
                <MenuItem value="">
                  <em>All Makes</em>
                </MenuItem>
                {makes.map((make) => (
                  <MenuItem key={make} value={make}>
                    {make}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddDialogOpen}>
              Add Model
            </Button>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <GridToolbarFilterButton />
          <GridToolbarExport />
          <GridToolbarQuickFilter />
        </Box>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <div style={{ height: 400, width: '100%' }}>
        <DataGridPremium
          rows={models}
          columns={columns}
          getRowId={(row) => row._id}
          loading={loading}
          slots={{
            toolbar: CustomToolbar,
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          pagination
          checkboxSelection
          disableRowSelectionOnClick
          autoHeight
        />

        {/* Add Dialog */}
        <Dialog open={openAddDialog} onClose={handleAddDialogClose} fullWidth maxWidth="sm">
          <DialogTitle>Add New Model</DialogTitle>
          <DialogContent>
            <form>
              <Stack direction="column" spacing={2}>
                <TextField
                  label="Year"
                  variant="outlined"
                  fullWidth
                  value={newModel.year}
                  onChange={(e) => setNewModel({ ...newModel, year: e.target.value })}
                />
                <TextField
                  label="Make"
                  variant="outlined"
                  fullWidth
                  value={newModel.make}
                  onChange={(e) => setNewModel({ ...newModel, make: e.target.value })}
                />
                <TextField
                  label="Model"
                  variant="outlined"
                  fullWidth
                  value={newModel.model}
                  onChange={(e) => setNewModel({ ...newModel, model: e.target.value })}
                />
                <TextField
                  label="Trim"
                  variant="outlined"
                  fullWidth
                  value={newModel.trim}
                  onChange={(e) => setNewModel({ ...newModel, trim: e.target.value })}
                />
                <TextField
                  label="Model Number"
                  variant="outlined"
                  fullWidth
                  value={newModel.model_no}
                  onChange={(e) => setNewModel({ ...newModel, model_no: e.target.value })}
                />
              </Stack>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddModel} color="primary" variant="contained">
              Add
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Dialog */}
        <Dialog open={openEditDialog} onClose={handleEditDialogClose} fullWidth maxWidth="sm">
          <DialogTitle>Edit Model</DialogTitle>
          <DialogContent>
            <form>
              <Stack direction="column" spacing={2}>
                <TextField
                  label="Year"
                  variant="outlined"
                  fullWidth
                  value={selectedModel?.year || ''}
                  onChange={(e) => setSelectedModel({ ...selectedModel, year: e.target.value })}
                />
                <TextField
                  label="Make"
                  variant="outlined"
                  fullWidth
                  value={selectedModel?.make || ''}
                  onChange={(e) => setSelectedModel({ ...selectedModel, make: e.target.value })}
                />
                <TextField
                  label="Model"
                  variant="outlined"
                  fullWidth
                  value={selectedModel?.model || ''}
                  onChange={(e) => setSelectedModel({ ...selectedModel, model: e.target.value })}
                />
                <TextField
                  label="Trim"
                  variant="outlined"
                  fullWidth
                  value={selectedModel?.trim || ''}
                  onChange={(e) => setSelectedModel({ ...selectedModel, trim: e.target.value })}
                />
                <TextField
                  label="Model Number"
                  variant="outlined"
                  fullWidth
                  value={selectedModel?.model_no || ''}
                  onChange={(e) => setSelectedModel({ ...selectedModel, model_no: e.target.value })}
                />
              </Stack>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleUpdateModel} color="primary" variant="contained">
              Update
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete Dialog */}
        <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose} fullWidth maxWidth="sm">
          <DialogTitle>Delete Model</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this model?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteModel} color="secondary" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Models;