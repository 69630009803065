import React, { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchInput = ({ onSearch, initialSearchValue }) => {
  const [searchValue, setSearchValue] = useState(initialSearchValue || '');

  // Debounce the search input to minimize API calls
  const debouncedSearch = useMemo(
    () => debounce(onSearch, 600), // Adjust the delay as needed
    [onSearch]
  );

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(searchValue);
  };

  // Sync local search value with external changes in initialSearchValue
  useEffect(() => {
    setSearchValue(initialSearchValue);
  }, [initialSearchValue]);

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        placeholder="Search users..."
        variant="outlined"
        size="small"
        value={searchValue}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        name="search"
      />
    </form>
  );
};

export default SearchInput;
