export const accountDescriptions = [
  { Account: '4010', Description: 'SLS N/T SIENNA RTL' },
  { Account: '4012', Description: 'SLS N/T SIENNA LSE' },
  { Account: '4020', Description: 'SLS N/C COROLLA RTL' },
  { Account: '4022', Description: 'SLS N/C COROLLA LSE' },
  { Account: '4034', Description: 'SLS N/T RAV4 PRIME RTL' },
  { Account: '4036', Description: 'SLS N/T RAV4 PRIME LSE' },
  { Account: '4050', Description: 'SLS N/C CAMRY RTL' },
  { Account: '4052', Description: 'SLS N/C CAMRY LSE' },
  { Account: '4065', Description: 'SLS N/T RAV4 RTL' },
  { Account: '4067', Description: 'SLS N/T RAV4 LSE' },
  { Account: '4070', Description: 'SLS N/T 4RUNNER RTL' },
  { Account: '4072', Description: 'SLS N/T 4RUNNER LSE' },
  { Account: '4075', Description: 'SLS N/T HIGHLANDER RTL' },
  { Account: '4077', Description: 'SLS N/T HIGHLANDER LSE' },
  { Account: '4080', Description: 'SLS N/T SEQUOIA RTL' },
  { Account: '4095', Description: 'SLS N/C PRIUS RTL' },
  { Account: '4097', Description: 'SLS N/C PRIUS LSE' },
  { Account: '4105', Description: 'SLS N/C PRIUS PRIME RTL' },
  { Account: '4107', Description: 'SLS N/C PRIUS PRIME LSE' },
  { Account: '4114', Description: 'SLS N/T COROLLA CROSS RTL' },
  { Account: '4116', Description: 'SLS N/T COROLLA CROSS LSE' },
  { Account: '4134', Description: 'N/C CROWN RTL' },
  { Account: '4136', Description: 'SLS N/C CROWN LSE' },
  { Account: '4144', Description: 'SLS N/T bZ4X RTL' },
  { Account: '4146', Description: 'SLS N/T bZ4X LSE' },
  { Account: '4147', Description: 'SLS N/T HIGHLANDER GRAND RTL' },
  { Account: '4148', Description: 'SLS N/T HIGHLANDER GRAND LSE' },
  { Account: '4025', Description: 'SLS N/C GR SUPRA RTL' },
  { Account: '4027', Description: 'SLS N/C GR SUPRA LSE' },
  { Account: '4030', Description: 'SLS N/T VENZA RTL' },
  { Account: '4032', Description: 'SLS N/T VENZA LSE' },
  { Account: '4100', Description: 'SLS N/T TACOMA 2WD RTL' },
  { Account: '4102', Description: 'SLS N/T TACOMA 2WD LSE' },
  { Account: '4110', Description: 'SLS N/T TACOMA 4WD RTL' },
  { Account: '4112', Description: 'SLS N/T TACOMA 4WD LSE' },
  { Account: '4115', Description: 'SLS N/C GR 86 RTL' },
  { Account: '4117', Description: 'SLS N/C GR 86 LSE' },
  { Account: '4122', Description: 'SLS N/T TUNDRA 2WD LSE' },
  { Account: '4130', Description: 'SLS N/T TUNDRA 4WD RTL' },
  { Account: '4132', Description: 'SLS N/T TUNDRA 4WD LSE' },
  { Account: '4137', Description: 'SLS N/C GR COROLLA RTL' },
  { Account: '4270', Description: 'SLS SERVICE CONTRACTS-NEW TOY' },
  { Account: '4272', Description: 'SLS PREPAID MAINT-NEW TOY' },
  { Account: '4275', Description: 'SLS T/WL PROT W/WO KEY-NEW TOY' },
  { Account: '4292', Description: 'SLS GAP-NEW TOY' },
  { Account: '4374', Description: 'SLS EXCESS WEAR & USE-NEW TOY' },
  { Account: '4339', Description: 'SLS USD VEHICLE WHSL-OTHER' },
  { Account: '4340', Description: 'SLS USD VEHICLE WHSL-TOYOTA' },
  { Account: '4359', Description: 'SLS TOY CERTIFIED USD CAR-RTL' },
  { Account: '4361', Description: 'SLS TOY CERTIFIED USD TRK-RTL' },
  { Account: '4364', Description: 'SLS TOY NON-CERT USD CAR-RTL' },
  { Account: '4365', Description: 'SLS TOY NON-CERT USD TRK-RTL' },
  { Account: '4384', Description: 'SLS USD CAR OTHER RETAIL' },
  { Account: '4385', Description: 'SLS USD TRK OTHER RETAIL' },
  { Account: '4350', Description: 'SLS AFTERMARKET-NON-CERT USD' },
  { Account: '4370', Description: 'SLS SERV CONTRACT-NON-CERT USD' },
  { Account: '4675', Description: 'SLS T/WL PRT W/WO KEY-NCRT USD' },
  { Account: '4172', Description: 'SLS PREPAID MAINT-CERT USD' },
  { Account: '4792', Description: 'SLS GAP-CERTIFIED USD' },
  { Account: '4970', Description: 'SLS SERVICE CONTRACTS-CERT USD' },
  { Account: '4400', Description: 'SLS CUS MECH MAINT LBR-TOY RTL' },
  { Account: '4420', Description: 'SLS WARR MECH LBR-TOY' },
  { Account: '4430', Description: 'SLS INT MECH MAINT LBR-TOY RTL' },
  { Account: '4455', Description: 'SLS TOY CARE MECHANICAL LABOR' },
  { Account: '4466', Description: 'SALES CERTIFIED' },
  { Account: '4460', Description: 'SLS SUBLET REPAIRS MECH' },
  { Account: '4468', Description: 'SLS TOY PPM SRV DRV CONTRACTS' },
  { Account: '4630', Description: 'SLS INT DETAIL' },
  { Account: '4700', Description: 'SLS PRT RO CUST MECH-TOY RTL' },
  { Account: '4720', Description: 'SLS PRT WARR-TOYOTA' },
  { Account: '4725', Description: 'SLS PRT RO CUST PAY TIRES-TOY' },
  { Account: '4730', Description: 'SLS PRT RO INTERNAL RTL-TOY' },
  { Account: '4734', Description: 'SLS PRT TIRES INTERNAL-TOYOTA' },
  { Account: '4740', Description: 'SLS PRT COUNTER RTL-TOY' },
  { Account: '4750', Description: 'SLS PRT WHOLESALE MECH-TOY' },
  { Account: '4755', Description: 'SLS TOYCARE MECHANICAL PRT' },
  { Account: '4765', Description: 'SLS ACCESSORY RO CUST-TOY' },
  { Account: '4775', Description: 'SLS ACCESSORY RO WARR-TOY' },
  { Account: '4785', Description: 'SLS ACCESS INTERNAL-TOY' },
  { Account: '4770', Description: 'SLS GAS, OIL AND GREASE' },
  { Account: '6010', Description: 'CST N/T SIENNA RTL' },
  { Account: '6012', Description: 'CST N/T SIENNA LSE' },
  { Account: '6020', Description: 'CST N/C COROLLA RTL' },
  { Account: '6022', Description: 'CST N/C COROLLA LSE' },
  { Account: '6034', Description: 'CST N/T RAV4 PRIME RTL' },
  { Account: '6036', Description: 'CST N/T RAV4 PRIME LSE' },
  { Account: '6045', Description: 'CST N/C MIRAI RTL' },
  { Account: '6050', Description: 'CST N/C CAMRY RTL' },
  { Account: '6052', Description: 'CST N/C CAMRY LSE' },
  { Account: '6065', Description: 'CST N/T RAV4 RTL' },
  { Account: '6067', Description: 'CST N/T RAV4 LSE' },
  { Account: '6070', Description: 'CST N/T 4RUNNER RTL' },
  { Account: '6072', Description: 'CST N/T 4RUNNER LSE' },
  { Account: '6075', Description: 'CST N/T HIGHLANDER RTL' },
  { Account: '6077', Description: 'CST N/T HIGHLANDER LSE' },
  { Account: '6080', Description: 'CST N/T SEQUOIA RTL' },
  { Account: '6095', Description: 'CST N/C PRIUS RTL' },
  { Account: '6097', Description: 'CST N/C PRIUS LSE' },
  { Account: '6105', Description: 'CST N/C PRIUS PRIME RTL' },
  { Account: '6107', Description: 'CST N/C PRIUS PRIME LSE' },
  { Account: '6114', Description: 'CST N/T COROLLA CROSS RTL' },
  { Account: '6116', Description: 'CST N/T COROLLA CROSS LSE' },
  { Account: '6134', Description: 'CST N/C CROWN RTL' },
  { Account: '6136', Description: 'CST N/C CROWN LSE' },
  { Account: '6144', Description: 'CST N/T bZ4X RTL' },
  { Account: '6146', Description: 'CST N/T bZ4X LSE' },
  { Account: '6147', Description: 'CST N/T HIGHLANDER GRAND RTL' },
  { Account: '6148', Description: 'CST N/T HIGHLANDER GRAND LSE' },
  { Account: '6025', Description: 'CST N/C GR SUPRA RTL' },
  { Account: '6027', Description: 'CST N/C GR SUPRA LSE' },
  { Account: '6030', Description: 'CST N/T VENZA RTL' },
  { Account: '6032', Description: 'CST N/T VENZA LSE' },
  { Account: '6100', Description: 'CST N/T TACOMA 2WD RTL' },
  { Account: '6102', Description: 'CST N/T TACOMA 2WD LSE' },
  { Account: '6110', Description: 'CST N/T TACOMA 4WD RTL' },
  { Account: '6112', Description: 'CST N/T TACOMA 4WD LSE' },
  { Account: '6115', Description: 'CST N/C GR 86 RTL' },
  { Account: '6117', Description: 'CST N/C GR 86 LSE' },
  { Account: '6120', Description: 'CST N/T TUNDRA 2WD RTL' },
  { Account: '6122', Description: 'CST N/T TUNDRA 2WD LSE' },
  { Account: '6130', Description: 'CST N/T TUNDRA 4WD RTL' },
  { Account: '6132', Description: 'CST N/T TUNDRA 4WD LSE' },
  { Account: '6137', Description: 'CST N/C GR COROLLA' },
  { Account: '6270', Description: 'CST SERVICE CONTRACTS-NEW TOY' },
  { Account: '6272', Description: 'CST PREPAID MAINT-NEW TOY' },
  { Account: '6275', Description: 'CST T/WL PROT W/WO KEY-NEW TOY' },
  { Account: '6280', Description: 'FINANCE INCOME-NEW TOY' },
  { Account: '6281', Description: 'FINANCE INC CHARGEBACK-NEW TOY' },
  { Account: '6292', Description: 'CST GAP-NEW TOY' },
  { Account: '6374', Description: 'CST EXCESS WEAR & USE-NEW TOY' },
  { Account: '6339', Description: 'CST USD VEHICLE WHSL-OTHER' },
  { Account: '6340', Description: 'CST USD VEHICLE WHSL-TOYOTA' },
  { Account: '6359', Description: 'CST TOY CERTIFIED USD CAR-RTL' },
  { Account: '6361', Description: 'CST TOY CERTIFIED USD TRK-RTL' },
  { Account: '6364', Description: 'CST TOY NON-CERT USD CAR-RTL' },
  { Account: '6365', Description: 'CST TOY NON-CERT USD TRK-RTL' },
  { Account: '6384', Description: 'CST USD CAR OTHER RETAIL' },
  { Account: '6385', Description: 'CST USD TRK OTHER RETAIL' },
  { Account: '6350', Description: 'CST AFTERMARKET-NON-CERT USD' },
  { Account: '6370', Description: 'CST SERV CONTRACT-NON-CERT USD' },
  { Account: '6380', Description: 'FINANCE INCOME-NON-CERT USD' },
  { Account: '6675', Description: 'CST T/WL PRT W/WO KEY-NCRT USD' },
  { Account: '6172', Description: 'CST PREPAID MAINT-CERT USD' },
  { Account: '6792', Description: 'CST GAP-CERTIFIED USD' },
  { Account: '6970', Description: 'CST SERVICE CONTRACTS-CERT USD' },
  { Account: '6399', Description: 'REPO LOSSES - U/V' },
  { Account: '6400', Description: 'CST CUS MECH MAINT LBR-TOY RTL' },
  { Account: '6420', Description: 'CST WARR MECH LBR-TOY' },
  { Account: '6430', Description: 'CST INT MECH MAINT LBR-TOY RTL' },
  { Account: '6455', Description: 'CST TOY CARE MECHANICAL LABOR' },
  { Account: '6450', Description: 'CST UNAPPLIED LABOR' },
  { Account: '6460', Description: 'CST SUBLET REPAIRS MECH' },
  { Account: '6468', Description: 'CST TOY PPM SRV DRV CONTRACTS' },
  { Account: '6700', Description: 'CST PRT RO CUST MECH-TOY RTL' },
  { Account: '6720', Description: 'CST PRT WARR-TOYOTA' },
  { Account: '6725', Description: 'CST PRT RO CUST PAY TIRES-TOY' },
  { Account: '6730', Description: 'CST PRT RO INTERNAL RTL-TOY' },
  { Account: '6734', Description: 'CST PRT TIRES INTERNAL-TOYOTA' },
  { Account: '6740', Description: 'CST PRT COUNTER RTL-TOY' },
  { Account: '6750', Description: 'CST PRT WHOLESALE MECH-TOY' },
  { Account: '6755', Description: 'CST TOYCARE MECHANICAL PRT' },
  { Account: '6765', Description: 'CST ACCESSORY RO CUST-TOY' },
  { Account: '6775', Description: 'CST ACCESSORY RO WARR-TOY' },
  { Account: '6785', Description: 'CST ACCESS INTERNAL-TOY' },
  { Account: '6770', Description: 'CST GAS, OIL AND GREASE' },
  { Account: '6799', Description: 'P&A PURCH DISCOUNT EARNED' },
  { Account: '7041', Description: 'ADVERTISING - NEW' },
  { Account: '7042', Description: 'ADVERTISING - USED' },
  { Account: '7045', Description: 'ADVERTISING - PARTS' },
  { Account: '7243', Description: 'SUPERVISION SALARIES - SERVICE' },
  { Account: '7245', Description: 'SUPERVISION SALARIES - PARTS' },
  { Account: '7043', Description: 'ADVERTISING - SERVICE' },
  { Account: '7001', Description: 'NEW SALES COMPENSATION' },
  { Account: '7002', Description: 'USD SALES COMPENSATION' },
  { Account: '7003', Description: 'SRV SALES COMPENSATION' },
  { Account: '7005', Description: 'P&A SALES COMPENSATION' },
  { Account: '7011', Description: 'NEW SUPRV COMPENSATION' },
  { Account: '7012', Description: 'USD SUPRV COMPENSATION' },
  { Account: '7013', Description: 'SRV SUPRV COMPENSATION' },
  { Account: '7015', Description: 'P&A SUPRV COMPENSATION' },
  { Account: '7021', Description: 'NEW DELIVERY EXP' },
  { Account: '7031', Description: 'NEW FIN, INS & S.C. COMM' },
  { Account: '7032', Description: 'USD FIN, INS & S.C. COMM' },
  { Account: '7071', Description: 'NEW ADVERTISING MEDIA' },
  { Account: '7072', Description: 'USD ADVERTISING MEDIA' },
  { Account: '7081', Description: 'NEW ADVERTISING DIGITAL' },
  { Account: '7082', Description: 'USD ADVERTISING DIGITAL' },
  { Account: '7083', Description: 'SRV ADVERTISING DIGITAL' },
  { Account: '7085', Description: 'P&A ADVERTISING DIGITAL' },
  { Account: '7050', Description: 'INTEREST FLOORING REBATE' },
  { Account: '7051', Description: 'NEW INTEREST FLOOR PLAN' },
  { Account: '7111', Description: 'NEW POLICY ADJ' },
  { Account: '7112', Description: 'USD POLICY ADJ' },
  { Account: '7113', Description: 'SRV POLICY ADJ' },
  { Account: '7115', Description: 'P&A POLICY ADJ' },
  { Account: '7125', Description: 'P&A CLAIMS ADJ' },
  { Account: '7126', Description: 'DETAIL CLAIMS ADJ' },
  { Account: '7141', Description: 'NEW DEMO & VEH EXP' },
  { Account: '7142', Description: 'USD DEMO & VEH EXP' },
  { Account: '7143', Description: 'SRV DEMO & VEH EXP' },
  { Account: '7145', Description: 'P&A DEMO & VEH EXP' },
  { Account: '7161', Description: 'NEW PERSONNEL TRAINING' },
  { Account: '7163', Description: 'SRV PERSONNEL TRAINING' },
  { Account: '7171', Description: 'NEW OUTSIDE SERVICES' },
  { Account: '7172', Description: 'USD OUTSIDE SERVICES' },
  { Account: '7173', Description: 'SRV OUTSIDE SERVICES' },
  { Account: '7175', Description: 'P&A OUTSIDE SERVICES' },
  { Account: '7185', Description: 'P&A FREIGHT EXP' },
  { Account: '7191', Description: 'NEW SUPPLIES & SML TOOLS' },
  { Account: '7192', Description: 'USD SUPPLIES & SML TOOLS' },
  { Account: '7193', Description: 'SRV SUPPLIES & SML TOOLS' },
  { Account: '7195', Description: 'P&A SUPPLIES & SML TOOLS' },
  { Account: '7203', Description: 'SRV LAUNDRY & UNIFORMS' },
  { Account: '7205', Description: 'P&A LAUNDRY & UNIFORMS' },
  { Account: '7211', Description: 'NEW DEPR EQUIP & VEH' },
  { Account: '7213', Description: 'SRV DEPR EQUIP & VEH' },
  { Account: '7215', Description: 'P&A DEPR EQUIP & VEH' },
  { Account: '7221', Description: 'NEW MAINT & REPAIR EQUIP' },
  { Account: '7222', Description: 'USD MAINT & REPAIR EQUIP' },
  { Account: '7223', Description: 'SRV MAINT & REPAIR EQUIP' },
  { Account: '7225', Description: 'P&A MAINT & REPAIR EQUIP' },
  { Account: '7251', Description: 'NEW SALARIES & WAGES' },
  { Account: '7253', Description: 'SRV SALARIES & WAGES' },
  { Account: '7255', Description: 'P&A SALARIES & WAGES' },
  { Account: '7261', Description: 'NEW CLERICAL SALARIES' },
  { Account: '7262', Description: 'USD CLERICAL SALARIES' },
  { Account: '7263', Description: 'SRV CLERICAL SALARIES' },
  { Account: '7265', Description: 'P&A CLERICAL SALARIES' },
  { Account: '7271', Description: 'NEW VAC&TIME OFF PROD PER' },
  { Account: '7341', Description: 'NEW PROPERTY TAX' },
  { Account: '7342', Description: 'USD PROPERTY TAX' },
  { Account: '7343', Description: 'SRV PROPERTY TAX' },
  { Account: '7345', Description: 'P&A PROPERTY TAX' },
  { Account: '7401', Description: 'NEW LSE EXP-RL EST/RENT EQUIV' },
  { Account: '7402', Description: 'USD LSE EXP-RL EST/RENT EQUIV' },
  { Account: '7403', Description: 'SRV LSE EXP-RL EST/RENT EQUIV' },
  { Account: '7405', Description: 'P&A LSE EXP-RL EST/RENT EQUIV' },
  { Account: '7631', Description: 'NEW LSE EXP-NON REAL ESTATE' },
  { Account: '7632', Description: 'USD LSE EXP-NON REAL ESTATE' },
  { Account: '7633', Description: 'SRV LSE EXP-NON REAL ESTATE' },
  { Account: '7635', Description: 'P&A LSE EXP-NON REAL ESTATE' },
  { Account: '7411', Description: 'NEW SALARIES/WAGE ADM/GEN' },
  { Account: '7412', Description: 'USD SALARIES/WAGE ADM/GEN' },
  { Account: '7413', Description: 'SRV SALARIES/WAGE ADM/GEN' },
  { Account: '7415', Description: 'P&A SALARIES/WAGE ADM/GEN' },
  { Account: '7421', Description: 'NEW OWNERS SALARIES' },
  { Account: '7422', Description: 'USD OWNERS SALARIES' },
  { Account: '7423', Description: 'SRV OWNERS SALARIES' },
  { Account: '7425', Description: 'P&A OWNERS SALARIES' },
  { Account: '7431', Description: 'NEW PAYROLL TAXES' },
  { Account: '7432', Description: 'USD PAYROLL TAXES' },
  { Account: '7433', Description: 'SRV PAYROLL TAXES' },
  { Account: '7435', Description: 'P&A PAYROLL TAXES' },
  { Account: '7441', Description: 'NEW EMPLOYEE BENEFITS' },
  { Account: '7442', Description: 'USD EMPLOYEE BENEFITS' },
  { Account: '7443', Description: 'SRV EMPLOYEE BENEFITS' },
  { Account: '7445', Description: 'P&A EMPLOYEE BENEFITS' },
  { Account: '7461', Description: 'NEW ADVERTISING GEN/INST' },
  { Account: '7462', Description: 'USD ADVERTISING GEN/INST' },
  { Account: '7463', Description: 'SRV ADVERTISING GEN/INST' },
  { Account: '7465', Description: 'P&A ADVERTISING GEN/INST' },
  { Account: '7471', Description: 'NEW STAT/OFFICE SUPPLIES' },
  { Account: '7472', Description: 'USD STAT/OFFICE SUPPLIES' },
  { Account: '7473', Description: 'SRV STAT/OFFICE SUPPLIES' },
  { Account: '7475', Description: 'P&A STAT/OFFICE SUPPLIES' },
  { Account: '7481', Description: 'NEW DATA PROCESSING SERV' },
  { Account: '7482', Description: 'USD DATA PROCESSING SERV' },
  { Account: '7483', Description: 'SRV DATA PROCESSING SERV' },
  { Account: '7485', Description: 'P&A DATA PROCESSING SERV' },
  { Account: '7491', Description: 'NEW OUTSIDE SERVICES' },
  { Account: '7492', Description: 'USD OUTSIDE SERVICES' },
  { Account: '7493', Description: 'SRV OUTSIDE SERVICES' },
  { Account: '7495', Description: 'P&A OUTSIDE SERVICES' },
  { Account: '7501', Description: 'NEW COMPANY VEH EXP' },
  { Account: '7505', Description: 'P&A COMPANY VEH EXP' },
  { Account: '7511', Description: 'NEW CONTRIBUTIONS' },
  { Account: '7512', Description: 'USD CONTRIBUTIONS' },
  { Account: '7513', Description: 'SRV CONTRIBUTIONS' },
  { Account: '7515', Description: 'P&A CONTRIBUTIONS' },
  { Account: '7521', Description: 'NEW DUES/SUBSCRIPTIONS' },
  { Account: '7522', Description: 'USD DUES/SUBSCRIPTIONS' },
  { Account: '7523', Description: 'SRV DUES/SUBSCRIPTIONS' },
  { Account: '7525', Description: 'P&A DUES/SUBSCRIPTIONS' },
  { Account: '7531', Description: 'NEW TELEPHONE' },
  { Account: '7532', Description: 'USD TELEPHONE' },
  { Account: '7533', Description: 'SRV TELEPHONE' },
  { Account: '7535', Description: 'P&A TELEPHONE' },
  { Account: '7541', Description: 'NEW LEGAL/AUDITING EXP' },
  { Account: '7542', Description: 'USD LEGAL/AUDITING EXP' },
  { Account: '7543', Description: 'SRV LEGAL/AUDITING EXP' },
  { Account: '7545', Description: 'P&A LEGAL/AUDITING EXP' },
  { Account: '7551', Description: 'NEW POSTAGE' },
  { Account: '7552', Description: 'USD POSTAGE' },
  { Account: '7553', Description: 'SRV POSTAGE' },
  { Account: '7555', Description: 'P&A POSTAGE' },
  { Account: '7331', Description: 'NEW MEETING MEALS' },
  { Account: '7332', Description: 'USD MEETING MEALS' },
  { Account: '7333', Description: 'SRV MEETING MEALS' },
  { Account: '7561', Description: 'NEW TRAVEL/ENTERTAINMENT' },
  { Account: '7562', Description: 'USD TRAVEL/ENTERTAINMENT' },
  { Account: '7563', Description: 'SRV TRAVEL/ENTERTAINMENT' },
  { Account: '7565', Description: 'P&A TRAVEL/ENTERTAINMENT' },
  { Account: '7571', Description: 'NEW HEAT LGT POWER WATER' },
  { Account: '7572', Description: 'USD HEAT LGT POWER WATER' },
  { Account: '7573', Description: 'SRV HEAT LGT POWER WATER' },
  { Account: '7575', Description: 'P&A HEAT LGT POWER WATER' },
  { Account: '7580', Description: 'DEPR/MAINT FURN/FIX' },
  { Account: '7581', Description: 'NEW DEPR/MAINT FURN/FIX' },
  { Account: '7591', Description: 'NEW INS OTHER THAN BLDGS' },
  { Account: '7592', Description: 'USD INS OTHER THAN BLDGS' },
  { Account: '7593', Description: 'SRV INS OTHER THAN BLDGS' },
  { Account: '7595', Description: 'P&A INS OTHER THAN BLDGS' },
  { Account: '7601', Description: 'NEW TAXES NOT REAL/INC' },
  { Account: '7602', Description: 'USD TAXES NOT REAL/INC' },
  { Account: '7603', Description: 'SRV TAXES NOT REAL/INC' },
  { Account: '7605', Description: 'P&A TAXES NOT REAL/INC' },
  { Account: '7611', Description: 'NEW INTEREST OTHER' },
  { Account: '7612', Description: 'USD INTEREST OTHER' },
  { Account: '7613', Description: 'SRV INTEREST OTHER' },
  { Account: '7615', Description: 'P&A INTEREST OTHER' },
  { Account: '8042', Description: 'DOC FEES' },
  { Account: '8010', Description: 'INTEREST EARNED' },
  { Account: '8040', Description: 'OTHER INCOME' },
  { Account: '8080', Description: 'MARKETING COVENANT BONUS' },
  { Account: '8084', Description: 'DELIVERY ALLOWANCE PGM (DAP)' },
  { Account: '8143', Description: 'SUPPLIES & SMALL TOOLS-DETAIL' },
  { Account: '8144', Description: 'LAUNDRY & UNIFORMS - DETAIL' },
  { Account: '8145', Description: 'SUPERVISION SALARIES-DETAIL' },
  { Account: '8146', Description: 'OTHER SALARIES & WAGES-DETAIL' },
  { Account: '8140', Description: 'OTHER DEDUCTIONS' },
  { Account: '8197', Description: 'CREDIT CARD EXPENSES' },
];
