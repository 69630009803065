import React, { useState, useEffect } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { uniqueId } from 'lodash';

const LenderScoreRangesTable = ({
  scoreRanges = [],
  handleEdit,
  handleDelete,
}) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const initialRows = scoreRanges.map((scoreRange) => ({
      _id: scoreRange._id,
      description: scoreRange.description,
      min: scoreRange.min,
      max: scoreRange.max,
    }));
    console.log(initialRows);
    setRows(initialRows);
  }, [scoreRanges]);

  const processRowUpdate = (newRow) => {
    console.log('ProcessingRow: ', newRow);
    const updatedRows = rows.map((row) =>
      row._id === newRow._id ? newRow : row,
    );
    handleEdit(newRow.id, newRow);
    setRows(updatedRows);
    return newRow;
  };

  const handleAddClick = () => {
    const newId = uniqueId();
    setRows([...rows, { _id: newId, description: '', min: '', max: '' }]);
  };

  const handleDeleteClick = (id) => {
    handleDelete(id);
    setRows(rows.filter((row) => row._id !== id));
  };

  const AddRow = (props) => (
    <GridToolbarContainer>
      <Button
        color="primary"
        size="small"
        startIcon={<AddIcon />}
        onClick={handleAddClick}
        variant="contained"
      >
        Add
      </Button>
    </GridToolbarContainer>
  );

  const columns = [
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      editable: true,
    },
    {
      field: 'min',
      headerName: 'Min',
      type: 'number',
      flex: 1,
      editable: true,
    },
    {
      field: 'max',
      headerName: 'Max',
      type: 'number',
      flex: 1,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteClick(params.id)}
        />,
      ],
    },
  ];

  return (
    <Box
      sx={{
        height: 400,
        width: '50%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row._id}
        processRowUpdate={processRowUpdate}
        initialState={{
          sorting: {
            sortModel: [{ field: 'max', sort: 'desc' }],
          },
        }}
        slots={{
          toolbar: AddRow,
        }}
        slotProps={{
          toolbar: { setRows },
        }}
      />
    </Box>
  );
};

export default LenderScoreRangesTable;
