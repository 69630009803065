import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import dayjs from 'dayjs';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import { Typography, Paper, Stack, Button } from '@mui/material';
import { purple, red, orange, yellow, green } from '@mui/material/colors';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const AgingReservationsChart = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${API_BASE_URL}/api/coversheets`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            dealerID: user?.dealerid,
          },
        });
        const data = response.data;
        setData(data.filter((item) => item.vehiclestatus === 'IN TRANSIT'));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [getAccessTokenSilently, user?.dealerid]);

  const categorizeByAge = (data) => {
    const categories = {
      '0-7 days': 0,
      '8-14 days': 0,
      '15-30 days': 0,
      '31-60 days': 0,
      '61+ days': 0,
    };

    const today = dayjs();
    data.forEach((item) => {
      const reservationDate = dayjs(item.date);
      const age = today.diff(reservationDate, 'day');

      if (age <= 7) {
        categories['0-7 days']++;
      } else if (age <= 14) {
        categories['8-14 days']++;
      } else if (age <= 30) {
        categories['15-30 days']++;
      } else if (age <= 60) {
        categories['31-60 days']++;
      } else {
        categories['61+ days']++;
      }
    });

    return Object.keys(categories).map((category) => ({
      category,
      count: categories[category],
    }));
  };

  const graphData = categorizeByAge(data);

  const getColor = (category) => {
    switch (category) {
      case '0-7 days':
        return green['A400'];
      case '8-14 days':
        return yellow['A400'];
      case '15-30 days':
        return orange['A400'];
      case '31-60 days':
        return red['A400'];
      case '61+ days':
        return purple['A400'];
      default:
        return '#8884d8';
    }
  };

  return (
    isAuthenticated && (
      <Paper sx={{ p: 2, height: '100%' }} elevation={0} variant="outlined">
        <Stack
          spacing={2}
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          alignItems={'center'}
          justifyContent={'space-between'}
          marginBottom={2}
        >
          <Typography variant="h6" sx={{ fontSize: '1rem' }}>
            Aging Deposits
          </Typography>
          <Button
            href="/log/reservations"
            size="small"
            aria-label="View all reservations"
            variant="text"
            endIcon={<OpenInNewIcon />}
          >
            View Deposits
          </Button>
        </Stack>
        {isLoading ? (
          <Typography variant="body1">Loading...</Typography>
        ) : (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={graphData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="category" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count">
                {graphData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={getColor(entry.category)} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      </Paper>
    )
  );
};

export default AgingReservationsChart;
