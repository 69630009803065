import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts';
import dayjs from 'dayjs';
import { Typography, CircularProgress, Stack, Paper } from '@mui/material';

const NEW_VEHICLES_GOAL = 250;
const USED_VEHICLES_GOAL = 150;

const calculateDailyGoal = (monthlyGoal) => {
  const totalDaysInMonth = dayjs().daysInMonth();
  return monthlyGoal / totalDaysInMonth;
};

const NewMonthlyCumulativeSalesChart = ({ data, loading }) => {
  const { isAuthenticated } = useAuth0();

  const cumulativeData = [];
  let cumulativeNew = 0;
  let cumulativeUsed = 0;
  let cumulativeNewGoal = 0;
  let cumulativeUsedGoal = 0;

  const dailyNewGoal = calculateDailyGoal(NEW_VEHICLES_GOAL);
  const dailyUsedGoal = calculateDailyGoal(USED_VEHICLES_GOAL);

  for (let day = 1; day <= dayjs().date(); day++) {
    const currentDate = dayjs()
      .startOf('month')
      .add(day - 1, 'day')
      .format('YYYY-MM-DD');
    const dailySales = data.filter(
      (sale) => dayjs(sale.ContractDate).format('YYYY-MM-DD') === currentDate,
    );

    const newSales = dailySales.filter(
      (sale) => sale.DealType === 'New',
    ).length;
    const usedSales = dailySales.filter(
      (sale) => sale.DealType === 'Used',
    ).length;

    cumulativeNew += newSales;
    cumulativeUsed += usedSales;
    cumulativeNewGoal += dailyNewGoal;
    cumulativeUsedGoal += dailyUsedGoal;

    cumulativeData.push({
      date: currentDate,
      cumulativeNew,
      cumulativeUsed,
      cumulativeNewGoal,
      cumulativeUsedGoal,
    });
  }

  return (
    isAuthenticated && (
      <Paper sx={{ p: 2, height: '100%' }} elevation={0} variant="outlined">
        <Stack spacing={2}>
          <Typography variant="h6" sx={{ fontSize: '1rem' }}>
            New Monthly Cumulative Sales
          </Typography>
          {loading ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ height: 400 }}
            >
              <CircularProgress />
            </Stack>
          ) : (
            <ResponsiveContainer width="100%" height={400}>
              <ComposedChart data={cumulativeData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="cumulativeNew"
                  stackId="1"
                  stroke="#8884d8"
                  fill="#8884d8"
                  name="New Sales"
                />
                <Line
                  type="monotone"
                  dataKey="cumulativeNewGoal"
                  stroke="#ff7300"
                  name={'New Sales Goal (' + NEW_VEHICLES_GOAL + ')'}
                  dot={false}
                />
              </ComposedChart>
            </ResponsiveContainer>
          )}
        </Stack>
      </Paper>
    )
  );
};

export default NewMonthlyCumulativeSalesChart;
