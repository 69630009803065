import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import dayjs from 'dayjs';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import {
  Typography,
  Paper,
  Stack,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';

const ReservationGraph = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [dateRange, setDateRange] = useState('Month');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getDateRange = (range) => {
    const today = dayjs().startOf('day');
    switch (range) {
      case 'Today':
        return [today, today];
      case 'Yesterday':
        return [today.subtract(1, 'day'), today.subtract(1, 'day')];
      case 'Week':
        return [today.startOf('week'), today];
      case 'Month':
        return [today.startOf('month'), today];
      case 'Previous Month':
        return [
          today.subtract(1, 'month').startOf('month'),
          today.subtract(1, 'month').endOf('month'),
        ];
      case 'Year':
        return [today.startOf('year'), today];
      default:
        return [today, today];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${API_BASE_URL}/api/coversheets`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            dealerID: user?.dealerid,
            startDate: getDateRange(dateRange)[0].format('YYYY-MM-DD'),
            endDate: getDateRange(dateRange)[1].format('YYYY-MM-DD'),
          },
        });
        const data = response.data;
        setData(data.filter((item) => item.vehiclestatus === 'IN TRANSIT'));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dateRange, user?.dealerid, getAccessTokenSilently]);

  const handleDateRangeChange = (event) => {
    setDateRange(event.target.value);
  };

  const processDataForGraph = (data) => {
    const result = {};
    data.forEach((item) => {
      const salesManager = item.salesmanager;
      if (!result[salesManager]) {
        result[salesManager] = 0;
      }
      result[salesManager]++;
    });
    return Object.keys(result).map((salesManager) => ({
      salesManager,
      count: result[salesManager],
    }));
  };

  const graphData = processDataForGraph(data);

  return (
    isAuthenticated && (
      <Paper sx={{ p: 2, height: '100%' }} elevation={0} variant="outlined">
        <Stack spacing={2}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h6" sx={{ fontSize: '1rem' }}>
              Deposits by Sales Manager
            </Typography>
            <div style={{ flexGrow: 1 }} />
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
            >
              <FormControl sx={{ minWidth: 120 }} size="small">
                <Select
                  value={dateRange}
                  onChange={handleDateRangeChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Date Range' }}
                >
                  <MenuItem value="Today">Today</MenuItem>
                  <MenuItem value="Yesterday">Yesterday</MenuItem>
                  <MenuItem value="Week">Week</MenuItem>
                  <MenuItem value="Month">Month</MenuItem>
                  <MenuItem value="Previous Month">Previous Month</MenuItem>
                  <MenuItem value="Year">Year</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Stack>
          {isLoading ? (
            <Typography variant="body1">Loading...</Typography>
          ) : (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={graphData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="salesManager" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </Stack>
      </Paper>
    )
  );
};

export default ReservationGraph;
