// LoadingComponent.jsx

import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const LoadingComponent = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Box textAlign="center">
        <CircularProgress />
        <Typography variant="h6" mt={2}>
          Loading...
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingComponent;
