import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Tab, Tabs, Stack, Paper, Divider } from '@mui/material';
import OverviewTable from './Tables/Overview';
import SalespersonTable from './Tables/Salesperson';
import SalesManagerTable from './Tables/SalesManager';
import FinanceManagerTable from './Tables/FinanceManager';
import TeamTable from './Tables/Team';
import dayjs from 'dayjs';
import CalendarDateRangePicker from 'components/DateRangePicker';
import { API_BASE_URL } from 'config';
import axios from 'axios';

const CarDealTables = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [tabValue, setTabValue] = useState('1');
  const [dateRange, setDateRange] = useState([
    dayjs().startOf('month'),
    dayjs(),
  ]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${API_BASE_URL}/fisaleshistory/${user?.dealerid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              startDate: dateRange[0].format('YYYY-MM-DD'),
              endDate: dateRange[1].format('YYYY-MM-DD'),
            },
          },
        );
        const fetchedData = response.data;
        setData(
          fetchedData.filter(
            (item) =>
              (item.FiWipStatusCode === 'F' || item.FiWipStatusCode === 'B') &&
              item.DealType !== 'Wholesale',
          ),
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [user, dateRange, getAccessTokenSilently]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDateRangeChange = (newDateRange) => setDateRange(newDateRange);

  const tabs = [
    { value: '1', label: 'Overview', component: OverviewTable },
    { value: '2', label: 'Salesperson', component: SalespersonTable },
    { value: '3', label: 'Sales Manager', component: SalesManagerTable },
    { value: '4', label: 'Finance Manager', component: FinanceManagerTable },
    { value: '5', label: 'Team', component: TeamTable },
  ];

  return (
    isAuthenticated && (
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack direction="row" spacing={2} sx={{ padding: 2 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map(({ value, label }) => (
              <Tab key={value} label={label} value={value} />
            ))}
          </Tabs>
          <Box flexGrow={1} />
          <CalendarDateRangePicker
            dateRange={dateRange}
            onDateRangeChange={handleDateRangeChange}
          />
        </Stack>
        <Divider />
        <Box sx={{ flexGrow: 1, height: 800 }}>
          {tabs.map(({ value, component: Component }) =>
            tabValue === value ? (
              <Component
                key={value}
                tableData={data}
                isLoading={loading}
                setTableData={setData} // Pass setData function here
              />
            ) : null,
          )}
        </Box>
      </Paper>
    )
  );
};

export default CarDealTables;
