import React from 'react';
import { TextField, Grid, Autocomplete } from '@mui/material';
import Sources from 'data/sources';

const CustomerInformation = ({
  formData,
  handleChange,
  handleBlur,
  errorFields,
  handlePhoneChange,
  formatPhoneNumber,
}) => {
  const bureauOptions = [
    { label: 'Select One', value: '' },
    { label: 'Equifax', value: 'EQU' },
    { label: 'Experian', value: 'EXP' },
    { label: 'Transunion', value: 'TRANS' },
  ];

  const sourceOptions = [
    { label: 'Select One', value: '' },
    ...Sources.sort().map((item) => ({ label: item, value: item })),
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <TextField
          label="Date"
          fullWidth
          type="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          autoFocus
          label="Deal #"
          fullWidth
          name="dealno"
          id="dealno"
          required
          value={formData.dealno}
          onChange={handleChange}
          onBlur={() => handleBlur('dealno')}
          error={errorFields.includes('dealno')}
          helperText={
            errorFields.includes('dealno') ? 'This field is required' : ''
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="eMail"
          fullWidth
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          label="Phone"
          fullWidth
          name="phone"
          value={formatPhoneNumber(formData.phone)}
          onChange={handlePhoneChange}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Autocomplete
          fullWidth
          options={sourceOptions}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label="Source" variant="outlined" />
          )}
          value={
            sourceOptions.find((option) => option.value === formData.source) ||
            sourceOptions[0]
          }
          onChange={(event, newValue) => {
            handleChange({
              target: {
                name: 'source',
                value: newValue ? newValue.value : '',
              },
            });
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          autoComplete
          autoSelect
        />
      </Grid>
      <Grid item xs={12} sm={7}>
        <TextField
          label="Buyer Name"
          fullWidth
          name="buyername"
          value={formData.buyername}
          onChange={handleChange}
          onBlur={() => handleBlur('buyername')}
          error={errorFields.includes('buyername')}
          helperText={
            errorFields.includes('buyername') ? 'This field is required' : ''
          }
          required
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          label="Buyer #"
          fullWidth
          name="buyerno"
          value={formData.buyerno}
          onChange={handleChange}
          onBlur={() => handleBlur('buyerno')}
          error={errorFields.includes('buyerno')}
          helperText={
            errorFields.includes('buyerno') ? 'This field is required' : ''
          }
          required
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Autocomplete
          fullWidth
          options={bureauOptions}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label="Bureau" variant="outlined" />
          )}
          value={
            bureauOptions.find(
              (option) => option.value === formData.buyerbureau,
            ) || bureauOptions[0]
          }
          onChange={(event, newValue) => {
            handleChange({
              target: {
                name: 'buyerbureau',
                value: newValue ? newValue.value : '',
              },
            });
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          autoComplete
          autoSelect
        />
      </Grid>
      <Grid item xs={12} sm={1}>
        <TextField
          label="Score"
          fullWidth
          name="buyerscore"
          value={formData.buyerscore}
          onChange={handleChange}
          inputProps={{ maxLength: 3 }}
        />
      </Grid>
      <Grid item xs={12} sm={7}>
        <TextField
          label="Co-Buyer Name"
          fullWidth
          name="cobuyername"
          value={formData.cobuyername}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          label="Co-Buyer #"
          fullWidth
          name="cobuyerno"
          value={formData.cobuyerno}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Autocomplete
          fullWidth
          options={bureauOptions}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label="Co-Buyer Bureau" variant="outlined" />
          )}
          value={
            bureauOptions.find(
              (option) => option.value === formData.cobuyerbureau,
            ) || bureauOptions[0]
          }
          onChange={(event, newValue) => {
            handleChange({
              target: {
                name: 'cobuyerbureau',
                value: newValue ? newValue.value : '',
              },
            });
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          autoComplete
          autoSelect
        />
      </Grid>
      <Grid item xs={12} sm={1}>
        <TextField
          label="Score"
          fullWidth
          name="cobuyerscore"
          value={formData.cobuyerscore}
          onChange={handleChange}
          inputProps={{ maxLength: 3 }}
        />
      </Grid>
    </Grid>
  );
};

export default CustomerInformation;
