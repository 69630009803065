import React, { useState, useRef, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { useAuth0 } from '@auth0/auth0-react';
import { API_BASE_URL } from 'config';

import CoverSheetForm from '../Coversheet';

import {
  DataGridPro,
  GridToolbar,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';

import {
  Alert,
  Divider,
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  Snackbar,
} from '@mui/material';

import { Check, Close, Edit } from '@mui/icons-material';

const CoverSheetLogTable = ({ tableData, setTableData, isLoading }) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [selectedRows, setSelectedRows] = useState([]);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const formRef = useRef(null);

  useEffect(() => {
    const selectRows = tableData
      .filter((row) => row.matched)
      .map((row) => row._id);
    setSelectedRows(selectRows);
  }, [tableData]);

  const columns = [
    {
      field: 'fisalesmodel',
      headerName: 'inDMS',
      description: 'Does the coversheet match a deal in the DMS?',
      width: 75,
      align: 'center',
      headerAlign: 'center',
      // valueGetter: (value, row) => {
      //   return row.isInFIExtractDelta ? true : false;
      // },
      renderCell: (params) => {
        return (
          <span>
            {params.value ? (
              <Check color="success" />
            ) : (
              <Close className="text-muted" color="error" />
            )}
          </span>
        );
      },
    },
    {
      field: '_id',
      headerName: 'Actions',
      width: 75,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton onClick={() => handleEdit(params.row)}>
          <Edit />
        </IconButton>
      ),
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 110,
      sortable: true,
      renderCell: (params) => {
        const originalDate = params.value;
        const parsedDate = parseISO(originalDate.split('T')[0]);
        const formattedDate = format(parsedDate, 'MM/dd/yyyy');
        return <span>{formattedDate}</span>;
      },
    },
    {
      field: 'dealno',
      headerName: 'Deal No.',
      width: 100,
      sortable: true,
      renderCell: (params) => (
        <Button variant="text" onClick={() => handleOpenModal(params.value)}>
          {params.value}
        </Button>
      ),
    },
    {
      field: 'reserved',
      headerName: 'Prior Reserved',
      width: 125,
      renderCell: (params) => (
        <span className={`badge ${params.value ? 'bg-secondary' : 'bg-dark'}`}>
          {params.value ? 'Reserved' : 'Not Reserved'}
        </span>
      ),
    },
    {
      field: 'vehiclestatus',
      headerName: 'Vehicle Status',
      width: 125,
      renderCell: (params) => (
        <span
          className={`badge ${
            params.value === 'IN STOCK' ? 'bg-info' : 'bg-warning'
          }`}
        >
          {params.value}
        </span>
      ),
    },
    { field: 'buyername', headerName: 'Buyer Name', width: 200 },
    { field: 'stockno', headerName: 'Stock No.' },
    { field: 'vehicletype', headerName: 'Vehicle Type' },
    { field: 'year', headerName: 'Year', width: 75 },
    { field: 'make', headerName: 'Make' },
    { field: 'model', headerName: 'Model', width: 200 },
    { field: 'miles', headerName: 'Miles', width: 75 },
    { field: 'sp1', headerName: 'SP1', width: 200 },
    { field: 'salesmanager', headerName: 'Sales Manager', width: 200 },
    {
      field: 'fisalesmodel.FrontGross',
      headerName: 'Front Gross',
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const frontGross = params.row?.fisalesmodel?.FrontGross;
        if (frontGross) {
          return (
            <span>
              {Number(frontGross).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </span>
          );
        }
        return null;
      },
    },
    {
      field: 'fisalesmodel.BackGross',
      headerName: 'Back Gross',
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const backGross = params.row?.fisalesmodel?.BackGross;
        if (backGross) {
          return (
            <span>
              {Number(backGross).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </span>
          );
        }
        return null;
      },
    },
    {
      field: 'fisalesmodel.TotalGross',
      headerName: 'Total Gross',
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const totalGross = params.row?.fisalesmodel?.TotalGross;
        if (totalGross) {
          return (
            <span>
              {Number(totalGross).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </span>
          );
        }
        return null;
      },
    },
    {
      field: 'tradeStock1',
      headerName: 'Trade 1 Details',
      width: 200,
      renderCell: (params) => {
        const tradeStock1 = params.row.tradestock1;
        const trade =
          params.row.tradeyear1 +
          ' ' +
          params.row.trademake1 +
          ' ' +
          params.row.trademodel1;
        if (tradeStock1) {
          return (
            <span>
              {tradeStock1} - {trade}
            </span>
          );
        }
        return null;
      },
    },
    {
      field: 'tradeStock2',
      headerName: 'Trade 2 Details',
      width: 200,
      renderCell: (params) => {
        const tradeStock2 = params.row.tradestock2;
        const trade =
          params.row.tradeyear2 +
          ' ' +
          params.row.trademake2 +
          ' ' +
          params.row.trademodel2;
        if (tradeStock2) {
          return (
            <span>
              {tradeStock2} - {trade}
            </span>
          );
        }
        return null;
      },
    },
  ];

  //Dialog Editor Style
  const style = {
    width: '400px', // Adjust the width as needed
    padding: '20px',
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleEdit = (rowData) => {
    setEditedData(rowData);
    setShowEditDialog(true);
  };

  const handleOpenModal = (deal) => {
    setSelectedDeal({
      dealNo: deal,
      dealerId: user?.dealerid, // Include dealerId when opening the modal
    });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setEditedData(null);
    setShowEditDialog(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const newData = tableData.map((row) =>
      row._id === editedData._id
        ? { ...row, ...getFormData(event.target) }
        : row,
    );
    setTableData(newData);

    handleModalClose();
  };

  const handleDelete = async () => {
    try {
      if (editedData) {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${API_BASE_URL}/api/coversheets/${editedData.dealno}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ dealerID: user?.dealerid }),
          },
        );

        if (response.ok) {
          handleSnackbarOpen('Cover Sheet deleted successfully', 'success');
          setTableData((prevData) =>
            prevData.filter((row) => row._id !== editedData._id),
          );
          handleModalClose();
        } else {
          handleSnackbarOpen('Failed to delete Cover Sheet', 'error');
          throw new Error('Failed to delete Cover Sheet');
        }
      }
    } catch (error) {
      console.error(error);
      handleSnackbarOpen(
        'An error occurred while deleting the Cover Sheet',
        'error',
      );
    }
  };

  // Helper function to extract form data
  const getFormData = (form) => {
    const formData = new FormData(form);
    const data = {};

    for (let [name, value] of formData) {
      const [key, nestedKey] = name.split('.');
      if (nestedKey) {
        if (!data[key]) {
          data[key] = {};
        }
        data[key][nestedKey] = value;
      } else {
        data[key] = value;
      }
    }

    return data;
  };

  return (
    isAuthenticated && (
      <>
        <DataGridPro
          sx={{
            border: 'none',
            borderRadius: 0,
          }}
          loading={isLoading}
          rows={tableData}
          columns={columns}
          getRowId={(row) => row._id} // Define the unique 'id' property for each row
          selectRows={selectedRows}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'date',
                  sort: 'desc',
                },
              ],
            },
          }}
          slots={{
            toolbar: () => {
              return (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: 2,
                    }}
                  >
                    <GridToolbar />
                    <GridToolbarQuickFilter />
                  </Box>
                  <Divider />
                </>
              );
            }, // Hide the default toolbar
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          // checkboxSelection
          disableRowSelectionOnClick
        />

        {/* Dialog for editing */}
        <Dialog
          open={showEditDialog}
          onClose={handleModalClose}
          component="form"
          autoComplete="off"
          onSubmit={handleFormSubmit}
        >
          <DialogTitle>Edit Summary Table</DialogTitle>

          {/* Snackbar for showing success/error messages */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: '100%' }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>

          <DialogContent sx={style}>
            <Stack spacing={2} padding={2}>
              <TextField
                variant="filled"
                label="Deal No."
                defaultValue={editedData?.dealno || ''}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                variant="filled"
                label="Stock No."
                defaultValue={editedData?.stockno || ''}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                variant="filled"
                label="Sales Manager"
                defaultValue={editedData?.salesmanager || ''}
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                variant="standard"
                label="Front Gross"
                name="fisalesmodel.FrontGross"
                defaultValue={editedData?.fisalesmodel?.FrontGross || ''}
              />

              <TextField
                variant="standard"
                label="Back Gross"
                name="fisalesmodel.BackGross"
                defaultValue={editedData?.fisalesmodel?.BackGross || ''}
              />

              <TextField
                variant="standard"
                label="Total Gross"
                name="fisalesmodel.TotalGross"
                defaultValue={editedData?.fisalesmodel?.TotalGross || ''}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDelete} color="error">
              Delete
            </Button>
            <Button onClick={handleModalClose} color="secondary">
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog for displaying cover sheet form */}
        <Dialog
          open={showModal}
          onClose={handleCloseModal}
          scroll="body"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogTitle>Edit Coversheet</DialogTitle>
          <DialogContent>
            <Box>
              {selectedDeal && (
                <CoverSheetForm
                  viewDealNo={selectedDeal.dealNo}
                  dealerId={selectedDeal.dealerId}
                  ref={formRef}
                />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowModal(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  );
};

export default CoverSheetLogTable;
