import React from 'react';
import { Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const BasicDealInformation = ({
  formData,
  onChange,
  saleTypes,
  disableWAQNumber,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'ContractDate') {
      // Set Contract Date as selected by the user
      onChange(name, value);
    } else {
      onChange(name, value);
    }
  };

  const getCurrentDate = () => {
    const now = new Date();
    return now.toISOString().split('T')[0];
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          name="WAQNumber"
          label="WAQ Number"
          value={formData.WAQNumber}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="HostItemID"
          label="Host Item ID"
          value={formData.HostItemID}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          required
          fullWidth
          name="ContractDate"
          label="Contract Date"
          type="date"
          value={formData.ContractDate || ''}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          required
          fullWidth
          name="AccountingDate"
          label="Accounting Date"
          type="date"
          value={formData.AccountingDate || getCurrentDate()}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Autocomplete
          options={saleTypes}
          value={formData.SaleType || null}
          onChange={(event, newValue) => {
            onChange('SaleType', newValue || '');
          }}
          renderInput={(params) => (
            <TextField {...params} label="Sale Type" required fullWidth />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default BasicDealInformation;
