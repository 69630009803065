import React from 'react';
import { TextField, Grid, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const DealStructure = ({ formData, handleChange, handleBlur, errorFields }) => {
  const reservedOptions = [
    { label: 'True', value: true },
    { label: 'False', value: false },
  ];

  const purchaseTypeOptions = [
    { label: 'RETAIL', value: 'P' },
    { label: 'LEASE', value: 'L' },
    { label: 'CASH', value: 'C' },
    { label: 'OPTION', value: 'O' },
  ];

  const handleAutocompleteChange = (name) => (event, newValue) => {
    handleChange({
      target: {
        name,
        value: newValue ? newValue.value : '',
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <Autocomplete
          fullWidth
          options={reservedOptions}
          getOptionLabel={(option) => option.label}
          value={
            reservedOptions.find(
              (option) => option.value === formData.reserved,
            ) || null
          }
          onChange={handleAutocompleteChange('reserved')}
          onBlur={() => handleBlur('reserved')}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Prior Reserved"
              error={errorFields.includes('reserved')}
              helperText={
                errorFields.includes('reserved') ? 'This field is required' : ''
              }
              required
            />
          )}
          autoComplete
          autoSelect
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Autocomplete
          fullWidth
          options={purchaseTypeOptions}
          getOptionLabel={(option) => option.label}
          value={
            purchaseTypeOptions.find(
              (option) => option.value === formData.purchasetype,
            ) || null
          }
          onChange={handleAutocompleteChange('purchasetype')}
          onBlur={() => handleBlur('purchasetype')}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Type"
              error={errorFields.includes('purchasetype')}
              helperText={
                errorFields.includes('purchasetype')
                  ? 'This field is required'
                  : ''
              }
              required
            />
          )}
          autoComplete
          autoSelect
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          name="downpayment"
          label="Down Payment"
          value={formData.downpayment}
          onChange={handleChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          name="term"
          label="Term"
          value={formData.term}
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">MO</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          name="rate"
          label="Rate"
          value={formData.rate}
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          name="payment"
          label="PAYMENT"
          value={formData.payment}
          onChange={handleChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DealStructure;
