import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';

import { rebateDescriptions } from '../constants';

const vehicleTypeOptions = ['New', 'Used'];
const transactionTypeOptions = ['Finance', 'Lease', 'Cash'];

export default function Rebates({ deskingData, handleFieldChange }) {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState([]);
  const [rebates, setRebates] = useState([...rebateDescriptions]);
  const [rebateId, setRebateId] = useState(null); // Track the ID of the rebate being edited
  const [rebateName, setRebateName] = useState('');
  const [isSelected, setIsSelected] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    const updatedRebates = rebateDescriptions.map((rd) => {
      const deskingDataRebate = deskingData.rebates.find(
        (dc) => dc.id === rd.id,
      );
      return deskingDataRebate ? { ...rd, ...deskingDataRebate } : rd;
    });

    setRebates(updatedRebates);

    // Set checked state based on deskingData
    const checkedRebates = deskingData.rebates
      .filter((rebate) => rebate.selected)
      .map((rebate) => rebate.id);

    setChecked(checkedRebates);
  }, [deskingData.rebates]);

  const handleOpen = (rebate = null) => {
    if (rebate) {
      setRebateId(rebate.id);
      setRebateName(rebate.name);
      setIsSelected(rebate.selected);
      setVehicleTypes(rebate.vehicleType);
      setTransactionTypes(rebate.transactionType);
      setAmount(rebate.amount);
    } else {
      // Reset the form for adding a new rebate
      setRebateId(null);
      setRebateName('');
      setIsSelected(false);
      setVehicleTypes([]);
      setTransactionTypes([]);
      setAmount(0);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const existingRebateIndex = rebates.findIndex(
      (rebate) => rebate.id === rebateId,
    );

    let updatedRebates =
      existingRebateIndex !== -1
        ? rebates.map((rebate) =>
            rebate.id === rebateId
              ? {
                  ...rebate,
                  name: rebateName,
                  selected: isSelected,
                  vehicleType: vehicleTypes,
                  transactionType: transactionTypes,
                  amount: amount,
                }
              : rebate,
          )
        : [
            ...rebates,
            {
              id: Math.max(...rebates.map((r) => r.id)) + 1,
              name: rebateName,
              selected: isSelected,
              vehicleType: vehicleTypes,
              transactionType: transactionTypes,
              amount: amount,
            },
          ];

    setRebates(updatedRebates);
    handleFieldChange('rebates', updatedRebates);
    handleClose();
  };

  const handleToggle = (id) => () => {
    const newChecked = [...checked];
    let isSelectedNow = false;

    const updatedRebates = rebates.map((rebate) => {
      if (rebate.id === id) {
        isSelectedNow = !rebate.selected;
        return { ...rebate, selected: isSelectedNow };
      }
      return rebate;
    });

    setRebates(updatedRebates);

    if (isSelectedNow) {
      newChecked.push(id);
    } else {
      const currentIndex = checked.indexOf(id);
      if (currentIndex !== -1) {
        newChecked.splice(currentIndex, 1);
      }
    }
    setChecked(newChecked);

    const selectedRebates = updatedRebates.filter((rebate) => rebate.selected);
    handleFieldChange('rebates', selectedRebates);
  };

  const handleOpenForNewRebate = () => {
    // Reset the form for adding a new rebate
    setRebateId(null); // Indicate that a new rebate will be added
    setRebateName('');
    setIsSelected(false);
    setVehicleTypes([]);
    setTransactionTypes([]);
    setAmount(0);

    // Open the dialog
    setOpen(true);
  };

  if (rebates.length === 0) {
    return null;
  }

  return (
    <>
      <Box>
        <List>
          {rebates.map((rebate) => {
            const labelId = `checkbox-list-label-${rebate.id}`;
            return (
              <ListItem
                key={rebate.id}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="more-options"
                    onClick={() => handleOpen(rebate)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
                disablePadding
              >
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(rebate.id)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.includes(rebate.id)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={`${rebate.name} - $${rebate.amount}`}
                    secondary={`${rebate.vehicleType},${rebate.transactionType}`}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenForNewRebate}
          >
            Add New Rebate
          </Button>
        </Box>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add or Update Rebate Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill in the details to add or update a rebate type.
          </DialogContentText>
          <TextField
            autoFocus
            value={rebateName}
            onChange={(e) => setRebateName(e.target.value)}
            margin="dense"
            id="name"
            label="Rebate Name"
            fullWidth
            variant="standard"
          />

          <Box mt={2}>
            <Autocomplete
              multiple
              value={vehicleTypes}
              onChange={(event, newValue) => setVehicleTypes(newValue)}
              options={vehicleTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  variant="standard"
                  label="Vehicle Types"
                  placeholder="Choose..."
                  fullWidth
                />
              )}
            />
          </Box>

          <Box mt={2}>
            <Autocomplete
              multiple
              value={transactionTypes}
              onChange={(event, newValue) => setTransactionTypes(newValue)}
              options={transactionTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  variant="standard"
                  label="Transaction Types"
                  placeholder="Choose..."
                  fullWidth
                />
              )}
            />
          </Box>

          <Box mt={2}>
            <TextField
              value={amount}
              onChange={(e) => setAmount(Number(e.target.value))}
              margin="dense"
              id="amount"
              label="Rebate Amount"
              type="number"
              fullWidth
              variant="standard"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            {' '}
            {/* Connect the Save button to the handleSave function */}
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
