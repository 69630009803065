import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Stack, Paper, Typography, CircularProgress, MenuItem, Select, FormControl } from '@mui/material';
import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import CalendarDateRangePicker from 'components/DateRangePicker';
import { API_BASE_URL } from 'config';
import axios from 'axios';


const TradesDataGrid = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [dateRange, setDateRange] = useState([
    dayjs().startOf('month'),
    dayjs(),
  ]);
  const [loading, setLoading] = useState(false);
  const [trades, setTrades] = useState([]);
  const theme = useTheme();

  const fetchData = useCallback(async () => {
    if (!isAuthenticated) return;

    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${API_BASE_URL}/api/trades/${user?.dealerid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            startDate: dateRange[0].format('YYYY-MM-DD'),
            endDate: dateRange[1].format('YYYY-MM-DD'),
          },
        },
      );
      setTrades(response.data);
    } catch (error) {
      console.error('Error fetching trade data:', error);
    } finally {
      setLoading(false);
    }
  }, [isAuthenticated, user?.dealerid, dateRange, getAccessTokenSilently]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDateRangeChange = (newDateRange) => setDateRange(newDateRange);

  const handleStatusChange = async (WAQNumber, TradeLine, newStatus) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.put(
        `${API_BASE_URL}/api/trades`,
        {
          WAQNumber,
          dealerId: user?.dealerid,
          TradeLine,
          [`${TradeLine}Status`]: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      setTrades((prevTrades) =>
        prevTrades.map((trade) =>
          trade.WAQNumber === WAQNumber && trade.TradeLine === TradeLine
            ? { ...trade, ...response.data }
            : trade
        )
      );
    } catch (error) {
      console.error('Error updating trade status:', error);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Wholesale':
        return theme.palette.error.main;
      case 'Retail':
        return theme.palette.success.main;
      case 'Undecided':
        return theme.palette.warning.main;
      default:
        return theme.palette.text.primary;
    }
  };

  const columns = [
    { field: 'WAQNumber', headerName: 'WAQ Number', width: 100 },
    { field: 'ContractDate', headerName: 'Contract Date', width: 125, valueFormatter: (value) => dayjs(value).format('MM/DD/YYYY') },
    { field: 'CRMSalesMgrName', headerName: 'Sales Manager', width: 150 },
    { field: 'FiWipStatusCode', headerName: 'Status', width: 80 },
    { field: 'DealType', headerName: 'Deal Type', width: 80 },
    { field: 'TradeLine', headerName: 'Trade Line', width: 100 },
    { field: 'VIN', headerName: 'VIN', width: 250 },
    { field: 'Year', headerName: 'Year', type: 'number', width: 80 },
    { field: 'Make', headerName: 'Make', width: 100 },
    { field: 'Model', headerName: 'Model', width: 125 },
    { field: 'Mileage', headerName: 'Mileage', type: 'number', width: 100 },
    { field: 'ACV', headerName: 'ACV', type: 'number', width: 100 },
    {
      field: 'TradeStatus',
      headerName: 'Trade Status',
      width: 150,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}>
          <FormControl fullWidth size="small">
            <Select
              value={params.value || 'Undecided'}
              onChange={(event) => handleStatusChange(params.row.WAQNumber, params.row.TradeLine, event.target.value)}
              sx={{
                color: getStatusColor(params.value || 'Undecided'),
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: getStatusColor(params.value || 'Undecided'),
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: getStatusColor(params.value || 'Undecided'),
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: getStatusColor(params.value || 'Undecided'),
                },
              }}
            >
              <MenuItem value="Retail" sx={{ color: theme.palette.success.main }}>Retail</MenuItem>
              <MenuItem value="Wholesale" sx={{ color: theme.palette.error.main }}>Wholesale</MenuItem>
              <MenuItem value="Undecided" sx={{ color: theme.palette.warning.main }}>Undecided</MenuItem>
            </Select>
          </FormControl>
        </Box>
      ),
    },
  ];

  if (!isAuthenticated) return null;

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
      <Stack direction="row" spacing={2} sx={{ padding: 2 }}>
        <Typography variant="h6">Trades Data</Typography>
        <Box flexGrow={1} />
        <CalendarDateRangePicker
          dateRange={dateRange}
          onDateRangeChange={handleDateRangeChange}
        />
      </Stack>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1, height: 800, width: '100%' }}>
          <DataGridPremium
            rows={trades}
            columns={columns}
            checkboxSelection
            disableSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            getRowId={(row) => row.id}
            initialState={{
              sorting: {
                sortModel: [{ field: 'ContractDate', sort: 'desc' }],
              },
            }}
            autoPageSize
            sx={{
              borderRadius: 0,
              border: 'none',
            }}
          />
        </Box>
      )}
    </Paper>
  );
};

export default TradesDataGrid;