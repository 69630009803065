import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Typography, Paper, CircularProgress, Stack } from '@mui/material';
import {
  DataGridPremium,
  GridToolbar,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';

import dayjs from 'dayjs';
import CalendarDateRangePicker from 'components/DateRangePicker';

import { API_BASE_URL } from 'config';
import axios from 'axios'; // Assuming axios is used for HTTP requests

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const columns = [
  {
    field: 'ContractDate',
    headerName: 'Contract Date',
    width: 150,
    groupable: false,
  },
  {
    field: 'AccountingDate',
    headerName: 'Accounting Date',
    width: 150,
    groupable: false,
  },
  {
    field: 'CRMSaleCreditSP1', //We can reasonably assume that this credit amount is for all salespeople
    headerName: 'Sales Credit',
    width: 90,
    groupable: true,
    availableAggregationFunctions: ['sum', 'count'],
  },
  {
    field: 'CRMSP1Name',
    headerName: 'Salesperson 1',
    width: 250,
    groupable: true,
  },
  {
    field: 'CRMSP2Name',
    headerName: 'Salesperson 2',
    width: 250,
    groupable: true,
  },
  {
    field: 'CRMSP3Name',
    headerName: 'Salesperson 3',
    width: 250,
    groupable: true,
  },
  {
    field: 'CRMSalesMgrName',
    headerName: 'Sales Manager',
    width: 250,
    groupable: true,
  },
  {
    field: 'CRMFIMgrName',
    headerName: 'Finance Manager',
    width: 250,
    groupable: true,
  },
  {
    field: 'CRMClosingMgrName',
    headerName: 'Closing Manager',
    width: 250,
    groupable: true,
  },
  {
    field: 'ContractYear',
    headerName: 'Contract Year',
    width: 150,
    groupable: true,
  },
  {
    field: 'ContractMonth',
    headerName: 'Contract Month',
    width: 150,
    groupable: true,
  },
  {
    field: 'WAQNumber',
    headerName: 'Deal Number',
    width: 120,
    groupable: false,
  },
  {
    field: 'FiWipStatusCode',
    headerName: 'Status',
    width: 100,
    groupable: true,
  },
  {
    field: 'SaleType',
    headerName: 'Sales Type',
    width: 120,
    groupable: true,
  },
  {
    field: 'StockNo',
    headerName: 'Stock No.',
    width: 120,
    groupable: false,
  },
  {
    field: 'FIDealType',
    headerName: 'Vehicle Type',
    width: 120,
    groupable: true,
  },
  {
    field: 'Year',
    headerName: 'Year',
    width: 75,
    groupable: true,
  },
  {
    field: 'MakeName',
    headerName: 'Make',
    width: 100,
    groupable: true,
  },
  {
    field: 'ModelName',
    headerName: 'Model',
    width: 150,
    groupable: true,
  },
  {
    field: 'YearMakeModel',
    headerName: 'Year Make Model',
    width: 400,
    groupable: true,
  },
  {
    field: 'MSRP',
    headerName: 'MSRP',
    width: 150,
    valueFormatter: (value) => {
      if (!value) {
        return value;
      }
      return currencyFormatter.format(value);
    },
    availableAggregationFunctions: ['sum', 'avg', 'min', 'max', 'count'],
    groupable: false,
  },
  {
    field: 'CashPrice',
    headerName: 'Cash Price',
    width: 150,
    valueFormatter: (value) => {
      if (!value) {
        return value;
      }
      return currencyFormatter.format(value);
    },
    availableAggregationFunctions: ['sum', 'avg', 'min', 'max', 'count'],
    groupable: false,
  },
  {
    field: 'Discount',
    headerName: 'Discount',
    width: 150,
    valueFormatter: (value) => {
      if (!value) {
        return value;
      }
      return currencyFormatter.format(value);
    },
    availableAggregationFunctions: ['sum', 'avg', 'min', 'max', 'count'],
    groupable: false,
  },
  {
    field: 'FrontGross',
    headerName: 'FEG',
    width: 100,
    availableAggregationFunctions: ['sum', 'avg', 'min', 'max', 'count'],
    groupable: false,
    valueFormatter: (value) => {
      if (!value) {
        return value;
      }
      return currencyFormatter.format(value);
    },
  },
  {
    field: 'BackGross',
    headerName: 'BEG',
    width: 100,
    availableAggregationFunctions: ['sum', 'avg', 'min', 'max', 'count'],
    groupable: false,
    valueFormatter: (value) => {
      if (!value) {
        return value;
      }
      return currencyFormatter.format(value);
    },
  },
  {
    field: 'FIIncome',
    headerName: 'Reserve',
    width: 100,
    availableAggregationFunctions: ['sum', 'avg', 'min', 'max', 'count'],
    groupable: false,
    valueFormatter: (value) => {
      if (!value) {
        return value;
      }
      return currencyFormatter.format(value);
    },
  },
  {
    field: 'TotalGross',
    headerName: 'Total Gross',
    width: 100,
    availableAggregationFunctions: ['sum', 'avg', 'min', 'max', 'count'],
    groupable: false,
    valueFormatter: (value) => {
      if (!value) {
        return value;
      }
      return currencyFormatter.format(value);
    },
  },
  {
    field: 'CRMCommisionSP1',
    headerName: 'Commission SP1',
    width: 100,
    groupable: false,
    availableAggregationFunctions: ['sum', 'avg', 'min', 'max', 'count'],
    valueFormatter: (value) => {
      if (!value) {
        return value;
      }
      return currencyFormatter.format(value);
    },
  },
  {
    field: 'CRMCommisionSP2',
    headerName: 'Commission SP2',
    width: 100,
    groupable: false,
    availableAggregationFunctions: ['sum', 'avg', 'min', 'max', 'count'],
    valueFormatter: (value) => {
      if (!value) {
        return value;
      }
      return currencyFormatter.format(value);
    },
  },
  {
    field: 'CRMCommisionSP3',
    headerName: 'Commission SP3',
    width: 100,
    groupable: false,
    availableAggregationFunctions: ['sum', 'avg', 'min', 'max', 'count'],
    valueFormatter: (value) => {
      if (!value) {
        return value;
      }
      return currencyFormatter.format(value);
    },
  },
  {
    field: 'CRMCommisionSalesMgr',
    headerName: 'Commission Sales Mgr',
    width: 100,
    groupable: false,
    availableAggregationFunctions: ['sum', 'avg', 'min', 'max', 'count'],
    valueFormatter: (value) => {
      if (!value) {
        return value;
      }
      return currencyFormatter.format(value);
    },
  },
  {
    field: 'CRMCommisionFIMgr',
    headerName: 'Commission FI Mgr',
    width: 100,
    groupable: false,
    availableAggregationFunctions: ['sum', 'avg', 'min', 'max', 'count'],
    valueFormatter: (value) => {
      if (!value) {
        return value;
      }
      return currencyFormatter.format(value);
    },
  },
  {
    field: 'CRMCommisionClosingMgr',
    headerName: 'Commission Closing Mgr',
    width: 100,
    groupable: false,
    availableAggregationFunctions: ['sum', 'avg', 'min', 'max', 'count'],
    valueFormatter: (value) => {
      if (!value) {
        return value;
      }
      return currencyFormatter.format(value);
    },
  },
];

const ReportBuilder = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [dateRange, setDateRange] = React.useState([
    dayjs().startOf('month'),
    dayjs(),
  ]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const apiRef = useGridApiRef();

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${API_BASE_URL}/api/deals/${user.dealerid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              startDate: dateRange[0].format('YYYY-MM-DD'),
              endDate: dateRange[1].format('YYYY-MM-DD'),
            },
          },
        );
        const data = response.data;

        // Add discount column and Year/Make/Model column
        data.forEach((row) => {
          row.YearMakeModel = `${row.Year} ${row.MakeName} ${row.ModelName}`;
          row.ContractYear = dayjs(row.ContractDate).year();
          row.ContractMonth = dayjs(row.ContractDate).month();
          // Return the name of the month
          row.ContractMonth = dayjs().month(row.ContractMonth).format('MMMM');
          row.FormattedDate = dayjs(row.ContractDate);
          //Parsefloat the gross values
          row.CashPrice = parseFloat(row.CashPrice) || 0;
          row.MSRP = parseFloat(row.MSRP) || 0;
          row.Discount = parseFloat(row.CashPrice - row.MSRP) || 0;
          row.FrontGross = parseFloat(row.FrontGross) || 0;
          row.BackGross = parseFloat(row.BackGross) || 0;
          row.TotalGross = parseFloat(row.FrontGross + row.BackGross) || 0;
          row.FIIncome = parseFloat(row.FIIncome) || 0;
          row.CRMCommisionSP1 = parseFloat(row.CRMCommisionSP1) || 0;
          row.CRMCommisionSP2 = parseFloat(row.CRMCommisionSP2) || 0;
          row.CRMCommisionSP3 = parseFloat(row.CRMCommisionSP3) || 0;
          row.CRMCommisionSalesMgr = parseFloat(row.CRMCommisionSalesMgr) || 0;
          row.CRMCommisionFIMgr = parseFloat(row.CRMCommisionFIMgr) || 0;
          row.CRMCommisionClosingMgr =
            parseFloat(row.CRMCommisionClosingMgr) || 0;

          //Divide the sales credit by 1000
          row.CRMSaleCreditSP1 = parseFloat(row.CRMSaleCreditSP1) / 1000 || 0;
        });

        console.log('Data:', data);

        setData(
          data.filter(
            (item) =>
              (item.FiWipStatusCode === 'F' || item.FiWipStatusCode === 'B') &&
              item.DealType !== 'Wholesale',
          ),
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [user.dealerid, dateRange, getAccessTokenSilently]);

  const handleDateRangeChange = (newDateRange) => setDateRange(newDateRange);

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      // rowGrouping: {
      //   model: ['CRMSalesMgrName', 'YearMakeModel'],
      // },
      columns: {
        columnVisibilityModel: {
          ContractMonth: false,
          ContractYear: false,
          MSRP: false,
          CashPrice: false,
          Discount: false,
          Year: false,
          MakeName: false,
          ModelName: false,
          CRMCommisionSP1: false,
          CRMCommisionSP2: false,
          CRMCommisionSP3: false,
          CRMCommisionSalesMgr: false,
          CRMCommisionFIMgr: false,
          CRMCommisionClosingMgr: false,
          CRMSP3Name: false,
          CRMClosingMgrName: false,
        },
      },
      aggregation: {
        model: {
          Discount: 'avg',
          MSRP: 'avg',
          CashPrice: 'avg',
          FrontGross: 'avg',
          BackGross: 'avg',
          TotalGross: 'avg',
          FIIncome: 'avg',
          CRMSaleCreditSP1: 'sum',
          CRMCommisionSP1: 'sum',
          CRMCommisionSP2: 'sum',
          CRMCommisionSP3: 'sum',
          CRMCommisionSalesMgr: 'sum',
          CRMCommisionFIMgr: 'sum',
          CRMCommisionClosingMgr: 'sum',
        },
      },
    },
  });

  return (
    isAuthenticated && (
      <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
        <Stack direction="row" spacing={2} sx={{ padding: 2 }}>
          <Typography variant="h6">Create and Build Custom Reports</Typography>
          <Box flexGrow={1} />
          <CalendarDateRangePicker
            dateRange={dateRange}
            onDateRangeChange={handleDateRangeChange}
          />
        </Stack>
        {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1, height: 800, width: '100%' }}>
          <DataGridPremium
            sx={{
              borderRadius: 0,
              border: 'none',
            }}
            apiRef={apiRef}
            rows={data}
            columns={columns}
            loading={loading}
            getRowId={(row) => row._id} // Define the unique 'id' property for each row
            initialState={initialState}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            hideFooter
            disableRowSelectionOnClick
          />
        </Box>
      )}
      </Paper>
    )
  );
};

export default ReportBuilder;
