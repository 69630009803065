import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Box, Typography, Paper, Stack, CircularProgress } from '@mui/material';
import { round } from 'math.js';
import dayjs from 'dayjs';

const GrossSummaryTable = ({ data, loading, error }) => {
  const { isAuthenticated } = useAuth0();

  const calculateGross = (type) =>
    data.reduce((total, item) => total + Number(item[type]), 0);
  const summaryMetrics = (type) =>
    round((calculateGross(type) / dayjs().date()) * dayjs().daysInMonth());

  const summary = {
    frontGross: calculateGross('FrontGross'),
    frontGrossPacing: summaryMetrics('FrontGross'),
    backGross: calculateGross('BackGross'),
    backGrossPacing: summaryMetrics('BackGross'),
    totalGross: calculateGross('TotalGross'),
    totalGrossPacing: summaryMetrics('TotalGross'),
  };

  const xLabels = ['Front Gross', 'Back Gross', 'Total Gross'];
  const yData = [summary.frontGross, summary.backGross, summary.totalGross];
  const yPacingData = [
    summary.frontGrossPacing,
    summary.backGrossPacing,
    summary.totalGrossPacing,
  ].filter((pacing) => pacing !== null);

  return (
    isAuthenticated && (
      <Paper sx={{ p: 2, height: '100%' }} elevation={0} variant="outlined">
        <Stack spacing={2}>
          <Typography variant="h6" sx={{ fontSize: '1rem' }}>
            Monthly Gross Summary
          </Typography>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography color="error">Error: {error.message}</Typography>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <BarChart
                series={[
                  {
                    data: yData,
                    label: 'Gross',
                  },
                  {
                    data: yPacingData,
                    label: 'Pacing',
                  },
                ]}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: xLabels,
                  },
                ]}
                height={300}
                width={450}
              />
            </Box>
          )}
        </Stack>
      </Paper>
    )
  );
};

export default GrossSummaryTable;
