import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
  Paper,
  Stepper,
  Step,
  StepButton,
  StepContent,
  Snackbar,
  Alert,
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from '@auth0/auth0-react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import PrintIcon from '@mui/icons-material/Print';

import axios from 'axios';
import { API_BASE_URL } from 'config';

// Import form components
import BasicDealInformation from './BasicDealInformation';
import CustomerForm from './CustomerForm';
import VehicleInformation from './VehicleInformation';
import WeOweForm from './WeOweForm';
import ContractForm from './ContractForm';
import TradeForm from './TradeForm';
import GrossCalculations from './GrossCalculations';
import SalesNamesForm from './SalesNamesForm';

// Import new components
import RecapContent from './RecapContent';
import PrintRecap from './PrintRecap';

const activeFIManagers = [
  { name: 'AKOPYAN,NERSES', employeeId: '997602' },
  { name: 'DIAZ,OMAR J', employeeId: '997767' },
  { name: 'LOPEZ,AURELIO J', employeeId: '997601' },
  { name: 'OGTANYAN,AMBARTSUM', employeeId: '997760' },
  { name: 'TORRES,FRANCISCO A', employeeId: '997646' },
  { name: 'SALINAS MARTINEZ,JOHNNY', employeeId: '997751' },
  { name: 'NAVA,ALFREDO', employeeId: '997768' },
];

const activeSalesManagers = [
  { name: 'MORALES,HOWARD ROBERT', employeeId: '997519' },
  { name: 'MORALES,OSCAR', employeeId: '997518' },
  { name: 'BAHROOS,KABIR', employeeId: '997672' },
  { name: 'KHAN,SHAHID A', employeeId: '997940' },
  { name: 'RAMIREZ,CESAR', employeeId: '998016' },
  { name: 'HADDADA,MOHAMED', employeeId: '998017' },
  { name: 'NAVA,ALFREDO', employeeId: '997768' },
];

const activeSalesPeople = [
  { name: 'HERNANDEZ,JOSE', employeeId: '998009' },
  { name: 'HERRERA,RENE JESUS', employeeId: '998026' },
  { name: 'MAGANA,LILY', employeeId: '998027' },
  { name: 'GUEVARA,BRYAN', employeeId: '998028' },
  { name: 'HOUSE', employeeId: 'HOUSE' },
  { name: 'HUAZA,JOAN', employeeId: '998024' },
  { name: 'ILYAS,SHELUKH', employeeId: '998010' },
  { name: 'KEARIN,SHAWN', employeeId: '997594' },
  { name: 'KIMELMAN,LEONID', employeeId: '997695' },
  { name: 'LAVA,JOSE M', employeeId: '997759' },
  { name: 'LOMELI,CASSANDRA', employeeId: '997781' },
  { name: 'LYTHCKE,CARL A', employeeId: '997724' },
  { name: 'MACIAS,ALFREDO', employeeId: '998011' },
  { name: 'MIRANDA,NORBERTO', employeeId: '997774' },
  { name: 'MORALES,JONATHAN', employeeId: '997750' },
  { name: 'NAVA,ALFREDO', employeeId: '997768' },
  { name: 'NGUYEN,THANH', employeeId: '997786' },
  { name: 'RAMOS,JADIEL', employeeId: '997813' },
  { name: 'RIVERA,RUBEN', employeeId: '997775' },
  { name: 'SINGH,HARPREET K', employeeId: '997738' },
  { name: 'SINGH,PRAGATI K', employeeId: '997205' },
  { name: 'TARROZA,BIEN', employeeId: '997828' },
  { name: 'WHITE,MARLIN A', employeeId: '997901' },
  { name: 'GARCIA,DAISY', employeeId: '997779' },
  { name: 'GOMEZ,CLAUDIA M', employeeId: '997770' },
  { name: 'ABRAHAM,ROBERT', employeeId: '997710' },
  { name: 'DESILVA,SUNIL', employeeId: '997793' },
  { name: 'HARUTYUNYAN,DAVIT', employeeId: '997757' },
  { name: 'CORTEZ,MARTIN', employeeId: '997752' },
  { name: 'GONZALES,BELEN', employeeId: '997733' },
  { name: 'DUARTE,EDWARD J', employeeId: '997776' },
  { name: 'DECOSTE,JASMINE V', employeeId: '997628' },
  { name: 'ELNAEB,KAMAL', employeeId: '998015' },
  { name: 'BRAN,JOSE', employeeId: '998014' },
  { name: 'GONZALEZ,KEIRY', employeeId: '998022' },
  { name: 'CALZADILLAS,GENARO H', employeeId: '997861' },
  { name: 'GUERRA,SEBASTIAN A', employeeId: '997656' },
];

const saleTypes = ['Cash', 'Finance', 'Lease'];

const initialFormData = {
  Name: '',
  Name1: '',
  CustNo: '',
  WAQNumber: '',
  HostItemID: '',
  ContractDate: '',
  AccountingDate: new Date().toISOString().split('T')[0],
  DealType: '',
  FIDealType: '',
  StockNo: '',
  GLVehicleCost: '',
  WeOwes: [],
  Contracts: [],
  FrontWeOwesGrossSales: 0,
  FrontWeOwesGrossCost: 0,
  BackWeOwes: 0,
  MBIIncome: 0,
  Ins2Income: 0,
  Ins3Income: 0,
  CRMSP1Id: '',
  CRMSP2Id: '',
  CRMSP3Id: '',
  CRMSaleCreditSP1: 1000,
  CRMSP1Name: '',
  CRMSpiffSP1: '',
  CRMSaleCreditSP2: '',
  CRMSP2Name: '',
  CRMSpiffSP2: '',
  CRMSaleCreditSP3: '',
  CRMSP3Name: '',
  CRMSpiffSP3: '',
  CRMSalesMgrId: '',
  CRMSalesMgrName: '',
  CRMSpiffSalesMgr: '',
  CRMFIMgrId: '',
  CRMFIMgrName: '',
  CRMSpiffFIMgr: '',
  CRMSaleCreditFIMgr: '',
  CRMClosingMgrName: '',
  CRMClosingMgrId: '',
  CRMSpiffClosingMgr: '',
  CRMSaleCreditClosingMgr: '',
  FiMgrId: '',
  FIMgr1: '',
  CRMCommisionSP1: 0,
  CRMCommisionSP2: 0,
  CRMCommisionSP3: 0,
  trades: [
    { acv: '', allowance: '' },
    { acv: '', allowance: '' },
  ],
  tradeDifference: 0,
  VIN: '',
  Make: '',
  Model: '',
  Year: '',
  MSRP: '',
  adjustments: [],
  CashPrice: '',
  FIIncome: '',
  FrontGross: 0,
  BackGross: 0,
  TotalGross: 0,
  GrossProfit: 0,
  FiWipStatusCode: 'F',
  FinanceSource: 'GENERIC',
  SaleType: '',
  CommissionPack: 0,
};

const DealInputForm = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [isWAQDialogOpen, setIsWAQDialogOpen] = useState(false);
  const [isWAQSearched, setIsWAQSearched] = useState(false);
  const [existingDealData, setExistingDealData] = useState(null);
  const [isEditingExistingDeal, setIsEditingExistingDeal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isConfirmationStep, setIsConfirmationStep] = useState(false);

  const WAQInputRef = useRef(null);

  const getLocalDate = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset();
    now.setMinutes(now.getMinutes() - offset);
    return now.toISOString().split('T')[0];
  };

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      HostItemID: uuidv4().slice(0, 40),
      AccountingDate: getLocalDate(),
    }));
  }, []);

  const handleFormChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const searchWAQNumber = async () => {
    const WAQNumber = WAQInputRef.current.value;
    if (!WAQNumber) {
      setError('Please enter a WAQ Number');
      return;
    }

    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${API_BASE_URL}/api/manual-deal-input`,
        {
          params: { dealerId: user.dealerid, WAQNumber },
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      // If we reach here, a deal was found
      setExistingDealData(response.data.deal);
      setIsWAQDialogOpen(true);
      setIsWAQSearched(true);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Deal not found, proceed with new deal
        setIsWAQSearched(true);
        proceedWithNewDeal(WAQNumber);
      } else {
        // Handle other errors
        console.error('Error searching WAQNumber:', error);
        setError('Error searching WAQNumber. Please try again.');
      }
    }
  };

  const loadExistingDeal = () => {
    setFormData({ ...existingDealData, HostItemID: uuidv4().slice(0, 40) });
    setActiveStep(1);
    setIsWAQDialogOpen(false);
    setIsEditingExistingDeal(true); // Set this to true when loading an existing deal
  };

  const proceedWithNewDeal = (WAQNumber) => {
    setFormData({
      ...initialFormData,
      WAQNumber,
      HostItemID: uuidv4().slice(0, 40),
    });
    setActiveStep(1);
    setSuccessMessage('No existing deal found. Proceeding with a new deal.');
    setIsEditingExistingDeal(false); // Set this to false for a new deal
  };

  const WAQNumberSearchStep = () => (
    <Box>
      <TextField
        fullWidth
        label="WAQ Number"
        inputRef={WAQInputRef}
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={searchWAQNumber}>
        Search WAQ Number
      </Button>
      <Dialog open={isWAQDialogOpen} onClose={() => setIsWAQDialogOpen(false)}>
        <DialogTitle>Existing Deal Found</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A deal with this WAQ Number already exists. Would you like to load
            the existing deal data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsWAQDialogOpen(false)} color="primary">
            Try New Number
          </Button>
          <Button onClick={loadExistingDeal} color="primary" autoFocus>
            Load Existing Deal
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );

  // Modify the BasicDealInformation step
  const modifiedBasicDealInformation = () => (
    <BasicDealInformation
      formData={formData}
      onChange={handleFormChange}
      saleTypes={saleTypes}
      disableWAQNumber={true}
    />
  );

  const handleAddWeOwe = (weOwe) => {
    setFormData((prevState) => {
      const newWeOwes = [...prevState.WeOwes, weOwe];

      const FrontWeOwesGrossSales = newWeOwes.reduce(
        (sum, item) => (item.type === 'front' ? sum + Number(item.sale) : sum),
        0,
      );

      const FrontWeOwesGrossCost = newWeOwes.reduce(
        (sum, item) => (item.type === 'front' ? sum + Number(item.cost) : sum),
        0,
      );

      const BackWeOwes = newWeOwes.reduce(
        (sum, item) =>
          item.type === 'back'
            ? sum + (Number(item.sale) - Number(item.cost))
            : sum,
        0,
      );

      return {
        ...prevState,
        WeOwes: newWeOwes,
        FrontWeOwesGrossSales,
        FrontWeOwesGrossCost,
        BackWeOwes,
      };
    });
  };

  const handleRemoveWeOwe = (index) => {
    setFormData((prevState) => {
      const newWeOwes = prevState.WeOwes.filter((_, i) => i !== index);
      return {
        ...prevState,
        WeOwes: newWeOwes,
        FrontWeOwesGrossSales: newWeOwes.reduce(
          (sum, item) =>
            item.type === 'front' ? sum + Number(item.sale) : sum,
          0,
        ),
        FrontWeOwesGrossCost: newWeOwes.reduce(
          (sum, item) =>
            item.type === 'front' ? sum + Number(item.cost) : sum,
          0,
        ),
        BackWeOwes: newWeOwes.reduce(
          (sum, item) =>
            item.type === 'back'
              ? sum + Number(item.sale) - Number(item.cost)
              : sum,
          0,
        ),
      };
    });
  };

  const handleAddContract = (contract) => {
    setFormData((prevState) => {
      // Calculate profit (income) based on contract type
      const income = Number(contract.sale) - Number(contract.cost);

      // Update the Contracts array with the new contract
      const newContracts = [...prevState.Contracts, { ...contract, income }];

      // Calculate MBIIncome, Ins2Income, Ins3Income based on updated contracts
      const MBIIncome = newContracts.reduce((sum, contract) => {
        return contract.type === 'MBIIncome' ? sum + contract.income : sum;
      }, 0);

      const Ins2Income = newContracts.reduce((sum, contract) => {
        return contract.type === 'Ins2Income' ? sum + contract.income : sum;
      }, 0);

      const Ins3Income = newContracts.reduce((sum, contract) => {
        return contract.type === 'Ins3Income' ? sum + contract.income : sum;
      }, 0);

      return {
        ...prevState,
        Contracts: newContracts,
        MBIIncome,
        Ins2Income,
        Ins3Income,
      };
    });
  };

  const handleRemoveContract = (index) => {
    setFormData((prevState) => {
      const newContracts = prevState.Contracts.filter((_, i) => i !== index);

      // Recalculate MBIIncome, Ins2Income, Ins3Income based on remaining contracts
      const MBIIncome = newContracts.reduce((sum, contract) => {
        return contract.type === 'MBIIncome'
          ? sum + Number(contract.sale)
          : sum;
      }, 0);

      const Ins2Income = newContracts.reduce((sum, contract) => {
        return contract.type === 'Ins2Income'
          ? sum + Number(contract.sale)
          : sum;
      }, 0);

      const Ins3Income = newContracts.reduce((sum, contract) => {
        return contract.type === 'Ins3Income'
          ? sum + Number(contract.sale)
          : sum;
      }, 0);

      return {
        ...prevState,
        Contracts: newContracts,
        MBIIncome,
        Ins2Income,
        Ins3Income,
      };
    });
  };

  const handleTradeChange = (index, name, value) => {
    setFormData((prevState) => {
      const newTrades = [...prevState.trades];
      newTrades[index] = { ...newTrades[index], [name]: value };

      const tradeDifference = newTrades.reduce((sum, trade) => {
        return (
          sum + (parseFloat(trade.acv || 0) - parseFloat(trade.allowance || 0))
        );
      }, 0);

      return {
        ...prevState,
        trades: newTrades,
        tradeDifference,
      };
    });
  };

  const handleAddAdjustment = (adjustment) => {
    // Set the adjustment amount to a negative value
    adjustment.amount = -Math.abs(parseFloat(adjustment.amount));
    setFormData((prevState) => ({
      ...prevState,
      adjustments: [...prevState.adjustments, adjustment],
    }));
  };

  const handleRemoveAdjustment = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      adjustments: prevState.adjustments.filter((_, i) => i !== index),
    }));
  };

  const handleGrossUpdate = (grossData) => {
    setFormData((prevState) => ({
      ...prevState,
      ...grossData,
    }));
  };

  // Validation functions for each step
  const validationFunctions = {
    'Basic Deal Information': () => {
      const requiredFields = [
        'WAQNumber',
        'ContractDate',
        'AccountingDate',
        'SaleType',
      ];
      const emptyFields = requiredFields.filter((field) => !formData[field]);
      return emptyFields.length === 0
        ? true
        : `Please fill out: ${emptyFields.join(', ')}`;
    },
    'Customer Information': () => {
      const requiredFields = ['Name', 'CustNo'];
      const emptyFields = requiredFields.filter((field) => !formData[field]);
      return emptyFields.length === 0
        ? true
        : `Please fill out: ${emptyFields.join(', ')}`;
    },
    'Vehicle Information': () => {
      const requiredFields = [
        'DealType',
        'StockNo',
        'Year',
        'Make',
        'Model',
        'Year',
        'GLVehicleCost',
      ];
      const emptyFields = requiredFields.filter((field) => !formData[field]);
      return emptyFields.length === 0
        ? true
        : `Please fill out: ${emptyFields.join(', ')}`;
    },
    'Gross Calculations': () => {
      const requiredFields = ['CashPrice', 'FIIncome'];
      const emptyFields = requiredFields.filter((field) => !formData[field]);
      return emptyFields.length === 0
        ? true
        : `Please fill out: ${emptyFields.join(', ')}`;
    },
    'Sales Names': () => {
      const requiredFields = [
        'CRMSP1Id',
        'CRMSP1Name',
        'CRMSaleCreditSP1',
        'CRMSalesMgrId',
        'CRMSalesMgrName',
        'CRMFIMgrId',
        'CRMFIMgrName',
      ];
      const emptyFields = requiredFields.filter((field) => !formData[field]);
      return emptyFields.length === 0
        ? true
        : `Please fill out: ${emptyFields.join(', ')}`;
    },
  };

  const validateStep = (stepIndex) => {
    const stepName = steps[stepIndex].label;
    const validationFunction = validationFunctions[stepName];
    if (validationFunction) {
      const result = validationFunction();
      if (result !== true) {
        setError(result);
        return false;
      }
    }
    return true;
  };

  const isStepComplete = (step) => {
    return completed[step];
  };

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const resetForm = () => {
    setFormData({
      ...initialFormData,
      HostItemID: uuidv4().slice(0, 40),
      AccountingDate: getLocalDate(),
    });
    setActiveStep(0);
    setCompleted({});
    setIsConfirmationStep(false);
    setIsEditingExistingDeal(false); // Reset this when form is reset
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    try {
      const token = await getAccessTokenSilently();
      const dealData = {
        ...formData,
        dealerId: user.dealerid,
        CommissionPack: formData.DealType === 'New' ? 1000 : 500,
        MakeName: formData.Make,
        ModelName: formData.Model,
        FIDealType: formData.DealType,
      };

      let response;
      if (isEditingExistingDeal) {
        // Use PUT request to update existing deal
        response = await axios.put(
          `${API_BASE_URL}/api/manual-deal-input`,
          dealData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        console.log('Deal updated successfully:', response.data);
        setSuccessMessage('Deal updated successfully!');
      } else {
        // Use POST request to create new deal
        response = await axios.post(
          `${API_BASE_URL}/api/manual-deal-input`,
          dealData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        console.log('New deal saved successfully:', response.data);
        setSuccessMessage('New deal submitted successfully!');
      }

      resetForm();
    } catch (error) {
      console.error('Error saving/updating deal:', error);
      setError(
        error.response?.data?.message ||
          'An error occurred while submitting the deal. Please try again.',
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const ConfirmationStep = () => (
    <Paper square elevation={0} style={{ padding: '20px' }}>
      <Typography variant="h6" gutterBottom>
        Review and Confirm Deal Details
      </Typography>
      <RecapContent formData={formData} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <Button
          onClick={() => setIsConfirmationStep(false)}
          startIcon={<ArrowBackIcon />}
        >
          Back to Edit
        </Button>
        <Button
          onClick={() => PrintRecap(formData)}
          startIcon={<PrintIcon />}
          variant="outlined"
        >
          Print Recap
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          endIcon={<CheckIcon />}
        >
          {isSubmitting ? 'Submitting...' : 'Confirm and Submit'}
        </Button>
      </Box>
    </Paper>
  );

  const handleNextStep = () => {
    if (activeStep === 0 && !isWAQSearched) {
      setError('Please search for a WAQ Number before proceeding');
      return;
    }

    if (!validateStep(activeStep)) {
      return;
    }

    if (isLastStep()) {
      setIsConfirmationStep(true);
    } else {
      const newActiveStep = activeStep + 1;
      setActiveStep(newActiveStep);
      setCompleted((prevCompleted) => ({
        ...prevCompleted,
        [activeStep]: true,
      }));
    }
  };

  const steps = [
    {
      label: 'WAQ Number Search',
      content: <WAQNumberSearchStep />,
    },
    {
      label: 'Basic Deal Information',
      content: modifiedBasicDealInformation(),
    },
    {
      label: 'Customer Information',
      content: <CustomerForm formData={formData} onChange={handleFormChange} />,
    },
    {
      label: 'Vehicle Information',
      content: (
        <VehicleInformation formData={formData} onChange={handleFormChange} />
      ),
    },
    {
      label: 'Trades',
      content: <TradeForm formData={formData} onChange={handleTradeChange} />,
    },
    {
      label: 'WeOwes',
      content: (
        <WeOweForm
          formData={formData}
          handleAddWeOwe={handleAddWeOwe}
          handleRemoveWeOwe={handleRemoveWeOwe}
        />
      ),
    },
    {
      label: 'Contracts',
      content: (
        <ContractForm
          formData={formData}
          handleAddContract={handleAddContract}
          handleRemoveContract={handleRemoveContract}
        />
      ),
    },
    {
      label: 'Gross Calculations',
      content: (
        <GrossCalculations
          formData={formData}
          adjustments={formData.adjustments}
          onUpdate={handleGrossUpdate}
          handleAddAdjustment={handleAddAdjustment}
          handleRemoveAdjustment={handleRemoveAdjustment}
        />
      ),
    },
    {
      label: 'Sales Names',
      content: (
        <SalesNamesForm
          formData={formData}
          onChange={handleFormChange}
          activeSalesManagers={activeSalesManagers}
          activeFIManagers={activeFIManagers}
          activeSalesPeople={activeSalesPeople}
        />
      ),
    },
  ];

  return (
    isAuthenticated && (
      <Paper style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
        <Typography variant="h6" gutterBottom>
          New Deal Input
        </Typography>
        <LinearProgress
          variant="determinate"
          value={
            ((isConfirmationStep ? steps.length : activeStep) / steps.length) *
            100
          }
          style={{ marginBottom: '24px' }}
        />
        {isConfirmationStep ? (
          <ConfirmationStep />
        ) : (
          <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
            {steps.map((step, index) => (
              <Step key={step.label} completed={isStepComplete(index)}>
                <StepButton
                  onClick={() => setActiveStep(index)}
                  disabled={index > activeStep && !isStepComplete(index - 1)}
                  style={{ textAlign: 'left' }}
                >
                  <Typography
                    variant="subtitle1"
                    color={isStepComplete(index) ? 'primary' : 'textSecondary'}
                    style={{ marginLeft: '8px' }}
                  >
                    {step.label}
                  </Typography>
                </StepButton>
                <StepContent>
                  <Card variant="outlined">
                    <CardContent>{step.content}</CardContent>
                  </Card>
                  <div
                    style={{
                      marginTop: '24px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Button
                      disabled={activeStep === 0}
                      onClick={() =>
                        setActiveStep((prevActiveStep) => prevActiveStep - 1)
                      }
                      startIcon={<ArrowBackIcon />}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNextStep}
                      endIcon={
                        isLastStep() ? <CheckIcon /> : <ArrowForwardIcon />
                      }
                    >
                      {isLastStep() ? 'Review' : 'Next'}
                    </Button>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        )}
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setError(null)}
            severity="error"
            sx={{ width: '100%' }}
          >
            {error}
          </Alert>
        </Snackbar>
        <Snackbar
          open={!!successMessage}
          autoHideDuration={6000}
          onClose={() => setSuccessMessage(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setSuccessMessage(null)}
            severity="success"
            sx={{ width: '100%' }}
          >
            {successMessage}
          </Alert>
        </Snackbar>
      </Paper>
    )
  );
};

export default DealInputForm;
