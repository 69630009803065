import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import algoliasearch from 'algoliasearch/lite';

// Material UI Components
import { Box } from '@mui/material';

// Algolia InstantSearch Components
import { InstantSearch } from 'react-instantsearch';

// Custom Components
import VehicleListTable from './desking-list-table';

// Hooks and Utils
import { useInventory } from 'hooks/useInventory';

// Initial Form Data
import { initialFormData } from './constants';

// Algolia Configuration
const APPLICATION_ID = 'SEWJN80HTN';
const API_KEY = '179608f32563367799314290254e3e44';
const searchClient = algoliasearch(APPLICATION_ID, API_KEY);

const Desking = () => {
  // Auth0 and Custom Hooks
  const { isAuthenticated, user } = useAuth0();
  const { inventory } = useInventory(user?.cdkid);
  const [formData, setFormData] = useState(initialFormData);

  // Component State
  const [sortOrder, setSortOrder] = useState(
    'frontiertoyota_production_inventory_specials_oem_price',
  );

  // Authentication Guard
  if (!isAuthenticated) {
    return <p>Please log in to view this data.</p>;
  }

  // Event Handlers
  const handleFormData = (newFormData) => {
    setFormData(newFormData);
  };

  return (
    <InstantSearch
      indexName={sortOrder}
      searchClient={searchClient}
      future={{ preserveSharedStateOnUnmount: false }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <VehicleListTable
          inventory={inventory}
          formData={formData}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          handleFormData={handleFormData}
        />
      </Box>
    </InstantSearch>
  );
};

export default Desking;
