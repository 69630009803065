import React from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

const ChecklistItem = ({ name, label, checked, onChange }) => (
  <ListItem dense>
    <FormControlLabel
      control={
        <Checkbox
          edge="start"
          checked={checked}
          onChange={onChange}
          name={name}
        />
      }
      label={label}
    />
  </ListItem>
);

const Checklists = ({ formData, handleCheckboxChange, checklistItems }) => {
  return (
    <Grid container spacing={2}>
      {Object.entries(checklistItems).map(([category, items]) => (
        <Grid item xs={12} sm={6} md={3} key={category}>
          <Card elevation={0} variant="outlined" sx={{
            height: '100%',
          }}>
            <CardHeader
              title={category}
              titleTypographyProps={{ variant: 'body1', fontWeight: 'bold' }}
            />
            <CardContent>
              <List dense disablePadding>
                {items.map((item) => (
                  <ChecklistItem
                    key={item.name}
                    name={item.name}
                    label={item.label}
                    checked={formData[item.name] || false}
                    onChange={handleCheckboxChange}
                  />
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Checklists;
