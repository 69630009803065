import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEmployees } from 'hooks/useEmployees';

const SummaryTable = ({ tableData, isLoading }) => {
  console.log('tableData', tableData);
  const { isAuthenticated, user } = useAuth0();

  const Employees = useEmployees(user, isAuthenticated);

  const SalesManagers = Employees.data
    .filter(
      (employee) =>
        employee.isActive && employee.accessRole === 'Sales Manager',
    )
    .map((employee) => `${employee.firstName} ${employee.lastName}`);

  SalesManagers.push('House');

  const summary = tableData
    ? SalesManagers.map((salesManager) => {
        let newInStockCount = 0;
        let priorReserved = 0;
        let newInTransitCount = 0;
        let usedCount = 0;
        let FrontGrossSum = 0;
        let BackGrossSum = 0;
        let TotalGrossSum = 0;

        tableData.forEach((item) => {
          if (!!item.fisalesmodel && item.salesmanager === salesManager) {
            if (item.vehicletype === 'NEW' && item.vehiclestatus === 'IN STOCK')
              newInStockCount++;
            if (item.vehicletype === 'NEW' && item.reserved) priorReserved++;
            if (item.vehicletype === 'USED') usedCount++;

            if (item.vehiclestatus !== 'IN TRANSIT') {
              FrontGrossSum += parseFloat(item.fisalesmodel?.FrontGross) || 0;
              BackGrossSum += parseFloat(item.fisalesmodel?.BackGross) || 0;
              TotalGrossSum += parseFloat(item.fisalesmodel?.TotalGross) || 0;
            }
          }

          if (
            item.salesmanager === salesManager &&
            item.vehicletype === 'NEW' &&
            item.vehiclestatus === 'IN TRANSIT'
          )
            newInTransitCount++;
        });

        return {
          SalesManager: salesManager,
          NewInStock: newInStockCount,
          PriorReserved: priorReserved,
          NewInTransit: newInTransitCount,
          Used: usedCount,
          FrontGrossSum,
          BackGrossSum,
          TotalGrossSum,
        };
      })
    : [];

  const filteredManager = summary.filter(
    (item) => item.SalesManager !== 'Total',
  );

  const totals = filteredManager.reduce(
    (acc, row) => {
      acc.NewInStock += row.NewInStock;
      acc.PriorReserved += row.PriorReserved;
      acc.NewInTransit += row.NewInTransit;
      acc.Used += row.Used;
      acc.FrontGrossSum += row.FrontGrossSum;
      acc.BackGrossSum += row.BackGrossSum;
      acc.TotalGrossSum += row.TotalGrossSum;
      return acc;
    },
    {
      NewInStock: 0,
      PriorReserved: 0,
      NewInTransit: 0,
      Used: 0,
      FrontGrossSum: 0,
      BackGrossSum: 0,
      TotalGrossSum: 0,
    },
  );

  // Calculate the totals row
  const totalsRow = {
    SalesManager: 'Total',
    NewInStock: totals.NewInStock,
    PriorReserved: totals.PriorReserved,
    NewInTransit: totals.NewInTransit,
    Used: totals.Used,
    FrontGrossSum: totals.FrontGrossSum,
    BackGrossSum: totals.BackGrossSum,
    TotalGrossSum: totals.TotalGrossSum,
  };

  // Append the totals row to the summary array
  summary.push(totalsRow);

  const summaryColumns = [
    {
      field: 'SalesManager',
      headerName: 'Sales Manager',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'NewInStock',
      headerName: 'New/In Stock',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'PriorReserved',
      headerName: 'Prior Reserved',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'NewInTransit',
      headerName: 'New/In Transit',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'Used',
      headerName: 'Used',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'Total',
      headerName: 'Total',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => params.row.NewInStock + params.row.Used,
    },
    {
      field: 'FrontGrossSum',
      headerName: 'Front GP',
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) =>
        Number(params.value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
    },
    {
      field: 'BackGrossSum',
      headerName: 'Back GP',
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) =>
        Number(params.value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
    },
    {
      field: 'TotalGrossSum',
      headerName: 'Total GP',
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) =>
        Number(params.value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
    },
  ];

  const columns = summaryColumns.map((column) => ({
    ...column,
    headerAlign: 'center',
    align: [
      'NewInStock',
      'PriorReserved',
      'NewInTransit',
      'Used',
      'Total',
    ].includes(column.field)
      ? 'center'
      : ['FrontGrossSum', 'BackGrossSum', 'TotalGrossSum'].includes(
          column.field,
        )
      ? 'right'
      : 'left',
  }));

  const rows = summary.map((item) => ({ id: item.SalesManager, ...item }));

  return (
    isAuthenticated && (
      <DataGridPro
        sx={{
          border: 'none',
          borderRadius: 0,
        }}
        loading={isLoading}
        rows={rows}
        columns={columns}
      />
    )
  );
};

export default SummaryTable;
