import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Box,
} from '@mui/material';
import { API_BASE_URL } from 'config';
import { transactionTypes, vehicleTypes } from 'data/constants'; // Import the constants

const CreateFeeDialog = ({ open, onClose, refetchFees, dealerID }) => {
  const [formData, setFormData] = useState({
    DealerID: dealerID,
    InCap: false,
    TaxInCap: false,
    Taxable: false,
    Description: '',
    TransactionType: transactionTypes[0].name, // Use the name field
    Value: 0,
    VehicleType: vehicleTypes[0].name, // Use the name field
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make an API call to create a new fee using the 'formData' state
      const response = await fetch(`${API_BASE_URL}/api/fees`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to create fee');
      }

      // Refresh fees data
      refetchFees();

      // Close the dialog
      onClose();
    } catch (error) {
      console.error('Error creating fee:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box>
        <DialogTitle>Create Fee</DialogTitle>
      </Box>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Description"
                name="Description"
                fullWidth
                value={formData.Description}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Transaction Type"
                name="TransactionType"
                select
                fullWidth
                value={formData.TransactionType}
                onChange={handleChange}
                required
              >
                {transactionTypes.map((option) => (
                  <MenuItem key={option.value} value={option.name}>
                    {' '}
                    {/* Use option.name */}
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Vehicle Type"
                name="VehicleType"
                select
                fullWidth
                value={formData.VehicleType}
                onChange={handleChange}
                required
              >
                {vehicleTypes.map((option) => (
                  <MenuItem key={option.value} value={option.name}>
                    {' '}
                    {/* Use option.name */}
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Value"
                name="Value"
                type="number"
                fullWidth
                value={formData.Value}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.InCap}
                    onChange={handleChange}
                    name="InCap"
                    color="primary"
                  />
                }
                label="Upfront (Lease)"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.TaxInCap}
                    onChange={handleChange}
                    name="TaxInCap"
                    color="primary"
                  />
                }
                label="Tax Cap (Lease)"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.Taxable}
                    onChange={handleChange}
                    name="Taxable"
                    color="primary"
                  />
                }
                label="Taxable"
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Create
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateFeeDialog;
