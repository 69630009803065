import React from 'react';
import { useRefinementList } from 'react-instantsearch';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SvgIcon from '@mui/material/SvgIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomRefinementList = (props) => {
  const { items, refine } = useRefinementList(props);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color="inherit"
        endIcon={
          <SvgIcon>
            <ExpandMoreIcon />
          </SvgIcon>
        }
      >
        {props.label}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {items.map((item) => (
          <MenuItem key={item.label}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.isRefined}
                  onClick={() => refine(item.value)}
                  inputProps={{ 'aria-labelledby': item.label }}
                />
              }
              label={item.label + ' (' + item.count + ')'}
              sx={{
                flexGrow: 1,
                mr: 0,
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CustomRefinementList;
