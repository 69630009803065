import React from 'react';

// DataGrid components
import { DataGrid } from '@mui/x-data-grid';

import { Box } from '@mui/material';

import { formatCurrency } from 'utils/numberUtils';

const DetailPanelContent = ({ position, data = [] }) => {
  if (!position) return null;

  const positionDeals = data.filter((item) => {
    if (Array.isArray(item[position.name])) {
      return (
        item[position.name].includes(position.id) ||
        item[position.name][0] === null
      );
    } else {
      return item[position.name] === position.id;
    }
  });

  const groupedData = positionDeals.reduce((result, deal) => {
    const {
      CRMSP1Name,
      CRMSP2Name,
      CRMSP3Name,
      DealType,
      FrontGross,
      BackGross,
      TotalGross,
    } = deal;

    const salesPeople = [CRMSP1Name, CRMSP2Name, CRMSP3Name].filter(
      (name) => name,
    ); // Filter out empty names

    const dealValue = 1 / salesPeople.length; // Calculate the deal value based on the number of salespeople
    const isUsed = DealType === 'Used'; // Check if deal is Used
    const isFinance = deal.SaleType === 'Finance'; // Check if deal is Finance

    salesPeople.forEach((salesPerson) => {
      if (!result[salesPerson]) {
        result[salesPerson] = {
          totalDeals: 0,
          totalNew: 0,
          totalUsed: 0,
          totalFrontGross: 0,
          totalBackGross: 0,
          totalGrossProfit: 0,
          totalUnitsSold: 0, // New property for total units sold
        };
      }

      result[salesPerson].totalDeals += dealValue;
      if (isUsed) {
        result[salesPerson].totalUsed += dealValue;
      } else {
        result[salesPerson].totalNew += dealValue;
      }

      // Adjust how gross numbers are accumulated based on isFinance
      result[salesPerson].totalFrontGross += isFinance
        ? parseFloat(FrontGross) / 2
        : parseFloat(FrontGross);

      result[salesPerson].totalBackGross += isFinance
        ? parseFloat(BackGross) / 2
        : parseFloat(BackGross);

      result[salesPerson].totalGrossProfit += isFinance
        ? parseFloat(TotalGross) / 2
        : parseFloat(TotalGross);

      // Increment total units sold
      result[salesPerson].totalUnitsSold += dealValue;
    });

    return result;
  }, {});

  const rows = Object.keys(groupedData).map((key) => {
    const { totalUnitsSold } = groupedData[key];
    const dayOfMonth = new Date().getDate(); // Get the day of the month
    const daysInMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0,
    ).getDate(); // Get the total days in the month

    return {
      id: key,
      ...groupedData[key],
      unitPace: (totalUnitsSold / dayOfMonth) * daysInMonth,
    };
  });

  const columns = [
    { field: 'id', headerName: 'Employee', flex: 2 },
    { field: 'totalNew', headerName: 'New', type: 'number', flex: 1 },
    { field: 'totalUsed', headerName: 'Used', type: 'number', flex: 1 },
    { field: 'totalDeals', headerName: 'Total', type: 'number', flex: 1 },
    {
      field: 'unitPace',
      headerName: 'Pace',
      type: 'number',
      valueFormatter: ({ value }) => value.toFixed(2), // Format to 2 decimal places
      flex: 1,
    },
    {
      field: 'totalFrontGross',
      headerName: 'Front',
      type: 'number',
      valueFormatter: (params) => formatCurrency(params.value),
      flex: 1,
    },
    {
      field: 'totalBackGross',
      headerName: 'Back',
      type: 'number',
      valueFormatter: (params) => formatCurrency(params.value),
      flex: 1,
    },
    {
      field: 'totalGrossProfit',
      headerName: 'Total',
      type: 'number',
      valueFormatter: (params) => formatCurrency(params.value),
      flex: 1,
    },
    {
      field: 'frontGrossPVR',
      headerName: 'Front',
      type: 'number',
      valueGetter: (params) =>
        params.row.totalUnitsSold === 0
          ? 0
          : params.row.totalFrontGross / params.row.totalUnitsSold,
      valueFormatter: (params) => {
        if (params.id === 'Totals' || params.id === 'Averages') {
          return ''; // or return a specific value/formatting for these rows
        }
        return formatCurrency(params.value);
      },
    },
    {
      field: 'backGrossPVR',
      headerName: 'Back',
      type: 'number',
      valueGetter: (params) =>
        params.row.totalUnitsSold === 0
          ? 0
          : params.row.totalBackGross / params.row.totalUnitsSold,
      valueFormatter: (params) => {
        if (params.id === 'Totals' || params.id === 'Averages') {
          return ''; // or return a specific value/formatting for these rows
        }
        return formatCurrency(params.value);
      },
    },
    {
      field: 'totalGrossPVR',
      headerName: 'Total',
      type: 'number',
      valueGetter: (params) =>
        params.row.totalUnitsSold === 0
          ? 0
          : params.row.totalGrossProfit / params.row.totalUnitsSold,
      valueFormatter: (params) => {
        if (params.id === 'Totals' || params.id === 'Averages') {
          return ''; // or return a specific value/formatting for these rows
        }
        return formatCurrency(params.value);
      },
    },
  ];

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
      }}
    >
      <DataGrid
        sx={{
          backgroundColor: 'background.paper',
        }}
        autoHeight
        density="compact"
        disableColumnFilter
        disableDensitySelector
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        hideFooter
        initialState={{
          columns: {
            columnVisibilityModel: {
              CRMSP2Name: false,
              CRMSP3Name: false,
            },
          },
        }}
      />
    </Box>
  );
};

export default DetailPanelContent;
