import React from 'react';
import { Box, Grid, TextField, Autocomplete } from '@mui/material';

import { lenders } from '../constants';

export default function LenderSettings({ deskingData, handleFieldChange }) {
  const { lender, creditscore } = deskingData;

  const handleLenderChange = (event, newValue) => {
    if (newValue) {
      handleFieldChange('lender', newValue.title);
    } else {
      handleFieldChange('lender', ''); // Handle case when the selection is cleared
    }
  };

  const handleCreditScoreChange = (event) => {
    handleFieldChange('creditscore', event.target.value);
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Autocomplete
            options={lenders}
            id="lender"
            name="lender"
            getOptionLabel={(option) => option.title}
            value={lenders.find((l) => l.value === lender)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Lender"
                variant="outlined"
                fullWidth
                sx={{ mb: 1 }}
              />
            )}
            onChange={handleLenderChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="creditscore"
            name="creditscore"
            label="Credit Score"
            variant="outlined"
            value={creditscore}
            onChange={handleCreditScoreChange}
            fullWidth
            sx={{ mb: 1 }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
