import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Paper, Typography, CircularProgress, Stack } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import dayjs from 'dayjs';

const NEW_VEHICLES_GOAL = 250;
const USED_VEHICLES_GOAL = 150;

const calculateRequiredDailyPace = (goal, currentCount) => {
  const today = dayjs();
  const totalDaysInMonth = today.daysInMonth();
  const daysRemaining = totalDaysInMonth - today.date() + 1;
  const remainingGoal = Math.max(0, goal - currentCount);
  return Math.round(remainingGoal / daysRemaining);
};

const RequiredDailyPaceChart = ({ data, loading }) => {
  const { isAuthenticated } = useAuth0();

  const newUnits = data.filter((item) => item.DealType === 'New').length;
  const usedUnits = data.filter((item) => item.DealType === 'Used').length;

  const newUnitsPace = calculateRequiredDailyPace(NEW_VEHICLES_GOAL, newUnits);
  const usedUnitsPace = calculateRequiredDailyPace(
    USED_VEHICLES_GOAL,
    usedUnits,
  );

  const unitData = [
    {
      label: 'New Units',
      requiredPace: newUnitsPace,
    },
    {
      label: 'Used Units',
      requiredPace: usedUnitsPace,
    },
  ];

  return (
    isAuthenticated && (
      <Paper sx={{ p: 2, height: '100%' }} elevation={0} variant="outlined">
        <Stack spacing={2}>
          <Typography variant="h6" sx={{ fontSize: '1rem' }}>
            Required Daily Pace to Hit Goals
          </Typography>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={unitData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="requiredPace"
                  fill="#8884d8"
                  name="Required Daily Pace"
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </Stack>
      </Paper>
    )
  );
};

export default RequiredDailyPaceChart;
