import React, { useState, useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Typography, Paper, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { API_BASE_URL } from 'config';
import axios from 'axios';

const WeeklySalesChart = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const dateRange = useMemo(() => {
    const today = dayjs();
    const startOfWeek = today.subtract(6, 'day').startOf('day');
    return [startOfWeek, today];
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.dealerid) return;

      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${API_BASE_URL}/api/deals/${user.dealerid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              startDate: dateRange[0].format('YYYY-MM-DD'),
              endDate: dateRange[1].format('YYYY-MM-DD'),
            },
          },
        );

        const processedData = processSalesData(response.data);
        setChartData(processedData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user?.dealerid, dateRange, getAccessTokenSilently]);

  function processSalesData(response) {
    const salesData = {
      Mo: { new: 0, used: 0 },
      Tu: { new: 0, used: 0 },
      We: { new: 0, used: 0 },
      Th: { new: 0, used: 0 },
      Fr: { new: 0, used: 0 },
      Sa: { new: 0, used: 0 },
      Su: { new: 0, used: 0 },
    };

    function getDayOfWeek(dateString) {
      const date = new Date(dateString);
      const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
      return days[date.getUTCDay()];
    }

    response.forEach((sale) => {
      if (sale.ContractDate) {
        const dayOfWeek = getDayOfWeek(sale.ContractDate);
        if (sale.DealType === 'New') {
          salesData[dayOfWeek].new++;
        } else if (sale.DealType === 'Used') {
          salesData[dayOfWeek].used++;
        }
      }
    });

    return Object.keys(salesData).map((day) => ({
      day,
      new: salesData[day].new,
      used: salesData[day].used,
    }));
  }

  function getLast7DaysLabels() {
    const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    const today = new Date();
    const todayIndex = today.getDay();
    const labels = [];

    for (let i = 6; i >= 0; i--) {
      const dayIndex = (todayIndex - i + 7) % 7;
      labels.push(days[dayIndex]);
    }

    return labels;
  }

  const xLabels = getLast7DaysLabels();
  const filledChartData = xLabels.map((day) => {
    const dataForDay = chartData.find((data) => data.day === day) || {
      day,
      new: 0,
      used: 0,
    };
    return dataForDay;
  });

  return (
    isAuthenticated && (
      <Paper sx={{ p: 2, height: '100%' }} elevation={0} variant="outlined">
        <Stack spacing={2}>
          <Typography variant="h6" sx={{ fontSize: '1rem' }}>
            Weekly Sales
          </Typography>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : error ? (
            <Typography color="error">Error: {error.message}</Typography>
          ) : (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={filledChartData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="new" stackId="sales" fill="#8884d8" />
                <Bar dataKey="used" stackId="sales" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </Stack>
      </Paper>
    )
  );
};

export default WeeklySalesChart;
