import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  GlobalStyles,
  styled,
} from '@mui/material';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0.5),
  height: '100%',
  border: '1px solid #000',
  boxShadow: 'none',
  '& h6': {
    fontSize: '0.7rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
    borderBottom: '1px solid #000',
    paddingBottom: theme.spacing(0.25),
  },
  '& .MuiTypography-body2': {
    fontSize: '0.65rem',
    lineHeight: 1.2,
    marginBottom: theme.spacing(0.25),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const ChecklistItem = ({ label, checked }) => (
  <FormControlLabel
    control={
      <Checkbox checked={checked} size="small" style={{ color: '#000' }} />
    }
    label={
      <Typography variant="body2" style={{ fontSize: '0.6rem' }}>
        {label}
      </Typography>
    }
    sx={{
      '& .MuiCheckbox-root': {
        paddingY: 0.25,
      },
    }}
  />
);

const PrintCoversheet = ({ formData }) => {
  const dealChecklistItems = [
    { name: 'salesmenu', label: 'SALES MENU' },
    { name: 'dealscreen', label: 'DEAL SCREEN' },
    { name: 'gscreen', label: 'G SCREEN' },
    { name: 'inoutform', label: 'IN/OUT FORM' },
    { name: 'rebateforms', label: 'REBATE FORMS' },
    { name: 'taxverification', label: 'TAX RATE VERIFICATION' },
  ];

  const customerChecklistItems = [
    { name: 'driverslicense', label: 'DRIVERS LICENSE' },
    { name: 'insurance', label: 'INSURANCE' },
    { name: 'creditapp', label: 'CREDIT APP' },
    { name: 'creditreport', label: 'CREDIT REPORT' },
    { name: 'ofac', label: 'OFAC' },
    { name: 'redflags', label: 'IDOne Verification' },
  ];

  const newChecklistItems = [
    { name: 'invoice', label: 'INVOICE' },
    { name: 'addendum', label: 'SIGNED ADDENDUM' },
    { name: 'connectedservices', label: 'CONNECTED SERVICES' },
    { name: 'pdichecklist', label: 'PDI CHECKLIST' },
    { name: 'partialautomationdisclosure', label: 'AUTOMATION DISCLOSURE' },
    { name: 'newrecallverification', label: 'RECALL VERIFICATION' },
  ];

  const usedChecklistItems = [
    { name: 'booksheet', label: 'BOOKSHEET' },
    { name: 'buyersguide', label: 'BUYERS GUIDE' },
    { name: 'carfax', label: 'CARFAX' },
    { name: 'ksr', label: 'KSR | NMVTIS' },
    { name: 'cpochecklist', label: 'CPO CHECKLIST' },
    { name: 'usedrecallverification', label: 'RECALL VERIFICATION' },
    { name: 'dmvcalc', label: 'DMV CALCULATION' },
  ];

  return (
    <>
      <GlobalStyles
        styles={{
          '@media print': {
            '@page': {
              size: 'letter',
              margin: '0.5in',
            },
            'body, html': {
              visibility: 'hidden',
              height: '100%',
              overflow: 'hidden',
              margin: 0,
              padding: 0,
            },
            '#print-coversheet': {
              visibility: 'visible',
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
            },
            '.MuiPaper-root': {
              boxShadow: 'none !important',
            },
          },
        }}
      />
      <Box
        id="print-coversheet"
        sx={{
          width: '8.5in',
          height: '11in',
          p: 0,
          boxSizing: 'border-box',
          fontSize: '8pt',
          '@media screen': {
            display: 'none',
          },
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 0.5 }}
        >
          <Grid item xs={4}>
            <Typography
              variant="body2"
              sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}
            >
              Deal #: {formData.dealno}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="h5"
              align="center"
              sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}
            >
              FRONTIER TOYOTA
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body2"
              align="right"
              sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}
            >
              Date: {formData.date}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={0.5}>
          {/* Customer Information and Vehicle Information */}
          <Grid item xs={6}>
            <StyledPaper>
              <Typography variant="h6">Customer Information</Typography>
              <Typography variant="body2">
                <strong>Email:</strong> {formData.email}
              </Typography>
              <Typography variant="body2">
                <strong>Phone:</strong> {formData.phone}
              </Typography>
              <Typography variant="body2">
                <strong>Buyer:</strong> {formData.buyername} ({formData.buyerno}
                )
              </Typography>
              <Typography variant="body2">
                <strong>Co-Buyer:</strong> {formData.cobuyername} (
                {formData.cobuyerno})
              </Typography>
            </StyledPaper>
          </Grid>
          <Grid item xs={6}>
            <StyledPaper>
              <Typography variant="h6">Vehicle Information</Typography>
              <Typography variant="body2">
                <strong>Status:</strong> {formData.vehiclestatus}
              </Typography>
              <Typography variant="body2">
                <strong>Stock #:</strong> {formData.stockno}
              </Typography>
              <Typography variant="body2">
                <strong>Type:</strong> {formData.vehicletype}
              </Typography>
              <Typography variant="body2">
                <strong>Year:</strong> {formData.year}
              </Typography>
              <Typography variant="body2">
                <strong>Make:</strong> {formData.make}
              </Typography>
              <Typography variant="body2">
                <strong>Model:</strong> {formData.model}
              </Typography>
              <Typography variant="body2">
                <strong>Miles:</strong> {formData.miles}
              </Typography>
            </StyledPaper>
          </Grid>

          {/* Personnel Information and Deal Structure */}
          <Grid item xs={6}>
            <StyledPaper>
              <Typography variant="h6">Personnel Information</Typography>
              <Typography variant="body2">
                <strong>Salesperson 1:</strong> {formData.sp1}
              </Typography>
              <Typography variant="body2">
                <strong>Salesperson 2:</strong> {formData.sp2}
              </Typography>
              <Typography variant="body2">
                <strong>Sales Manager:</strong> {formData.salesmanager}
              </Typography>
              <Typography variant="body2">
                <strong>Closer:</strong> {formData.teamleaders}
              </Typography>
              <Typography variant="body2">
                <strong>F&I Manager:</strong> {formData.fimanager}
              </Typography>
            </StyledPaper>
          </Grid>
          <Grid item xs={6}>
            <StyledPaper>
              <Typography variant="h6">Deal Structure</Typography>
              <Typography variant="body2">
                <strong>Prior Reserved:</strong>{' '}
                {formData.reserved ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body2">
                <strong>Type:</strong> {formData.purchasetype}
              </Typography>
              <Typography variant="body2">
                <strong>Down Payment:</strong> {formData.downpayment}
              </Typography>
              <Typography variant="body2">
                <strong>Term:</strong> {formData.term}
              </Typography>
              <Typography variant="body2">
                <strong>Rate:</strong> {formData.rate}
              </Typography>
              <Typography variant="body2">
                <strong>Payment:</strong> {formData.payment}
              </Typography>
            </StyledPaper>
          </Grid>

          {/* Checklists */}
          <Grid item xs={3}>
            <StyledPaper>
              <Typography variant="h6">Deal Checklist</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {dealChecklistItems.map((item) => (
                  <ChecklistItem
                    key={item.name}
                    label={item.label}
                    checked={formData[item.name]}
                  />
                ))}
              </Box>
            </StyledPaper>
          </Grid>
          <Grid item xs={3}>
            <StyledPaper>
              <Typography variant="h6">Customer Checklist</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {customerChecklistItems.map((item) => (
                  <ChecklistItem
                    key={item.name}
                    label={item.label}
                    checked={formData[item.name]}
                  />
                ))}
              </Box>
            </StyledPaper>
          </Grid>
          <Grid item xs={3}>
            <StyledPaper>
              <Typography variant="h6">New Checklist</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {newChecklistItems.map((item) => (
                  <ChecklistItem
                    key={item.name}
                    label={item.label}
                    checked={formData[item.name]}
                  />
                ))}
              </Box>
            </StyledPaper>
          </Grid>
          <Grid item xs={3}>
            <StyledPaper>
              <Typography variant="h6">Used Checklist</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {usedChecklistItems.map((item) => (
                  <ChecklistItem
                    key={item.name}
                    label={item.label}
                    checked={formData[item.name]}
                  />
                ))}
              </Box>
            </StyledPaper>
          </Grid>

          {/* Trades */}
          <Grid item xs={12}>
            <StyledPaper>
              <Typography variant="h6">Trades</Typography>
              <Grid container spacing={0.5}>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: '0.65rem', fontWeight: 'bold' }}
                  >
                    Trade 1
                  </Typography>
                  <Grid container spacing={0.5}>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        <strong>Stock #:</strong> {formData.tradestock1}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Year:</strong> {formData.tradeyear1}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Make:</strong> {formData.trademake1}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Model:</strong> {formData.trademodel1}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        <strong>Miles:</strong> {formData.trademiles1}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Allow:</strong> {formData.tradeallow1}
                      </Typography>
                      <Typography variant="body2">
                        <strong>ACV:</strong> {formData.tradeacv1}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Payoff:</strong> {formData.tradepayoff1}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: '0.65rem', fontWeight: 'bold' }}
                  >
                    Trade 2
                  </Typography>
                  <Grid container spacing={0.5}>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        <strong>Stock #:</strong> {formData.tradestock2}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Year:</strong> {formData.tradeyear2}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Make:</strong> {formData.trademake2}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Model:</strong> {formData.trademodel2}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        <strong>Miles:</strong> {formData.trademiles2}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Allow:</strong> {formData.tradeallow2}
                      </Typography>
                      <Typography variant="body2">
                        <strong>ACV:</strong> {formData.tradeacv2}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Payoff:</strong> {formData.tradepayoff2}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>

          {/* Products and Stips */}
          <Grid item xs={4}>
            <StyledPaper>
              <Typography variant="h6">Taxable Products</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {[
                  'presold1',
                  'presold2',
                  'presold3',
                  'presold4',
                  'presold5',
                ].map((item) => (
                  <ChecklistItem
                    key={item}
                    label={formData[`${item}Desc`]}
                    checked={formData[item]}
                  />
                ))}
              </Box>
            </StyledPaper>
          </Grid>
          <Grid item xs={4}>
            <StyledPaper>
              <Typography variant="h6">Non-Taxable Products</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {[
                  'presold6',
                  'presold7',
                  'presold8',
                  'presold9',
                  'presold10',
                ].map((item) => (
                  <ChecklistItem
                    key={item}
                    label={formData[`${item}Desc`]}
                    checked={formData[item]}
                  />
                ))}
              </Box>
            </StyledPaper>
          </Grid>
          <Grid item xs={4}>
            <StyledPaper>
              <Typography variant="h6">Stips</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {['stip1', 'stip2', 'stip3', 'stip4', 'stip5'].map((item) => (
                  <ChecklistItem
                    key={item}
                    label={formData[`${item}Desc`]}
                    checked={formData[item]}
                  />
                ))}
              </Box>
            </StyledPaper>
          </Grid>

          {/* Notes */}
          <Grid item xs={12}>
            <StyledPaper>
              <Typography variant="h6">Notes</Typography>
              <Box sx={{ height: '80px' }}>
                <Typography variant="body2" sx={{ fontSize: '0.65rem' }}>
                  {formData.notes}
                </Typography>
              </Box>
            </StyledPaper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PrintCoversheet;
