import React, { useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import axios from 'axios';
import { API_BASE_URL } from 'config';

const RebateTable = ({ data }) => {
  const [rebates, setRebates] = useState(data);

  // Handle cell edits
  const handleCellEdit = (params) => {
    const updatedRebates = rebates.map((rebate) => {
      if (rebate.id === params.id) {
        return { ...rebate, [params.field]: params.value };
      }
      return rebate;
    });

    // Update the local state immediately
    setRebates(updatedRebates);

    // Send the updated data to your backend
    const updatedRebate = updatedRebates.find(
      (rebate) => rebate.id === params.id,
    );
    axios
      .put(`${API_BASE_URL}/api/incentives/${params.id}`, updatedRebate)
      .then((response) => {
        // Handle success
        console.log('Rebate updated:', response.data);
      })
      .catch((error) => {
        console.error('Error updating rebate:', error);
      });
  };

  const columns = [
    { field: 'name', headerName: 'Name', width: 200, editable: true },
    { field: 'amount', headerName: 'Amount', width: 150, editable: true },
    { field: 'logic', headerName: 'Operator', width: 150, editable: true },
  ];

  return (
    <>
      {/* Add the Editable DataGridPro here */}
      <DataGridPro
        rows={rebates}
        columns={columns}
        getRowId={(row) => row._id}
        onEditCellChange={handleCellEdit}
        hideFooter
        sx={{ minHeight: '100px' }} // Adjust the height as needed
      />
    </>
  );
};

export default RebateTable;
