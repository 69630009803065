import React, { useState } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const WeOweForm = ({ formData, handleAddWeOwe, handleRemoveWeOwe }) => {
  const [weOwe, setWeOwe] = useState({
    description: '',
    type: 'front',
    cost: '',
    sale: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWeOwe((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddWeOwe(weOwe);
    setWeOwe({ description: '', type: 'front', cost: '', sale: '' });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="description"
              label="Description"
              value={weOwe.description}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                name="type"
                value={weOwe.type}
                onChange={handleChange}
                label="Type"
              >
                <MenuItem value="front">Front</MenuItem>
                <MenuItem value="back">Back</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              name="cost"
              label="Cost"
              type="number"
              value={weOwe.cost}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              name="sale"
              label="Sale"
              type="number"
              value={weOwe.sale}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Add WeOwe
            </Button>
          </Grid>
        </Grid>
      </form>
      <List>
        {formData.WeOwes.map((weOwe, index) => (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleRemoveWeOwe(index)}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText
              primary={weOwe.description}
              secondary={`${
                weOwe.type.charAt(0).toUpperCase() + weOwe.type.slice(1)
              } - Cost: $${weOwe.cost}, Sale: $${weOwe.sale}`}
            />
          </ListItem>
        ))}
      </List>
      <Typography variant="subtitle1">
        Front WeOwes Gross Sales: ${formData.FrontWeOwesGrossSales}
      </Typography>
      <Typography variant="subtitle1">
        Front WeOwes Gross Cost: ${formData.FrontWeOwesGrossCost}
      </Typography>
      <Typography variant="subtitle1">
        Back WeOwes: ${formData.BackWeOwes}
      </Typography>
    </>
  );
};

export default WeOweForm;
