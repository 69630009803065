import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from 'config';
import { useAuth0 } from '@auth0/auth0-react';
import { useLenders } from 'hooks/useLenders';
import {
  Autocomplete,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Divider,
  FormGroup,
  Input,
  FormHelperText,
  Stack,
} from '@mui/material';

import {manufacturers} from 'data/manufacturers';
import LenderScoreRangesTable from './LenderScoreRangesTable';

const LendersDataGrid = () => {
  const { user } = useAuth0();
  const { lenders } = useLenders(user?.dealerid);
  const [selectedLender, setSelectedLender] = useState({
    Active: false,
    ActiveForLease: false,
    ActiveForRetail: false,
  });
  const [snackbar, setSnackbar] = useState({ message: '', open: false });
  const [tempSupportedYears, setTempSupportedYears] = useState('');
  const [tempResidualTerms, setTempResidualTerms] = useState('');

    console.log(manufacturers);

  useEffect(() => {
    if (lenders.length > 0) {
      const firstLender = lenders[0];
      setSelectedLender({
        ...firstLender,
        ScoreRanges: firstLender.ScoreRanges || [],
      });
      setTempSupportedYears(firstLender.SupportedYears.join(', '));
      setTempResidualTerms(firstLender.ResidualTerms.join(', '));
    }
  }, [lenders]);

  const handleLenderChange = (event) => {
    const lender = lenders.find((l) => l._id === event.target.value) || {};
    setSelectedLender(lender);
  };

  const handleFieldChange = async (field, value) => {
    setSelectedLender((prev) => ({ ...prev, [field]: value }));
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/lenders/${selectedLender?._id}`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ [field]: value }),
        },
      );

      if (!response.ok) {
        throw new Error('Error updating lender');
      }
    } catch (error) {
      setSnackbar({
        message: `Error updating lender: ${error.message}`,
        open: true,
      });
    }
  };

  const handleSaveSupportedYears = () => {
    const yearsArray = tempSupportedYears
      .split(',')
      .map((year) => parseInt(year.trim()));

    handleFieldChange('SupportedYears', yearsArray);
  };

  const handleSaveResidualTerms = () => {
    const termsArray = tempResidualTerms
      .split(',')
      .map((term) => parseInt(term.trim()));

    handleFieldChange('ResidualTerms', termsArray);
  };

  const handleScoreRangeChange = async (scoreRanges) => {
    console.log(scoreRanges);
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/lenders/${selectedLender?._id}`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ScoreRanges: scoreRanges }),
        },
      );

      if (!response.ok) {
        throw new Error('Error updating score ranges');
      }

      setSelectedLender({ ...selectedLender, ScoreRanges: scoreRanges });
    } catch (error) {
      setSnackbar({
        message: `Error updating score ranges: ${error.message}`,
        open: true,
      });
    }
  };

  return (
    <>
      <Box>
        <Typography
          fontWeight="bold"
          fontSize="1em"
          gutterBottom
          component="div"
        >
          Details
        </Typography>
        <Typography variant="caption" gutterBottom component="div">
          Update the Lender Settings
        </Typography>
      </Box>
      <FormControl sx={{ my: 2, minWidth: 240 }} variant="standard">
        <InputLabel id="select-lender">Select Lender</InputLabel>
        <Select
          htmlFor="select-lender"
          label="Select Lender"
          value={selectedLender?._id || ''}
          onChange={handleLenderChange}
        >
          {lenders.map((lender) => (
            <MenuItem key={lender._id} value={lender._id}>
              {lender.Name} ({lender.ShortCode})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Divider sx={{ my: 2 }} />
      {selectedLender && (
        <Box component="form" noValidate autoComplete="off">
          <Box>
            <Typography
              fontWeight="bold"
              fontSize="1em"
              gutterBottom
              component="div"
            >
              Select Active Status
            </Typography>
            <Typography variant="caption" gutterBottom component="div">
              Control what the lender is active for.
            </Typography>
          </Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedLender.Active}
                  onChange={(e) =>
                    handleFieldChange('Active', e.target.checked)
                  }
                  color="primary"
                />
              }
              label="Active"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedLender.ActiveForLease}
                  onChange={(e) =>
                    handleFieldChange('ActiveForLease', e.target.checked)
                  }
                  color="primary"
                />
              }
              label="Active for Lease"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedLender.ActiveForRetail}
                  onChange={(e) =>
                    handleFieldChange('ActiveForRetail', e.target.checked)
                  }
                  color="primary"
                />
              }
              label="Active for Retail"
            />
          </FormGroup>
          <Divider sx={{ my: 2 }} />
          <Box>
            <Typography
              fontWeight="bold"
              fontSize="1em"
              gutterBottom
              component="div"
            >
              Primary Manufacturer
            </Typography>
            <Typography variant="caption" gutterBottom component="div">
              Does this lender have a primary manufacturer?
            </Typography>
          </Box>
          <Autocomplete
            id="automotive-manufacturers"
            options={Array.isArray(manufacturers) ? manufacturers : []}
            value={selectedLender.PrimaryManufacturer || null}
            onChange={(e, newValue) =>
              handleFieldChange('PrimaryManufacturer', newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Select Manufacturer"
                placeholder="Select manufacturer"
              />
            )}
            sx={{ width: 300 }}
          />
          <Divider sx={{ my: 2 }} />
          <Box>
            <Typography
              fontWeight="bold"
              fontSize="1em"
              gutterBottom
              component="div"
            >
              Supported Years
            </Typography>
            <Typography variant="caption" gutterBottom component="div">
              Which model years does this lender support?
            </Typography>
          </Box>
          <Stack direction="row" spacing={2}>
            <FormControl variant="standard">
              <InputLabel htmlFor="support-years">Years</InputLabel>
              <Input
                htmlFor="support-years"
                label="Years"
                value={tempSupportedYears}
                onChange={(e) => setTempSupportedYears(e.target.value)}
              />
              <FormHelperText id="year-helper-text">
                Separate years with a comma
              </FormHelperText>
            </FormControl>
            <Box sx={{ ml: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveSupportedYears}
              >
                Save
              </Button>
            </Box>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Box>
            <Typography
              fontWeight="bold"
              fontSize="1em"
              gutterBottom
              component="div"
            >
              Default Residual Terms
            </Typography>
            <Typography variant="caption" gutterBottom component="div">
              Enter the Terms for the Residuals
            </Typography>
          </Box>
          <Stack direction="row" spacing={2}>
            <FormControl variant="standard">
              <InputLabel htmlFor="lender-residual-terms">Terms</InputLabel>
              <Input
                htmlFor="lender-residual-terms"
                label="Terms"
                value={tempResidualTerms}
                onChange={(e) => setTempResidualTerms(e.target.value)}
                sx={{ width: 300 }}
              />
              <FormHelperText id="residual-terms-helper-text">
                Separate terms with a comma
              </FormHelperText>
            </FormControl>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveResidualTerms}
              >
                Save
              </Button>
            </Box>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Box>
            <Typography
              fontWeight="bold"
              fontSize="1em"
              gutterBottom
              component="div"
            >
              Default Score Ranges
            </Typography>
            <Typography variant="caption" gutterBottom component="div">
              Define Default Score Ranges
            </Typography>
          </Box>

          {/* Display the Default Score Ranges as a table */}
          <LenderScoreRangesTable
            scoreRanges={selectedLender ? selectedLender.ScoreRanges : []}
            handleEdit={(index, updatedRange) => {
              const updatedRanges = [...selectedLender.ScoreRanges];
              updatedRanges[index] = updatedRange;
              handleScoreRangeChange(updatedRanges);
            }}
            handleDelete={(index) => {
              const updatedRanges = selectedLender.ScoreRanges.filter(
                (_, idx) => idx !== index,
              );
              handleScoreRangeChange(updatedRanges);
            }}
          />
          <Divider sx={{ my: 2 }} />
        </Box>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        message={snackbar.message}
        action={
          <Button
            color="secondary"
            size="small"
            onClick={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          >
            CLOSE
          </Button>
        }
      />
    </>
  );
};

export default LendersDataGrid;
