import React from 'react';
import { TextField, Grid, Autocomplete } from '@mui/material';

const VehicleInformation = ({
  formData,
  handleChange,
  handleBlur,
  errorFields,
}) => {
  const statusOptions = [
    { label: 'IN STOCK', value: 'IN STOCK' },
    { label: 'IN TRANSIT', value: 'IN TRANSIT' },
  ];

  const typeOptions = [
    { label: 'NEW', value: 'NEW' },
    { label: 'USED', value: 'USED' },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <Autocomplete
          fullWidth
          options={statusOptions}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Status"
              variant="outlined"
              error={errorFields.includes('vehiclestatus')}
              helperText={
                errorFields.includes('vehiclestatus')
                  ? 'This field is required'
                  : ''
              }
              required
            />
          )}
          value={
            statusOptions.find(
              (option) => option.value === formData.vehiclestatus,
            ) || null
          }
          onChange={(event, newValue) => {
            handleChange({
              target: {
                name: 'vehiclestatus',
                value: newValue ? newValue.value : '',
              },
            });
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          autoComplete
          autoSelect
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          label="Stock Number"
          fullWidth
          name="stockno"
          value={formData.stockno}
          onChange={handleChange}
          onBlur={() => handleBlur('stockno')}
          error={errorFields.includes('stockno')}
          helperText={
            errorFields.includes('stockno') ? 'This field is required' : ''
          }
          required
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Autocomplete
          fullWidth
          options={typeOptions}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label="NEW/USED"
              variant="outlined"
              error={errorFields.includes('vehicletype')}
              helperText={
                errorFields.includes('vehicletype')
                  ? 'This field is required'
                  : ''
              }
              required
            />
          )}
          value={
            typeOptions.find(
              (option) => option.value === formData.vehicletype,
            ) || null
          }
          onChange={(event, newValue) => {
            handleChange({
              target: {
                name: 'vehicletype',
                value: newValue ? newValue.value : '',
              },
            });
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          autoComplete
          autoSelect
        />
      </Grid>
      <Grid item xs={12} sm={1}>
        <TextField
          label="Year"
          fullWidth
          name="year"
          value={formData.year}
          onChange={handleChange}
          inputProps={{ minLength: 4 }}
          onBlur={() => handleBlur('year')}
          error={errorFields.includes('year')}
          helperText={
            errorFields.includes('year') ? 'This field is required' : ''
          }
          required
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          label="Make"
          fullWidth
          name="make"
          value={formData.make}
          onChange={handleChange}
          onBlur={() => handleBlur('make')}
          error={errorFields.includes('make')}
          helperText={
            errorFields.includes('make') ? 'This field is required' : ''
          }
          required
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          label="Model"
          fullWidth
          name="model"
          value={formData.model}
          onChange={handleChange}
          onBlur={() => handleBlur('model')}
          error={errorFields.includes('model')}
          helperText={
            errorFields.includes('model') ? 'This field is required' : ''
          }
          required
        />
      </Grid>
      <Grid item xs={12} sm={1}>
        <TextField
          label="Miles"
          fullWidth
          name="miles"
          value={formData.miles}
          onChange={handleChange}
          onBlur={() => handleBlur('miles')}
          error={errorFields.includes('miles')}
          helperText={
            errorFields.includes('miles') ? 'This field is required' : ''
          }
          required
        />
      </Grid>
    </Grid>
  );
};

export default VehicleInformation;
