import React from 'react';
import { Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const SalesNamesForm = ({
  formData,
  onChange,
  activeSalesManagers,
  activeFIManagers,
  activeSalesPeople,
}) => {
  const handleChange = (name, value) => {
    onChange(name, value);
  };

  const sortByName = (a, b) => a.name.localeCompare(b.name);

  const sortedSalesManagers = [...activeSalesManagers].sort(sortByName);
  const sortedFIManagers = [...activeFIManagers].sort(sortByName);
  const sortedSalesPeople = [...activeSalesPeople].sort(sortByName);

  const creditOptions = [
    { label: '100%', value: '1000' },
    { label: '50%', value: '500' },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Autocomplete
          options={sortedSalesPeople}
          getOptionLabel={(option) => `${option.name} (${option.employeeId})`}
          value={
            sortedSalesPeople.find(
              (sp) => sp.employeeId === formData.CRMSP1Id,
            ) || null
          }
          onChange={(event, newValue) => {
            handleChange('CRMSP1Id', newValue ? newValue.employeeId : '');
            handleChange('CRMSP1Name', newValue ? newValue.name : '');
          }}
          renderInput={(params) => (
            <TextField {...params} label="Sales Person 1" fullWidth />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          options={creditOptions}
          getOptionLabel={(option) => option.label}
          value={
            creditOptions.find(
              (option) => option.value === formData.CRMSaleCreditSP1,
            ) || null
          }
          onChange={(event, newValue) => {
            handleChange(
              'CRMSaleCreditSP1',
              newValue ? newValue.value : '1000',
            );
          }}
          renderInput={(params) => (
            <TextField {...params} label="Sale Credit SP1" fullWidth />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          options={sortedSalesPeople}
          getOptionLabel={(option) => `${option.name} (${option.employeeId})`}
          value={
            sortedSalesPeople.find(
              (sp) => sp.employeeId === formData.CRMSP2Id,
            ) || null
          }
          onChange={(event, newValue) => {
            handleChange('CRMSP2Id', newValue ? newValue.employeeId : '');
            handleChange('CRMSP2Name', newValue ? newValue.name : '');
          }}
          renderInput={(params) => (
            <TextField {...params} label="Sales Person 2" fullWidth />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          options={creditOptions}
          getOptionLabel={(option) => option.label}
          value={
            creditOptions.find(
              (option) => option.value === formData.CRMSaleCreditSP2,
            ) || null
          }
          onChange={(event, newValue) => {
            handleChange('CRMSaleCreditSP2', newValue ? newValue.value : 0);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Sale Credit SP2" fullWidth />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          options={sortedSalesManagers}
          getOptionLabel={(option) => `${option.name} (${option.employeeId})`}
          value={
            sortedSalesManagers.find(
              (sm) => sm.employeeId === formData.CRMSalesMgrId,
            ) || null
          }
          onChange={(event, newValue) => {
            handleChange('CRMSalesMgrId', newValue ? newValue.employeeId : '');
            handleChange('CRMSalesMgrName', newValue ? newValue.name : '');
          }}
          renderInput={(params) => (
            <TextField {...params} label="Sales Manager" fullWidth />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          options={sortedFIManagers}
          getOptionLabel={(option) => `${option.name} (${option.employeeId})`}
          value={
            sortedFIManagers.find(
              (fm) => fm.employeeId === formData.CRMFIMgrId,
            ) || null
          }
          onChange={(event, newValue) => {
            handleChange('CRMFIMgrId', newValue ? newValue.employeeId : '');
            handleChange('CRMFIMgrName', newValue ? newValue.name : '');
          }}
          renderInput={(params) => (
            <TextField {...params} label="F&I Manager" fullWidth />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default SalesNamesForm;
