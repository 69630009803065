import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  Chip,
  MenuItem,
} from '@mui/material';
import {
  leaseTypes,
  securityDeposits,
  acqFeeOptions,
  milesPerYear,
} from '../constants';

export default function LeaseSettings({ deskingData, handleFieldChange }) {
  const {
    leasetype,
    milesperyear,
    leasereserve,
    securitydeposits,
    applyacqfeeto,
  } = deskingData;
  const [leaseTerms, setLeaseTerms] = useState(deskingData.leaseterms);
  const [driveOffs, setDriveOffs] = useState(deskingData.driveoffs);

  useEffect(() => {
    setLeaseTerms(deskingData.leaseterms);
    setDriveOffs(deskingData.driveoffs);
  }, [deskingData]);

  // Handlers
  const handleDriveoffsChange = (event, newValue) => {
    // Filter to allow only valid numeric values (including decimals)
    const validDriveoffs = newValue.filter((value) => {
      const num = parseFloat(value);
      return !isNaN(num) && num.toString() === value.toString();
    });

    setDriveOffs(validDriveoffs);
    handleFieldChange('driveoffs', validDriveoffs);
  };

  const handleTermsChange = (event, newValue) => {
    const validTerms = newValue.filter((value) => {
      const num = parseInt(value, 10);
      return !isNaN(num) && num >= 12 && num <= 63 && Number.isInteger(num);
    });

    setLeaseTerms(validTerms);
    handleFieldChange('leaseterms', validTerms);
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            id="leasetype"
            name="leasetype"
            select
            label="Rate Plan"
            value={leasetype}
            variant="outlined"
            fullWidth
            sx={{ mb: 1 }}
            onChange={(event) =>
              handleFieldChange('leasetype', event.target.value)
            }
          >
            {leaseTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Drive Offs */}
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="driveoffs"
            freeSolo
            options={[]}
            value={driveOffs}
            onChange={handleDriveoffsChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Drive Offs"
                placeholder="Enter Drive Offs"
              />
            )}
          />
        </Grid>

        {/* Lease Terms */}
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="lease-terms"
            freeSolo
            options={[]}
            value={leaseTerms}
            onChange={handleTermsChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Lease Terms"
                placeholder="Enter terms"
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="milesperyear"
            name="milesperyear"
            select
            label="Miles Per Year"
            value={milesperyear}
            variant="outlined"
            fullWidth
            sx={{ mb: 1 }}
            onChange={(event) =>
              handleFieldChange('milesperyear', event.target.value)
            }
          >
            {milesPerYear.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="leasereserve"
            name="leasereserve"
            value={leasereserve}
            label="Reserve"
            type="number"
            required
            variant="outlined"
            InputProps={{ inputProps: { step: 0.0001 } }}
            onChange={(event) =>
              handleFieldChange('leasereserve', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="securitydeposits"
            name="securitydeposits"
            select
            label="Security Deposits"
            value={securitydeposits}
            variant="outlined"
            fullWidth
            sx={{ mb: 1 }}
            onChange={(event) =>
              handleFieldChange('securitydeposits', event.target.value)
            }
          >
            {securityDeposits.map((deposit) => (
              <MenuItem key={deposit} value={deposit}>
                {deposit}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="applyacqfeeto"
            name="applyacqfeeto"
            select
            label="Aqu. Fee To"
            value={applyacqfeeto}
            variant="outlined"
            fullWidth
            sx={{ mb: 1 }}
            onChange={(event) =>
              handleFieldChange('applyacqfeeto', event.target.value)
            }
          >
            {acqFeeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Box>
  );
}
