const PrintRecap = (formData) => {
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value || 0);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };

  const generatePrintContent = () => {
    const sections = [
      {
        title: 'Basic Deal Information',
        fields: [
          ['WAQ Number', formData.WAQNumber],
          ['Host Item ID', formData.HostItemID],
          ['Contract Date', formatDate(formData.ContractDate)],
          ['Accounting Date', formatDate(formData.AccountingDate)],
          ['Sale Type', formData.SaleType],
          ['Deal Type', formData.DealType],
          ['FI Deal Type', formData.FIDealType],
        ],
      },
      {
        title: 'Customer Information',
        fields: [
          ['Name', formData.Name],
          ['Secondary Name', formData.Name1],
          ['Customer Number', formData.CustNo],
        ],
      },
      {
        title: 'Vehicle Information',
        fields: [
          ['VIN', formData.VIN],
          ['Year', formData.Year],
          ['Make', formData.Make],
          ['Model', formData.Model],
          ['Stock Number', formData.StockNo],
          ['MSRP', formatCurrency(formData.MSRP)],
          ['GL Vehicle Cost', formatCurrency(formData.GLVehicleCost)],
        ],
      },
      {
        title: 'Financial Information',
        fields: [
          ['Cash Price', formatCurrency(formData.CashPrice)],
          ['FI Income', formatCurrency(formData.FIIncome)],
          ['Front Gross', formatCurrency(formData.FrontGross)],
          ['Back Gross', formatCurrency(formData.BackGross)],
          ['Total Gross', formatCurrency(formData.TotalGross)],
          ['Gross Profit', formatCurrency(formData.GrossProfit)],
        ],
      },
      {
        title: 'We Owes',
        fields: [
          [
            'Front We Owes Gross Sales',
            formatCurrency(formData.FrontWeOwesGrossSales),
          ],
          [
            'Front We Owes Gross Cost',
            formatCurrency(formData.FrontWeOwesGrossCost),
          ],
          ['Back We Owes', formatCurrency(formData.BackWeOwes)],
        ],
      },
      {
        title: 'Contracts',
        fields: [
          ['MBI Income', formatCurrency(formData.MBIIncome)],
          ['INS2 Income', formatCurrency(formData.Ins2Income)],
          ['INS3 Income', formatCurrency(formData.Ins3Income)],
        ],
      },
      {
        title: 'Sales Information',
        fields: [
          ['SP1 ID', formData.CRMSP1Id],
          ['SP1 Name', formData.CRMSP1Name],
          ['SP1 Sale Credit', formData.CRMSaleCreditSP1],
          ['SP1 Spiff', formatCurrency(formData.CRMSpiffSP1)],
          ['SP1 Commission', formatCurrency(formData.CRMCommisionSP1)],
          ['SP2 ID', formData.CRMSP2Id],
          ['SP2 Name', formData.CRMSP2Name],
          ['SP2 Sale Credit', formData.CRMSaleCreditSP2],
          ['SP2 Spiff', formatCurrency(formData.CRMSpiffSP2)],
          ['SP2 Commission', formatCurrency(formData.CRMCommisionSP2)],
          ['SP3 ID', formData.CRMSP3Id],
          ['SP3 Name', formData.CRMSP3Name],
          ['SP3 Sale Credit', formData.CRMSaleCreditSP3],
          ['SP3 Spiff', formatCurrency(formData.CRMSpiffSP3)],
          ['SP3 Commission', formatCurrency(formData.CRMCommisionSP3)],
        ],
      },
      {
        title: 'Management Information',
        fields: [
          ['Sales Manager ID', formData.CRMSalesMgrId],
          ['Sales Manager Name', formData.CRMSalesMgrName],
          ['Sales Manager Spiff', formatCurrency(formData.CRMSpiffSalesMgr)],
          ['FI Manager ID', formData.CRMFIMgrId],
          ['FI Manager Name', formData.CRMFIMgrName],
          ['FI Manager Spiff', formatCurrency(formData.CRMSpiffFIMgr)],
          ['FI Manager Sale Credit', formData.CRMSaleCreditFIMgr],
          ['Closing Manager ID', formData.CRMClosingMgrId],
          ['Closing Manager Name', formData.CRMClosingMgrName],
          [
            'Closing Manager Spiff',
            formatCurrency(formData.CRMSpiffClosingMgr),
          ],
          ['Closing Manager Sale Credit', formData.CRMSaleCreditClosingMgr],
        ],
      },
      {
        title: 'Additional Information',
        fields: [
          ['FI WIP Status Code', formData.FiWipStatusCode],
          ['Finance Source', formData.FinanceSource],
          ['Commission Pack', formatCurrency(formData.CommissionPack)],
        ],
      },
    ];

    return sections
      .map(
        (section) => `
        <div class="section">
          <h2>${section.title}</h2>
          ${section.fields
            .map(
              ([label, value]) => `
            <div class="field">
              <span class="label">${label}:</span> ${value || ''}
            </div>
          `,
            )
            .join('')}
        </div>
      `,
      )
      .join('');
  };

  const printWindow = window.open('', '_blank');
  printWindow.document.write(`
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Deal Recap</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              font-size: 9px;
              line-height: 1.2;
              margin: 0;
              padding: 10px;
            }
            h1 { 
              font-size: 14px; 
              margin-bottom: 10px; 
              text-align: center;
            }
            h2 { 
              font-size: 12px; 
              margin-top: 10px; 
              margin-bottom: 5px; 
              border-bottom: 1px solid #000;
            }
            .section { 
              margin-bottom: 10px; 
              break-inside: avoid;
            }
            .field { 
              margin-bottom: 2px; 
              display: flex;
              justify-content: space-between;
            }
            .label { 
              font-weight: bold; 
              margin-right: 5px;
            }
            @media print {
              body { 
                -webkit-print-color-adjust: exact; 
              }
              .section {
                page-break-inside: avoid;
              }
            }
            @page {
              size: auto;
              margin: 5mm;
            }
          </style>
        </head>
        <body>
          <h1>Deal Recap</h1>
          ${generatePrintContent()}
        </body>
      </html>
    `);
  printWindow.document.close();
  printWindow.print();
};

export default PrintRecap;
