import React, { useState, useCallback } from 'react';
import {
  DataGridPro,
  GridToolbar,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import SummaryTable from './overviewSummaryTable';
import columns from './columns';
import axios from 'axios';
import { API_BASE_URL } from 'config';
import { useAuth0 } from '@auth0/auth0-react';

const OverviewTable = ({ tableData, isLoading, setTableData }) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleDeleteClick = (id) => {
    if (user && user['https://www.dealeros.io/roles'].includes('admin')) {
      setDeleteId(id);
      setOpenConfirmDialog(true);
    } else {
      console.warn('User is not authorized to delete.');
    }
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setDeleteId(null);
  };

  const handleConfirmDelete = async () => {
    setDeleteLoading(true);
    try {
      const token = await getAccessTokenSilently();
      await axios.delete(`${API_BASE_URL}/fisaleshistory/${deleteId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTableData((prevData) => prevData.filter((row) => row.id !== deleteId));
      handleCloseConfirmDialog();
    } catch (error) {
      console.error('Error deleting row:', error);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleEditCellChange = useCallback(
    async (params) => {
      const { id, field, value } = params;
      try {
        const token = await getAccessTokenSilently();
        await axios.put(
          `${API_BASE_URL}/fisaleshistory/${id}`,
          { [field]: value },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setTableData((prevData) =>
          prevData.map((row) =>
            row.id === id ? { ...row, [field]: value } : row,
          ),
        );
      } catch (error) {
        console.error('Error updating cell:', error);
        // Revert the change in the UI
        setTableData((prevData) => [...prevData]);
      }
    },
    [getAccessTokenSilently, setTableData],
  );

  const columnsWithActions = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(params.id);
            }}
            color="error"
            size="small"
            disabled={deleteLoading}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
    ...columns.filter((col) => col.field !== 'delete'),
  ];

  const editableColumns = ['FrontGross', 'BackGross', 'TotalGross'];
  const columnsWithEdit = columnsWithActions.map((col) => {
    if (editableColumns.includes(col.field)) {
      return {
        ...col,
        editable: true,
      };
    }
    return col;
  });

  return (
    <>
      <DataGridPro
        sx={{
          border: 'none',
          borderRadius: 0,
        }}
        rows={tableData}
        columns={columnsWithEdit}
        loading={isLoading || deleteLoading}
        getRowId={(row) => row.id}
        density="compact"
        initialState={{
          columns: {
            columnVisibilityModel: {
              CRMCommisionSP1: false,
              CRMCommisionSP2: false,
              CRMCommisionSP3: false,
              CRMCommisionClosingMgr: false,
              CRMCommisionFIMgr: false,
              CRMCommisionSalesMgr: false,
              CRMCommisionTotal: false,
            },
          },
          sorting: {
            sortModel: [{ field: 'ContractDate', sort: 'desc' }],
          },
        }}
        slots={{
          toolbar: () => (
            <Box display="flex" flexDirection="column" padding={2}>
              <Box display="flex" alignItems="baseline">
                <GridToolbar />
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarQuickFilter />
              </Box>
              <Box padding={1}>
                <SummaryTable tableData={tableData} />
              </Box>
            </Box>
          ),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        disableRowSelectionOnClick
        editMode="cell"
        onCellEditStop={handleEditCellChange}
      />
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Deletion'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this record? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OverviewTable;
