import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ListItemText,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@mui/material';
import {
  DataGridPro,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from '@mui/x-data-grid-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { GlobalStyles } from '@mui/material';

import { API_BASE_URL } from 'config';
import axios from 'axios'; // Assuming axios is used for HTTP requests

// Define a formatter function for currency values
const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD', // Adjust the currency code as needed
  }).format(value);
};

const shortcutsItems = [
  {
    label: 'Month to Date',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today];
    },
  },
  {
    label: 'Previous Month',
    getValue: () => {
      const today = dayjs();
      const startOfPrevMonth = today.subtract(1, 'month').startOf('month');
      const endOfPrevMonth = today.subtract(1, 'month').endOf('month');
      return [startOfPrevMonth, endOfPrevMonth];
    },
  },

  {
    label: 'This Week',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week'), today.endOf('week')];
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: 'Current Month',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    },
  },
  { label: 'Reset', getValue: () => [dayjs().startOf('month'), dayjs()] },
];

const deal_types = ['All', 'New', 'Used', 'Other'];
const deal_status = ['B', 'F', 'I', 'P', 'C', 'U'];
const employee_types = [
  { label: 'Salesperson', value: 'salesperson' },
  { label: 'Finance Manager', value: 'financemanager' },
  { label: 'Sales Manager', value: 'salesmanager' },
  { label: 'Closer', value: 'closer' },
];

const Experimental = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [gridData, setGridData] = React.useState([]);
  const [summaryData, setSummaryData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [dateRange, setDateRange] = React.useState([
    dayjs().startOf('month'),
    dayjs(),
  ]);
  const [dealType, setDealType] = React.useState('All');
  const [dealStatus, setDealStatus] = React.useState(['B', 'F']);
  const [employeeType, setEmployeeType] = React.useState('salesperson');

  const [pinnedRow, setPinnedRow] = React.useState([]);

  const defaultMainColumnVisibilityModel = React.useMemo(
    () => ({
      TotalFrontGross: true,
      PVRFrontGross: false,
      TotalProductGross: true,
      PVRProductGross: false,
      TotalFIIncome: true,
      PVRFIIncome: false,
      TotalBackGross: true,
      PVRBackGross: false,
      TotalGross: true,
      PVRTotalGross: false,
    }),
    [],
  );

  const defaultColumnVisibilityModel = React.useMemo(
    () => ({
      CRMSP1Name: false,
      CRMSaleCreditSP1: false,
      CRMSP2Name: false,
      CRMSaleCreditSP2: false,
      CRMSP3Name: false,
      CRMSaleCreditSP3: false,
      CRMClosingMgrName: false,
      CRMSalesMgrName: false,
      CRMFIMgrName: false,
      VIN: false,
    }),
    [],
  );

  //Set Main Table Column Visibility based upon Employee Type
  const [mainColumnVisibilityModel, setMainColumnVisibilityModel] =
    React.useState(defaultMainColumnVisibilityModel);

  //Set Column Visibility based upon Employee Type
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    defaultColumnVisibilityModel,
  );

  // New state to track expanded row IDs
  const [expandedRowIds, setExpandedRowIds] = React.useState([]);

  //Switch Column Visibility based upon Employee Type
  useEffect(() => {
    if (employeeType === 'salesperson') {
      setMainColumnVisibilityModel({
        ...defaultMainColumnVisibilityModel,
      });
      setColumnVisibilityModel({
        ...defaultColumnVisibilityModel,
        Products: false,
        BackGross: false,
        ProductGross: false,
        FIIncome: false,
        TotalGross: false,
      });
    } else if (employeeType === 'financemanager') {
      setMainColumnVisibilityModel({
        TotalFrontGross: false,
        PVRFrontGross: false,
        TotalGross: false,
        PVRTotalGross: false,
      });

      setColumnVisibilityModel({
        ...defaultColumnVisibilityModel,
        PayableFrontGross: false,
        PayableBackGross: false,
        FrontGross: false,
        CommGross: false,
        TotalGross: false,
        BonusCommission: false,
      });
    } else if (employeeType === 'salesmanager') {
      setMainColumnVisibilityModel({
        ...defaultMainColumnVisibilityModel,
        TotalCommission: false,
        TotalBonus: false,
      });
      setColumnVisibilityModel({
        ...defaultColumnVisibilityModel,
        PayableFrontGross: false,
        PayableBackGross: false,
        primaryEmployeeCommission: false,
        CalculatedCommission: false,
        BonusCommission: false,
      });
    } else if (employeeType === 'closer') {
      setMainColumnVisibilityModel({
        ...defaultMainColumnVisibilityModel,
      });
      setColumnVisibilityModel({
        ...defaultColumnVisibilityModel,
      });
    }
  }, [
    employeeType,
    defaultMainColumnVisibilityModel,
    defaultColumnVisibilityModel,
  ]);

  // Function to handle expanding all rows
  const toggleExpandAll = () => {
    setExpandedRowIds((prevExpandedRowIds) => {
      if (prevExpandedRowIds.length === gridData.length) {
        return []; // Collapse all rows if all are expanded
      } else {
        return gridData.map((row) => row.EmployeeID); // Expand all rows
      }
    });
  };

  const handleDealChange = (event) => {
    setDealType(event.target.value);
  };

  // Define columns for the DataGrid
  const columns = [
    { field: 'EmployeeID', headerName: 'Employee No.', minWidth: 100, flex: 1 },
    {
      field: 'EmployeeName',
      headerName: 'Employee Name',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'PayplanCategory',
      headerName: 'Plan Type',
      minWidth: 75,
      flex: 1,
    },
    {
      field: 'TotalDealCredit',
      headerName: 'Deal Credit',
      minWidth: 70,
      flex: 1,
    },
    {
      field: 'TotalFrontGross',
      headerName: 'Front Gross',
      minWidth: 115,
      flex: 1,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'PVRFrontGross',
      headerName: 'PVR FEG',
      minWidth: 115,
      flex: 1,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'TotalProductGross',
      headerName: 'Product Gross',
      minWidth: 115,
      flex: 1,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'PVRProductGross',
      headerName: 'PVR PG',
      minWidth: 115,
      flex: 1,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'TotalFIIncome',
      headerName: 'Reserve',
      minWidth: 115,
      flex: 1,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'PVRFIIncome',
      headerName: 'PVR Reserve',
      minWidth: 115,
      flex: 1,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'TotalBackGross',
      headerName: 'Back Gross',
      minWidth: 115,
      flex: 1,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'PVRBackGross',
      headerName: 'PVR BG',
      minWidth: 115,
      flex: 1,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'TotalGross',
      headerName: 'Total Gross',
      minWidth: 115,
      flex: 1,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'PVRTotalGross',
      headerName: 'PVR TG',
      minWidth: 115,
      flex: 1,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'TotalCommission',
      headerName: 'Commission',
      minWidth: 115,
      flex: 1,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'CalculatedCommission',
      headerName: 'Audit',
      minWidth: 115,
      flex: 1,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      field: 'TotalBonus',
      headerName: 'Bonus',
      minWidth: 115,
      flex: 1,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
  ];

  // Effect to fetch data whenever filters change
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${API_BASE_URL}/api/deals/${user.dealerid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              startDate: dateRange[0].format('YYYY-MM-DD'),
              endDate: dateRange[1].format('YYYY-MM-DD'),
              dealType,
              statusCode: dealStatus.join(','),
              employeeType: employeeType, // Map your UI value to the expected API value if necessary
            },
          },
        );
        const summary = response.data.summary;
        const details = response.data.details;

        // Add Summary row to the beginning of the data array
        const summaryRow = {
          EmployeeID: 'Summary',
          EmployeeName: '',
          PayplanCategory: '',
          TotalDealCredit: details.reduce(
            (acc, curr) => acc + curr.TotalDealCredit,
            0,
          ),
          TotalFrontGross: details.reduce(
            (acc, curr) => acc + curr.TotalFrontGross,
            0,
          ),
          PVRFrontGross:
            details.reduce((acc, curr) => acc + curr.TotalFrontGross, 0) /
            details.reduce((acc, curr) => acc + curr.TotalDealCredit, 0),
          TotalProductGross: details.reduce(
            (acc, curr) => acc + curr.TotalProductGross,
            0,
          ),
          PVRProductGross:
            details.reduce((acc, curr) => acc + curr.TotalProductGross, 0) /
            details.reduce((acc, curr) => acc + curr.TotalDealCredit, 0),
          TotalFIIncome: details.reduce(
            (acc, curr) => acc + curr.TotalFIIncome,
            0,
          ),
          PVRFIIncome:
            details.reduce((acc, curr) => acc + curr.TotalFIIncome, 0) /
            details.reduce((acc, curr) => acc + curr.TotalDealCredit, 0),
          TotalBackGross: details.reduce(
            (acc, curr) => acc + curr.TotalBackGross,
            0,
          ),
          PVRBackGross:
            details.reduce((acc, curr) => acc + curr.TotalBackGross, 0) /
            details.reduce((acc, curr) => acc + curr.TotalDealCredit, 0),
          TotalGross: details.reduce((acc, curr) => acc + curr.TotalGross, 0),
          PVRTotalGross:
            details.reduce((acc, curr) => acc + curr.TotalGross, 0) /
            details.reduce((acc, curr) => acc + curr.TotalDealCredit, 0),
          TotalCommission: details.reduce(
            (acc, curr) => acc + curr.TotalCommission,
            0,
          ),
          CalculatedCommission: details.reduce(
            (acc, curr) => acc + curr.CalculatedCommission,
            0,
          ),
          TotalBonus: details.reduce((acc, curr) => acc + curr.TotalBonus, 0),
          Sales: [],
        };

        setPinnedRow([summaryRow]);

        setGridData(details);
        setSummaryData(summary);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        // Handle error appropriately
      }
      setLoading(false);
    };
    fetchData();
  }, [
    dateRange,
    dealType,
    dealStatus,
    employeeType,
    user,
    getAccessTokenSilently,
  ]);

  const getDetailPanelContent = (params) => {
    // Construct data for the detail table based on the row data
    const detailColumns = [
      { field: 'primaryEmployeeNo', headerName: 'Employee No.', width: 100 },
      { field: 'primaryEmployeeName', headerName: 'Employee', width: 150 },
      { field: 'ContractDate', headerName: 'Contract Date', width: 100 },
      { field: 'AccountingDate', headerName: 'Acct. Date', width: 100 },
      { field: 'DealCredit', headerName: 'Credit', width: 70 },
      { field: 'FiWipStatusCode', headerName: 'Status', width: 70 },
      { field: 'WAQNumber', headerName: 'Deal No', width: 75 },
      { field: 'StockNo', headerName: 'Stock No', width: 115 },
      { field: 'VIN', headerName: 'VIN', width: 175 },
      { field: 'FIDealType', headerName: 'Type', width: 75 },
      {
        field: 'Year',
        headerName: 'Year/Make/Model',
        valueGetter: (params) =>
          `${params.row.Year} ${params.row.MakeName} ${params.row.ModelName}`,
        minWidth: 200,
        flex: 1,
      },
      { field: 'Age', headerName: 'Age', width: 100 },
      {
        field: 'PayableFrontGross',
        headerName: 'Payable FEG',
        width: 115,
        valueFormatter: ({ value }) => formatCurrency(value),
      },
      {
        field: 'PayableBackGross',
        headerName: 'Payable BEG',
        width: 115,
        valueFormatter: ({ value }) => formatCurrency(value),
      },
      {
        field: 'ProductGross',
        headerName: 'Products',
        width: 115,
        valueFormatter: ({ value }) => formatCurrency(value),
      },
      {
        field: 'FIIncome',
        headerName: 'Reserve',
        width: 115,
        valueFormatter: ({ value }) => formatCurrency(value),
      },
      {
        field: 'BackGross',
        headerName: 'Back Gross',
        width: 115,
        valueFormatter: ({ value }) => formatCurrency(value),
      },
      {
        field: 'TotalGross',
        headerName: 'Total Gross',
        width: 115,
        valueFormatter: ({ value }) => formatCurrency(value),
      },
      {
        field: 'primaryEmployeeCommission',
        headerName: 'Commission',
        width: 115,
        valueFormatter: ({ value }) => formatCurrency(value),
      },
      {
        field: 'CalculatedCommission',
        headerName: 'Audit',
        width: 115,
        valueFormatter: ({ value }) => formatCurrency(value),
      },
      {
        field: 'BonusCommission',
        headerName: 'Bonus',
        width: 115,
        valueFormatter: ({ value }) => formatCurrency(value),
      },
      { field: 'CRMSalesMgrName', headerName: 'Sales Mgr Name', width: 180 },
      { field: 'CRMFIMgrName', headerName: 'FI Mgr Name', width: 180 },
      { field: 'CRMSP1Name', headerName: 'SP1 Name', width: 180 },
      {
        field: 'CRMSaleCreditSP1',
        headerName: 'SP1 Credit',
        width: 100,
        valueFormatter: ({ value }) => {
          return value / 1000;
        },
      },
      { field: 'CRMSP2Name', headerName: 'SP2 Name', width: 180 },
      {
        field: 'CRMSaleCreditSP2',
        headerName: 'SP2 Credit',
        width: 100,
        valueFormatter: ({ value }) => {
          return value / 1000;
        },
      },
      { field: 'CRMSP3Name', headerName: 'SP3 Name', width: 180 },
      {
        field: 'CRMSaleCreditSP3',
        headerName: 'SP3 Credit',
        width: 100,
        valueFormatter: ({ value }) => {
          return value / 1000;
        },
      },
      {
        field: 'CRMClosingMgrName',
        headerName: 'Closing Mgr Name',
        width: 100,
      },
    ];

    return (
      <Box sx={{ width: '100%', padding: 2, backgroundColor: 'action.hover' }}>
        <DataGridPro
          sx={{
            backgroundColor: 'background.paper',
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          density="compact"
          getRowId={(row) => row._id}
          rows={params.row ? params.row.Sales : []}
          getRowClassName={(params) =>
            Math.abs(
              params.row.primaryEmployeeCommission -
                params.row.CalculatedCommission,
            ).toFixed(2) <= 0.011
              ? ''
              : 'rowHighlight'
          }
          columns={detailColumns}
          disableColumnFilter
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          hideFooter
        />
      </Box>
    );
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{
          paddingBottom: 2,
          paddingX: 2,
        }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <Button
          variant="text"
          size="small"
          startIcon={
            expandedRowIds.length === gridData.length ? (
              <RemoveIcon />
            ) : (
              <AddIcon />
            )
          }
          onClick={toggleExpandAll}
        >
          {expandedRowIds.length === gridData.length
            ? 'Collapse All'
            : 'Expand All'}
        </Button>
        <div style={{ flexGrow: 1 }} />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };

  return (
    isAuthenticated && (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <GlobalStyles
          styles={{
            '.rowHighlight': {
              backgroundColor: 'rgba(255, 0, 0, 0.1)',
            },
          }}
        />
        <Box>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="button">Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction="row" spacing={2}>
                {/* Date Range Filter */}
                <Box sx={{ width: 500 }}>
                  <DateRangePicker
                    value={dateRange}
                    onChange={(newValue) => setDateRange(newValue)}
                    localeText={{
                      start: 'Start',
                      end: 'End',
                    }}
                    slotProps={{
                      shortcuts: {
                        items: shortcutsItems,
                      },
                      textField: { size: 'small' },
                    }}
                  />
                </Box>
                {/* Vehicle Type Filter */}
                <Box sx={{ width: 400 }}>
                  <FormControl size="small" fullWidth>
                    <InputLabel>Vehicle Type</InputLabel>
                    <Select
                      value={dealType}
                      onChange={handleDealChange}
                      label="Vehicle Type"
                    >
                      {deal_types.map((type, index) => (
                        <MenuItem key={index} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {/* Deal Status Filter */}
                <Box sx={{ width: 400 }}>
                  <FormControl size="small" fullWidth>
                    <InputLabel>Deal Status</InputLabel>
                    <Select
                      multiple
                      value={dealStatus}
                      onChange={(event) => setDealStatus(event.target.value)}
                      renderValue={(selected) => selected.join(', ')}
                      label="Deal Status"
                    >
                      {deal_status.map((status, index) => (
                        <MenuItem key={index} value={status}>
                          <Checkbox checked={dealStatus.indexOf(status) > -1} />
                          <ListItemText primary={status} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {/* Employee Type Filter */}
                <Box sx={{ width: 400 }}>
                  <FormControl size="small" fullWidth>
                    <InputLabel>Employee Type</InputLabel>
                    <Select
                      value={employeeType}
                      onChange={(event) => setEmployeeType(event.target.value)}
                      label="Employee Type"
                    >
                      {employee_types.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
            </AccordionDetails>
          </Accordion>
          {/* Summary Data */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1b-content"
              id="panel1b-header"
            >
              <Typography variant="button">Summary</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                paddingX: 0,
              }}
            >
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>Units</TableCell>
                      <TableCell>Front End Gross</TableCell>
                      <TableCell>Product Gross</TableCell>
                      <TableCell>Reserve</TableCell>
                      <TableCell>Back Gross</TableCell>
                      <TableCell>Total Gross</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(summaryData).map(([type, data]) => (
                      <TableRow key={type}>
                        <TableCell>{type}</TableCell>
                        <TableCell>{data.units}</TableCell>
                        <TableCell>
                          {formatCurrency(data.totalFrontGross)}
                        </TableCell>
                        <TableCell>
                          {formatCurrency(data.totalProductGross)}
                        </TableCell>
                        <TableCell>
                          {formatCurrency(data.totalFIIncome)}
                        </TableCell>
                        <TableCell>
                          {formatCurrency(data.totalBackGross)}
                        </TableCell>
                        <TableCell>
                          {formatCurrency(data.totalTotalGross)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
          {/* DataGridPro component */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              <Typography variant="button">Results</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                paddingX: 0,
                paddingBottom: 0,
              }}
            >
              <Box sx={{ width: '100%' }}>
                <DataGridPro
                  sx={{
                    border: 'none',
                  }}
                  density="compact"
                  getRowId={(row) => row.EmployeeID}
                  rows={gridData || []}
                  rowThreshold={1}
                  pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
                  pinnedRows={{ top: pinnedRow }}
                  columns={columns}
                  columnVisibilityModel={mainColumnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setMainColumnVisibilityModel(newModel)
                  }
                  autoHeight // Enable row virtualization
                  getDetailPanelContent={getDetailPanelContent}
                  getDetailPanelHeight={({ row }) => 'auto'}
                  detailPanelExpandedRowIds={expandedRowIds}
                  onDetailPanelExpandedRowIdsChange={(newExpandedRowIds) =>
                    setExpandedRowIds(newExpandedRowIds)
                  }
                  loading={loading}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </LocalizationProvider>
    )
  );
};

export default Experimental;
