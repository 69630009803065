import { formatCurrency } from 'utils/numberUtils';
import clsx from 'clsx';

const rankData = (data, rankField) => {
  const rankedData = [...data];

  rankedData.sort((a, b) => b[rankField] - a[rankField]);

  let rank = 1;
  let lastValue = null;

  for (let i = 0; i < rankedData.length; i++) {
    if (lastValue !== rankedData[i][rankField]) {
      lastValue = rankedData[i][rankField];
      rank = i + 1;
    }
    rankedData[i][`${rankField}_rank`] = rank;
  }

  return rankedData;
};

const columns = [
  { field: 'id', headerName: 'Employee', flex: 2 },
  {
    field: 'totalUsed',
    headerName: 'Used',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    width: 60,
    disableColumnMenu: true,
  },
  {
    field: 'totalUnits',
    headerName: 'Total',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    width: 60,
    disableColumnMenu: true,
  },
  {
    field: 'totalBackGross',
    headerName: 'Back',
    headerAlign: 'center',
    type: 'number',
    valueFormatter: (params) => formatCurrency(params.value),
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: 'totalGrossProfit',
    headerName: 'Total',
    headerAlign: 'center',
    type: 'number',
    valueFormatter: (params) => formatCurrency(params.value),
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: 'totalGrossPVR',
    headerName: 'PVR',
    headerAlign: 'center',
    type: 'number',
    valueFormatter: (params) => formatCurrency(params.value),
    disableColumnMenu: true,
  },
  {
    field: 'totalPhone',
    headerName: 'Calls',
    type: 'number',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    cellClassName: (params) => {
      return clsx('phone-cell'); //This is how we change the color of the cell
    },
    editable: true,
    disableColumnMenu: true,
  },
  {
    field: 'totalUsed_rank',
    headerName: 'Used Units',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: 'totalUnits_rank',
    headerName: 'Total Units',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: 'totalBackGross_rank',
    headerName: 'Back Gross',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: 'totalGrossProfit_rank',
    headerName: 'TTL Gross',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: 'totalGrossPVR_rank',
    headerName: 'PVR',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: 'realtimePhone_rank',
    headerName: 'Phone',
    type: 'number',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    disableColumnMenu: true,
    valueGetter: (params) => {
      const currentTable = params.api.getSortedRows();
      const rankedPhone = rankData(currentTable, 'totalPhone');
      const phoneRank = rankedPhone.find((item) => item.id === params.row.id);
      return phoneRank.totalPhone_rank;
    },
  },
  {
    field: 'updated_rank',
    headerName: 'Rank',
    type: 'number',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    cellClassName: () => {
      return clsx('rank-cell-light-bold'); //This is how we change the color of the cell
    },
    disableColumnMenu: true,
    valueGetter: (params) => {
      const dealRank =
        params.api.getCellValue(params.row.id, 'totalUsed_rank') +
        params.api.getCellValue(params.row.id, 'totalUnits_rank') +
        params.api.getCellValue(params.row.id, 'totalBackGross_rank') +
        params.api.getCellValue(params.row.id, 'totalGrossProfit_rank') +
        params.api.getCellValue(params.row.id, 'totalGrossPVR_rank');
      const modifyByUnits =
        params.api.getCellValue(params.row.id, 'totalUnits_rank') / 10; //This is how we break ties...Units first and then Gross
      const modifyByGross =
        params.api.getCellValue(params.row.id, 'totalGrossProfit_rank') / 100; //This is how we break ties...Units first and then Gross
      const modifiedRank = dealRank + modifyByUnits + modifyByGross;
      if (!params.api.getCellValue(params.row.id, 'totalPhone_rank'))
        return modifiedRank;
      const phoneRank = params.api.getCellValue(
        params.row.id,
        'totalPhone_rank',
      );
      return modifiedRank + phoneRank;
    },
  },
  {
    field: 'Percentage',
    headerName: '%',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    width: 50,
    cellClassName: (params) => {
      return clsx('rank-cell-medium'); //This is how we change the color of the cell
    },
    disableColumnMenu: true,
    valueGetter: (params) => {
      const percentages = [1.7, 1.5, 1.35, 1.2, 1.0, 1.0, 1.0];
      const data = params.api.getSortedRows(); // Get all rows
      // Sort the data by the rankField in descending order
      data.sort(
        (a, b) =>
          params.api.getCellValue(a.id, 'updated_rank') -
          params.api.getCellValue(b.id, 'updated_rank'),
      );
      const rank = data.findIndex((item) => item.id === params.row.id) + 1;
      return percentages[rank - 1];
    },
  },
  {
    field: 'Amount',
    headerName: 'Amount',
    type: 'number',
    flex: 1,
    cellClassName: (params) => {
      return clsx('rank-cell-medium-bold'); //This is how we change the color of the cell
    },
    disableColumnMenu: true,
    valueGetter: (params) => {
      const amount =
        (params.api.getCellValue(params.row.id, 'dealerProfit') *
          params.api.getCellValue(params.row.id, 'Percentage')) /
        100;
      return formatCurrency(amount);
    },
  },
  {
    field: 'Pacing',
    headerName: 'Pacing',
    type: 'number',
    flex: 1,
    cellClassName: (params) => {
      return clsx('rank-cell-medium'); //This is how we change the color of the cell
    },
    disableColumnMenu: true,
    valueGetter: (params) => {
      const currentDate = new Date();
      const currentDay = currentDate.getDate();
      const daysInMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0,
      ).getDate();

      const amount =
        (params.api.getCellValue(params.row.id, 'dealerProfit') *
          params.api.getCellValue(params.row.id, 'Percentage')) /
        100;
      const pacing = (amount / currentDay) * daysInMonth;

      return formatCurrency(pacing);
    },
  },
];

export default columns;
