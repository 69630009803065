import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { format, parseISO } from 'date-fns';
import {
  DataGridPro,
  GridToolbar,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import {
  Box,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Stack,
  Paper,
  Snackbar,
  Alert,
} from '@mui/material';
import dayjs from 'dayjs';
import CoverSheetForm from '../Coversheet';
import CalendarDateRangePicker from 'components/DateRangePicker';
import { API_BASE_URL } from 'config';
import axios from 'axios';

const CoverSheetDialog = ({ open, onClose, selectedDeal }) => (
  <Dialog
    open={open}
    onClose={onClose}
    scroll="body"
    fullWidth={true}
    maxWidth="xl"
  >
    <DialogTitle>View Coversheet</DialogTitle>
    <DialogContent>
      <Box>
        {selectedDeal && (
          <CoverSheetForm
            viewDealNo={selectedDeal.dealNo}
            dealerId={selectedDeal.dealerId}
          />
        )}
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

const CancelDialog = ({
  open,
  onClose,
  cancelReason,
  setCancelReason,
  onCancel,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Cancel Deal</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Please provide a reason for the cancellation:
      </DialogContentText>
      <TextField
        autoFocus
        multiline
        rows={3}
        fullWidth
        value={cancelReason}
        onChange={(e) => setCancelReason(e.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button onClick={onCancel} color="primary">
        OK
      </Button>
    </DialogActions>
  </Dialog>
);

const CancelReasonDialog = ({ open, onClose, cancelReason }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Cancellation Reason</DialogTitle>
    <DialogContent>
      <DialogContentText>{cancelReason}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

const ReservationsTable = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [dateRange, setDateRange] = useState([
    dayjs().startOf('month'),
    dayjs(),
  ]);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [openCancelReasonDialog, setOpenCancelReasonDialog] = useState(false);
  const [selectedCancelReason, setSelectedCancelReason] = useState('');
  const [selectedDealNo, setSelectedDealNo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState(null);

  const fetchData = useCallback(async () => {
    if (!isAuthenticated || !user?.dealerid) return;

    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${API_BASE_URL}/api/coversheets`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          dealerID: user.dealerid,
          startDate: dateRange[0].format('YYYY-MM-DD'),
          endDate: dateRange[1].format('YYYY-MM-DD'),
        },
      });
      setData(
        response.data.filter((item) => item.vehiclestatus === 'IN TRANSIT'),
      );
    } catch (error) {
      console.error('Error fetching data:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch data',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }, [isAuthenticated, user?.dealerid, dateRange, getAccessTokenSilently]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleOpenCancelDialog = (row) => {
    setSelectedDealNo(row.dealno);
    if (row.cancel) {
      setSelectedCancelReason(row.cancelreason);
      setOpenCancelReasonDialog(true);
    } else {
      setOpenCancelDialog(true);
    }
  };

  const handleCloseCancelDialog = () => {
    setSelectedDealNo(null);
    setSelectedCancelReason('');
    setOpenCancelDialog(false);
    setOpenCancelReasonDialog(false);
  };

  const handleCancel = async () => {
    if (!selectedDealNo || !cancelReason) return;

    try {
      const token = await getAccessTokenSilently();
      await axios.put(
        `${API_BASE_URL}/api/coversheets/${selectedDealNo}`,
        {
          dealerID: user?.dealerid,
          cancel: true,
          cancelreason: cancelReason,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      handleCloseCancelDialog();
      setSnackbar({
        open: true,
        message: 'Deal cancelled successfully',
        severity: 'success',
      });
      fetchData();
    } catch (error) {
      console.error('Error cancelling deal:', error);
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Error cancelling deal',
        severity: 'error',
      });
    }
  };

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const handleOpenModal = (deal) => {
    setSelectedDeal({
      dealNo: deal,
      dealerId: user?.dealerid,
    });
    setShowModal(true);
  };

  const columns = [
    {
      field: 'cancel',
      headerName: 'Status',
      width: 100,
      align: 'left',
      renderCell: (params) => (
        <Button
          size="small"
          color={params.value ? 'error' : 'primary'}
          onClick={() => handleOpenCancelDialog(params.row)}
        >
          {params.value ? 'Cancelled' : 'Pending'}
        </Button>
      ),
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 110,
      sortable: true,
      renderCell: (params) => {
        const parsedDate = parseISO(params.value.split('T')[0]);
        return <span>{format(parsedDate, 'MM/dd/yyyy')}</span>;
      },
    },
    {
      field: 'dealno',
      headerName: 'Deal No.',
      width: 100,
      sortable: true,
      renderCell: (params) => (
        <Button variant="text" onClick={() => handleOpenModal(params.value)}>
          {params.value}
        </Button>
      ),
    },
    { field: 'buyername', headerName: 'Buyer Name', width: 200 },
    { field: 'stockno', headerName: 'Stock No.' },
    { field: 'year', headerName: 'Year', width: 75 },
    { field: 'make', headerName: 'Make' },
    { field: 'model', headerName: 'Model', width: 200 },
    { field: 'sp1', headerName: 'SP1', width: 200 },
    { field: 'sp2', headerName: 'SP2', width: 200 },
    { field: 'salesmanager', headerName: 'Sales Manager', width: 200 },
  ];

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
      <DataGridPro
        sx={{ borderRadius: 0, border: 'none' }}
        density="compact"
        rows={data}
        columns={columns}
        loading={isLoading}
        initialState={{
          sorting: { sortModel: [{ field: 'date', sort: 'desc' }] },
        }}
        getRowId={(row) => row._id}
        slots={{
          toolbar: () => (
            <>
              <Stack direction="row" spacing={2} alignItems="center" p={2}>
                <GridToolbar />
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarQuickFilter />
                <CalendarDateRangePicker
                  dateRange={dateRange}
                  onDateRangeChange={handleDateRangeChange}
                />
              </Stack>
              <Divider />
            </>
          ),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        disableRowSelectionOnClick
      />

      <CoverSheetDialog
        open={showModal}
        onClose={() => setShowModal(false)}
        selectedDeal={selectedDeal}
      />

      <CancelDialog
        open={openCancelDialog}
        onClose={handleCloseCancelDialog}
        cancelReason={cancelReason}
        setCancelReason={setCancelReason}
        onCancel={handleCancel}
      />

      <CancelReasonDialog
        open={openCancelReasonDialog}
        onClose={handleCloseCancelDialog}
        cancelReason={selectedCancelReason}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default ReservationsTable;
