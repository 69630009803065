import React from 'react';
import { formatCurrency } from 'utils/numberUtils';

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SummaryTable = ({ rows = [] }) => {
  const totalRow = rows.reduce((accum, currentRow) => {
    [
      'totalNew',
      'totalUsed',
      'totalDeals',
      'totalFrontGross',
      'totalBackGross',
      'totalGrossProfit',
      'unitPace',
    ].forEach((field) => {
      accum[field] = (accum[field] || 0) + currentRow[field];
    });
    return accum;
  }, {});

  const totalUnits = totalRow.totalDeals;

  const averageRow = {
    totalNew: totalRow.totalNew / rows.length,
    totalUsed: totalRow.totalUsed / rows.length,
    totalDeals: totalRow.totalDeals / rows.length,
    unitPace: totalRow.unitPace / rows.length,
    totalFrontGross: totalRow.totalFrontGross / totalUnits,
    totalBackGross: totalRow.totalBackGross / totalUnits,
    totalGrossProfit: totalRow.totalGrossProfit / totalUnits,
  };

  totalRow.id = 'Totals';
  averageRow.id = 'Averages';

  const summaryTable = [totalRow, averageRow];

  const renderBody = () =>
    summaryTable.map((row, index) => (
      <TableRow key={index}>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.totalNew ? row.totalNew.toFixed(2) : 'N/A'}</TableCell>
        <TableCell>
          {row.totalUsed ? row.totalUsed.toFixed(2) : 'N/A'}
        </TableCell>
        <TableCell>
          {row.totalDeals ? row.totalDeals.toFixed(2) : 'N/A'}
        </TableCell>
        <TableCell>{row.unitPace ? row.unitPace.toFixed(2) : 'N/A'}</TableCell>
        <TableCell>{formatCurrency(row.totalFrontGross)}</TableCell>
        <TableCell>{formatCurrency(row.totalBackGross)}</TableCell>
        <TableCell>{formatCurrency(row.totalGrossProfit)}</TableCell>
      </TableRow>
    ));

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          fontWeight: 'bold',
        }}
      >
        Summary Table
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Metric</TableCell>
                <TableCell>New</TableCell>
                <TableCell>Used</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Unit Pace</TableCell>
                <TableCell>Front</TableCell>
                <TableCell>Back</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderBody()}</TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default SummaryTable;
