import React, { useEffect } from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    // Perform logout logic here
    logout({ returnTo: window.location.origin });
  }, [logout]);

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Typography variant="h4" gutterBottom>
          You have been logged out
        </Typography>
        <Typography variant="body1" gutterBottom>
          Thank you for visiting our site. Click the button below to log in
          again.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLoginRedirect}
        >
          Login
        </Button>
      </Box>
    </Container>
  );
};

export default Logout;
