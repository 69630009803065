import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from 'config';

export const useEmployees = (user, isAuthenticated) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Define a function to fetch employees
  const fetchEmployees = async (dealerID) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/employees/${dealerID}`,
      );
      setData(response.data);
    } catch (error) {
      setError(error);
      console.error('Error fetching employees:', error.message);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (isAuthenticated && isMounted) {
        await fetchEmployees(user?.dealerid);
      } else if (!isAuthenticated) {
        setData([]);
      }
    };

    fetchData(); // Call fetchData directly

    return () => {
      isMounted = false;
    };
  }, [user, isAuthenticated]);

  // Expose the refetch function
  const refetch = () => {
    fetchEmployees(user?.dealerid);
  };

  return {
    data,
    isLoading,
    error,
    refetch, // Expose the refetch function
  };
};
