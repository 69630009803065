import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Typography, Paper, Stack, CircularProgress, Divider } from '@mui/material';
import {
  DataGridPremium,
  GridToolbar,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  GridLogicOperator,
} from '@mui/x-data-grid-premium';

import { red, green, orange, yellow } from '@mui/material/colors';

import dayjs from 'dayjs';
import CalendarDateRangePicker from 'components/DateRangePicker';

import { API_BASE_URL } from 'config';
import axios from 'axios'; // Assuming axios is used for HTTP requests

const goalsByPosition = [
  {
    position: 'Salesperson',
    field: 'position',
    NewVehicles: 8,
    UsedVehicles: 4,
  },
  {
    position: 'CRMSalesMgrName',
    field: 'position',
    NewVehicles: 60,
    UsedVehicles: 35,
  },
  {
    position: 'CRMFIMgrName',
    field: 'position',
    NewVehicles: 0,
    UsedVehicles: 0,
  },
  {
    position: 'CRMClosingMgrName',
    field: 'position',
    NewVehicles: 0,
    UsedVehicles: 0,
  },
];

const ignoreIndividualGoals = [
  'NAVA,ALFREDO',
  'CALZADILLAS,GENARO H',
  'TARROZA,BIEN',
];

const individualGoals = [
  {
    name: 'NAVA,ALFREDO',
    position: 'Salesperson',
    field: 'name',
    NewVehicles: 45,
    UsedVehicles: 2,
  },
  {
    name: 'CALZADILLAS,GENARO H',
    position: 'Salesperson',
    field: 'name',
    NewVehicles: 15,
    UsedVehicles: 5,
  },
  {
    name: 'TARROZA,BIEN',
    position: 'Salesperson',
    field: 'name',
    NewVehicles: 20,
    UsedVehicles: 5,
  },
  {
    name: 'BAHROOS,KABIR',
    position: 'CRMSalesMgrName',
    field: 'name',
    NewVehicles: 60,
    UsedVehicles: 35,
    ignore: ignoreIndividualGoals,
  },
  {
    name: 'MORALES,OSCAR',
    position: 'CRMSalesMgrName',
    field: 'name',
    NewVehicles: 60,
    UsedVehicles: 35,
    ignore: ignoreIndividualGoals,
  },
  {
    name: 'MORALES,HOWARD ROBERT',
    position: 'CRMSalesMgrName',
    field: 'name',
    NewVehicles: 60,
    UsedVehicles: 35,
    ignore: ignoreIndividualGoals,
  },
  {
    name: 'KHAN,SHAHID A',
    position: 'CRMSalesMgrName',
    field: 'name',
    NewVehicles: 60,
    UsedVehicles: 35,
    ignore: ignoreIndividualGoals,
  },
];

// Create a filter function that returns the goal for a individual or a position
const getGoal = (name, position) => {
  // Check if name and position are defined
  if (!name || !position) {
    return {};
  }

  const individualGoal = individualGoals.find(
    (item) => item.name === name && item.position === position,
  );

  if (individualGoal) {
    return individualGoal;
  }

  return goalsByPosition.find((item) => item.position === position);
};

// Create a filter model for the DataGrid to filter by the indiviual and position goals
const filterModel = {
  items: individualGoals.map((item) => ({
    id: item.name,
    field: item.field,
    operator: 'contains',
    value: item.name,
  })),
  logicOperator: GridLogicOperator.Or,
};

// Create a function to categorize positions into broader categories
// Create a function to categorize positions into broader categories
const categorizePosition = (name) => {
  // Check if name is defined
  if (name) {
    // Assuming names like CRMSP1Name, CRMSP2Name, and CRMSP3Name represent salespeople
    if (name === 'Salesperson') {
      return 'Salesperson';
    }
    // Assuming names like CRMSalesMgrName represent Sales Managers
    else if (name === 'CRMSalesMgrName') {
      return 'Sales Manager';
    }
    // Assuming names like CRMFIMgrName represent Finance Managers
    else if (name === 'CRMFIMgrName') {
      return 'Finance Manager';
    }
    // Assuming names like CRMClosingMgrName represent Closers
    else if (name === 'CRMClosingMgrName') {
      return 'Closer';
    }
    // Assuming other names represent administrative staff
    else {
      return 'Administrative Staff';
    }
  } else {
    // Return a default value if name is undefined
    return 'Unknown';
  }
};

// Create a function that calculates the pace, whih is calculated by taking the today's date and subtracting the start date of the month and dividing by the total days in the month
const calculatePace = (value) => {
  const today = dayjs();
  const startOfMonth = today.startOf('month');
  const totalDaysInMonth = today.daysInMonth();
  const daysElapsed = today.diff(startOfMonth, 'day');
  const pace = (value / daysElapsed) * totalDaysInMonth;
  // Return the pace rounded to the nearest whole number
  return Math.round(pace);
};

const ProgressBar = ({ value }) => {
  const getColor = (value) => {
    if (value >= 90) {
      return green[500]; // Green for 90% to 100%, indicating near or complete success
    } else if (value >= 70) {
      return yellow[500]; // Yellow for 70% to 89%, indicating good progress
    } else if (value >= 50) {
      return orange[500]; // Orange for 50% to 69%, indicating moderate progress
    } else {
      return red[500]; // Red for less than 50%, indicating below expectations
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          border: 1,
          borderColor: 'grey.500',
          borderRadius: 0.5,
          width: '100%',
          position: 'relative',
          overflow: 'hidden',
          height: '26px',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            lineHeight: '24px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {value.toFixed(2)}%
        </Box>
        <Box
          sx={{
            height: '100%',
            width: value >= 100 ? '100%' : `${value}%`,
            bgcolor: getColor(value),
          }}
        />
      </Box>
    </Box>
  );
};

const columns = [
  {
    field: 'name',
    headerName: 'Employee',
    minWidth: 250,
    flex: 1,
    groupable: false,
  },
  {
    field: 'position',
    headerName: 'Position',
    minWidth: 250,
    flex: 1,
    groupable: true,
    valueGetter: (value) => categorizePosition(value),
  },
  {
    field: 'NewVehicles',
    headerName: 'Units',
    minWidth: 110,
    flex: 1,
    groupable: false,
    type: 'number',
  },
  {
    field: 'NewVehiclesPace',
    headerName: 'Pace',
    minWidth: 110,
    flex: 1,
    groupable: false,
    type: 'number',
  },
  {
    field: 'NewVehiclesGoal',
    headerName: 'Goal',
    minWidth: 110,
    flex: 1,
    valueGetter: (value, row) =>
      getGoal(row.name, row.position).NewVehicles || 0,
    groupable: false,
    type: 'number',
  },
  {
    field: 'NewVehiclesOnTarget',
    headerName: 'Pace to Goal',
    minWidth: 110,
    flex: 1,
    valueGetter: (value, row) => {
      // Calculate the pace percentage of the goal
      const pacePercentage =
        (row.NewVehiclesPace / getGoal(row.name, row.position).NewVehicles) *
        100;
      return pacePercentage;
    },
    renderCell: (params) => {
      return <ProgressBar value={params.value} />;
    },
    groupable: false,
    type: 'number',
  },
  {
    field: 'UsedVehicles', //We can reasonably assume that this credit amount is for all salespeople
    headerName: 'Units',
    minWidth: 110,
    flex: 1,
    groupable: false,
    type: 'number',
  },
  {
    field: 'UsedVehiclesPace',
    headerName: 'Pace',
    minWidth: 110,
    flex: 1,
    groupable: false,
    type: 'number',
  },
  {
    field: 'UsedVehiclesGoal',
    headerName: 'Goal',
    minWidth: 110,
    flex: 1,
    valueGetter: (value, row) =>
      getGoal(row.name, row.position).UsedVehicles || 0,
    groupable: false,
    type: 'number',
  },
  {
    field: 'UsedVehiclesOnTarget',
    headerName: 'Pace to Goal',
    minWidth: 110,
    flex: 1,
    valueGetter: (value, row) => {
      // Calculate the pace percentage of the goal
      const pacePercentage =
        (row.UsedVehiclesPace / getGoal(row.name, row.position).UsedVehicles) *
        100;
      return pacePercentage;
    },
    renderCell: (params) => {
      return <ProgressBar value={params.value} />;
    },
    groupable: false,
    type: 'number',
  },
];

// Column grouping model for the DataGrid
const columnGroupingModel = [
  {
    groupId: 'New',
    children: [
      { field: 'NewVehicles' },
      { field: 'NewVehiclesPace' },
      { field: 'NewVehiclesGoal' },
      { field: 'NewVehiclesOnTarget' },
    ],
  },
  {
    groupId: 'Used',
    children: [
      { field: 'UsedVehicles' },
      { field: 'UsedVehiclesPace' },
      { field: 'UsedVehiclesGoal' },
      { field: 'UsedVehiclesOnTarget' },
    ],
  },
];

const PaceReport = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [dateRange, setDateRange] = React.useState([
    dayjs().startOf('month'),
    dayjs(),
  ]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const apiRef = useGridApiRef();

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${API_BASE_URL}/api/deals/${user.dealerid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              startDate: dateRange[0].format('YYYY-MM-DD'),
              endDate: dateRange[1].format('YYYY-MM-DD'),
            },
          },
        );

        const data = response.data;

        // Filter out deals not related to the individual
        const filteredData = data.filter((item) => {
          // Create a deal and name credit array of objects
          const dealCreditArray = [
            { name: item.CRMSP1Name, credit: item.CRMSaleCreditSP1 },
            { name: item.CRMSP2Name, credit: item.CRMSaleCreditSP2 },
            { name: item.CRMSP3Name, credit: item.CRMSaleCreditSP3 },
          ];

          // Remove the isIgnored individuals from the dealCreditArray
          const filteredDealCreditArray = dealCreditArray.filter(
            (dealCredit) =>
              !individualGoals.some(
                (goal) => goal.ignore && goal.ignore.includes(dealCredit.name),
              ),
          );

          // Calculate the total sales credit for the deal from the filteredDealCreditArray
          const totalSalesCredit = filteredDealCreditArray.reduce(
            (acc, dealCredit) => acc + parseFloat(dealCredit.credit),
            0,
          );

          // Add the totalSalesCredit to the item object
          item.totalSalesCredit = totalSalesCredit;

          // Return the updated object
          return item;
        });

        // Create a new array by employee name from the data
        const employeePositions = [
          'CRMSP1Name',
          'CRMSP2Name',
          'CRMSP3Name',
          'CRMSalesMgrName',
          'CRMFIMgrName',
          'CRMClosingMgrName',
        ];

        const employeeData = employeePositions.reduce((acc, name) => {
          const employeeData = filteredData
            .filter(
              (item) =>
                (item.FiWipStatusCode === 'F' ||
                  item.FiWipStatusCode === 'B') &&
                item.DealType !== 'Wholesale',
            )
            .reduce((acc, row) => {
              if (row[name] && row[name].trim() !== '') {
                // Add this condition to check for blank names
                if (!acc[row[name]]) {
                  acc[row[name]] = {
                    name: row[name],
                    // Combine the CRMSP1Name, CRMSP2Name, and CRMSP3Name into one position called Salesperson
                    position:
                      name === 'CRMSP1Name' ||
                      name === 'CRMSP2Name' ||
                      name === 'CRMSP3Name'
                        ? 'Salesperson'
                        : name,
                    ProductGross: 0,
                    NewVehicles: 0,
                    UsedVehicles: 0,
                  };
                }

                // Only add the sales credit if the employee is a salesperson
                if (
                  name === 'CRMSP1Name' ||
                  name === 'CRMSP2Name' ||
                  name === 'CRMSP3Name'
                ) {
                  acc[row[name]].NewVehicles +=
                    row.FIDealType === 'New'
                      ? parseFloat(row.CRMSaleCreditSP1) / 1000
                      : 0;
                  acc[row[name]].UsedVehicles +=
                    row.FIDealType === 'Used'
                      ? parseFloat(row.CRMSaleCreditSP1) / 1000
                      : 0;
                } else {
                  acc[row[name]].NewVehicles +=
                    row.FIDealType === 'New'
                      ? parseFloat(row.totalSalesCredit) / 1000
                      : 0;
                  acc[row[name]].UsedVehicles +=
                    row.FIDealType === 'Used'
                      ? parseFloat(row.totalSalesCredit) / 1000
                      : 0;
                }
              }
              return acc;
            }, {});

          return acc.concat(Object.values(employeeData));
        }, []);

        // Filter out employees that have multiple positions and prioritize the Salesperson position
        const employeeDataFiltered = employeeData.reduce((acc, row) => {
          if (row.position === 'Salesperson') {
            acc.push(row);
          } else {
            const existingRow = acc.find((item) => item.name === row.name);
            if (!existingRow) {
              acc.push(row);
            }
          }
          return acc;
        }, []);

        // Group the data by employee name and position
        const groupedData = employeeDataFiltered.reduce((acc, row) => {
          const key = `${row.name}_${row.position}`; // Create a unique key for name and position

          if (!acc[key]) {
            acc[key] = {
              name: row.name,
              position: row.position,
              NewVehicles: 0,
              UsedVehicles: 0,
            };
          }

          // Accumulate values for the specific name-position pair
          acc[key].NewVehicles += row.NewVehicles;
          acc[key].UsedVehicles += row.UsedVehicles;

          return acc;
        }, {});

        // Convert the grouped data object into an array
        const groupedDataArray = Object.values(groupedData);

        setData(
          groupedDataArray.map((row, index) => ({
            id: index,
            ...row,
            NewVehiclesPace: calculatePace(row.NewVehicles),
            UsedVehiclesPace: calculatePace(row.UsedVehicles),
          })),
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [user, dateRange, getAccessTokenSilently]);

  const handleDateRangeChange = (newDateRange) => setDateRange(newDateRange);

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      filter: {
        filterModel,
      },
      columns: {
        columnVisibilityModel: {
          name: true,
          NewVehicles: true,
          NewVehiclesPace: true,
          NewVehiclesGoal: true,
          UsedVehicles: true,
          UsedVehiclesPace: true,
          UsedVehiclesGoal: true,
        },
      },
      aggregation: {
        model: {
          NewVehicles: 'sum',
          NewVehiclesPace: 'sum',
          NewVehiclesGoal: 'sum',
          NewVehiclesOnTarget: 'avg',
          UsedVehicles: 'sum',
          UsedVehiclesPace: 'sum',
          UsedVehiclesGoal: 'sum',
          UsedVehiclesOnTarget: 'avg',
        },
      },
    },
  });

  return (
    isAuthenticated && (
      <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
        <Stack direction="row" spacing={2} sx={{ padding: 2 }}>
          <Typography variant="h6">Pace to Goal Report</Typography>
          <Box flexGrow={1} />
          <CalendarDateRangePicker
            dateRange={dateRange}
            onDateRangeChange={handleDateRangeChange}
          />
        </Stack>
        <Divider sx={{mb: 2 }} />
        {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1, width: '100%' }}>
          <DataGridPremium
            sx={{
              border: 'none',
            }}
            apiRef={apiRef}
            rows={data}
            columns={columns}
            getRowId={(row) => row.id}
            loading={loading}
            initialState={initialState}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={columnGroupingModel}
            hideFooter
            disableRowSelectionOnClick
          />
        </Box>
      )}
      </Paper>
    )
  );
};

export default PaceReport;
