import MergeTypeIcon from '@mui/icons-material/MergeType';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import MinorCrashOutlinedIcon from '@mui/icons-material/MinorCrashOutlined';
import TimeToLeaveOutlinedIcon from '@mui/icons-material/TimeToLeaveOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';

//Filters
export const filters = [
  {
    label: 'Type',
    filter: 'type',
    icon: <MergeTypeIcon />,
  },
  {
    label: 'In Transit',
    filter: 'intransit_filter',
    icon: <FmdGoodOutlinedIcon />,
  },
  {
    label: 'Year',
    filter: 'year',
    icon: <QueryBuilderOutlinedIcon />,
  },
  {
    label: 'Make',
    filter: 'make',
    icon: <MinorCrashOutlinedIcon />,
  },
  {
    label: 'Model',
    filter: 'model',
    icon: <TimeToLeaveOutlinedIcon />,
  },
  {
    label: 'Trim',
    filter: 'trim',
    icon: <AddBoxOutlinedIcon />,
  },
  {
    label: 'Engine',
    filter: 'engine_description',
    icon: <CarRepairIcon />,
  },
  {
    label: 'Color',
    filter: 'ext_color',
    icon: <ColorLensOutlinedIcon />,
  },
  {
    label: 'Body Style',
    filter: 'body',
    icon: <AirportShuttleOutlinedIcon />,
  },
];

// Specify sort options
export const sortOptions = {
  frontiertoyota_production_inventory_specials_oem_price: 'Recommended',
  frontiertoyota_production_inventory_low_to_high: 'Lowest Price',
  frontiertoyota_production_inventory_mileage_low_to_high: 'Lowest Mileage',
  frontiertoyota_production_inventory_year_high_to_low: 'Newest Year',
  frontiertoyota_production_inventory_days_in_stock_low_to_high:
    'Lowest Days in Stock',
};
