import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import {
  Button,
  Select,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';

import { useLenders } from 'hooks/useLenders';
import { useRates } from 'hooks/useRates';

const Rates = () => {
  const { user } = useAuth0();
  const {
    lenders,
    loading: lendersLoading,
    error: lendersError,
  } = useLenders(user?.dealerid);
  const [selectedLenderID, setSelectedLenderID] = useState(null);
  const {
    rates,
    loading: ratesLoading,
    error: ratesError,
  } = useRates(selectedLenderID);

  const [rows, setRows] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [termsInput, setTermsInput] = useState([]);
  const [tiersInput, setTiersInput] = useState([]);

  // Custom footer component to place the Save Rates button
  const CustomFooter = (props) => {
    return (
      <div
        {...props}
        style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}
      >
        <Button onClick={handleSaveRates} variant="contained" color="primary">
          Save Rates
        </Button>
      </div>
    );
  };

  const columns = [
    { field: 'month', headerName: 'Month', width: 120 },
    { field: 'tier', headerName: 'Credit Tier', width: 120 },
    {
      field: 'rate',
      headerName: 'Rate (%)',
      width: 150,
      editable: true,
    },
  ];

  useEffect(() => {
    if (lenders && lenders.length > 0 && !selectedLenderID) {
      setSelectedLenderID(lenders[0]._id);
    }
    if (rates && rates.length) {
      setRows(rates);
    }
  }, [lenders, rates, selectedLenderID]);

  const handleEditCommit = (params) => {
    const newData = [...rows];
    const index = newData.findIndex((row) => row.id === params.id);
    if (index > -1) {
      newData[index][params.field] = params.value;
      setRows(newData);
    }
  };

  const handleSaveRates = () => {
    // Logic to save the rates.
    // This would likely involve making an API call with the data in 'rows' state.
  };

  const selectedLender = lenders.find(
    (lender) => lender._id === selectedLenderID,
  );

  // Function to populate empty rates for all month-tier combinations

  const handleAddRates = () => {
    // Use the termsInput and tiersInput to add the rates.
    const newRows = [];
    let id = rows.length ? rows[rows.length - 1].id + 1 : 0;
    for (let term of termsInput) {
      for (let tier of tiersInput) {
        newRows.push({
          id: id++,
          month: term,
          tier: tier,
          rate: '', // Default as empty, can be populated based on data
        });
      }
    }

    setRows((prevRows) => [...prevRows, ...newRows]);
  };

  return (
    <div>
      {lendersLoading || ratesLoading ? (
        <CircularProgress /> // Display a spinner when data is loading
      ) : (
        <>
          <h2>Enter Rates for {selectedLender?.Name || 'Lender'}</h2>

          {/* Error handling */}
          {lendersError && (
            <p>Error fetching lenders: {lendersError.message}</p>
          )}
          {ratesError && <p>Error fetching rates: {ratesError.message}</p>}

          {/* Drop-down to select a Lender */}
          <Select
            value={selectedLenderID}
            onChange={(e) => setSelectedLenderID(e.target.value)}
            style={{ marginBottom: '20px' }}
          >
            {lenders.map((lender) => (
              <MenuItem key={lender._id} value={lender._id}>
                {lender.Name}
              </MenuItem>
            ))}
          </Select>

          {rates.length === 0 && (
            <div>
              <p>No rates available for the selected lender.</p>
              <Button
                onClick={() => setOpenDialog(true)}
                variant="contained"
                color="primary"
                style={{ marginBottom: '20px' }}
              >
                Add Rates
              </Button>
            </div>
          )}

          <div style={{ height: 600, width: '100%' }}>
            <DataGridPro
              rows={rows}
              columns={columns}
              onEditCellChangeCommitted={handleEditCommit}
              components={{
                Footer: CustomFooter,
              }}
            />
          </div>
        </>
      )}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add Rates</DialogTitle>
        <DialogContent>
          <TextField
            label="Terms (comma-separated)"
            fullWidth
            value={termsInput.join(', ')}
            onChange={(e) =>
              setTermsInput(
                e.target.value.split(',').map((term) => term.trim()),
              )
            }
          />
          <TextField
            label="Tiers (comma-separated)"
            fullWidth
            style={{ marginTop: 16 }}
            value={tiersInput.join(', ')}
            onChange={(e) =>
              setTiersInput(
                e.target.value.split(',').map((tier) => tier.trim()),
              )
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              // Here you will handle the logic to add the rates
              handleAddRates();
              setOpenDialog(false);
            }}
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Rates;
