import { formatCurrency } from 'utils/numberUtils';

const columns = [
  { field: 'id', headerName: 'Employee', flex: 2 },
  { field: 'totalNew', headerName: 'New', type: 'number', flex: 1 },
  { field: 'totalUsed', headerName: 'Used', type: 'number', flex: 1 },
  { field: 'totalDeals', headerName: 'Total', type: 'number', flex: 1 },
  {
    field: 'unitPace',
    headerName: 'Pace',
    type: 'number',
    valueFormatter: ({ value }) => value.toFixed(2), // Format to 2 decimal places
    flex: 1,
  },
  {
    field: 'totalFrontGross',
    headerName: 'Front',
    type: 'number',
    valueFormatter: (params) => formatCurrency(params.value),
    flex: 1,
  },
  {
    field: 'totalProductGross',
    headerName: 'Product',
    type: 'number',
    valueFormatter: (params) => formatCurrency(params.value),
    flex: 1,
  },
  {
    field: 'totalFIIncome',
    headerName: 'Reserve',
    type: 'number',
    valueFormatter: (params) => {
      if (params.value) {
        return formatCurrency(params.value);
      } else {
        return formatCurrency(0);
      }
    },
    flex: 1,
  },
  {
    field: 'totalBackGross',
    headerName: 'BEG',
    type: 'number',
    valueFormatter: (params) => formatCurrency(params.value),
    flex: 1,
  },
  {
    field: 'totalGrossProfit',
    headerName: 'Total',
    type: 'number',
    valueFormatter: (params) => formatCurrency(params.value),
    flex: 1,
  },
  {
    field: 'frontGrossPVR',
    headerName: 'Front',
    type: 'number',
    valueGetter: (params) =>
      params.row.totalUnitsSold === 0
        ? 0
        : params.row.totalFrontGross / params.row.totalUnitsSold,
    valueFormatter: (params) => {
      if (params.id === 'Totals' || params.id === 'Averages') {
        return ''; // or return a specific value/formatting for these rows
      }
      return formatCurrency(params.value);
    },
  },
  {
    field: 'backGrossPVR',
    headerName: 'Back',
    type: 'number',
    valueGetter: (params) =>
      params.row.totalUnitsSold === 0
        ? 0
        : params.row.totalBackGross / params.row.totalUnitsSold,
    valueFormatter: (params) => {
      if (params.id === 'Totals' || params.id === 'Averages') {
        return ''; // or return a specific value/formatting for these rows
      }
      return formatCurrency(params.value);
    },
  },
  {
    field: 'totalGrossPVR',
    headerName: 'Total',
    type: 'number',
    valueGetter: (params) =>
      params.row.totalUnitsSold === 0
        ? 0
        : params.row.totalGrossProfit / params.row.totalUnitsSold,
    valueFormatter: (params) => {
      if (params.id === 'Totals' || params.id === 'Averages') {
        return ''; // or return a specific value/formatting for these rows
      }
      return formatCurrency(params.value);
    },
  },
];

export default columns;
