import React, { useState, useCallback, forwardRef } from 'react';
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import PricingPlan from './pricingplans';
import TaxesAndFees from './taxesandfees';
import LenderSettings from './lendersettings';
import FinanceSettings from './financesettings';
import LeaseSettings from './leasesettings';
import Contracts from './contracts';
import Rebates from './rebates';
import Weowes from './weowes';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const renderSection = (title, Component, deskingData, handleFieldChange) => (
  <Card
    sx={{
      marginBottom: 2,
      borderRadius: 2,
      boxShadow: 3,
      ':hover': {
        boxShadow: 5,
      },
    }}
  >
    <CardContent>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Component
        deskingData={deskingData}
        handleFieldChange={handleFieldChange}
      />
    </CardContent>
  </Card>
);

export default function DeskingDialog({
  open,
  setOpen,
  formData,
  handleFormData,
}) {
  const [deskingData, setDeskingData] = useState(formData);

  const handleUpdate = useCallback(() => {
    console.log('deskingData', deskingData);
    handleFormData({ ...deskingData });
    setOpen(false);
  }, [deskingData, handleFormData, setOpen]);

  //const resetForm = useCallback(() => setDeskingData(initialFormData), []);

  const handleFieldChange = useCallback((fieldName, newValue) => {
    setDeskingData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
  }, []);

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'fixed', backgroundColor: 'primary.main' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Customize Quote
          </Typography>
          <Button variant="outlined" color="inherit" onClick={handleUpdate}>
            Update
          </Button>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Box p={2}>
        <Grid container spacing={3}>
          {/* Grid layout for sections */}
          <Grid item xs={12} sm={4}>
            {renderSection(
              'Pricing Plan',
              PricingPlan,
              deskingData,
              handleFieldChange,
            )}
            {renderSection(
              'Lender Settings',
              LenderSettings,
              deskingData,
              handleFieldChange,
            )}
            {renderSection(
              'Taxes and Fees',
              TaxesAndFees,
              deskingData,
              handleFieldChange,
            )}
            {renderSection('Rebates', Rebates, deskingData, handleFieldChange)}
          </Grid>
          <Grid item xs={12} sm={4}>
            {renderSection(
              'Finance Settings',
              FinanceSettings,
              deskingData,
              handleFieldChange,
            )}
            {renderSection(
              'Lease Settings',
              LeaseSettings,
              deskingData,
              handleFieldChange,
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            {renderSection('We Owe', Weowes, deskingData, handleFieldChange)}
            {renderSection(
              'Contracts',
              Contracts,
              deskingData,
              handleFieldChange,
            )}
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
