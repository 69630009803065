import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
  useTheme,
  Typography,
} from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import * as Icons from '@mui/icons-material';

const MenuItems = React.memo(({ items, open }) => {
  const [openItems, setOpenItems] = useState({});
  const theme = useTheme();
  const location = useLocation();

  const handleClick = (itemLabel) => {
    setOpenItems((prev) => ({ ...prev, [itemLabel]: !prev[itemLabel] }));
  };

  const isActive = (path) => location.pathname === path;

  const hasActiveNestedItem = (item) => {
    if (item.items) {
      return item.items.some(
        (subItem) => isActive(subItem.to) || hasActiveNestedItem(subItem),
      );
    }
    return false;
  };

  const renderMenuItems = (menuItems, depth = 0) => {
    return menuItems.map((item, index) => {
      if (item.visible === false) return null;

      const isNested = item.items && item.items.length > 0;
      const active = isActive(item.to);
      const hasActiveNested = hasActiveNestedItem(item);
      const isDesking = item.label === 'Desking';

      const listItemButton = (
        <ListItemButton
          component={isNested ? 'div' : NavLink}
          to={isNested ? undefined : item.to}
          onClick={isNested ? () => handleClick(item.label) : item.onClick}
          target={isDesking ? '_blank' : undefined}
          rel={isDesking ? 'noopener noreferrer' : undefined}
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
            pl: open ? `${(depth + 1) * 16}px` : undefined,
            color:
              active || hasActiveNested
                ? theme.palette.primary.main
                : 'inherit',
            borderRight: active ? '2px solid' : 'none',
            backgroundColor:
              active && open
                ? `${theme.palette.primary.main}20`
                : 'transparent',
          }}
        >
          {depth === 0 && (
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
                color:
                  active || hasActiveNested
                    ? theme.palette.primary.main
                    : 'inherit',
              }}
            >
              {item.Icon ? <item.Icon /> : <Icons.CircleTwoTone />}
            </ListItemIcon>
          )}
          <ListItemText
            primary={item.label}
            primaryTypographyProps={{
              variant: depth > 0 ? 'caption' : 'body2',
            }}
            sx={{
              opacity: open ? 1 : 0,
              color:
                active || hasActiveNested
                  ? theme.palette.primary.main
                  : 'inherit',
            }}
          />
          {isNested && open && (
            <Icons.ExpandMoreTwoTone
              sx={{
                transform: openItems[item.label]
                  ? 'rotate(-180deg)'
                  : 'rotate(0)',
                transition: '0.2s',
                color:
                  active || hasActiveNested
                    ? theme.palette.primary.main
                    : 'inherit',
              }}
            />
          )}
        </ListItemButton>
      );

      const tooltipContent = isNested ? (
        <List>
          {item.items.map((subItem, subIndex) => {
            const subActive = isActive(subItem.to);
            return (
              <ListItem key={subIndex} disablePadding dense>
                <ListItemButton
                  component={NavLink}
                  to={subItem.to}
                  onClick={subItem.onClick}
                  sx={{
                    color: subActive ? theme.palette.primary.main : 'inherit',
                  }}
                >
                  <ListItemText
                    primary={subItem.label}
                    primaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Typography variant="caption">{item.label}</Typography>
      );

      return (
        <ListItem
          key={index}
          disablePadding
          sx={{ display: 'block' }}
          component="div"
        >
          {open ? (
            listItemButton
          ) : (
            <Tooltip
              title={tooltipContent}
              placement="right"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    '& .MuiTooltip-arrow': {
                      color: theme.palette.background.paper,
                    },
                    boxShadow: theme.shadows[1],
                  },
                },
              }}
            >
              {listItemButton}
            </Tooltip>
          )}
          {isNested && open && (
            <Collapse in={openItems[item.label]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderMenuItems(item.items, depth + 1)}
              </List>
            </Collapse>
          )}
        </ListItem>
      );
    });
  };

  return <List>{renderMenuItems(items)}</List>;
});

export default MenuItems;
