import axios from 'axios';
import { API_BASE_URL } from '../config';

export const fetchData = async (dealerid, startDate, endDate) => {
  try {
    const url = `${API_BASE_URL}/fisaleshistory/${dealerid}`;
    const params = new URLSearchParams();
    if (startDate && endDate) {
      params.append('startDate', startDate);
      params.append('endDate', endDate);
    }
    const response = await axios.get(url, { params: params });
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
