import React from 'react';

// DataGrid components
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { Box } from '@mui/material';

import { formatDate } from 'utils/dateUtils';
import { formatCurrency } from 'utils/numberUtils';

const DealsDialog = ({ salesperson, data = [] }) => {
  if (!salesperson) return null;

  // Filter the deals related to the salesperson.
  const salespersonDeals = data
    .filter(
      (item) =>
        item.CRMSP1Name === salesperson.id ||
        item.CRMSP2Name === salesperson.id ||
        item.CRMSP3Name === salesperson.id,
    )
    .map((deal) => ({
      ...deal,
      salesPeople: [deal.CRMSP1Name, deal.CRMSP2Name, deal.CRMSP3Name].filter(
        Boolean,
      ), // This ensures that only existing names are added
    }));

  const columns = [
    {
      field: 'ContractDate',
      headerName: 'Contract Date',
      width: 150,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: 'Credit',
      headerName: 'Credit',
      width: 110,
      valueGetter: (params) => (1 / params.row.salesPeople.length).toFixed(2),
    },
    { field: 'CRMSP1Name', headerName: 'SP1', width: 110 },
    { field: 'CRMSP2Name', headerName: 'SP2', width: 110 },
    { field: 'CRMSP3Name', headerName: 'SP3', width: 110 },
    { field: 'CRMSalesMgrName', headerName: 'Sales Manager', width: 150 },
    { field: 'CRMFIMgrName', headerName: 'FI Manager', width: 150 },
    { field: 'WAQNumber', headerName: 'Deal No.', width: 130 },
    { field: 'DealType', headerName: 'Deal Type', width: 130 },
    { field: 'StockNo', headerName: 'Stock No', width: 130 },
    { field: 'Year', headerName: 'Year', width: 110 },
    { field: 'MakeName', headerName: 'Make', width: 130 },
    { field: 'ModelName', headerName: 'Model', width: 130 },
    {
      field: 'FrontGross',
      headerName: 'Front Gross',
      width: 130,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: 'BackGross',
      headerName: 'Back Gross',
      width: 130,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: 'TotalGross',
      headerName: 'Total Gross',
      width: 130,
      valueFormatter: (params) => formatCurrency(params.value),
    },
  ];

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
      }}
    >
      <DataGrid
        sx={{
          backgroundColor: 'background.paper',
        }}
        density="compact"
        disableColumnFilter
        disableDensitySelector
        rows={salespersonDeals}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        hideFooter
        initialState={{
          columns: {
            columnVisibilityModel: {
              CRMSP2Name: false,
              CRMSP3Name: false,
            },
          },
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </Box>
  );
};

export default DealsDialog;
