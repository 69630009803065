import { formatDate } from 'utils/dateUtils';
import { formatCurrency } from 'utils/numberUtils';

const columns = [
  {
    field: 'ContractDate',
    headerName: 'Contract Date',
    width: 150,
    valueFormatter: (params) => formatDate(params.value),
  },
  {
    field: 'WAQNumber',
    headerName: 'Deal Number',
    width: 120,
  },
  {
    field: 'FiWipStatusCode',
    headerName: 'Status',
    width: 100,
  },
  {
    field: 'SaleType',
    headerName: 'Sales Type',
    width: 120,
  },
  {
    field: 'Name',
    headerName: 'Customer',
    width: 150,
  },
  {
    field: 'StockNo',
    headerName: 'Stock No.',
    width: 120,
  },
  {
    field: 'FIDealType',
    headerName: 'Vehicle Type',
    width: 120,
  },
  {
    field: 'Year',
    headerName: 'Year',
    width: 75,
  },
  {
    field: 'MakeName',
    headerName: 'Make',
    width: 100,
  },
  {
    field: 'ModelName',
    headerName: 'Model',
    width: 150,
  },
  {
    field: 'CRMSP1Name',
    headerName: 'Sls 1',
    width: 150,
  },
  {
    field: 'CRMSP2Name',
    headerName: 'Sls 2',
    width: 150,
  },
  {
    field: 'CRMFIMgrName',
    headerName: 'F&I Manager',
    width: 150,
  },
  {
    field: 'CRMSalesMgrName',
    headerName: 'Sales Manager',
    width: 150,
  },
  {
    field: 'FrontGross',
    headerName: 'FEG',
    width: 150,
    valueFormatter: (params) => formatCurrency(params.value),
  },
  {
    field: 'BackGross',
    headerName: 'BEG',
    width: 150,
    valueFormatter: (params) => formatCurrency(params.value),
  },
  {
    field: 'TotalGross',
    headerName: 'Total Gross',
    width: 150,
    valueFormatter: (params) => formatCurrency(params.value),
  },
  {
    field: 'CRMCommisionSP1',
    headerName: 'SP1 Comm',
    type: 'number',
    valueFormatter: (params) => formatCurrency(params.value),
    width: 150,
  },
  {
    field: 'CRMCommisionSP2',
    headerName: 'SP2 Comm',
    type: 'number',
    valueFormatter: (params) => formatCurrency(params.value),
    width: 150,
  },
  {
    field: 'CRMCommisionSP3',
    headerName: 'SP3 Comm',
    type: 'number',
    valueFormatter: (params) => formatCurrency(params.value),
    width: 150,
  },
  {
    field: 'CRMCommisionClosingMgr',
    headerName: 'ClosingMgr Comm',
    type: 'number',
    valueFormatter: (params) => formatCurrency(params.value),
    width: 150,
  },
  {
    field: 'CRMCommisionFIMgr',
    headerName: 'FIMgr Comm',
    type: 'number',
    valueFormatter: (params) => formatCurrency(params.value),
    width: 150,
  },
  {
    field: 'CRMCommisionSalesMgr',
    headerName: 'SalesMgr Comm',
    type: 'number',
    valueFormatter: (params) => formatCurrency(params.value),
    width: 150,
  },
  {
    field: 'CRMCommisionTotal',
    headerName: 'Total Comm',
    type: 'number',
    valueFormatter: (params) => formatCurrency(params.value),
    width: 150,
  },
];

export default columns;
