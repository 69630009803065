import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const sortOptions = {
  frontiertoyota_production_inventory_specials_oem_price: 'Recommended',
  frontiertoyota_production_inventory_low_to_high: 'Lowest Price',
  frontiertoyota_production_inventory_mileage_low_to_high: 'Lowest Mileage',
  frontiertoyota_production_inventory_year_high_to_low: 'Newest Year',
  frontiertoyota_production_inventory_days_in_stock_low_to_high:
    'Lowest Days in Stock',
};

const CustomSort = ({ sortOrder, setSortOrder }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenuText, setSelectedMenuText] = useState(sortOrder);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleSortChange = (value) => {
    setSelectedMenuText(value);
    setSortOrder(value);
    handleClose();
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        endIcon={<SwapVertIcon />}
      >
        {sortOptions[selectedMenuText]}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {Object.entries(sortOptions).map(([value, label]) => (
          <MenuItem
            key={value}
            onClick={() => handleSortChange(value)}
            selected={value === sortOrder}
            style={{ fontSize: '0.875em' }}
          >
            <CheckIcon
              sx={{
                marginRight: '14px',
                fontSize: '1.25em',
                visibility: value === sortOrder ? 'visible' : 'hidden',
              }}
            />
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CustomSort;
