// --- LIBRARY IMPORTS ---
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth0 } from '@auth0/auth0-react';
import { Formik, Form, Field } from 'formik';

// --- LOCAL IMPORTS ---
import { API_BASE_URL } from 'config';
import { useTeams } from 'hooks/useTeams';
import departments from 'data/departments';
import LoadingComponent from 'components/misc/LoadingComponent';

// --- MATERIAL UI IMPORTS ---
import { DataGridPro, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TableContainer,
} from '@mui/material';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@mui/icons-material';

// Constants
const SORTED_DEPARTMENTS = departments
  .slice()
  .sort((a, b) => a.localeCompare(b));

// Components
const ActionButtons = ({ onEdit, onDelete }) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <EditIcon
      color="primary"
      onClick={onEdit}
      style={{ cursor: 'pointer', marginRight: 8 }}
    />
    <DeleteIcon
      color="error"
      onClick={onDelete}
      style={{ cursor: 'pointer' }}
    />
  </div>
);

const TeamTable = () => {
  const { isAuthenticated, user } = useAuth0();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteTeamId, setDeleteTeamId] = useState(null);
  const [editTeamId, setEditTeamId] = useState(null); // New state for edit
  const [initialValues, setInitialValues] = useState({}); // New state for form initial values
  const [teamsData, setTeamsData] = useState([]); // New state for team data

  const {
    data: teamsAPIData,
    isLoading: isLoadingTeams,
    error: teamsError,
    refetch: teamsRefetch,
  } = useTeams(user?.dealerid);

  if (teamsError) {
    console.error('Error loading teams:', teamsError.message);
  }

  useEffect(() => {
    if (!isLoadingTeams) {
      setTeamsData(teamsAPIData);
    }
  }, [teamsAPIData, isLoadingTeams]);

  const columns = [
    {
      field: 'name',
      headerName: 'Team Name',
      flex: 1, // Make the column fill available space
    },
    {
      field: 'department',
      headerName: 'Department',
      flex: 1, // Make the column fill available space
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <ActionButtons
          onEdit={() => handleEdit(params.row)}
          onDelete={() => handleDelete(params.row._id)}
        />
      ),
    },
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Team Name is required'),
    department: Yup.string().required('Department is required'),
  });

  const handleOpenAddDialog = () => {
    setEditTeamId(null);
    setInitialValues({ name: '', department: '' }); // Set initial values to empty
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setEditTeamId(null);
    setOpenAddDialog(false);
  };

  // Handle adding a new team
  const handleAddTeam = async (values) => {
    try {
      const teamData = {
        ...values,
        dealerID: user.dealerid, // Include dealerID from user
      };

      const response = await fetch(`${API_BASE_URL}/api/teams`, {
        // Use API_BASE_URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(teamData),
      });

      if (response.ok) {
        // Team added successfully
        console.log('Team added successfully');
        // Reset form data and close dialog
        handleCloseAddDialog();
        // Refresh the team data
        teamsRefetch();
      } else {
        // Handle error response
        const responseData = await response.json();
        console.error('Failed to add team:', responseData.message);
      }
    } catch (error) {
      console.error('Error while adding team:', error.message);
    }
  };

  // Mutation function for deleting an team
  const deleteTeam = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/teams/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(responseData.message);
      }
      teamsRefetch();
    } catch (error) {
      throw new Error(`Error while deleting team: ${error.message}`);
    }
  };

  // Replace your existing handleEdit and handleDelete functions with these mutations

  const handleEdit = (team) => {
    // Populate the form with the existing team data
    const initialValues = {
      name: team.name,
      department: team.department,
    };

    setEditTeamId(team._id); // Set the ID of the team being edited
    setInitialValues(initialValues); // Set the form initial values
    setOpenAddDialog(true); // Open the dialog for editing
  };

  const handleEditTeam = async (teamId, values) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/teams/${teamId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        // Team updated successfully
        console.log('Team updated successfully');
        handleCloseAddDialog(); // Close the dialog after update
        teamsRefetch(); // Refresh the team data
      } else {
        // Handle error response
        const responseData = await response.json();
        console.error('Failed to update team:', responseData.message);
      }
    } catch (error) {
      console.error('Error while updating team:', error.message);
    }
  };

  const handleDelete = (id) => {
    setConfirmDeleteOpen(true);
    setDeleteTeamId(id); // Set the team ID to delete
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteTeam.mutateAsync(deleteTeamId);
      console.log('Team deleted successfully');
      setConfirmDeleteOpen(false); // Close the confirmation dialog
    } catch (error) {
      console.error('Failed to delete team:', error.message);
    }
  };

  return (
    isAuthenticated && (
      <>
        <TableContainer>
          {!isLoadingTeams && teamsData ? (
            <DataGridPro
              rows={teamsData}
              columns={columns}
              getRowId={(row) => row._id} // Define the unique 'id' property for each row
              slots={{
                toolbar: () => {
                  return (
                    <Box display="flex" alignItems="center" p={2}>
                      <Box display="flex" alignItems="baseline">
                        <Button
                          onClick={handleOpenAddDialog}
                          startIcon={<AddIcon />}
                          variant="text"
                        >
                          Add Team
                        </Button>
                      </Box>
                      <Box sx={{ flexGrow: 1 }} />
                      <Box>
                        <GridToolbarQuickFilter />
                      </Box>
                    </Box>
                  );
                }, // Hide the default toolbar
              }}
              loading={isLoadingTeams}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              disableRowSelectionOnClick
            />
          ) : (
            <LoadingComponent />
          )}
        </TableContainer>

        {/* Add Team Dialog */}
        <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
          <DialogTitle>{editTeamId ? 'Edit Team' : 'Add Team'}</DialogTitle>
          <DialogContent>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema} // Set the validation schema
              onSubmit={(values, { resetForm }) => {
                if (editTeamId) {
                  handleEditTeam(editTeamId, {
                    ...values,
                  });
                } else {
                  handleAddTeam(values);
                }
                resetForm();
                setInitialValues({}); // Reset the initialValues state to clear the dialog
              }}
            >
              {({ handleSubmit, values, errors, touched }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        type="text"
                        placeholder="Team Name"
                        name="name"
                        as={TextField}
                        fullWidth
                        variant="outlined"
                        value={values.name}
                        error={touched.name && !!errors.name}
                      />
                      <FormHelperText error={touched.name && !!errors.name}>
                        {touched.name && errors.name}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="department">Department</InputLabel>
                        <Field
                          as={Select}
                          name="department"
                          label="Department"
                          value={values.department}
                          error={touched.department && !!errors.department}
                        >
                          {SORTED_DEPARTMENTS.map((department) => (
                            <MenuItem key={department} value={department}>
                              {department}
                            </MenuItem>
                          ))}
                        </Field>
                        <FormHelperText
                          error={touched.department && !!errors.department}
                        >
                          {touched.department && errors.department}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button onClick={handleCloseAddDialog}>Cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      {editTeamId ? 'Save' : 'Add'}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={confirmDeleteOpen}
          onClose={() => setConfirmDeleteOpen(false)}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this team?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
            <Button onClick={handleConfirmDelete} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  );
};

export default TeamTable;
