import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  Chip,
  MenuItem,
} from '@mui/material';
import { financeTypes } from '../constants';

export default function FinanceSettings({ deskingData, handleFieldChange }) {
  const [financeTerms, setFinanceTerms] = useState(deskingData.financeterms);

  const [downPayments, setDownPayments] = useState(deskingData.downpayments);

  useEffect(() => {
    setFinanceTerms(deskingData.financeterms);
    setDownPayments(deskingData.downpayments);
  }, [deskingData]);

  // Handlers
  const handleDownpaymentsChange = (event, newValue) => {
    // Filter to allow only valid numeric values (including decimals)
    const validDownpayments = newValue.filter((value) => {
      const num = parseFloat(value);
      return !isNaN(num) && num.toString() === value.toString();
    });

    setDownPayments(validDownpayments);
    handleFieldChange('downpayments', validDownpayments);
  };

  const handleTermsChange = (event, newValue) => {
    const validTerms = newValue.filter((value) => {
      const num = parseInt(value, 10);
      return !isNaN(num) && num >= 12 && num <= 96 && Number.isInteger(num);
    });

    setFinanceTerms(validTerms);
    handleFieldChange('financeterms', validTerms);
  };

  return (
    <Box>
      <Grid container spacing={3}>
        {/* Rate Plan */}
        <Grid item xs={12}>
          <TextField
            id="financetype"
            name="financetype"
            select
            label="Rate Plan"
            value={deskingData.financetype}
            variant="outlined"
            fullWidth
            onChange={(event) =>
              handleFieldChange('financetype', event.target.value)
            }
          >
            {financeTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Down Payments */}
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="downpayments"
            freeSolo
            options={[]}
            value={downPayments}
            onChange={handleDownpaymentsChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Down Payments"
                placeholder="Enter Down Payments"
              />
            )}
          />
        </Grid>

        {/* Finance Terms */}
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="finance-terms"
            freeSolo
            options={[]}
            value={financeTerms}
            onChange={handleTermsChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Finance Terms"
                placeholder="Enter terms"
              />
            )}
          />
        </Grid>

        {/* Days to 1st Payment */}
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="daystofirstpayment"
            name="daystofirstpayment"
            value={deskingData.daystofirstpayment}
            label="Days to 1st Payment"
            type="number"
            required
            variant="outlined"
            onChange={(event) =>
              handleFieldChange('daystofirstpayment', event.target.value)
            }
          />
        </Grid>

        {/* Reserve */}
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="financereserve"
            name="financereserve"
            value={deskingData.financereserve}
            label="Reserve"
            type="number"
            required
            variant="outlined"
            InputProps={{
              inputProps: { min: 0, max: 2.5, step: 0.01 },
            }}
            onChange={(event) =>
              handleFieldChange('financereserve', event.target.value)
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}
