import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { format, parseISO } from 'date-fns';
import {
  DataGridPremium,
  GridToolbar,
  GridToolbarQuickFilter,
  useGridApiRef,
  useGridSelector,
  useGridApiContext,
  gridRowsLookupSelector,
  gridDetailPanelExpandedRowIdsSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';

import {
  Avatar,
  Box,
  Divider,
  Stack,
  Paper,
  Typography,
  IconButton,
  Button,
} from '@mui/material';

import { yellow, amber, orange, deepOrange, red } from '@mui/material/colors';

import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import dayjs from 'dayjs';
import CalendarDateRangePicker from 'components/DateRangePicker';
import { API_BASE_URL } from 'config';
import axios from 'axios';

const RepairOrderTable = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [dateRange, setDateRange] = React.useState([
    dayjs().startOf('month'),
    dayjs(),
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const apiRef = useGridApiRef();

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${API_BASE_URL}/api/repair-orders`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            dealerID: user.dealerid,
            startDate: dateRange[0].format('YYYY-MM-DD'),
            endDate: dateRange[1].format('YYYY-MM-DD'),
          },
        });
        const data = response.data;
        // Need to flatten the vehicle object to be able to display it in the grid
        data.forEach((row) => {
          let vehicle = row.vehicle;
          if (vehicle) {
            row.year = vehicle.year || '';
            row.make = vehicle.makeDesc || '';
            row.model = vehicle.modelDesc || '';
            row.vehId = vehicle.vehId || '';
          }
        });
        setData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dateRange, getAccessTokenSilently, user.dealerid]);

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const getDetailPanelContent = React.useCallback(
    ({ row }) => (
      <Box
        sx={{
          p: 2,
          background: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
        }}
      >
        {`Comments: ${row.comments}`}
      </Box>
    ),
    [],
  );

  function CustomDetailPanelHeader() {
    const apiRef = useGridApiContext();

    const expandedRowIds = useGridSelector(
      apiRef,
      gridDetailPanelExpandedRowIdsSelector,
    );
    const rowsWithDetailPanels = useGridSelector(
      apiRef,
      gridDetailPanelExpandedRowsContentCacheSelector,
    );

    const noDetailPanelsOpen = expandedRowIds.length === 0;

    const expandOrCollapseAll = () => {
      const dataRowIdToModelLookup = gridRowsLookupSelector(apiRef);
      const allRowIdsWithDetailPanels = Object.keys(rowsWithDetailPanels).map(
        (key) => apiRef.current.getRowId(dataRowIdToModelLookup[key]),
      );

      apiRef.current.setExpandedDetailPanels(
        noDetailPanelsOpen ? allRowIdsWithDetailPanels : [],
      );
    };

    const Icon = noDetailPanelsOpen ? UnfoldMoreIcon : UnfoldLessIcon;

    return (
      <IconButton
        size="small"
        tabIndex={-1}
        onClick={expandOrCollapseAll}
        aria-label={noDetailPanelsOpen ? 'Expand All' : 'Collapse All'}
      >
        <Icon fontSize="inherit" />
      </IconButton>
    );
  }

  const handleCustomerClick = (row) => {
    setSelectedRow(row);
    setOpenDialog(true);
  };

  const columns = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderHeader: () => <CustomDetailPanelHeader />,
    },
    {
      field: 'age',
      headerName: 'Age',
      width: 75,
      sortable: true,
      valueGetter: (value, row) => {
        const originalDate = row.openDate;
        if (!originalDate) return null;
        const parsedDate = parseISO(originalDate.split('T')[0]);
        const formattedDate = format(parsedDate, 'MM/dd/yyyy');
        return dayjs().diff(dayjs(formattedDate), 'days');
      },
      renderCell: (params) => {
        // Color the avatar based on the age of the repair order
        const age = params.value;
        let bgcolor = 'default';
        let color = 'default';
        if (age >= 3 && age <= 5) {
          bgcolor = yellow[500];
          color = 'black';
        } else if (age >= 6 && age <= 10) {
          bgcolor = amber[500];
        } else if (age >= 11 && age <= 15) {
          bgcolor = orange[500];
        } else if (age >= 16 && age <= 20) {
          bgcolor = deepOrange[500];
        } else if (age > 20) {
          bgcolor = red[500];
        }
        return (
          <div
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            <Avatar
              sx={{ bgcolor, color, width: 24, height: 24, fontSize: 12 }}
            >
              {age}
            </Avatar>
          </div>
        );
      },
    },
    {
      field: 'openDate',
      headerName: 'Open Date',
      width: 110,
      sortable: true,
      renderCell: (params) => {
        const originalDate = params.row.openDate;
        if (!originalDate) return null;
        const parsedDate = parseISO(originalDate.split('T')[0]);
        const formattedDate = format(parsedDate, 'MM/dd/yyyy');
        return <span>{formattedDate}</span>;
      },
    },
    {
      field: 'roNumber',
      headerName: 'RO Number',
      width: 100,
      groupable: false,
    },
    { field: 'tagNo', headerName: 'Tag', width: 100, groupable: false },
    { field: 'statusDesc', headerName: 'Status', width: 120 },
    { field: 'serviceAdvisor', headerName: 'Advisor', width: 120 },
    {
      field: 'customer',
      headerName: 'Customer',
      width: 200,
      groupable: false,
      renderCell: (params) => {
        const customer = params.value;
        if (!customer) return null;
        return (
          <Button onClick={() => handleCustomerClick(params.row)}>
            {customer.name1}
          </Button>
        );
      },
    },
    {
      field: 'apptFlag',
      headerName: 'Appt',
      width: 25,
      renderCell: (params) => {
        const apptFlag = params.value;
        if (!apptFlag) return null;
        return <div>{apptFlag ? 'Y' : 'N'}</div>;
      },
    },
    {
      field: 'isCustomerWaiting',
      headerName: 'Wait',
      width: 25,
      renderCell: (params) => {
        const isCustomerWaiting = params.value;
        if (!isCustomerWaiting) return null;
        return <div>{isCustomerWaiting ? 'Y' : 'N'}</div>;
      },
    },
    {
      field: 'lastServiceDate',
      headerName: 'Last Svc',
      width: 150,
      renderCell: (params) => {
        const originalDate = params.row.lastServiceDate;
        if (!originalDate) return null;
        const parsedDate = parseISO(originalDate.split('T')[0]);
        const formattedDate = format(parsedDate, 'MM/dd/yyyy');
        return <span>{formattedDate}</span>;
      },
    },
    {
      field: 'year',
      headerName: 'Year',
      width: 75,
    },
    {
      field: 'make',
      headerName: 'Make',
      width: 100,
    },
    {
      field: 'model',
      headerName: 'Model',
      width: 125,
    },
    {
      field: 'vehId',
      headerName: 'Veh ID',
      width: 100,
      groupable: false,
    },
  ];

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    sorting: {
      sortModel: [{ field: 'age', sort: 'desc' }],
    },
  });

  return (
    isAuthenticated && (
      <Paper
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 2,
          }}
        >
          <Typography variant="h6">Real-Time Service RO's</Typography>
          <CalendarDateRangePicker
            dateRange={dateRange}
            onDateRangeChange={handleDateRangeChange}
          />
        </Box>
        <DataGridPremium
          apiRef={apiRef}
          sx={{
            borderRadius: 0,
            border: 'none',
          }}
          density="compact"
          rows={data}
          columns={columns}
          loading={isLoading}
          initialState={initialState}
          getRowId={(row) => row._id}
          slots={{
            toolbar: () => {
              return (
                <>
                  <Stack direction="row" spacing={2} alignItems="center" p={2}>
                    <GridToolbar />
                    <Box sx={{ flexGrow: 1 }} />
                    <GridToolbarQuickFilter />
                  </Stack>
                  <Divider />
                </>
              );
            },
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={({ row }) => 'auto'}
          disableRowSelectionOnClick
        />
      </Paper>
    )
  );
};

export default RepairOrderTable;
